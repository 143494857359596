import gql from 'graphql-tag';

/**
 * 获得授权专利列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const authorizedPatents = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		authorizedPatents (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                impowerdate
                image 
                shenhe
                reason
                type{
                    id
                    dictionary_code
                    name{
                        zh
                        mn
                        en
                    }
                }
                name  {
                    zh
                    mn
                    en
                }
                impowernumber{
                    zh
                    mn
                    en
                }
                inventor {
                    en
                    mn
                    zh
                }
                nation{
                    en
                    mn
                    zh
                }
                created_by
            }
		}
	}
`;
// /**
//  * 获得授权专利单条数据
//  */
const findauthorizedPatent = gql`
	query ($id: Int!){
		authorizedPatent (id: $id) {
            id
            teacher_id 
            impowerdate
            image 
            shenhe
            type{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            name  {
                zh
                mn
                en
            }
            impowernumber{
                zh
                mn
                en
            }
            inventor {
                en
                mn
                zh
            }
            nation{
                en
                mn
                zh
            }
            created_by
		}
	}
`;
// /**
//  * 获得授权专利添加数据
//  */
const addAuthorizedPatent = gql`
	mutation addAuthorizedPatent($inputData: AuthorizedPatentInput){
		addAuthorizedPatent(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 获得授权专利删除数据
 */
const deleteAuthorizedPatent = gql`
    mutation deleteAuthorizedPatent($id: [Int]!){
        deleteAuthorizedPatent(id:$id){
            code
            msg
        }
    }
`;


/**
 * 论著和译著列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const translatedWorks = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		translatedWorks (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                authornumber {
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                impowerdate  
                wordsnumber 
                image
                shenhe
                reason
                booktitle  {
                    zh
                    mn
                    en
                }
                pressname{
                    zh
                    mn
                    en
                }
                created_by
            }
		}
	}
`;
// /**
//  * 论著和译著单条数据
//  */
const findtranslatedWork= gql`
	query ($id: Int!){
		translatedWork (id: $id) {
            id
            teacher_id 
            authornumber{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            } 
            impowerdate  
            wordsnumber 
            image
            shenhe
            booktitle  {
                zh
                mn
                en
            }
            pressname{
                zh
                mn
                en
            }
            created_by
		}
	}
`;
// /**
//  * 论著和译著添加数据
//  */
const addTranslatedWorks = gql`
	mutation addTranslatedWorks($inputData: TranslatedWorksInput){
		addTranslatedWorks(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 论著和译著删除数据
 */
const deleteTranslatedWorks = gql`
    mutation deleteTranslatedWorks($id: [Int]!){
        deleteTranslatedWorks(id:$id){
            code
            msg
        }
    }
`;


/**
 * 承担课题项目列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const undertakeProjects = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		undertakeProjects (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                time 
                entime   
                fund 
                num 
                image
                shenhe
                reason
                name{
                    zh
                    mn
                    en
                }
                unit{
                    zh
                    mn
                    en
                }
                source{
                    zh
                    mn
                    en
                }
                inputstyle{
                    zh
                    mn
                    en
                }
                roal{
                    zh
                    mn
                    en
                }
                created_by
            }
		}
	}
`;
// /**
//  * 承担课题项目单条数据
//  */
const findundertakeProject= gql`
	query ($id: Int!){
		undertakeProject (id: $id) {
            id
            teacher_id 
            time 
            entime   
            fund 
            num 
            image
            image1
            shenhe
            name{
                zh
                mn
                en
            }
            unit{
                zh
                mn
                en
            }
            source{
                zh
                mn
                en
            }
            inputstyle{
                zh
                mn
                en
            }
            roal{
                zh
                mn
                en
            }
            created_by
		}
	}
`;
// /**
//  * 承担课题项目添加数据
//  */
const addUndertakeProject = gql`
	mutation addUndertakeProject($inputData: UndertakeProjectInput){
		addUndertakeProject(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 承担课题项目删除数据
 */
const deleteUndertakeProject = gql`
    mutation deleteUndertakeProject($id: [Int]!){
        deleteUndertakeProject(id:$id){
            code
            msg
        }
    }
`;


/**
 * 学术团体任职情况列表列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const academicOrganizations = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		academicOrganizations (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            
            data {
                id
                teacher_id 
                time 
                entime   
                image
                shenhe
                reason
                name{
                    zh
                    mn
                    en
                }
                position{
                    zh
                    mn
                    en
                }
                
                created_by
            }
		}
	}
`;
// /**
//  * 学术团体任职情况列表单条数据
//  */
const findacademicOrganization= gql`
	query ($id: Int!){
		academicOrganization (id: $id) {
            id
            teacher_id 
            time 
            entime   
            image
            shenhe
            name{
                zh
                mn
                en
            }
            position{
                zh
                mn
                en
            }
            
            created_by
		}
	}
`;
// /**
//  * 学术团体任职情况列表添加数据
//  */
const addAcademicOrganization = gql`
	mutation addAcademicOrganization($inputData: AcademicOrganizationInput){
		addAcademicOrganization(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 学术团体任职情况列表删除数据
 */
const deleteAcademicOrganization = gql`
    mutation deleteAcademicOrganization($id: [Int]!){
        deleteAcademicOrganization(id:$id){
            code
            msg
        }
    }
`;

/**
 * 兼职信息列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const parttimeJobs = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		parttimeJobs (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                property{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                time 
                entime   
                image
                shenhe
                reason
                name{
                    zh
                    mn
                    en
                }
                position{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                
                created_by
            }
		}
	}
`;
// /**
//  * 兼职信息单条数据
//  */
const findparttimeJob= gql`
	query ($id: Int!){
		parttimeJob (id: $id) {
            id
            teacher_id 
            property{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            time 
            entime   
            image
            shenhe
            name{
                zh
                mn
                en
            }
            position{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            created_by
            linkJobName
            classNumber
		}
	}
`;
// /**
//  * 兼职信息添加数据
//  */
const addParttimeJob = gql`
	mutation addParttimeJob($inputData: ParttimeJobInput){
		addParttimeJob(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 兼职信息删除数据
 */
const deleteParttimeJob = gql`
    mutation deleteParttimeJob($id: [Int]!){
        deleteParttimeJob(id:$id){
            code
            msg
        }
    }
`;

/**
 * 留学情况列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const studyAbroads = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		studyAbroads (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                country{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                pub
                time 
                entime  
                back
                projectdate
                projectmoney 
                image
                shenhe
                name{
                    zh
                    mn
                    en
                }
                educational{
                    zh
                    mn
                    en
                }
                project{
                    zh
                    mn
                    en
                }
                created_by
            }
		}
	}
`;
// /**
//  * 留学情况单条数据
//  */
const findstudyAbroad= gql`
	query ($id: Int!){
		studyAbroad (id: $id) {
            id
            teacher_id 
            country{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            pub
            time 
            entime  
            back
            projectdate
            projectmoney 
            image
            shenhe
            name{
                zh
                mn
                en
            }
            educational{
                zh
                mn
                en
            }
            project{
                zh
                mn
                en
            }
            created_by
		}
	}
`;
// /**
//  * 留学情况添加数据
//  */
const addStudyAbroad = gql`
	mutation addStudyAbroad($inputData: StudyAbroadInput){
		addStudyAbroad(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 留学情况删除数据
 */
const deleteStudyAbroad = gql`
    mutation deleteStudyAbroad($id: [Int]!){
        deleteStudyAbroad(id:$id){
            code
            msg
        }
    }
`;

/**
 * 获得资助情况列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const getFundings = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		getFundings (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                fundyear
                time 
                projectmoney  
                image
                shenhe
                name{
                    zh
                    mn
                    en
                }
                fundunit{
                    zh
                    mn
                    en
                }
                fundcontent{
                    zh
                    mn
                    en
                }
                created_by
            }
		}
	}
`;
// /**
//  * 获得资助情况单条数据
//  */
const findgetFunding= gql`
	query ($id: Int!){
		getFunding (id: $id) {
            id
            teacher_id 
            fundyear
            time 
            projectmoney  
            image
            shenhe
            name{
                zh
                mn
                en
            }
            fundunit{
                zh
                mn
                en
            }
            fundcontent{
                zh
                mn
                en
            }
            created_by
		}
	}
`;
// /**
//  * 获得资助情况添加数据
//  */
const addGetFunding = gql`
	mutation addGetFunding($inputData: GetFundingInput){
		addGetFunding(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 获得资助情况删除数据
 */
const deleteGetFunding = gql`
    mutation deleteGetFunding($id: [Int]!){
        deleteGetFunding(id:$id){
            code
            msg
        }
    }
`;

/**
 * 展出和表演列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const showPerformances = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		showPerformances (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
          
            data {
                id
                teacher_id 
                authornumber{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                time 
                image
                shenhe
                reason
                name{
                    zh
                    mn
                    en
                }
                fundunit{
                    zh
                    mn
                    en
                }
                fundcontent{
                    zh
                    mn
                    en
                }
                created_by
            }
		}
	}
`;
// /**
//  * 展出和表演单条数据
//  */
const findshowPerformance= gql`
	query ($id: Int!){
		showPerformance (id: $id) {
            id
            teacher_id 
            authornumber{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            time 
            image
            shenhe
            name{
                zh
                mn
                en
            }
            fundunit{
                zh
                mn
                en
            }
            fundcontent{
                zh
                mn
                en
            }
            created_by
		}
	}
`;
// /**
//  * 展出和表演添加数据
//  */
const addShowPerformance = gql`
	mutation addShowPerformance($inputData: ShowPerformanceInput){
		addShowPerformance(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 展出和表演删除数据
 */
const deleteShowPerformance = gql`
    mutation deleteShowPerformance($id: [Int]!){
        deleteShowPerformance(id:$id){
            code
            msg
        }
    }
`;

/**
 * 团队建设列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const teamBuildings = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		teamBuildings (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                memberid
                memberphone 
                image
                shenhe
                commonproject{
                    zh
                    mn
                    en
                }
                membername{
                    zh
                    mn
                    en
                }
                membereducation{
                    zh
                    mn
                    en
                }
                memberpost{
                    zh
                    mn
                    en
                }
            }
		}
	}
`;
// /**
//  * 团队建设单条数据
//  */
const findteamBuilding= gql`
	query ($id: Int!){
		teamBuilding (id: $id) {
            id
            teacher_id 
            memberid
            memberphone 
            image
            shenhe
            commonproject{
                zh
                mn
                en
            }
            membername{
                zh
                mn
                en
            }
            membereducation{
                zh
                mn
                en
            }
            memberpost{
                zh
                mn
                en
            }
		}
	}
`;
// /**
//  * 团队建设添加数据
//  */
const addTeamBuilding = gql`
	mutation addTeamBuilding($inputData: TeamBuildingInput){
		addTeamBuilding(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 团队建设删除数据
 */
const deleteTeamBuilding = gql`
    mutation deleteTeamBuilding($id: [Int]!){
        deleteTeamBuilding(id:$id){
            code
            msg
        }
    }
`;
export {
    authorizedPatents,
    findauthorizedPatent,
    addAuthorizedPatent,
    deleteAuthorizedPatent,
    translatedWorks,
    findtranslatedWork,
    addTranslatedWorks,
    deleteTranslatedWorks,
    undertakeProjects,
    findundertakeProject,
    addUndertakeProject,
    deleteUndertakeProject,
    academicOrganizations,
    findacademicOrganization,
    addAcademicOrganization,
    deleteAcademicOrganization,
    parttimeJobs,
    findparttimeJob,
    addParttimeJob,
    deleteParttimeJob,
    studyAbroads,
    findstudyAbroad,
    addStudyAbroad,
    deleteStudyAbroad,
    getFundings,
    findgetFunding,
    addGetFunding,
    deleteGetFunding,
    showPerformances,
    findshowPerformance,
    addShowPerformance,
    deleteShowPerformance,
    teamBuildings,
    findteamBuilding,
    addTeamBuilding,
    deleteTeamBuilding
}
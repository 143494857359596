/*
 * @Author: 刘凯龙
 * @Date: 2020-06-29 15:04:03
 * @Last Modified by: mouthliu
 * @Last Modified time: 2020-06-29 15:04:03
 */
import gql from 'graphql-tag';

/**
 * 备课计划添加
 * @param
 */
const addPrepareLessonPlan = gql`
mutation addPrepareLessonPlan(
   $inputData: PrepareLessonPlanInput
){
    addPrepareLessonPlan(
      inputData:$inputData
    ){
      code
      msg
    }
    }
    `
/**
 * 备课添加
 * @param
 */
const addPrepareLesson = gql`
mutation addPrepareLesson(
   $inputData: PrepareLessonInput
){
    addPrepareLesson(
      inputData:$inputData
    ){
        id
        code
        msg
    }
}
    `
/**
 * 备课更新
 * @param
 */
const updatePrepareLesson = gql`
mutation updatePrepareLesson(
   $inputData: PrepareLessonUpdateInput
){
    updatePrepareLesson(
      inputData:$inputData
    ){
      code
      msg
    }
    }
    `
const updatePrepareLessonPlan = gql`
mutation updatePrepareLessonPlan(
   $inputData: PrepareLessonPlanUpdateInput
){
    updatePrepareLessonPlan(
      inputData:$inputData
    ){
      code
      msg
    }
    }
    `
/**
 * 删除备课
 * @param
 */
const deletePrepareLesson = gql`
	mutation ($ids: [Int]!) {
		deletePrepareLesson(ids: $ids) {
			code
			msg
		}
	}
`
/**
 * 删除备课计划
 * @param
 */
const deletePrepareLessonPlan = gql`
	mutation ($ids: [Int]!) {
		deletePrepareLessonPlan(ids: $ids) {
			code
			msg
		}
	}
`
/**
 * 备课计划列表
 * @param page 分页
 * @param limit 每页数据量
 */
const prepareLessonPlans = gql`
	query ($page: Int!, $limit: Int!, $condition:PrepareLessonPlanCondition){
		prepareLessonPlans (page: $page, limit: $limit, condition:$condition ){
            data {
               id
               departmentId
               semesterId
               departmentName{
                    zh
                    mn
                    en
                }
               prepareLessonPlanTitle
               prepareLessonPlanType
               prepareLessonPlanNum
               completedNum
               deadline
               subjectImageMn
                subjectImageZh
                semesterName{
                    zh
                    mn
                    en
                }
                createdByUserName{
                    zh
                    mn
                    en
                }
               taskResponsePeople{
                    teacherName
                    prepareLessonNum
               }
               createdAt
            }
            total
            msg
            code
		}
	}
`
/**
 * 我的任务列表
 * @param page 分页
 * @param limit 每页数据量
 */
const myPrepareLessonPlans = gql`
	query ($page: Int!, $limit: Int!, $condition:PrepareLessonPlanCondition){
		myPrepareLessonPlans (page: $page, limit: $limit, condition:$condition ){
		    code
		    msg
            finishData {
               id
               departmentId
               semesterId
               prepareLessonPlanTitle
               prepareLessonPlanType
               prepareLessonPlanNum
               completedNum
               deadline
               subjectImageMn
                subjectImageZh
                semesterName{
                    zh
                    mn
                    en
                }
                createdByUserName{
                    zh
                    mn
                    en
                }
               taskResponsePeople{
                    teacherName
                    prepareLessonNum
               }
               createdAt
            }
            finishDataTotal
            noFinishData {
               id
               departmentId
               semesterId
               prepareLessonPlanTitle
               prepareLessonPlanType
               prepareLessonPlanNum
               completedNum
               deadline
               semesterName{
                    zh
                    mn
                    en
                }
                createdByUserName{
                    zh
                    mn
                    en
                }
               taskResponsePeople{
                    teacherName
                    prepareLessonNum
               }
               createdAt
            }
            noFinishDataTotal
		}
	}
`
/**
 * 我的备课列表
 * @param page 分页
 * @param limit 每页数据量
 */
const prepareLessons = gql`
	query ($page: Int!, $limit: Int!, $condition:PrepareLessonCondition){
		prepareLessons (page: $page, limit: $limit, condition:$condition ){
            data {
               id
               userId
               prepareLessonPlanId
               prepareLessonTitle
               departmentId
               prepareLessonPlanType
               prepareLessonContent
               prepareLessonAccessories
               subjectImageMn
                subjectImageZh
               semesterId
               lang
               semesterName{
                    zh
                    mn
                    en
                }
                teacherName{
                    zh
                    mn
                    en
                }
               publishType
               createdAt
            }
            total
            msg
            code
		}
	}
`
/**
 * 查询学校单条数据
 */
const prepareLessonPlan = gql`
    query($id: Int!){
        prepareLessonPlan(id: $id) {
            code
            data{
                id
                departmentId
                departmentName{
                    zh
                    mn
                    en
                }
                semesterId
                prepareLessonPlanTitle
                prepareLessonPlanType
                prepareLessonPlanNum
                startline
                semesterName{
                     zh
                     mn
                     en
                 }
                 createdByUserName{
                     zh
                     mn
                     en
                 }
                completedNum
                startline
                deadline
                subjectImageMn
                 subjectImageZh
                taskResponsePeople{
                     teacherName
                     prepareLessonNum
                }
                createdAt
            }
        }
    }
`;
const prepareLesson = gql`
    query($id: Int!) {
        prepareLesson(id: $id) {
            code
            msg
            data {
                id
                prepareLessonPlanId
                prepareLessonTitle
                departmentId
                departmentName{
                    zh
                    mn
                    en
                }
                prepareLessonPlanType
                prepareLessonContent
                prepareLessonAccessories
                prepareLessonContentType
                semesterId
                semesterName {
                    zh
                    mn
                    en
                }
                teacherName{
                    zh
                    mn
                    en
                }
                lang
                publishType
                userId
                createdAt
                updatedAt
            }
        }
    }
`;
/**
 * 学科统计
 */
const homePageSubjectGroupStatistics = gql`
    query($semesterId: Int!){
        homePageSubjectGroupStatistics(semesterId : $semesterId) {
            code
            data{
               teacherName
               teacherUserId
               departmentId
               departmentId
               departmentName
               prepareLessonNum
               accomplishedPreparationPlan
               unaccomplishedPreparationPlan
            }
        }
    }
`;
/**
 * 个人统计
 */
const homePagePersonStatistics = gql`
    query($semesterId: Int!){
        homePagePersonStatistics(semesterId : $semesterId) {
            code
            data{
               teacherName
               teacherUserId
               departmentId
               departmentId
               departmentName
               prepareLessonNum
               accomplishedPreparationPlan
               unaccomplishedPreparationPlan
            }
        }
    }
`;
/**
 * 备课组统计
 */
const homePagePreparationGroupStatistics = gql`
    query($semesterId: Int!){
        homePagePreparationGroupStatistics(semesterId : $semesterId) {
            code
            data{
               teacherName
               teacherUserId
               departmentId
               departmentId
               departmentName
               prepareLessonNum
               accomplishedPreparationPlan
               unaccomplishedPreparationPlan
            }
        }
    }
`;
/**
 * 查询部门数据
 * @param page 分页
 * @param limit 每页数据量
 * @param condition 查询条件
 */
const departmentList = gql`
	query ($page: Int!, $limit: Int!, $condition: DepartmentCondition){
		Departments (page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data {
                id
                department_name {
                    en
                    mn
                    zh
                }
            }
		}
	}
`;
/**
 * 查询教学计划
 * @param page 分页
 * @param limit 每页数据量
 * @param condition 查询条件
 */
const PreparationTeachingPlans = gql`
	query ($page: Int!, $limit: Int!, $condition: PreparationTeachingPlanCondition){
		PreparationTeachingPlans (page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data {
                id
                teachingPlanTitle
                semesterId
                teachingPlanContent
                organizationId
                teachingPlanSummary
                createdBy
                teachingPlanContentCreateTime
                createdAt
                updatedAt
                createdByUserName{
                    zh
                    mn
                    en
                }
                subjectImageMn
                subjectImageZh
                departmentName
                semesterName{
                    zh
                    mn
                    en
                }
            }
		}
	}
`
const myLessonGroup = gql`
    query{
        getLoginUserPreparationSubjectGroupInfo{
            data{
                departmentId
                departmentType
                departmentZhName
                departmentMnName
            }
        }
    }
`
/**
 * 添加教学计划
 * @param
 */
const addPrepareTeachingPlan = gql`
mutation addPrepareTeachingPlan(
   $inputData: PrepareTeachingPlanInput
){
    addPrepareTeachingPlan(
      inputData:$inputData
    ){
      code
      msg
   
    }
    }
    `
const updatePrepareTeachingPlan = gql`
mutation addPrepareTeachingPlan(
   $inputData: PrepareTeachingPlanInput
){
    updatePrepareTeachingPlan(
      inputData:$inputData
    ){
      code
      msg
    }
    }
    `
/**
 * 删除教学计划
 * @param
 */
const deletePrepareTeachingPlan = gql`
	mutation ($ids: [Int]!) {
		deletePrepareTeachingPlan(ids: $ids) {
			code
			msg
		}
	}
`
//查询单条
const PreparationTeachingPlan = gql`
	query ($id: Int!){
		PreparationTeachingPlan (id: $id){
            code
            msg
            data {
                id
                departmentName
                teachingPlanTitle
                semesterId
                teachingPlanContent
                organizationId
                teachingPlanSummary
                createdBy
                teachingPlanContentCreateTime
                createdAt
                updatedAt
                createdByUserName{
                    zh
                    mn
                    en
                }
                departmentName
                semesterName{
                    zh
                    mn
                    en
                }
            }
		}
	}
`
/**
 * 教学设计列表
 */
const TeachingDesigns = gql`
    query($page: Int!, $limit: Int!, $condition: TeachingDesignsCondition) {
        teachingDesigns(page: $page, limit: $limit, condition: $condition) {
            code
            msg
            total
            data {
                id
                prepareLessonTitle
                createdAt
                departmentName{
                    zh
                    mn
                    en
                }
                departmentId
                semesterName{
                    zh
                    mn
                    en
                }
                semesterId
                teacherName{
                    zh
                    mn
                    en
                }
                lang
                prepareLessonPlanType
                subjectImageMn
                subjectImageZh
            }
        }
    }
`;

/**
 * 添加教学设计
 */
const addTeachingDesign = gql`
    mutation addTeachingDesign($inputData: TeachingDesignInput) {
        addTeachingDesign(inputData: $inputData) {
            id
            code
            msg
        }
    }
`;

/**
 * 添加教学设计
 */
const updateTeachingDesign = gql`
    mutation updateTeachingDesign($inputData: TeachingDesignInput) {
        updateTeachingDesign(inputData: $inputData) {
            code
            msg
        }
    }
`;
/**
 * 备课教学设计
 */
const teachingDesign = gql`
    query($id: Int!) {
        teachingDesign(id: $id) {
            code
            msg
            data {
                id
                prepareLessonPlanId
                prepareLessonTitle
                departmentId
                departmentName{
                    zh
                    mn
                    en
                }
                lang
                
                prepareLessonPlanType
                prepareLessonContent
                prepareLessonAccessories
                semesterName{
                    zh
                    mn
                    en
                }
                semesterId
                teacherName{
                    zh
                    mn
                    en
                }
                publishType
                userId
                createdAt
                updatedAt
            }
        }
    }
`;
/**
 * 删除教学设计
 */
const deleteTeachingDesign = gql`
    mutation deleteTeachingDesign($ids: [Int]) {
        deleteTeachingDesign(ids: $ids) {
            code
            msg
        }
    }
`;
export {addTeachingDesign,
    TeachingDesigns,
    teachingDesign,
    updateTeachingDesign,
    prepareLesson,
    deleteTeachingDesign, 
    PreparationTeachingPlan,
    myLessonGroup, 
    deletePrepareTeachingPlan, 
    addPrepareTeachingPlan, 
    PreparationTeachingPlans, 
    deletePrepareLesson, 
    updatePrepareLesson, 
    addPrepareLesson, 
    homePagePreparationGroupStatistics, 
    homePagePersonStatistics, 
    homePageSubjectGroupStatistics, 
    prepareLessons, 
    myPrepareLessonPlans, 
    deletePrepareLessonPlan, 
    updatePrepareLessonPlan, 
    prepareLessonPlan, 
    addPrepareLessonPlan, 
    departmentList, 
    prepareLessonPlans, 
    updatePrepareTeachingPlan
}

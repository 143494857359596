import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { BusinessService } from 'src/app/service/business/business.service';
import { RequestService } from 'src/app/service/request/request.service';
import { UtilsService } from 'src/app/service/utils/utils.service';

@Component({
    selector: 'orh-folder-block',
    templateUrl: './orh-folder-block.component.html',
    styleUrls: ['./orh-folder-block.component.less']
})
export class OrhFolderBlockComponent implements OnInit {
    @Input() blockrename: any;
    @Input() dataList: any;
    @Input() state: boolean;
    @Output() dbclick: EventEmitter<any> = new EventEmitter();
    @Output() checkedChange: EventEmitter<any> = new EventEmitter();
    @Output() dataListChange: EventEmitter<any> = new EventEmitter();
    @Output() returnRename: EventEmitter<any> = new EventEmitter();
    checkNum: number;
    selectNum: number;
    file_type: string = '';
    fileName: string = '';
    editID: string = '';
    color: string = 'var(--omc-color-primary)';
    colors: string = 'var(--omc-color-primary-background-rgb)';
    styleColor: any;
    breadCrumb: any[] = [
        {
            path: '/1',
            name: '',
            parentRef: ''
        },
    ];
    dataFileList: any[] = [];
    langfiles: any;
    constructor(
        private http: RequestService,
        private message: NzMessageService,
        private util: UtilsService,
        private business: BusinessService
    ) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataFileList = []
        this.dataList.map(item => {
            if (item.thumbnail !== null) {
                this.dataFileList.push({
                    image: item.thumbnail.replace(/\"/g, ''),

                    ...item
                })
            } else {
                this.dataFileList.push({

                    ...item
                });
            }
        });
        if (changes.blockrename) {
            if(changes.blockrename.currentValue){
                this.getRename(changes.blockrename.currentValue.data, changes.blockrename.currentValue.index)
            }
            
        }
    }

    getRename(data: any, index: number) {
        this.editID = data.parentRef
        this.file_type = data.type;
        data.status = false
        // this.statusShow = true;
        if (data.type === 'sr:smartfolder') {

            this.fileName = data.name;
        } else {
            const fileFormat = data.name.substring(0, data.name.indexOf('.'));
            this.fileName = fileFormat;
        }
    }

    //修改文件
    submitShow() {
        if (this.file_type === 'sr:smartfolder') {
            this.http.post('resource/folder/edit', {
                folderName: this.fileName,
                identifier: this.editID,
            }).subscribe((res) => {
                if (res.code === 200) {
                    this.fileName = '';
                    this.returnRename.emit(true)
                } else if (res.code === 550) {
                    this.message.create('error', '有相同的文件名，请从新输入')
                }
            });
        } else {
            this.http.post('resource/file/filemanager/rename', {
                fileName: this.fileName,
                identifier: this.editID,
            }).subscribe((res) => {
                if (res.code === 200) {
                    this.fileName = '';
                    this.returnRename.emit(true);
                } else if (res.code === 550) {
                    this.message.create('error', '有相同的文件名，请从新输入')
                }
            });
        }
    }

    //取消修改
    submitCloses(item) {
        item.status = true;
        this.fileName = '';
    }

    //数据列表
    handleType2(el: any) {
        if (el.type === 'sr:smartfolder') {
            // 这个绝对是文件夹
            return 'iconFolder';
        } else {
            return this.handelImg(el.mime_type);
        }
    }

    // 处理文件类型缩略图
    handelImg(type) {
        let tIcon = 'iconText_File';
        if (type !== null) {
            switch (true) {
                case /image/i.test(type):
                    tIcon = 'iconJPG';
                    break;
                case /video/i.test(type):
                    tIcon = 'iconVideo_File';
                    break;
                case /audio/i.test(type):
                    tIcon = 'iconMP3';
                    break;
                case /word/i.test(type):
                    tIcon = 'iconDOC';
                    break;
                case /excel/i.test(type):
                    tIcon = 'iconXLS';
                    break;
                case /ppt/i.test(type):
                    tIcon = 'iconPPT';
                    break;
                case /pdf/i.test(type):
                    tIcon = 'iconPDF';
                    break;
                case /zip/i.test(type):
                    tIcon = 'iconZIP';
                    break;
                case /text/i.test(type):
                    tIcon = 'iconTXT';
                    break;
                default:
                    tIcon = 'iconText_File';
            }
        }
        return tIcon;
    }

    handleclick(data) {
        if (data.path) {
            this.dbclick.emit(data)
        }
    }

    //鼠标移上事件
    showHint(num: number) {
        this.getColor()
        this.checkNum = num;
    }
    getColor() {
        const backgroundColors = this.business.getItem('userInfo').school ? (this.business.getItem('userInfo').school.settings ? this.business.getItem('userInfo').school.settings.themeSettings.zh : '#206F53') : '';
        const color1 = this.util.color(backgroundColors, 0.05, true);
        const color2 = this.util.color(backgroundColors, 0.8, true);
        const color3 = this.util.color(backgroundColors, 0.38, true);

        this.color = backgroundColors;
        this.colors = color3;
        this.styleColor = {
            background: color1,
            'border': `1px solid ${color3}`
        }
    }
    //鼠标移出事件
    hidenHint() {
        this.checkNum = -1
    }

    clickCheck($event: any, data: any, ind: number) {
        this.selectNum = ind
        data.checked = !data.checked
        this.checkedChange.emit(data)
        this.dataListChange.emit(this.dataFileList)
    }
}

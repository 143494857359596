import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../service/menu/menu.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-orh-bread',
    templateUrl: './orh-bread.component.html',
    styleUrls: ['./orh-bread.component.less']
})
export class OrhBreadComponent implements OnInit {
    public breadCrumbsList: string[] = [];
    public breadSelected: string = '';
    constructor(private breadCrumbs: MenuService, private router: Router) {
        this.breadCrumbsList = this.breadCrumbs.getBreadCrumbs();
        this.formatterTheSelect(this.breadCrumbsList);
    }
    ngOnInit(): void {
        this.breadCrumbs.valueUpdated.subscribe((val) => {
            this.breadCrumbsList = val;
            this.formatterTheSelect(val);
        })
    }
    goHome() {
        this.router.navigate(['home']);
    }
    formatterTheSelect(val: string[]) {
        this.breadSelected = val[val.length - 1];
    }
}

import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NzFormatEmitEvent, NzTreeNodeOptions, NzMessageService } from 'ng-zorro-antd';
import { UtilsService } from '../../service/utils/utils.service';
import { teacherData } from '../../graphQl/school-administration/school-notice/announcement';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { Apollo } from 'apollo-angular';
import { departmentListData, departmentList, newTeachers } from '../../graphQl/systemctl/departments';
import { QueryDuityPeople } from '../../graphQl/base/personnelAlloction';
import { QueryEnquiryPeople } from '../../graphQl/systemctl/systemctl-users';
import { resolve } from 'url';

@Component({
    selector: 'app-orh-teacher',
    templateUrl: './orh-teacher.component.html',
    styleUrls: ['./orh-teacher.component.less']
})
export class OrhTeacherComponent implements OnInit, OnChanges {
    @Input() userData: any[]=[];
    @Output() teacherData: EventEmitter<any> = new EventEmitter();
    @Input() documentTitle:any;
    teacherList: any[] = [];
    nodes: any[] = [];
    OrganizeList: any[] = [];
    searchValue: any;
    searchShow: boolean = true;
    deSpinning:boolean = false;
    // 点击获取到的树附属信息
    TreeData: any = [];
    radioValue: any;
    departmentId: number | boolean = false;
    // 树形加载状态
    TreeLoading: boolean = false;
    // 树形没有数据
    NoDataStatus: boolean = false;
    formValidate = {
        duties_id: ''
    }
    listData: any[] = [];
    dutiesListData: any[] = [];
    checkOptionsOne: any[] = [];
    allChecked = false;
    indeterminate = true;
    loading: boolean = false;
    isSpinning:boolean = false;
    titles: string = ''
    nztitles:any='选中(0)人';
    constructor(
        private utils: UtilsService,
        private graph: GraphqlServiceService,
        private message: NzMessageService,
        private apollo: Apollo,
    ) {
        this.checkOptionsOne = []
    }

    ngOnInit() {
        this.isSpinning = true;        
        this.getDepartmentData();
        // this.getTeacher()
    }
    ngOnChanges(changes: SimpleChanges) {  
        this.teacherList = []
       
        if(changes.userData.currentValue){
            console.log('changes.userData.currentValue',changes.userData.currentValue);
            if(changes.userData.currentValue.data){
                this.teacherList = changes.userData.currentValue.data;   
            console.log(this.teacherList);
            }else{
                this.teacherList = changes.userData.currentValue
            }
            
            
        }else{
            this.teacherList = []
        }
        
        this.getTeacher();
        this.teacherData.emit(this.teacherList);
        this.titles = changes.userData.currentValue.title?changes.userData.currentValue.title:'选择老师'; 
        this.nztitles = `选中(${this.teacherList.length})人`;
    }
    getTeacher(): void {
        this.isSpinning = true           
        const params = {
            query: newTeachers,
            variables: {
                page: 0,
                limit: 0,
                condition:{
                    work_status:'workstatus_on-the-job'
                }
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.teachersNew.code === 200) {
                let data = res.data.teachersNew.data;
                let user = {}               
                this.checkOptionsOne=data.map(item => {                   
                    if (this.teacherList) {
                        let index = this.teacherList.findIndex(res=>res.value === item.user.id);     
                        if (index >-1) {                           
                            return {
                                value: item.user.id,
                                label: item.name ? item.name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: true
                            }
                        } else {                            
                            return{
                                value: item.user.id,
                                label: item.name ? item.name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: false
                            }                            
                        }                        
                    } else {
                       return {
                            value: item.user.id,
                            label: item.name ? item.name.zh : item.user.username,
                            idcard: item.user.idcard,
                            checked: false
                        }                       
                    }
                })   
                this.isSpinning = false            
            }else{
                this.message.error(res.data.teachersNew.msg)
            }
            this.isSpinning = false
        });
    }
    
    searchClick() {
        this.checkOptionsOne = []
        this.utils.getStorage().then(res => {
            let item = {
                name: this.searchValue
            }
            let datas = this.utils.handelCondition(item)
            const params = {
                query: teacherData,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: datas
                },
                policy: 'no-cache'
            };
            this.graph.GQuery(params).subscribe((res: any) => {
                if (res.data.teachers.code === 200) {
                    let data = res.data.teachers.data;
                    let user = {}
                    data.map(item => {
                        user = {
                            value: item.user.id,
                            label: item.name ? item.name.zh : item.user.username,
                            idcard: item.user.idcard,
                            checked: false
                        }
                        this.checkOptionsOne.push(user)
                    })
                }else{
                   
                }
            });
        });
    }
    // 获取组织树的信息
    getDepartmentData(): void {
        this.TreeLoading = true
        let that = this;
        that.apollo.watchQuery({
            query: departmentListData,
            variables: {
                page: 0,
                limit: 1000
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.Departments.code === 200) {
                that.listData = res.data.Departments.data.map((el: any) => ({
                    title: el.department_name.zh,
                    key: el.id,
                    info: el
                }));
                if (this.listData.length === 0) {
                    this.NoDataStatus = true;
                }
                this.TreeLoading = false;
            }else{
                this.message.error(res.data.Departments.msg)
            }
        })
    }
    nzEvent(event: Required<NzFormatEmitEvent>): void {
        let that = this;
        let id: any = event.keys[event.keys.length - 1];
        const node = event.node;
        if (event.eventName === 'expand') {
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(id).then(data => {
                    node.addChildren(data)
                })
            }
        } else if (event.eventName === 'click') {
            that.departmentId = id ? id : false;
            let arr = node.origin.info.duties.map(el => {
                return el.id
            });
            that.reloadDutiesData(arr)
        } else {

        }
    }
    loadNode(value: any): Promise<NzTreeNodeOptions[]> {
        let that = this;
        return new Promise(resolve => {
            that.apollo.watchQuery({
                query: departmentList,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: {
                        parent_id: value
                    }
                }
            }).valueChanges.subscribe((res: any) => {
                if (res.data.Departments.code === 200) {
                    let data = res.data.Departments.data.map((el: any) => ({
                        title: el.department_name.zh,
                        key: el.id,
                        info: el
                    }));
                    resolve(data)
                } else {
                    resolve()
                }
            })
        })
    }
    // 点击树形结构
    TreeClick(val) {
        this.searchShow = false
        this.TreeData = val.node.origin;
        // this.OrganizeList = val.node.origin.info.duties;
        this.getNodeDuity(0, 0, val.node.origin.key)
    }
    // 通过组织树获取人员列表
    getNodeDuity(page = 0, limit = 0, department_id: number) {
        this.checkOptionsOne = []
        const variables = {
            page,
            limit,
            department_id
        }
        this.apollo.watchQuery({
            query: QueryDuityPeople,
            variables,
        }).valueChanges.subscribe((res: any) => {
            if (res && res.data.DepartmentUsers.code === 200) {
                let data = res.data.DepartmentUsers.data;               
                let users = []
                let user = {}
                users = data.map(item => {                   
                    if (this.teacherList) {
                        let index = this.teacherList.findIndex(res=>res.value === item.user.id);     
                        if (index >-1) {                           
                            return {
                                value: item.user.id,
                                label: item.user_name ? item.user_name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: true
                            }
                        } else {                            
                            return{
                                value: item.user.id,
                                label: item.user_name ? item.user_name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: false
                            }                            
                        }                        
                    } else {
                       return {
                            value: item.user.id,
                            label: item.user_name ? item.user_name.zh : item.user.username,
                            idcard: item.user.idcard,
                            checked: false
                        }                       
                    }
                })   
                
                let teacher = []
                users.map(item => {
                    let index = teacher.findIndex(res => res.value === item.value)
                    if (index === -1) {
                        teacher.push(item)
                    } else {
                        return false
                    }
                })
                this.checkOptionsOne = teacher
            }else{
                this.message.error(res.data.DepartmentUsers.msg)
            }
        })
    }


    reloadDutiesData(data: Array<number>) {
        let list = [...this.dutiesListData];
        if (data) {
            list.map((el: any) => {
                let bool = data.find(w => w === el.key)
                if (bool) {
                    el.direction = 'right'
                } else {
                    el.direction = 'left'
                }
            });
            this.dutiesListData = list
        }
    }
    updateAllChecked(): void {
        this.indeterminate = false;
        if (this.allChecked) {
            this.checkOptionsOne = this.checkOptionsOne.map(item => {
                return {
                    ...item,
                    checked: true
                };
            });
            this.teacherList = this.checkOptionsOne
        } else {
            this.checkOptionsOne = this.checkOptionsOne.map(item => {
                return {
                    ...item,
                    checked: false
                };
            });
            this.teacherList = []
        }
    }

    updateSingleChecked(): void {
        if(this.teacherList){
            this.checkOptionsOne.map(item => {
                if (item.checked === true) {
                    
                    let index = this.teacherList.findIndex(res=>res.value === item.value)
                    if(index === -1){
                        this.teacherList.push(item)
                    }else{
                        return false
                    }
                    
                } 
                if(item.checked === false){
                    let index = this.teacherList.findIndex(res=>res.value === item.value)
                    if(index > -1){
                        this.teacherList.splice(index)
                    }else{
                        return false
                    }
                }
            })
        }else{
            this.checkOptionsOne.map(item => {
               
                if (item.checked === true) {
                    
                    this.teacherList.push(item)
                    
                } else{
                    return false
                }
                
            })
            console.log(this.teacherList)
        }
                
        this.nztitles = `选中（${this.teacherList.length}）人`;
        this.teacherData.emit(this.teacherList)
        if (this.checkOptionsOne.every(item => !item.checked)) {
            this.allChecked = false;
            this.indeterminate = false;
        } else if (this.checkOptionsOne.every(item => item.checked)) {
            this.allChecked = true;
            this.indeterminate = false;
        } else {
            this.indeterminate = true;
        }
    }
    delete(_id: any) {
        this.loading = true
        this.checkOptionsOne.map(item => {
            if (item.value === _id) {
                item.checked = false
            }
        })
        let index = this.teacherList.findIndex(item => item.value === _id)
        this.teacherList.splice(index, 1)
        this.loading = false
        this.teacherData.emit(this.teacherList);
        this.nztitles = `选中（${this.teacherList.length}）人`;
    }
}

import {Component, OnInit} from '@angular/core';
import {ControlWidget} from '@delon/form';
import {TinymceOptions} from 'ngx-tinymce';

@Component({
    selector: 'app-orh-editor-tinymce',
    templateUrl: './editor-tinymce.component.html',
    styleUrls: ['./editor-tinymce.component.less']
})
export class EditorTinymceComponent extends ControlWidget implements OnInit {
    static readonly KEY = 'tinymce';
    config: any = {
        height: 400,
        theme: 'modern',
        // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
        plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
        toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
        image_advtab: true,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        templates: [
            {title: 'Test template 1', content: 'Test 1'},
            {title: 'Test template 2', content: 'Test 2'}
        ],
        // content_css: [
        //     '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        //     '//www.tinymce.com/css/codepen.min.css'
        // ]
    };
    loading: string;

    ngOnInit() {
        this.loading = this.ui.loading || '加载中...';
        this.config = this.ui.config || {};
    }

    change(value: string) {
        if (this.ui.change) this.ui.change(value);
        this.setValue(value);
    }
}

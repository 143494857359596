import gql from 'graphql-tag';
/**
 * 论文列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const papers = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		papers (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                core 
                isinclude{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                authornumber{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                publishdate
                journalNumber
                image
                image1
                shenhe
                reason
                title{
                    zh
                    mn 
                    en
                }  
                production {
                    zh
                    mn 
                    en
                }
            }
		}
	}
`;
// /**
//  * 论文单条数据
//  */
const findpaper = gql`
	query ($id: Int!){
		paper (id: $id) {
            id
            teacher_id 
            core 
            isinclude{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            authornumber{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            }
            publishdate
            image
            image1
            shenhe
            journalNumber
            title{
                zh
                mn 
                en
            }  
            production {
                zh
                mn 
                en
            }
        }
	}
`;

const yearperformance = gql`
	query ($id: Int!){
		yearperformance (id: $id) {
            id
            year 
            level{
                dictionary_value
                name{
                  zh
                }
              }
        }
	}
`;

const qualificationcertificate = gql`
	query ($id: Int!){
		qualificationcertificate (id: $id) {
            id
            type{
              dictionary_value
              name{
                zh
              }
            }
            subject
            numberCode
            image
        }
	}
`;
// /**
//  * 论文添加数据
//  */
const addPaper = gql`
	mutation addPaper($inputData: PaperInput){
		addPaper(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

const addYearperformance = gql`
	mutation addYearperformance($inputData: YearperformanceInput){
		addYearperformance(inputData: $inputData){
			code
			msg
		}
	}
`;

const addQualificationcertificate = gql`
	mutation addQualificationcertificate($inputData: QualificationcertificateInput){
		addQualificationcertificate(inputData: $inputData){
			code
			msg
		}
	}
`;

/**
 * 论文删除数据
 */
const deletePaper = gql`
    mutation deletePaper($id: [Int]!){
        deletePaper(id:$id){
            code
            msg
        }
    }
`;
/**
 * 博导信息列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const doctoralSupervisors = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		doctoralSupervisors (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                isteach
                years
                image 
                shenhe
            }
		}
	}
`;
// /**
//  * 博导信息单条数据
//  */
const findDoctoralSupervisor = gql`
	query ($id: Int!){
		doctoralSupervisor (id: $id) {
            id
            teacher_id 
            isteach
            years
            image 
            shenhe
		}
	}
`;
// /**
//  * 博导信息添加数据
//  */
const addDoctoralSupervisor = gql`
	mutation addDoctoralSupervisor($inputData: DoctoralSupervisorInput){
		addDoctoralSupervisor(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 博导信息删除数据
 */
const deleteDoctoralSupervisor = gql`
    mutation deleteDoctoralSupervisor($id: [Int]!){
        deleteDoctoralSupervisor(id:$id){
            code
            msg
        }
    }
`;

/**
 * 现单位情况列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const currentUnitSituations = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		currentUnitSituations (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                image
                shenhe
                property{
                    id
                    dictionary_value 
                    name{
                        zh
                        mn
                        en
                    }
                }
                phone
                sheng {
                    id
                    name{
                        zh
                        mn
                        en
                    }
                } 
                shi {
                    id
                    name{
                        zh
                        mn
                        en
                    }
                }
                xian {
                    id
                    name{
                        zh
                        mn
                        en
                    }
                }
                dizhi{
                    zh
                    mn
                    en
                }
                commonproject{
                    zh
                    mn
                    en
                }
                membername{
                    zh
                    mn
                    en
                }
                membereducation{
                    zh
                    mn
                    en
                }
                memberpost{
                    zh
                    mn
                    en
                }
            }
		}
	}
`;
// /**
//  * 现单位情况单条数据
//  */
const findcurrentUnitSituation= gql`
	query ($id: Int!){
		currentUnitSituation (id: $id) {
            id
            teacher_id 
            image
            shenhe
            property{
                id
                dictionary_value 
                name{
                    zh
                    mn
                    en
                }
            }
            phone
            sheng {
                id
                name{
                    zh
                    mn
                    en
                }
            } 
            shi {
                id
                name{
                    zh
                    mn
                    en
                }
            }
            xian {
                id
                name{
                    zh
                    mn
                    en
                }
            }
            dizhi{
                zh
                mn
                en
            }
            commonproject{
                zh
                mn
                en
            }
            membername{
                zh
                mn
                en
            }
            membereducation{
                zh
                mn
                en
            }
            memberpost{
                zh
                mn
                en
            }
		}
	}
`;
// /**
//  * 现单位情况添加数据
//  */
const addCurrentUnitSituation = gql`
	mutation addCurrentUnitSituation($inputData: CurrentUnitSituationInput){
		addCurrentUnitSituation(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 现单位情况删除数据
 */
const deleteCurrentUnitSituation = gql`
    mutation deleteCurrentUnitSituation($id: [Int]!){
        deleteCurrentUnitSituation(id:$id){
            code
            msg
        }
    }
`;

/**
 * 专技工作经历列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const professionalExperiences = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		professionalExperiences (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                time
                entime
                image 
                shenhe
                commonproject {
                    zh
                    mn
                    en
                }
                membername{
                    zh
                    mn
                    en
                }
                jobcontent{
                    zh
                    mn
                    en
                }
            }
		}
	}
`;
// /**
//  * 专技工作经历单条数据
//  */
const findprofessionalExperience = gql`
	query ($id: Int!){
		professionalExperience (id: $id) {
            id
            teacher_id 
            time
            entime
            image 
            shenhe
            commonproject {
                zh
                mn
                en
            }
            membername{
                zh
                mn
                en
            }
            jobcontent{
                zh
                mn
                en
            }
		}
	}
`;
// /**
//  * 专技工作经历添加数据
//  */
const addProfessionalExperience = gql`
	mutation addProfessionalExperience($inputData: ProfessionalExperienceInput){
		addProfessionalExperience(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 专技工作经历删除数据
 */
const deleteProfessionalExperience = gql`
    mutation deleteProfessionalExperience($id: [Int]!){
        deleteProfessionalExperience(id:$id){
            code
            msg
        }
    }
`;

/**
 * 专业技能资格列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const professionalQualifications = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		professionalQualifications (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                experttype{
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                tradeid{
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                inputstyle
                jobtypeid{
                    id
                    name{
                        zh
                        mn
                        en
                    }
                }
                inputStyleQualification{
                    id
                    name
                }
                jobTypeIdQualification{
                    id
                    name
                }
                takedate 
                jtlevelid{
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                certificateno 
                image
                isemploy
                shenhe
                reason
                awarddept {
                    zh
                    mn
                    en
                }
            }
		}
	}
`;
// /**
//  * 专业技能资格单条数据
//  */
const findProfessionalQualification = gql`
	query ($id: Int!){
		professionalQualification (id: $id) {
            id
            teacher_id 
            experttype{
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            tradeid {
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            inputStyleQualification{
                id
                name
            }
            jobTypeIdQualification{
                id
                name
            }
            inputstyle 
            jobtypeid{
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            takedate 
            jtlevelid{
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            certificateno 
            image
            
            shenhe
            awarddept {
                zh
                mn
                en
            }
		}
	}
`;
// /**
//  * 专业技能资格添加数据
//  */
const addProfessionalQualification = gql`
	mutation addProfessionalQualification($inputData: ProfessionalQualificationInput){
		addProfessionalQualification(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 专业技能资格删除数据
 */
const deleteProfessionalQualification = gql`
    mutation deleteProfessionalQualification($id: [Int]!){
        deleteProfessionalQualification(id:$id){
            code
            msg
        }
    }
`;

/**
 * 护照列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const passports = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		passports (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                time  
                entime 
                gender{
                    id
                    dictionary_code
                    name{
                        zh
                        mn
                        en
                    }
                } 
                nation{
                    id
                    dictionary_code
                    name{
                        zh
                        mn
                        en
                    }
                } 
                idcard
                passportnumber 
                companyopinion
                companyopinionid 
                directorcompanyopinion
                directorcompanyopinionid 
                companyopinionimg
                directorcompanyopinionimg
                name{
                    zh
                    mn
                    en
                } 
                sourceofcost{
                    zh
                    mn
                    en
                } 
                sourceofcostwa{
                    zh
                    mn
                    en
                }
                reasonsabroad{
                    zh
                    mn
                    en
                }
                nationality {
                    zh
                    mn
                    en
                }
            }
		}
	}
`;
// /**
//  * 护照单条数据
//  */
const findpassport = gql`
	query ($id: Int!){
		passport (id: $id) {
            id
            teacher_id 
            time  
            entime 
            gender{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            } 
            nation{
                id
                dictionary_value
                name{
                    zh
                    mn
                    en
                }
            } 
            idcard
            passportnumber 
            companyopinion
            companyopinionid 
            directorcompanyopinion
            directorcompanyopinionid 
            companyopinionimg
            directorcompanyopinionimg
            name{
                zh
                mn
                en
            } 
            sourceofcost{
                zh
                mn
                en
            } 
            sourceofcostwa{
                zh
                mn
                en
            }
            reasonsabroad{
                zh
                mn
                en
            }
            nationality {
                zh
                mn
                en
            }
		}
	}
`;
// /**
//  * 护照添加数据
//  */
const addPassport = gql`
	mutation addPassport($inputData: PassportInput){
		addPassport(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`

/**
 * 护照删除数据
 */
const deletePassportAudit = gql`
    mutation deletePassportAudit($id: [Int]!){
        deletePassportAudit(id:$id){
            code
            msg
        }
    }
`;
const deletePassport  = gql`
mutation deletePassport($id: [Int]!){
    deletePassport(id:$id){
        code
        msg
    }
}
`;

const yearperformances = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		yearperformances (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data{
                id
                level{
                  dictionary_value
                  name{
                    zh
                  }
                }
                year
                shenhe
                reason
            }
		}
	}
`;

const deleteYearperformance  = gql`
mutation deleteYearperformance($id: Int!){
    deleteYearperformance(id:$id){
        code
        msg
    }
}
`;

const qualificationcertificates = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		qualificationcertificates (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data{
                id
                numberCode
                subject
                image
                shenhe
                reason
                type{
                    dictionary_value
                    name{
                    zh
                    }
                }
            }
		}
	}
`;

export {
    papers,
    findpaper,
    addPaper,
    deletePaper,
    doctoralSupervisors,
    findDoctoralSupervisor,
    addDoctoralSupervisor,
    deleteDoctoralSupervisor,
    currentUnitSituations,
    findcurrentUnitSituation,
    addCurrentUnitSituation,
    deleteCurrentUnitSituation,
    professionalExperiences,
    findprofessionalExperience,
    addProfessionalExperience,
    deleteProfessionalExperience,
    professionalQualifications,
    findProfessionalQualification,
    addProfessionalQualification,
    deleteProfessionalQualification,
    passports,
    findpassport,
    addPassport,
    deletePassportAudit,
    deletePassport,
    yearperformances,
    addYearperformance,
    yearperformance,
    deleteYearperformance,
    qualificationcertificates,
    addQualificationcertificate,
    qualificationcertificate
}

import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { BaseService } from 'src/app/service/base/base.service';
import { BusinessService } from 'src/app/service/business/business.service';
import { HttpService } from 'src/app/service/http/http.service';
import { RequestService } from 'src/app/service/request/request.service';
import { UtilsService } from 'src/app/service/utils/utils.service';

@Component({
    selector: 'orh-cloud-class',
    templateUrl: './orh-cloud-class.component.html',
    styleUrls: ['./orh-cloud-class.component.scss']
})
export class OrhCloudClassComponent implements OnInit {
    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();
    form: any = {
        page: 1,
        limit: 12,
        title: null,
        createdBy: null,
        sort: 'createdAt.desc',
        // nodeType: 'lesson',
        releaseStatus: null,
        reviewStatus: null
    };
    backgroundColor: any = {
        background: `linear-gradient(-90deg, rgba(68, 163, 255, 1), rgba(11, 135, 255, 1))`,
        boxShadow: '0px 10px 10px 0px rgba(17, 138, 255, 0.38)'
    };
    icons = 'iconxinjian';
    total = 0;
    userId: any;
    delParams: any;
    conditions: any | boolean;
    loading: boolean = false;
    opertionShow = false;
    type: any = null;
    public list = [];
    public imgUrl = '../../../assets/defaultImage1.jpg';
    public selectTotal = 0;
    constructor(
        private utils: UtilsService,
        private http: HttpService,
        private router: Router,
        private base: BaseService,
        private business: BusinessService,
        private message: NzMessageService,
        private req: RequestService
    ) {
    }

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;
        this.form.createdBy = this.userId;
       
        this.getDataList();
    }

    // 点击发布状态筛选
    pubClick(num: number) {
        this.form.releaseStatus = num;
        this.getDataList();
    }

    // 点击发布状态筛选
    statusClick(num: string) {
        this.form.reviewStatus = num;
        this.getDataList();
    }

    // 文件类型
    getImg(data: any) {
        if (data.coverImage) {
            return data.coverImage;
        } else {
            return '/assets/image/course.jpg';
        }
    }

    // 时间处理
    getCreated(date:any) {
        return this.base.getFormatDate(date);
    }

    operationItem(data: any, type: string) {
        switch (type) {
            case 'see':
                this.router.navigate(['/pages/single/my-cloud-detail'], {
                    queryParams: {
                        id: data.id
                    }
                });
                break;
            case 'edit':
                this.router.navigate(['/pages/single/cloud-chapter'], {
                    queryParams: {
                        id: data.id
                    }
                });
                break;
            case 'delete':
                this.getDelete(data);
                break;
        }
    }

    // 删除
    getDelete(data) {
        this.delParams = [data.id];
        const that = this;
        const params = {
            url: 'cms/course/delete',
            data: {
                ids: this.delParams
            }
        };
        this.http.get(params).subscribe((item: any) => {
            if (item.code === 200) {
                that.message.create('success','添加成功');
                this.getDataList();
            }else{
                this.message.error(item.message)
            }
        });
    }

    // 添加单课
    addClick() {
        this.conditions = false;
        this.opertionShow = true;
    }

    // 改变分页
    limitChange(event: any): void {
        this.form.limit = event;
        this.getDataList();
    }

    // 关闭添加框
    close() {
        this.opertionShow = false;
    }

    pageChange(event: any): void {
        this.form.page = event;
        this.getDataList();
    }

    // 数据列表
    getDataList() {
        this.loading = true;
        this.list = [];
        
        const form = this.utils.handelCondition(this.form);
        this.req.get('cms/course/list', form).subscribe((res) => {
            if (res.code === 200) {				
                this.list = res.data.content.map(el => {
                    return {
                        checked: false,
                        ...el
                    };
                });
                this.total = res.data.totalElements;
                // this.cdr.detectChanges()
                this.loading = false;
            }else{
                this.message.error(res.msg)
            }
        });
    }
    
    checkChange(): void {
        const data = this.list.filter(el => el.checked);
        this.selectTotal = data.length;
        this.orhChange.emit(data);
    }
    // 返回
    editBtu(str: boolean) {
        if (str === true) {
            this.opertionShow = false;
            this.getDataList();
        }
    }
}
import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[orhMouseWheelMn]'
})
export class ScrollDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('mousewheel', ['$event'])
    onMouseWheel(event: any) {
        this.el.nativeElement.scrollLeft += event.deltaY;
    }

}
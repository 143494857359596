import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare let File: any;

@Injectable({
    providedIn: 'root'
})
export class HashService {

    constructor() {
    }

    getHash(file): Promise<string> {
        return new Promise<string>(resolve => {
            let contractFile = file;
            let reader = new FileReader();
            let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;
            // 指定文件分块大小(2M)
            const chunkSize = 6 * 1024 * 1024;
            // 计算文件分块总数
            const chunks = Math.ceil(contractFile.size / chunkSize);
            // 指定当前块指针
            let currentChunk = 0;

            // let hasher = CryptoJS.algo.SHA256.create();
            let hasher = CryptoJS.algo.SHA1.create();

            // FileReader分片式读取文件
            // 计算开始读取的位置
            const start = currentChunk * chunkSize;
            // 计算结束读取的位置
            const end = start + chunkSize >= contractFile.size ? contractFile.size : start + chunkSize;
            reader.readAsArrayBuffer(blobSlice.call(contractFile, start, end));
            reader.onload = (evt: any) => {
                let fileStr = evt.target.result;
                let tmpWordArray = this.arrayBufferToWordArray(fileStr);
                hasher.update(tmpWordArray);
                currentChunk += 1;
                fileStr = null;
                tmpWordArray = null;
                // 判断文件是否都已经读取完
                if (currentChunk < chunks) {
                    // 计算开始读取的位置
                    const starts = currentChunk * chunkSize;
                    // 计算结束读取的位置
                    const ends = starts + chunkSize >= contractFile.size ? contractFile.size : starts + chunkSize;
                    reader.readAsArrayBuffer(blobSlice.call(contractFile, starts, ends));
                }
            };
            reader.onloadend = () => {
                if (currentChunk < chunks) {
                    return;
                }

                contractFile = null;
                // let fileobj = document.getElementById(fileId);
                let hash = hasher.finalize();
                const str = hash.toString();
                hasher = null;
                blobSlice = null;
                reader = null;
                hash = null;
                resolve(str);
            };
        });
    }

    arrayBufferToWordArray(ab) {
        const unit = new Uint8Array(ab);
        const a = [];
        for (let i = 0; i < unit.length; i += 4) {
            // tslint:disable-next-line:no-bitwise
            a.push(unit[i] << 24 | unit[i + 1] << 16 | unit[i + 2] << 8 | unit[i + 3]);
        }
        return CryptoJS.lib.WordArray.create(a, unit.length);
    }
}

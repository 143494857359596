/**
 * 周凯
 */
import gql from 'graphql-tag';

const AddPersonAll = gql`
    mutation ($department_id: Int!,$duty_id:Int!, $user_id:Int!){
        setDepartmentUserRecord(
            inputData: {
                department_id:$department_id,
                duty_id:$duty_id,
                user_id:$user_id
            }
        ){
            code
            msg
            data{
                id
                user{
                    id
                }
                duty{
                    id
                    duties_name{
                        zh
                        en
                        mn
                    }
                    level
                }
                department{
                    id
                    department_name{
                        zh
                        en
                        mn
                    }
                }
            }
            total
        }
    }
`;
// 查询部门所在的人员
const QueryDuityPeople = gql`
    query($page:Int,$limit:Int,$department_id:Int){
      DepartmentUsers(
        page: $page,
        limit: $limit,
        condition:{
          department_id: $department_id
        }
      ){
          code
          msg
          total
          data{
            id
            user_name{
                zh
            }
            user{
              id
              email
              username
              status
              idcard
              name{
                zh
              }
            }
            duty{
              id
              duties_name{
                mn
                zh
                en
              }
              duties_order
              level
            }
          }
          }
    }
`;
// 删除部门人员
const DeletePeople = gql`
  mutation($user_id:Int,$duty_id:Int,$department_id:Int){
    deleteDepartmentUser(condition:{user_id:$user_id,duty_id:$duty_id,department_id:$department_id}){
      code
      msg
    }
}
`;
export { AddPersonAll, QueryDuityPeople, DeletePeople }

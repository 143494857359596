import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { deleteUndertakeProject, undertakeProjects } from 'src/app/graphQl/teachers/teacher-profile/work';
import { PageConfig } from 'src/app/model';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
// 承担课题项目
@Component({
  selector: 'project-topic',
  templateUrl: './project-topic.component.html',
  styleUrls: ['./project-topic.component.less']
})
export class ProjectTopicComponent implements OnInit {

    @Input() teacherId: any;
    @Input() indexshow:boolean = true;
    pageConfig: PageConfig = {
        page: 1,
        limit:10000,
        total: 0,
    };
    loading: boolean = false;
    visible: boolean = false;
    listData: any[] = [];
    title: string = '';
    condition: any;
    printBtnBoolean: boolean = true;
    detailVisible:boolean = false;
    printStyle: any;
    printCSS: any = ['http://cdn.bootcss.com/bootstrap/3.3.7/css/bootstrap.min.css'];
    constructor(
        private graph: GraphqlServiceService,
        private message: NzMessageService,
        private modal: NzModalService
    ) { }

    ngOnInit() {
        this.getDataList()
    }
    printComplete() {
        this.printBtnBoolean = true;
    }

    browserPrint() {
        this.printBtnBoolean = false;
    }
    pageSizeChange($event) {
        this.pageConfig.limit = $event;
        this.getDataList();
    }
    pageChange($event) {
        this.pageConfig.page = $event;
        this.getDataList();
    }
    //列表
    getDataList() {

        let that = this;
        that.loading = true;
        let params = {
            query: undertakeProjects,
            variables: {
                page: 1,
                limit:10000,
                teacher_id: this.teacherId,
            },
        };
        that.graph.GQuery(params).subscribe((res: any) => {
            that.listData = res.data.undertakeProjects.data;
            // that.pageConfig.total = res.data.professionalQualifications.total;
            that.loading = false;

        })
    }
    //操作
    moreChange(str: string, data: any): void {
        let that = this
        switch (str) {
            case 'detail':
                that.title = '详情'
                that.condition = data.id;
                that.detailVisible = true;
                break;
            case 'edit':
                that.title = '修改'
                that.condition = data.id;
                that.visible = true;
                break;
            case 'del':
                let val = parseInt(data.id);
                that.deleteData(val);
                break;
        }
    }
    detailClose(){
        this.detailVisible = false;
    }
    addData() {
        this.title = '添加';
        this.visible = true;
        this.condition = null;
    }
    //删除
    deleteData(id: number) {
        let that = this
        let arr = [];
        arr.push(id);
        that.modal.confirm({
            nzTitle: '<i>删除确认</i>',
            nzContent: '<b>您确定要删除数据吗?</b>',
            nzOnOk: () => {
                let params = {
                    mutation: deleteUndertakeProject,
                    variables: {
                        id: arr
                    },
                };
                that.graph.GMutation(params).subscribe((res: any) => {

                    if (res.data.deleteUndertakeProject.code === 200) {
                        that.message.create('success', '删除成功');
                        that.getDataList()
                    } else{
                        this.message.error(res.data.deleteUndertakeProject.msg)
                    }
                })
            }
        })
    }
    close(evt?) {
        this.visible = false
        if (evt === true) {
            this.listData = [];
            this.getDataList();
            this.visible = false;
        }
    }

}


import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService,UploadFile, UploadFilter } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addUndertakeProject, findundertakeProject } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'project-topic-operation',
  templateUrl: './project-topic-operation.component.html',
  styleUrls: ['./project-topic-operation.component.less']
})
export class ProjectTopicOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    endOpen: boolean = false;

    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    ImageUrl1 = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    fileList1: any[] = [];
    
    formBody:any={
        time:null,
        entime:null

    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            time: ['', Validators.required],//承担时间
            entime: ['', Validators.required],//完成时间
            fund: ['', Validators.required],//经费
            num: ['', Validators.required],//立项编号
            image: [''],//证书扫描件(上传图片)
            image1: [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//项目名称
            unit: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//项目课题主管单位
            source: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//项目性质及来源
            roal: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//项目性质及来源
            inputstyle: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//等级
        })
    }
    dictionaryList: any = {
        typeofreward: []
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findundertakeProject,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.undertakeProject
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd')
            data.entime = this.date.transform(data.entime, 'yyyy-MM-dd')
            form.get('fund')!.setValue(data.fund);
            form.get('time')!.setValue(data.time);
            form.get('entime')!.setValue(data.entime);
            form.get('num')!.setValue(data.num);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('name')!.setValue(data.name ? data.name : { mn: null, zh: null, en: null });
            form.get('unit')!.setValue(data.unit ? data.unit : { mn: null, zh: null, en: null });
            form.get('source')!.setValue(data.source ? data.source : { mn: null, zh: null, en: null })
            form.get('roal')!.setValue(data.roal ? data.roal : { mn: null, zh: null, en: null })
            form.get('inputstyle')!.setValue(data.inputstyle ? data.inputstyle : { mn: null, zh: null, en: null });

            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
                
            }

            if (data.image1 !== null || data.image1 !== '') {
                let datas1 = data.image1 ? JSON.parse(`["${data.image1}"]`) : null;
                if(datas1){
                    this.updateMethods1(datas1)
                }
            }
           
        })
    }
   
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'typeofreward',
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    updateMethods1(data: any) {
        this.fileList1 = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    handelChange(event) {

        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    handelChange1(event) {
        console.log(event, 'hc1');
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image1 = event.fileList[0].response.data[0].newName;
        }
    }

    nzRemove = (file): boolean => {
        return true;
    }

    nzRemove1 = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };

    handlePreview1 = async (file: UploadFile) => {

    };

    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];

    filters1: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList1: UploadFile[]) => {
                console.log('fileList0033', fileList1);

                const filterFiles = fileList1.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList1.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList1;
            }
        },
    ];

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.entime) {
            return false;
        }
        return startValue.getTime() > this.formBody.entime.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.time) {
            return false;
        }
        return endValue.getTime() <= this.formBody.time.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.time = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.entime = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = open;
    }
    //提交数据
    postData(params: any) {
        let that = this;
        
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.entime = this.date.transform(params.entime, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }

        if (this.fileList1.length > 0) {
            let arr: any
            this.fileList1.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image1 = arr;
        }

        const mutationParams = {
            mutation: addUndertakeProject,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addUndertakeProject.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addUndertakeProject.msg)
            }
            that.form.reset()
        })
    }
}

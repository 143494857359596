import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter} from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addForeignLanguages, findforeignLanguages } from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';

@Component({
  selector: 'foreign-language-operation',
  templateUrl: './foreign-language-operation.component.html',
  styleUrls: ['./foreign-language-operation.component.less']
})
export class ForeignLanguageOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            names : [null, Validators.required],//语种名称
            image: [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核 2、审核通过)
            languelevel : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//语种等级
            
        })
    }
    dictionaryList: any = {
        languagename: []
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findforeignLanguages,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.foreignLanguages;
            let form = this.form;
            form.get('names')!.setValue(data.names?data.names.dictionary_value:null);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('languelevel')!.setValue(data.languelevel ? data.languelevel : { mn: null, zh: null, en: null });
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }
            
        })
    }
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ]; 
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'languagename',
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                        console.log(that.dictionaryList);
                        
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.teacher_id = this.teacherId
        const mutationParams = {
            mutation: addForeignLanguages,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addForeignLanguages.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addForeignLanguages.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
}

/*
 * @Author: 阿拉斯
 * @Date: 2019-09-02 16:43:03
 * @Last Modified by: 阿拉斯
 * @Last Modified time: 2019-09-21 15:19:49
 */
import gql from 'graphql-tag';

/**
 * 查询年级列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const gradesList = gql`
	query ($page: Int!, $limit: Int!,$condition:GradesInput){
		grades (page: $page, limit: $limit,condition:$condition){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                periods {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
            }
		}
	}
`
const gradesNewList = gql`
	query ($page: Int!, $limit: Int!,$condition:GradesInput){
		gradesNew (page: $page, limit: $limit,condition:$condition){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
				}
				periods{
					name{
					  en
				    	mn
						zh
					}
				  }
            }
		}
	}
`

/**
 * 年级添加/修改数据
 */
const addGradeData = gql`
	mutation addGrade(
        $id: Int
		$slug: String
		$number: String
		$name: LangInput
		$order: Int!
		$mark: String
		$period: [Int]!
	){
		addGrade(inputData: {
            id: $id
			slug: $slug
			number: $number
			name: $name
			order: $order
			mark: $mark
			period:$period
		}){
			code
			msg
		}
	}
`

/**
 * ID获取单个年级信息
 * @param id ID
 */
const findGradeData = gql`
	query ($id: Int!) {
		grade (id: $id) {
			id
			slug
			name {
                en
                mn
                zh
			}
			number
			order
			mark
			periods {
                id
                name {
                    en
                    mn
                    zh
                }
			}
		}
	}
`

/**
 * 年级信息删除
 * @param id 年级id
 */
const delGradeData = gql`
	mutation deleteGrade($id: [Int]!) {
		deleteGrade(id: $id) {
			code
			msg
		}
	}
`

export { gradesList, delGradeData, findGradeData, addGradeData, gradesNewList }

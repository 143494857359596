import { Directive, ElementRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import Setting from '../config';
import { Language } from '../model';

@Directive({
    selector: '[language]'
})
export class LanguageDirective implements OnChanges {
    @Input('language') language: Language;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    
        if (changes.language && changes.language.currentValue) {
            const lang = changes.language.currentValue;
            if (typeof (this.language) === 'string') {
                this.el.nativeElement.textContent += lang;
            } else {
                if (lang.name) {
                    this.el.nativeElement.textContent += lang.name[Setting.language];
                } else if (lang[Setting.language]) {
                    this.el.nativeElement.textContent += lang[Setting.language];
                } else {
                    this.el.nativeElement.textContent += '';
                }
            }
        }
    }
}

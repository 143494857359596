import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'orh-new-editor',
    styleUrls: ['./orh-new-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-tinymce">
            <div class="orh-tinymce-tool {{toolbar}}" style="height: 40px;padding-left: 2px"></div>
            <div class="orh-tinymce-content {{selector}}">
                <ng-container *ngIf="custom">
                    <ng-content></ng-content>
                </ng-container>
                <ng-container *ngIf="!custom">
                    <orh-new-editor-container [delay]="delay" [selector]="selector" [toolbar]="toolbar" [(ngModel)]="counts"
                                          width="100%"
                                          selector="orh-tinymce-container"
                                          (orhChange)="editorChange($event)"
                                          (orhInput)="editorInput($event)"
                                          (orhDelay)="tinymceDelay($event)"></orh-new-editor-container>
                </ng-container>
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrhNewEditorComponent),
            multi: true
        }
    ]
})
export class OrhNewEditorComponent implements ControlValueAccessor {

    @Input()
    selector!: string;

    @Input()
    toolbar!: string;

    @Input()
    custom = false;

    @Input()
    delay = 2000;

    @Output()
    orhDelay: EventEmitter<any> = new EventEmitter();

    @Output()
    orhInput: EventEmitter<any> = new EventEmitter();

    public counts = '';

    get count() {
        return this.counts;
    }

    set count(value: string) {
        this.counts = value;
        this.propagateChange(this.counts);
    }

    constructor() {
    }

    propagateChange = (_: any) => {};

    writeValue(value: any) {
        if (value !== this.count) {
            this.count = value;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
    }

    editorChange(event: any): void {
        this.count = event;
    }

    editorInput(event: any): void {
        if (!this.custom) {
            this.orhInput.emit(event);
        }
    }

    tinymceDelay(event: string): void {
        this.orhDelay.emit(event);
    }

}

import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { MESSAGE } from './utils/constant';
import { onError } from 'apollo-link-error';
import { NzNotificationService } from 'ng-zorro-antd';
import { environment } from '../environments/environment';

@NgModule({
    imports: [HttpClientModule, ApolloModule, HttpLinkModule],
})
export class GraphQLModule {
    public baseUrl: string = '/api/base/graphql';
    public graphData: Array<{ url: string; name: string }> = [
        {
            url: '/api/region/regiongql',
            name: 'region',
        },
        {
            url: '/api/attendence/graphql',
            name: 'attendance',
        },
        {
            url: '/api/teacher-profile/teachers-profile-gql',
            name: 'teacherprofile',
        },
        {
            url: '/api/material/graphql',
            name: 'material',
        },
        {
            url: '/api/examination/graphql',
            name: 'examination',
        },
        {
            url: '/api/base/graphql',
            name: MESSAGE,
        },
        {
            url: '/api/moral/graphql',
            name: 'moral',
        },
        {
            url: '/api/official-document/graphql',
            name: 'officialdocument',
        },
        {
            url: '/api/canteen/graphql',
            name: 'canteen',
        },
        {
            url: '/api/epidemic/graphql',
            name: 'epidemic',
        },
        {
            url: 'api/studentattendence/graphql',
            name: 'studentattendence',
        },
        {
            url: '/api/preparation/graphql',
            name: 'preparation',
        },
        {
            url: '/api/arrangecourse/graphql',
            name: 'arrangecourse',
        },
    ];

    constructor(apollo: Apollo, httpLink: HttpLink, private notifi: NzNotificationService) {
        const authMiddleware = new ApolloLink((operation, forward) => {
            let token = localStorage.getItem('token');
            operation.setContext({
                headers: new HttpHeaders().set(
                    'token',
                    token ? JSON.parse(token) : null,
                ),
            });
            return forward(operation);
        });
        /**
         * 错误处理
         */
        const logoutLink = onError(({ graphQLErrors, networkError }) => {
            if (!environment.production) {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) => {
                        this.notifi.blank(
                            'GrapqQl数据错误',
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                            { nzDuration: 0 },
                        );
                    });
                }

                if (networkError) {
                    //this.notifi.blank('GrapqQl路径错误', `[Network error]: ${networkError}`, { nzDuration: 0 });
                }
            }
        });
        /**
         * 默认接口
         */
        const baseDefault = httpLink.create({ uri: this.baseUrl });
        apollo.createDefault({
            link: logoutLink.concat(authMiddleware.concat(baseDefault)),
            cache: new InMemoryCache({
                addTypename: false,
            }),
        });

        /**
         * 扩展接口
         */
        this.graphData.map(el => {
            const httpL = httpLink.create({ uri: el.url });
            apollo.createNamed(el.name, {
                link: concat(authMiddleware, httpL),
                cache: new InMemoryCache({
                    addTypename: false,
                }),
            });
        });
    }
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AliPlayerConfig } from 'ngx-orhon-aliplayer/lib/player/player.component.modal';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
  selector: 'orh-lesson-video',
  templateUrl: './orh-lesson-video.component.html',
  styleUrls: ['./orh-lesson-video.component.less']
})
export class OrhLessonVideoComponent implements OnInit {

    @Input() videos: any
    data: any[] = [];
    config:AliPlayerConfig={
        autoplay:false
    }
    constructor(
        private http: RequestService
    ) { }

    ngOnInit() {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.videos.currentValue) {
            this.data = this.videos.map((data: any) => {
                this.http.post('resource/file/download', {
                    objectName: data.filePath,
                    newName: data.fileTitle
                }).subscribe((item) => {
                    data.fileUrl = item
                });

                data.fileCover = data.fileCover
                data.fileId = data.fileId;
                data.filePath = data.filePath;
                data.fileTitle = data.fileTitle;
                return data;

            })
        }

    }

}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addPassport, findpassport } from 'src/app/graphQl/teachers/teacher-profile/information';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
import { UtilsService } from '../../../../service/utils/utils.service';
@Component({
  selector: 'passport-management-operation',
  templateUrl: './passport-management-operation.component.html',
  styleUrls: ['./passport-management-operation.component.less']
})
export class PassportManagementOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        time:null,
        entime:null
    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
        private utils:UtilsService
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            gender : [null, Validators.required],//性别
            time: ['', Validators.required],//出国时间
            entime: ['', Validators.required],//回国时间
            companyopinion : [1],//所在单位审查意见审核(单选1、未审核2、审核通过3、不通过)
            nation :[null, Validators.required],//民族
            passportnumber:['', [Validators.required,this.utils.passportValidator]],//护照号码
           
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//姓名
            sourceofcost: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//费用来源
            sourceofcostwa: this.fb.group({
                zh: [''],
                mn: [''],
                en: ['']
            }),//海外关系
            reasonsabroad: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//出国申请理由
            nationality: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//出境地点
        })
    }
    dictionaryList: any = {
        nation: [],
        gender:[],
        nationality:[]

    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findpassport,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.passport
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd')    
            data.entime = this.date.transform(data.entime, 'yyyy-MM-dd')    
            form.get('gender')!.setValue(data.gender?data.gender.dictionary_value:null);
            form.get('time')!.setValue(data.time);  
            form.get('entime')!.setValue(data.entime);  
            form.get('nation')!.setValue(data.nation?data.nation.dictionary_value:null);
            form.get('passportnumber')!.setValue(data.passportnumber);
            form.get('companyopinion')!.setValue(data.companyopinion);
            form.get('name')!.setValue(data.name?data.name:{mn:null,zh:null,en:null});
            form.get('sourceofcost')!.setValue(data.sourceofcost?data.sourceofcost:{mn:null,zh:null,en:null});
            form.get('sourceofcostwa')!.setValue(data.sourceofcostwa?data.sourceofcostwa:{mn:null,zh:null,en:null})
            form.get('reasonsabroad')!.setValue(data.reasonsabroad?data.reasonsabroad:{mn:null,zh:null,en:null});
            form.get('nationality')!.setValue(data.nationality?data.nationality:{mn:null,zh:null,en:null})
            
        })
    }

    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.entime) {
            return false;
        }
        return startValue.getTime() > this.formBody.entime.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.time) {
            return false;
        }
        return endValue.getTime() <= this.formBody.time.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.time = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.entime = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = open;
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'nation',
            'gender',
            'nationality'
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.entime = this.date.transform(params.entime, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addPassport,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addPassport.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addPassport.msg)
            }
            that.form.reset()
        })
    }
}

import { PermissionsService } from './../../service/permissions/permissions.service'
import { Injectable } from '@angular/core'
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private permissions: PermissionsService) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.permissions.canActivate(state)
    }
}

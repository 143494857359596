import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { Apollo } from 'apollo-angular';
import { regionsList } from 'src/app/graphQl/base/regions';
import { BusinessService } from '../business/business.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(private apollo: Apollo, private business: BusinessService) { }

    encrypt(data: any) {
        let obj = JSON.stringify(data);
        return CryptoJS.AES.encrypt(obj, 'orhon').toString();
    }

    decrypt(data: any) {
        return JSON.parse(CryptoJS.AES.decrypt(data, 'orhon').toString(CryptoJS.enc.Utf8));
    }

    getRegions(callback?: Function) {
        let that = this;
        that.business.getIndexedDb('regions').subscribe((res: any) => {


            if (res.code === 200) {
                callback(res);
            } else {
                setTimeout(() => {
                    that.setRegions();
                }, 2000);
            }
        });
    }

    setRegions(): void {

        let that = this;
        that.apollo
            .watchQuery({
                query: regionsList,
                variables: {
                    page: 1,
                    limit: 10000,
                },
            })
            .valueChanges.subscribe((res: any) => {
                if (res.data.regions.code === 200) {
                    let province = res.data.regions.data.filter((el: any) => el.level === 0);
                    let city = res.data.regions.data.filter((el: any) => el.level === 1);
                    let area = res.data.regions.data.filter((el: any) => el.level === 2);
                    let data = province.map((el: any) => {
                        let id = parseInt(el.id);
                        let obj = {
                            label: el.name.zh,
                            value: id,
                            children: [],
                        };
                        obj.children = city
                            .filter((w: any) => el.id === w.parent.id)
                            .map((m: any) => {
                                let id = parseInt(m.id);
                                let obj1 = {
                                    label: m.name.zh,
                                    value: id,
                                    children: [],
                                };
                                obj1.children = area
                                    .filter((j: any) => m.id === j.parent.id)
                                    .map((n: any) => {
                                        let id = parseInt(n.id);
                                        let obj2 = {
                                            label: n.name.zh,
                                            value: id,
                                            isLeaf: true,
                                        };
                                        return obj2;
                                    });
                                return obj1;
                            });
                        return obj;
                    });
                    let dbData = {
                        data: data,
                    };
                    that.business.setIndexedDb(dbData, 'regions').subscribe(res => { });
                }
            });
    }

    handelCondition(data: object): any {
        let obj = Object.assign({}, data);
        Object.entries(obj).forEach((el, i) => {
            if (el[1] === null || el[1] === undefined || el[1] === ''|| el[1]===[]) delete obj[el[0]];
        });
        return obj;
    }

    // Step 去掉空数据
    handleFilterNull(data: object) {
        const hello = {};
        for (const key in data) {
            if (data[key].length !== 0) {
                hello[key] = data[key];
            }
        }
        return hello;
    }
    getStorage(): Promise<any> {
        const data = JSON.parse(localStorage.getItem('userInfo'));
        return Promise.resolve(data);
    }
    // 生成uuid
    uuid() {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }
    color(color: any, opacity: number, type?: boolean) {
        let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        // 把颜色值变成小写
        // var color = this.toLowerCase();
        if (reg.test(color)) {
            // 如果只有三位的值，需变成六位，如：#fff => #ffffff
            if (color.length === 4) {
                let colorNew = '#';
                for (let i = 1; i < 4; i += 1) {
                    colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
                }
                color = colorNew;
            }
            // 处理六位的颜色值，转为RGB
            let colorChange = [];
            for (let i = 1; i < 7; i += 2) {
                colorChange.push(parseInt('0x' + color.slice(i, i + 2)));
            }
            return 'rgba(' + colorChange.join(',') + ',' + opacity + ')';
        } else {
            return color;
        }
    }
    getUser(): Observable<any> {
        return new Observable(observe => {
            const user = localStorage.getItem('userInfo');
            observe.next(JSON.parse(user));
        });
    }
    //手机号验证
    mobileValidator(control: FormControl): any {

        // 获取到输入框的值
        const val = control.value;
        if (val) {
            // 手机号码正则
            const mobieReg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
            const result = mobieReg.test(val);
            // return result ? null : { idcard: { info: '手机号码格式不正确' } };
            if (!result) {
                return { confirm: true, error: true };
            }
        } else {
            return { required: true };
            // return { idcard: { info: '请输入手机号' } };
        }

    }
    //身份证号码验证
    idcardValidator(control: FormControl): any {

        // 获取到输入框的值
        const val = control.value;
        if (val) {
            // 身份证正则
            const mobieReg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/ || /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/;
            const result = mobieReg.test(control.value);
            if (!result) {
                return { confirm: true, error: true };
            }
            // return result ? null : { idcard: { info: '身份证格式不正确' } };
        } else {
            return { required: true };
            // return { idcard: { info: '请输入身份证' } };
        }

    }
    confirmationValidator(control: FormControl) {
        const val = control.value;
        if (val) {
            // 身份证正则
            const mobieReg = /^(\d{6})(\d{4})(\d{2})(\d{2})\d{2}(\d)(\d|X)$/;
            const result = mobieReg.test(val);
            if (!result) {
                return { confirm: true, error: true };
            }
        } else {
            return { required: true };
        }
    };
    //护照号码验证
    passportValidator(control: FormControl): any {

        // 获取到输入框的值
        const val = control.value;
        if (val) {
            // 身份证正则
            const mobieReg = /^[a-zA-Z0-9]{5,17}$/;
            const result = mobieReg.test(val);
            if (!result) {
                return { confirm: true, error: true };
            }
            // return result ? null : { passport: { info: '护照号码不正确' } };
        } else {
            return { required: true };
            // return { passport: { info: '请输入护照号码' } };
        }

    }
}

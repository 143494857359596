import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { addTeamBuilding, findteamBuilding } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { UtilsService } from 'src/app/service/utils/utils.service';
import Config from '../../../../config';
@Component({
  selector: 'team-building-operation',
  templateUrl: './team-building-operation.component.html',
  styleUrls: ['./team-building-operation.component.less']
})
export class TeamBuildingOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    
    constructor(
        private fb: FormBuilder,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private utils: UtilsService,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            memberid : [null, [Validators.required,this.utils.idcardValidator]],//成员身份证号
            memberphone: ['', [Validators.required,this.utils.mobileValidator]],//成员联系电话
            image : [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            commonproject: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//共同承担项目情况
            membername: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//成员姓名
            membereducation: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//成员学历
            memberpost: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//成员职称
        })
    }
    ngOnInit() {
        if (this.condition) {
            this.findData()
        }
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //单条
    findData() {
        let that = this;
        const params = {
            query: findteamBuilding,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.teamBuilding
            let form = this.form;
           
            form.get('memberid')!.setValue(data.memberid);   
            form.get('memberphone')!.setValue(data.memberphone);  
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('commonproject')!.setValue(data.commonproject?data.commonproject:{mn:null,zh:null,en:null});
            form.get('membername')!.setValue(data.membername?data.membername:{mn:null,zh:null,en:null});
            form.get('membereducation')!.setValue(data.membereducation?data.membereducation:{mn:null,zh:null,en:null});
            form.get('memberpost')!.setValue(data.memberpost?data.memberpost:{mn:null,zh:null,en:null});
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }else{
            for (const i in this.form.controls) {
                this.form.controls[i].markAsDirty();
                this.form.controls[i].updateValueAndValidity();
            }
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.teacher_id = this.teacherId
        const mutationParams = {
            mutation: addTeamBuilding,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addTeamBuilding.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            }  else{
                this.msg.error(res.data.addTeamBuilding.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];
}

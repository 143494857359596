import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { addDoctoralSupervisor, findDoctoralSupervisor } from 'src/app/graphQl/teachers/teacher-profile/information';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'ph-d-operation',
  templateUrl: './ph-d-operation.component.html',
  styleUrls: ['./ph-d-operation.component.less']
})
export class PhDOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    constructor(
        private fb: FormBuilder,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            isteach: ['', Validators.required],//是否博导1、是2、不是
            years: ['', Validators.required],//博导时间
            shenhe: [1],//审核(单选1、未审核2、审核通过)
        })
    }
    
    ngOnInit() {
       
        if (this.condition) {
            this.findData()
        }
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //单条
    findData() {
        let that = this;
        const params = {
            query: findDoctoralSupervisor,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {

            let data = res.data.doctoralSupervisor
            let form = this.form;
            data.years = this.date.transform(data.years, 'yyyy-MM-dd');          
            form.get('isteach')!.setValue(data.isteach)
            form.get('years')!.setValue(data.years)
            form.get('shenhe')!.setValue(data.shenhe)
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
                
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
   

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.years = this.date.transform(params.years, 'yyyy-MM-dd');          
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addDoctoralSupervisor,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addDoctoralSupervisor.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addDoctoralSupervisor.msg)
            }

            that.form.reset()


        })

    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);
                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];

    disabledDate = (current: Date): boolean => {
        // Can not select days before today and today
        return current > new Date()
    };
}

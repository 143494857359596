import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {
    constructor(private db: NgxIndexedDBService) {
        db.currentStore = 'regions';
    }
    public colors: string = null;
    // public UserInfos: Subject<Object> = new Subject();
    private _colors = new Subject<any>();


    sendcolos(Option: Subject<any>, message: any) {
        Option.next(message);
    }

    getcolos(): Observable<any> {
        return this._colors.asObservable();
    }
    freshUserInfo(){
        if(!this.colors){
        //    let backgroundColors = this.getItem('userInfo').school.settings ? this.getItem('userInfo').school.settings.themeSettings.zh : '#206F53';
        let backgroundColors = this.getItem('userInfo').school ? (this.getItem('userInfo').school.settings ? this.getItem('userInfo').school.settings.themeSettings.zh : '#206F53') : '#206F53';
            this.colors = backgroundColors;
        }
        this.sendcolos(this._colors, this.colors);
    }

    setcolos(colos: any) {
        this.colors = colos;
        this.sendcolos(this._colors, colos);
    }
    // 将数据写入localStorage
    setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // 从localStorage中读取key的值，转化为JSON对象
    getItem(key) {
        return localStorage.getItem(key)
            ? JSON.parse(localStorage.getItem(key))
            : '';
    }

    // 从localStorage中删除key的值
    removeItem(key) {
        localStorage.removeItem(key);
    }

    /**
     * 获取 IndexedDB 数据库数据
     * @param name key
     */
    getIndexedDb(name: string) {
        const id = localStorage.getItem(name);
        if (id) {
            return new Observable(observe => {
                this.db.getByID(parseInt(id)).then(
                    res => {
                        
                        if (res) {
                            observe.next({ code: 200, data: res, msg: '成功' });
                        } else {
                            observe.next({ code: 400, msg: '不存在' });
                        }
                    },
                    error => {
                        observe.next({ code: 400, msg: error });
                    }
                );
            });
        } else {
            return new Observable(observe => {
                observe.next({ code: 400, msg: '不存在' });
            });
        }
    }

    /**
     * 删除 IndexedDB 清楚数据库
     */
    clearIndexedDb(): void {
        this.db.clear();
    }

    /**
     * 添加/更新 IndexedDB 数据库
     * @param data 存储的数据
     * @param name key
     */
    setIndexedDb(data: any, name: string) {
        
        const id = localStorage.getItem(name);
        if (id) {
           
            const form = Object.assign(
                {
                    id: parseInt(id)
                },
                data
            );

            return new Observable(observe => {
                this.db.update(form).then(
                    () => {
                        observe.next({ code: 200, data: id, msg: '成功' });
                    },
                    error => {
                        observe.next({ code: 400, msg: error });
                    }
                );
            });
        } else {
           
            const timestamp = new Date().getTime();
            const form = Object.assign(
                {
                    id: timestamp
                },
                data
            );

            return new Observable(observe => {
                this.db.update(form).then(
                    () => {
                      
                        localStorage.setItem(name, JSON.stringify(timestamp));
                        observe.next({
                            code: 200,
                            data: timestamp,
                            msg: '成功'
                        });
                    },
                    error => {
                        observe.next({ code: 400, msg: error });
                    }
                );
            });
        }
    }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'orh-file-content',
    templateUrl: './orh-file-content.component.html',
    styleUrls: ['./orh-file-content.component.scss']
})
export class OrhFileContentComponent implements OnInit,OnChanges {
    @Input() content: any;
    data:any;
    constructor() { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.content.currentValue){
            this.data=this.content
        }
        
    }

}


export default [
    //平台基础
    {
        label: '平台管理',
        value: 'iconjichuxinxi',
    },
    // {
    //     label: 'API管理',
    //     value: '',
    // },
    // {
    //     label: '模块管理',
    //     value: '',
    // },
    // {
    //     label: '账号管理',
    //     value: '',
    // },
    // {
    //     label: '学段管理',
    //     value: '',
    // },
    // {
    //     label: '学科管理',
    //     value: '',
    // },
    // {
    //     label: '数据字典管理',
    //     value: '',
    // },
    // {
    //     label: '年级管理',
    //     value: '',
    // },
    // {
    //     label: '地区管理',
    //     value: '',
    // },
    {
        label: '学校管理',
        value: 'iconxuexiaoxinxi',
    },
    {
        label: '职务管理',
        value: '',
    },
    {
        label:'网盘',
        value:'iconwangpan'
    },
    //学校基础数据
    {
        label: '学校基础信息',
        value: 'iconxuexiaopeizhi',
    },
    {
        label:'学校基础数据',
        value:'iconxuexiaojichushuju'

    },
    {
        label: '资产管理',
        value: 'iconzichanguanli',
    },
    // {
    //     label: '场地管理',
    //     value: '',
    // },
    // {
    //     label: '楼宇管理',
    //     value: '',
    // },
    // {
    //     label: '单元管理',
    //     value: '',
    // },
    // {
    //     label: '楼层管理',
    //     value: '',
    // },
    // {
    //     label: '房间管理',
    //     value: '',
    // },
    {
        label: '校历管理',
        value: 'iconxiaoliguanli',
    },
    {
        label: '作息管理',
        value: 'iconzuoxiguanli',
    },
    {
        label: '课程管理',
        value: 'iconkecheng',
    },
    {
        label: '课程管理1',
        value: 'iconkechengguanli',
    },
    {
        label: '届维护',
        value: 'iconjiejiweihu',
    },
    {
        label: '班主任管理',
        value: 'iconbanzhurenguanli',
    },
    {
        label: '班级管理',
        value: 'iconbanjiguanli',
    },
    {
        label: '学期管理',
        value: 'iconxueqi',
    },
    {
        label: '组织架构管理',
        value: 'iconzuzhijiagouguanli',
    },
    // {
    //     label: '部门管理',
    //     value: '',
    // },
    // {
    //     label: '权限分配管理',
    //     value: '',
    // },
    // {
    //     label: '职务管理',
    //     value: '',
    // },
    // {
    //     label: '人员分配调配',
    //     value: '',
    // },
    {
        label: '假期管理',
        value: 'iconjiaqiguanli',
    },

    //学生管理模块
    {
        label: '学生管理模块',
        value: 'iconxueshengguanli1',
    },
    {
        label: '学生管理',
        value: 'iconxueshengguanli',
    },
    {
        label: '我的班级学生',
        value: 'iconwodebanjixuesheng',
    },
    {
        label: '学籍管理',
        value: 'iconxuejiguanli',
    },
    {
        label: '学生基础信息',
        value: 'iconxueshengjichuxinxi',
    },
    // {
    //     label: '走读/寄宿生',
    //     value: '',
    // },
    // {
    //     label: '学籍异动',
    //     value: '',
    // },
    // {
    //     label: '学生分班管理',
    //     value: '',
    // },
    {
        label: '档案管理',
        value: 'icondanganguanli1',
    },
    {
        label: '升学年',
        value: '',
    },
    {
        label: '学生成长档案',
        value: 'iconxueshengdangan',
    },
    {
        label: '档案模版',
        value: '',
    },
    {
        label: '我的班级学生',
        value: 'iconwodebanjixuesheng',
    },
    {
        label: '管理模版',
        value: '',
    },
    {
        label: '操作记录',
        value: '',
    },
    {
        label: '信息变更',
        value: '',
    },
    {
        label: '家长审核管理',
        value: 'iconjiachangshenheguanli',
    },

    //教职工管理
    {
        label: '教职工管理',
        value: 'iconjiaozhigongguanli',
    },
    {
        label: '教职工信息管理',
        value: 'iconjiaozhigongxinxi',
    },
    {

        label:'教职工信息统计',
        value:'iconjiaozhigongxinxitongji'
    },

    //考勤管理模块
    {
        label: '考勤管理',
        value: 'iconkaoqin',
    },
    {
        label: '考勤管理1',
        value: 'iconkaoqinguanli1',
    },
    {
        label: '首页',
        value: 'iconshouye',
    },
    {
        label: '考勤规则设置',
        value: 'iconkaoqinguizeshezhi',
    },
    {
        label: '请假管理',
        value: 'iconqingjiaguanli1',
    },
    {
        label: '考勤统计以及管理',
        value: 'iconkaokaoqintongjijiguanli',
    },

    //值班管理
    {
        label: '值班',
        value: 'iconzhiban'
    },
    {
        label: '值班管理',
        value: 'iconzhibanguanli',
    },
    {
        label: '行政值班',
        value: '',
    },
    {
        label: '我的值班',
        value: 'iconwodezhiban',
    },
    {
        label: '我的反馈',
        value: '',
    },
    {
        label: '值班统计',
        value: 'iconzhibantongji',
    },
    {
        label: '值班配置',
        value: 'iconzhibanpeizhi',
    },

    //德育管理
    {
        label: '德育管理',
        value: 'icondeyuguanli',
    },
    {
        label: '德育',
        value:'icondeyuzhongwen'
    },
    {
        label: '德育系统配置',
        value: 'icondeyuxitongpeizhi',
    },
    {
        label: '年级德育数据',
        value: 'icondeyushuju1',
    },
    {
        label: '学校德育数据',
        value: '',
    },
    {
        label: '我的德育数据（学生）',
        value: '',
    },
    {
        label: '我的班级德育数据（班主任）',
        value: '',
    },
    {
        label: '我的孩子德育数据',
        value: '',
    },
    {
        label: '德育文档',
        value: '',
    },
    {
        label: '学生德育分',
        value: 'iconxueshengdeyufen',
    },
    {
        label: '班主任常规工作',
        value: 'iconbanzhurenchangguigongzuo',
    },
    {
        label: '班主任统计',
        value: 'iconbanzhurentongji',
    },
    {
        label: '教师德育分组',
        value: '',
    },
    {
        label: '走读生考勤时间设置',
        value: '',
    },
    {
        label: '学生门禁记录',
        value: 'iconnianjixueshengmenjinjilu',
    },
    {
        label: '学生宿舍门禁记录',
        value: '',
    },
    {
        label: '教师宿舍门禁记录',
        value: 'iconjiaoshimenjinjilu',
    },
    {
        label: '我的班级门禁记录',
        value: 'iconbanjimenjinjilu',
    },
    {
        label: '我的门禁记录',
        value: '',
    },
    {
        label: '德育数据',
        value: 'icondeyushuju1',
    },

    //备课系统
    {
        label: '备课系统',
        value: 'iconbeike',
    },
    {
        label: '我的备课',
        value: 'iconwodebeike',
    },

    //培训管理iconpeixunguanli
    {
        label: '培训管理',
        value: 'iconpeixunguanli',
    },
    {
        label: '培训通知',
        value: 'iconpeixuntongzhi',
    },
    {
        label: '考勤管理',
        value: 'iconkaoqin',
    },

    //智慧校园区域版
    {
        label: '智慧校园区域版',
        value: 'iconzhihuixiaoyuan',
    },

    //物资管理
    {
        label: '物资管理',
        value: 'iconwuziguanli',
    },
    {
        label: '分类管理',
        value: 'iconsheshifenlei',
    },
    {
        label: '物品管理',
        value: 'iconwupinguanli',
    },
    {
        label: '物品查看',
        value: 'iconwupinchakan',
    },
    {
        label: '字典表',
        value: 'iconzidianbiao',
    },

    //考务管理
    {
        label: '考务管理',
        value: 'iconkaowuguanli',
    },
    {
        label: '考务管理首页',
        value: 'iconkaowuguanlishouye',
    },
    {
        label: '考试管理',
        value: 'iconkaoshiguanli',
    },
    {
        label: '考试管理1',
        value: 'iconkaoshiguanli1',
    },

    //课程管理
    {
        label: '课程管理',
        value: 'iconkecheng',
    },
    {
        label: '课程管理1',
        value: 'iconkechengguanli',
    },
    {
        label: '手动录课',
        value: 'iconshoudongluke',
    },
    {
        label: '班级分配教师',
        value: 'iconbanjifenpeijiaoshi',
    },
    {
        label: '智能排课',
        value: 'iconzhinengpaike',
    },
    {
        label: '任课管理',
        value: 'iconrenkeguanli',
    },
    {
        label: '教师课表',
        value: 'iconjiaoshikebiao',
    },
    {
        label: '手动调课',
        value: 'iconshoudongtiaoke',
    },

    //教师成长档案
    {
        label: '教师成长档案',
        value: 'iconjiaoshidangan',
    },
    {
        label: '教职工档案',
        value: 'iconjiaoshidangan',
    },
    {
        label: '教职工档案1',
        value: 'iconjiaozhigongdangan',
    },
    {
        label: '档案管理',
        value: 'icondanganguanli',
    },
    {
        label: '我的档案',
        value: 'iconwodedangan',
    },

    //消息通知
    {
        label: '消息通知',
        value: 'iconxiaoxitongzhi',
    },
    {
        label: '消息分类管理',
        value: 'iconxiaoxifenleiguanli',
    },
    {
        label: '消息管理',
        value: 'iconxiaoxiguanli',
    },
    {
        label: 'App版本管理',
        value: 'iconappbanbenguanli',
    },
    //学生档案
    {
        label: '学生档案',
        value: 'iconxueshengdangan',
    },
    {
        label: '档案管理',
        value: 'icondanganguanli2',
    },

    //成绩分析
    {
        label: '成绩分析',
        value: 'iconchengjifenxi',
    },

    //安全设施
    {
        label: '安全设施',
        value: 'iconanquansheshi',
    },
    {
        label: '安全设施1',
        value: 'iconanquansheshi2',
    },
    {
        label: '人员任务分配',
        value: 'iconrenyuanrenwufenpei',
    },
    {
        label: '设施分类',
        value: 'iconsheshifenlei',
    },

    //校务综合
    {
        label: '校务综合',
        value: 'iconxiaowuzonghe',
    },
    {
        label: '校务',
        value: 'iconxiaowu',
    },
    {
        label: '通知公告',
        value: 'icontongzhigonggao',
    },
    {
        label: '意见箱',
        value: 'iconyijianxiang',
    },
    {
        label: '名人名言轮播图',
        value: 'iconmingrenmingyanlunbotu',
    },

    //公文流转
    {
        label: '公文流转',
        value: 'icongongwenjiaohu',
    },
    {
        label: '公文常用人',
        value: 'icongongwenchangyongren',
    },
    {
        label: '所有公文',
        value: 'iconsuoyougongwen',
    },
    {
        label: '我发布的公文',
        value: 'iconwofabudegongwen',
    },
    {
        label: '收到的公文',
        value: 'iconshoudaodegongwen',
    },
    {
        label: '公文审核',
        value: 'icongongwenshenhe1',
    },

    //宿舍管理
    {
        label: '宿舍管理',
        value: 'iconsusheguanli',
    },
    {
        label: '人员管理',
        value: 'iconrenyuanguanli',
    },

    //食堂管理
    {
        label: '食堂管理',
        value: 'iconshitang',
    },
    {
        label: '承包商',
        value: 'iconchengbaoshang',
    },
    {
        label: '窗口',
        value: 'iconchuangkou',
    },
    {
        label: '食谱(学生)',
        value: 'iconxueshengshipu',
    },
    {
        label: '食谱(教师)',
        value: 'iconjiaoshishipu',
    },
    {
        label: '菜单分类',
        value: 'iconcaidanfenlei',
    },
    {
        label: '菜',
        value: 'iconcai',
    },
    {
        label: '员工管理',
        value: 'iconyuangongguanli',
    },
    {
        label: '窗口下菜单',
        value: 'iconchuangkouxialacaidan',
    },
    {
        label: '供应商',
        value: 'icongongyingshang',
    },
    {
        label: '采购管理',
        value: 'iconcaigouguanli',
    },

    //人员安全
    {
        label: '人员安全',
        value: 'iconrenyuananquan',
    },
    {
        label: '个人安全管理',
        value: 'icongerenanquanguanli',
    },
    {
        label: '学生安全管理',
        value: 'iconxueshenganquanguanli',
    },
    {
        label: '教师安全管理',
        value: 'iconjiaoshianquanguanli',
    },
    {
        label: '外来人员出入管理',
        value: 'iconwailairenyuanchuruguanli',
    },
    {
        label: '外来人员信息管理',
        value: 'iconwailairenyuanxinxiguanli',
    },


    //选课管理
    {
        label: '选课管理',
        value: 'iconxuanke',
    },
    {
        label: '选课管理1',
        value: 'iconxuankeguanli',
    },

    //学生选课模块
    {
        label: '学生选课模块',
        value: 'iconxueshengxuanke',
    },
    {
        label: '学生选课模块',
        value: 'iconxueshengxuankemokuai',
    },

    //工作管理
    {
        label: '工作管理',
        value: '',
    },
    {
        label: '日报管理',
        value: 'iconribaoguanli',
    },
    {
        label: '我的日报',
        value: 'iconwoderibao',
    },
    {
        label: '统计管理',
        value: 'iconzhibantongji',
    },

    //健康打卡统计App
    {
        label: '健康打卡统计App',
        value: 'iconyiqing',
    },

    //资源管理
    {
        label: '资源',
        value: 'iconziyuan',
    },
    {
        label: '资源管理',
        value: 'iconziyuanguanli',
    },
    {
        label: '文件管理',
        value: 'iconwenjianguanli1',
    },
    {
        label: '我的收藏',
        value: 'iconwodeshoucang',
    },
    {
        label: '积分排行',
        value: 'iconjifenpaihang',
    },
    {
        label: '积分配置',
        value: 'iconjifenpeizhi1',
    },
    {
        label: '人员资源统计分析',
        value: 'iconrenyuanziyuantongjifenxi',
    },



    {
        label: '全屏',
        value: 'iconquanping',
    },
    {
        label: '历史',
        value: 'iconlishi',
    },
    {
        label: '留言建议',
        value: 'iconliuyanjianyi',
    },
    {
        label: 'logo',
        value: 'iconlogo',
    },

    {
        label: '校园活动',
        value: 'iconxiaoyuanhuodong',
    },
    {
        label: '云课堂',
        value: 'iconyunketang',
    },

    {
        label: '教学计划总结',
        value: 'iconjiaoxuejihuazongjie',
    },
    {
        label: '素材库',
        value: 'iconsucaiku',
    },
    {
        label: '实验室管理',
        value: 'iconshiyanshiguanli',
    },
    {
        label: '通讯录',
        value: 'icontongxunlu',
    },
    {
        label: '听课评课',
        value: 'icontingkepingke',
    },
    {
        label: '校历周历',
        value: 'iconxiaolizhouli',
    },
    {
        label: '题库组卷',
        value: 'icontikuzujuan',
    },
    {
        label: '荣誉管理系统',
        value: 'iconrongyuguanlixitong',
    },
    {
        label: '民意测评',
        value: 'iconminyiceping',
    },
    {
        label: '人事管理',
        value: 'iconrenshiguanli',
    },
    {
        label: '教研工作统计',
        value: 'iconjiaoyangongzuotongji',
    },
    {
        label: '教学微博',
        value: 'iconjiaoxueweibo',
    },
    {
        label: '课堂管理',
        value: 'iconketangguanli',
    },
    {
        label: '考务管理',
        value: 'iconkaowuguanli',
    },
    {
        label: '教研活动',
        value: 'iconjiaoyanhuodong',
    },
    {
        label: '教师考勤',
        value: 'iconjiaoshikaoqin',
    },
    {
        label: '电子班牌',
        value: 'icondianzibanpai',
    },

    {
        label: '教师评价',
        value: 'iconjiaoshipingjia',
    },
    {
        label: '教学资源',
        value: 'iconjiaoxueziyuan',
    },
    {
        label: '学生保险',
        value: 'iconxueshengbaoxian',
    },
    {
        label: '我的组卷',
        value: 'iconwodezujuan',
    },
    {
        label: '学生荣誉',
        value: 'iconxueshengrongyu',
    },
    {
        label: '学生请假',
        value: 'iconxueshengqingjia',
    },
    {
        label: '排课',
        value: 'iconpaike',
    },
    {
        label: '实验室物资 采购申请',
        value: 'iconshiyanwuzicaigoushenqing',
    },
    {
        label: '我的课表',
        value: 'iconwodekebiao',
    },
    {
        label: '教师课时 量统计',
        value: 'iconjiaoshikeshiliangtongji',
    },
    {
        label: '学生信息',
        value: 'iconxueshengxinxi',
    },
    {
        label: '年级学生 信息',
        value: 'iconnianjixueshengxinxi',
    },

    {
        label: '学生考试 安排',
        value: 'iconxueshengkaoshianpai',
    },
    {
        label: '年级学生 德育数据',
        value: 'iconnianjixueshengdeyushuju',
    },
    {
        label: '班级课表',
        value: 'iconbanjikebiao',
    },
    {
        label: '我的题库',
        value: 'iconwodetiku',
    },
    {
        label: '我的监考',
        value: 'iconwodejiankao',
    },
    {
        label: '年级学生 门禁记录',
        value: 'iconnianjixueshengmenjinjilu',
    },
    {
        label: '年级学生 请假统计',
        value: 'iconnianjixueshengqingjiatongji',
    },
    {
        label: '实验室物 资申请',
        value: 'iconshiyanshiwuzishenqing',
    },
    {
        label: '请假审批',
        value: 'iconqingjiashenpi',
    },

    {
        label: '班级宿舍',
        value: 'iconbanjisushe',
    },
    {
        label: '监考老师 安排',
        value: 'iconjiankaolaoshianpai',
    },
    {
        label: '教师门禁 记录',
        value: 'iconjiaoshimenjinjilu',
    },
    {
        label: '班级门禁 记录',
        value: 'iconbanjimenjinjilu',
    },

    {
        label: '职工信息管理',
        value: 'iconzhigongxinxiguanli',
    },



    {
        label: '疫情',
        value: 'iconyiqing'
    },
    {
        label: '学生考勤',
        value: 'iconxueshengkaoqin'
    },
    {
        label: '消防安全',
        value: 'iconxiaofanganquan'
    },

    {
        label: '宿舍',
        value: 'iconsusheguanli'
    },
    {
        label: '宿舍',
        value: 'iconsushe'
    },
    {
        label: ' 排行',
        value: 'iconpaihang'
    },
    {
        label: '年级学生 请假统计',
        value: 'iconnianjixueshengqingjiatongji',
    },

];

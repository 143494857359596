import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { interval, Observable, Subscription } from 'rxjs';
declare let tinymce: any;

@Component({
    selector: 'orh-lesson-editor-container',
    styleUrls: ['./orh-lesson-editor.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-tinymce-container" [ngClass]="selector" [style.minHeigth]="width" (blur)="orhBlur()"
             [attr.placeholder]="placeholder" (input)="inputTinymce($event)">
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrhLessonEditorContainerComponent),
            multi: true
        }
    ]
})
export class OrhLessonEditorContainerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit {
    @Input() selector!: string;
    @Input() toolbar!: string;

    @Input() placeholder:string = '请输入内容';

    @Input() width:string = '50px';

    @Input() delay:number = 2000;

    @Output() orhChange:EventEmitter<any> = new EventEmitter();

    @Output() orhInput: EventEmitter<any> = new EventEmitter();

    @Output() orhDelay: EventEmitter<any> = new EventEmitter();

    public interval$: Subscription;

    public apiKey = 't3c6qnygees4ydj2cwy1wdi6k0ft4468dzhcg21zap0stjs5';
    public editorConfig = {
        selector: '',
        language: 'zh_CN',
        fixed_toolbar_container: '',
        menubar: false,
        inline: true,
        disabled: true,
        // language: 'mn_CN',
        // language_url: '/assets/tinymce1/langs/mn_CN.js',
        // | mongolcharmap removeformat table topic template spellchecker tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry
        toolbar1: 'undo fontselect fontsizeselect redo bold italic underline strikethrough forecolor alignleft aligncenter alignright alignjustify outdent indent removeformat ',
        toolbar2: 'paste pastetext | mongolcharmap removeformat table template spellchecker tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
        // 字体
        paste_as_text: true,
        // paste_use_dialog : true,
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px',
        plugins: 'orhoncovertcode spellchecker mongolcharmap emoticons autolink link image imagetools textcolor lists print insertdatetime media toc preview fullscreen paste preview tiny_mce_wiris template',
        // 行高
        lineheight_formats: '10px 12px 14px 16px 18px 20px 22px 24px 26px 30px',
        font_formats: '微软雅黑=微软雅黑; 宋体=宋体; 黑体=黑体; 仿宋=仿宋; 楷体=楷体; 隶书=隶书; 幼圆=幼圆; 方正舒体=方正舒体; 方正姚体=方正姚体; 等线=等线; 华文彩云=华文彩云; 华文仿宋=华文仿宋; 华文行楷=华文行楷; 华文楷体=华文楷体; 华文隶书=华文隶书;Arial=arial; Arial Black=arial black; Book Antiqua=book antiqua; Comic Sans MS=comic sans ms;ᠴᠠᠭᠠᠨ ᠲᠢᠭ=OrhonNotoTig;ᠭᠠᠷᠴᠠᠭ ᠲᠢᠭ=OrhonNotoTig;ᠬᠠᠸᠠᠩ ᠲᠢᠭ=OrhonHawangTig;ᠪᠠᠩ ᠪᠠᠩ ᠲᠢᠭ=OrhonBangbangTig;ᠪᠣᠣ ᠱᠠᠨ ᠲᠢᠭ=OrhonBaoshanTig;ᠼᠠᠢ ᠶᠦᠨ ᠲᠢᠭ=OrhonCaiyunTig;ᠬᠠᠷ᠎ᠠ ᠲᠢᠭ=OrhonHarTig;ᠬᠤᠯᠤᠰᠤᠨ ᠴᠠᠭᠠᠨ ᠲᠢᠭ=OrhonHulsunBaiti;ᠭᠣᠣᠯ ᠬᠣᠭᠤᠰᠤᠨ ᠲᠢᠭ=OrhonKongxinTig;ᠫᠠᠢᠰᠠᠨ ᠲᠢᠭ=OrhonPaibianTig;ᠰᠡᠶᠢᠯᠦᠮᠡᠯ ᠲᠢᠭ=OrhonSeilmelTig;ᠳᠤᠰᠤᠯ ᠲᠠᠢ ᠣᠢᠭᠤᠷᠵᠢᠨ ᠲᠢᠭ=OrhonYoudianhuiheTig;ᠳᠤᠰᠤᠯ ᠦᠭᠡᠢ ᠣᠢᠭᠤᠷᠵᠢᠨ ᠲᠢᠭ=OrhonWudianhuiheTig;ᠰᠢᠤ ᠯᠢ ᠲᠢᠭ=OrhonXiuliTig;ᠪᠢᠴᠢᠯ ᠲᠢᠭ=OrhonYoutiTig;', // 工具栏自定义字体选项

        image_description: false,
        paste_data_images: true,
        template_selected_content_classes: 'selcontent selectedcontent',
        template_cdate_classes: 'cdate creationdate',
        template_mdate_classes: 'mdate modifieddate',
        template_cdate_format: '%m/%d/%Y : %H:%M:%S',
        template_mdate_format: '%m/%d/%Y : %H:%M:%S',
        template_popup_height: '500',
        template_popup_width: '600',
        templates: '/api/tools/editor/v1/template',
        imagetools_toolbar: 'rotateleft rotateright imageoptions',
        imagetools_cors_hosts: ['storage.orhonedu.com'],
        // 上传路径
        images_upload_url: 'https://api.orhonedu.com/api/system/v1/new/editor/upload',
        // 自动上传图片
        automatic_uploads: true,
        images_dataimg_filter(img) {

            // return  tinymce.activeEditor.insertContent(`< img src="${img}" data-latex="data-latex">`);
            return !img.hasAttribute('data-latex');
        },
        images_reuse_filename: false,
        // 隐藏bar栏
        statusbar: false,
        relative_urls: false,
        remove_script_host: false,
        paste_preprocess: (plugin: any, args: any) => {
            setTimeout(() => {
                const content = args.target.targetElm.innerHTML;
                this.count = content;
                this.orhInput.emit(this.count);
                this.getTime();
            });
        },
        
        powerpaste_word_import: 'propmt',
        powerpaste_html_import: 'propmt',
        poverpaste_allow_local_images: true,
        end_container_on_empty_block: true,
    };

    public counts = '';
    editorM: any;
    editorState = false;

    get count() {
        return this.counts;
    }

    set count(value: string) {

        this.counts = value ? value : '';
        this.propagateChange(this.counts);


        if (this.editorState) {
            //this.editorM.setContent(this.counts);
            this.editorState = false;
        }
        this.cdr.detectChanges();
    }

    constructor(private cdr: ChangeDetectorRef) {
    }

    propagateChange = (_: any) => {
    };

    writeValue(value: any) {
        if (value !== this.count) {
            this.count = value;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
    }

    ngOnInit() {
        

    }

    ngAfterViewInit(): void {
        this.editorConfig.selector = `.${this.selector ? this.selector : 'orh-tinymce-content'}`;
        this.editorConfig.fixed_toolbar_container = `.${this.toolbar}`;
        console.log(this.editorConfig);
        
        tinymce.init(this.editorConfig).then((res:any) => {
            console.log(res);
            
            this.editorM = res[0];
            this.editorState = true;
            res[0].setContent(this.counts);
        });
    }

    ngOnDestroy(): void {
        tinymce.remove(this.selector);
    }

    inputTinymce(event: any): void {
        // const data = tinymce.activeEditor.getContent();
        const data = tinymce.activeEditor.targetElm.innerHTML;
        this.count = data;
        this.orhInput.emit(event);
        
    }
    getTime(){
        if (this.interval$) {
            this.interval$.unsubscribe();
        }
        const interval$: Observable<number> = interval(this.delay);
        this.interval$ = interval$.subscribe(res => {
            this.interval$.unsubscribe();
            this.orhDelay.emit();
        });
    }
    orhBlur(): void {
        const data = tinymce.activeEditor.getContent();
        this.count = data;
        this.orhChange.emit(data);
    }

}

import {Component, OnInit, Input, OnDestroy, AfterViewInit} from '@angular/core';

@Component({
    selector: 'app-orh-countdown',
    templateUrl: './orh-countdown.component.html',
    styleUrls: ['./orh-countdown.component.less']
})
export class OrhCountdownComponent implements OnInit, OnDestroy, AfterViewInit {
    ngOnInit(): void {

    }

// 父组件传递截止日期
    @Input() endDate: number;
    // 父组件传递标题
    @Input() title: string;
    // 小时差
    public hour: number;
    // 分钟差
    public minute: number;
    // 秒数差
    public second: number;
    // 时间差
    public _diff: number;
    public get diff() {
        return this._diff;
    }

    public set diff(val) {
        this._diff = Math.floor(val / 1000);
        this.hour = Math.floor(this._diff / 3600);
        this.minute = Math.floor((this._diff % 3600) / 60);
        this.second = (this._diff % 3600) % 60;
    }

    // 定时器
    public timer;

    // 每一秒更新时间差
    ngAfterViewInit() {
        this.timer = setInterval(() => {
            this.diff = this.endDate - Date.now();
            if (this.diff < 0) {
                this.diff = 0;
                clearInterval(this.timer);
            }
        }, 1000);
    }

    // 销毁组件时清除定时器
    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}

import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {DocumentNode} from 'graphql';
import {Apollo} from 'apollo-angular';
import {GraphqlMutationParams, GraphqlQueryParams, RefetchQuery} from '../../model';
import { HttpErrorResponse } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GraphqlServiceService {

    constructor(private apollo: Apollo) { }

    /**
     * query 请求
     * @param query
     * @param variables
     * @param policy
     * @constructor
     */
    Query(query: DocumentNode, variables?: any, policy: any = 'network-only') {
        return new Observable((observe) => {
            this.apollo.use('region').watchQuery({
                query,
                variables,
                fetchPolicy: policy
            }).valueChanges.subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            })
        })
    };

    /**
     * mutation 请求
     * @param mutation
     * @param variables
     * @param query
     * @constructor
     */
    Mutation(mutation: DocumentNode, variables?: any, query: Array<RefetchQuery> = []) {
        return new Observable((observe) => {
            this.apollo.use('region').mutate({
                mutation,
                variables,
                refetchQueries: [...query],
                fetchPolicy: 'no-cache'
            }).subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            })
        })
    };

    QueryAttendance(query: DocumentNode, variables?: any, policy: any = 'network-only') {
        return new Observable((observe) => {
            this.apollo.use('attendance').watchQuery({
                query,
                variables,
                fetchPolicy: policy
            }).valueChanges.subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            })
        })
    }
    MutationAttendance(mutation: DocumentNode, variables?: any, query: Array<RefetchQuery> = []) {
        return new Observable((observe) => {
            this.apollo.use('attendance').mutate({
                mutation,
                variables,
                refetchQueries: [...query],
                fetchPolicy: 'no-cache'
            }).subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            })
        })
    }

    /**
     * 统一使用下方方法请求接口数据、上方的后期会弃用
     *
     */


    /**
     * query 请求
     * @param params[qyery]     GQL 语句          必填
     * @param params[variables] 条件参数           选填
     * @param params[policy]    是否缓存 默认缓存   选填
     * @param params[name]      gql endpoint 语句  选填
     * @constructor
     */
    GQuery(params: GraphqlQueryParams) {
        let { query, variables = {}, policy = 'network-only', name } = params;
        const graphHttp = name ? this.apollo.use(name) : this.apollo;
        return new Observable((observe) => {
            graphHttp.watchQuery({
                query,
                variables,
                fetchPolicy: policy
            }).valueChanges.subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            });
        })
    };

    /**
     * mutation 请求
     * @param params[mutation]          GQL 语句           必填
     * @param params[variables]         条件参数           选填
     * @param params[query][query]      gql 语句           选填
     * @param params[query][variables]  条件参数            选填
     * @param params[name]              gql endpoint 语句  选填
     * @constructor
     */
    GMutation(params: GraphqlMutationParams) {
        let { mutation, variables = {}, query = [], name } = params;
        const graphHttp = name ? this.apollo.use(name) : this.apollo;
        return new Observable((observe) => {
            graphHttp.mutate({
                mutation,
                variables,
                refetchQueries: [...query],
                fetchPolicy: 'no-cache'
            }).subscribe((res) => {
                observe.next(res);
            }, (err) => {
                observe.error(err);
            })
        })
    };
/**
     * query 请求
     * @param params[query]     GQL 语句          必填
     * @param params[variables] 条件参数           选填
     * @param params[policy]    是否缓存 默认缓存   选填
     * @param params[name]      gql endpoint 语句  选填
     */
    QueryAll(params: GraphqlQueryParams): Observable<any> {
        const {query, variables = {}, policy = 'network-only', name} = params;
        const graphHttp = name ? this.apollo.use(name) : this.apollo;
        return graphHttp.watchQuery({
                    query,
                    variables,
                    fetchPolicy: policy,
                }).valueChanges.pipe(map(this.handleResult), catchError(this.handleError));
    }


    /**
     * 成功结果
     * @param res 结果
     */
    private handleResult(res): any {
        /**
         * 进行文字转码返回转码后数据
         */
        const body = JSON.parse(JSON.stringify(res));
        return body || {};
    }

    /**
     * 错误结果
     * @param error 错误信息
     */
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // 发生客户端或网络错误。相应处理
            console.error('发生错误:', error.error.message);
        } else {
            // 后端返回了不成功的响应代码。
            // 反应体可能包含了什么地方出了问题的线索，
            console.error(
                `后端返回代码: ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // 返回带有面向用户的错误消息的可观测数据
        return throwError('发生了不好的事情，请稍后再试。');
    }
}

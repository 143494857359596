import { Component, OnInit, Input, Output,EventEmitter, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../service/utils/utils.service';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { NzMessageService, NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Apollo } from 'apollo-angular';
import { teacherData } from '../../graphQl/school-administration/school-notice/announcement';
import { departmentListData, departmentList } from '../../graphQl/systemctl/departments';
import { QueryDuityPeople } from '../../graphQl/base/personnelAlloction';
import { documentTeacher } from '../../graphQl/documents/document-list';

@Component({
    selector: 'app-orh-document-user',
    templateUrl: './orh-document-user.component.html',
    styleUrls: ['./orh-document-user.component.less']
})
export class OrhDocumentUserComponent implements OnInit {
    @Input() userData: any[];
    @Output() teacherData: EventEmitter<any> = new EventEmitter();
    teacherList: any[] = [];
    nodes: any[] = [];
    OrganizeList: any[] = [];
    searchValue: any;
    searchShow: boolean = true
    // 点击获取到的树附属信息
    TreeData: any = [];
    radioValue: any;
    departmentId: number | boolean = false;
    // 树形加载状态
    TreeLoading: boolean = true;
    // 树形没有数据
    NoDataStatus: boolean = false;
    formValidate = {
        duties_id: ''
    }
    listData: any[] = [];
    dutiesListData: any[] = [];
    checkOptionsOne: any[] = [];
    allChecked = false;
    indeterminate = true;
    loading: boolean = false;
    titles: string = '选中(0)人'
    constructor(
        private utils: UtilsService,
        private graph: GraphqlServiceService,
        private message: NzMessageService,
        private apollo: Apollo,
    ) {

    }

    ngOnInit() {
        this.getDepartmentData();
        this.getTeacher();

    }
    ngOnChanges(changes: SimpleChanges) {
        this.teacherList = changes.userData.currentValue;
        this.titles=`选中(${this.teacherList.length})人`
        this.getTeacher();
    }
    getTeacher(): void {
        this.checkOptionsOne = []
        const params = {
            query: documentTeacher,
            variables: {
                page: 0,
                limit: 0,
                condition: {
                    frequentType:1,
                    work_status:'workstatus_on-the-job'
                }
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.documentTeacher.code === 200) {
                let data = res.data.documentTeacher.data;
                this.checkOptionsOne = data.map(item => {
                    if (this.teacherList) {
                       let index = this.teacherList.findIndex(res => res.value === item.id);
                        if (index > -1) {

                            return {
                                value: item.user?item.user.id:null,
                                label: item.name ? item.name.zh : item.user.username,
                                checked: true
                            }
                        } else {

                            return {
                                value: item.user?item.user.id:null,
                                label: item.name ? item.name.zh : item.user.username,
                                checked: false
                            }
                        }
                    } else {
                        return {
                            value: item.user?item.user.id:null,
                            label: item.name ? item.name.zh : item.user.username,
                            checked: false
                        }

                    }
                })

            }else{
                this.message.error(res.data.documentTeacher.msg)
            }
        });
    }
    searchClick() {
        this.checkOptionsOne = []
        this.utils.getStorage().then(res => {
            let item = {
                name: this.searchValue,
                work_status:'workstatus_on-the-job'
            }
            let datas = this.utils.handelCondition(item)
            const params = {
                query: documentTeacher,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: datas
                },
                policy: 'no-cache'
            };
            this.graph.GQuery(params).subscribe((res: any) => {
                if (res.data.documentTeacher.code === 200) {
                    let data = res.data.documentTeacher.data;
                    let user = {}
                    data.map(item => {
                        user = {
                            value: item.user?item.user.id:null,
                            label: item.name ? item.name.zh : item.user.username,
                            checked: false
                        }
                        this.checkOptionsOne.push(user)
                    })
                }else{
                    this.message.error(res.data.documentTeacher.msg)
                }
            });
        });
    }
    // 获取组织树的信息
    getDepartmentData(): void {
        let that = this;
        that.apollo.watchQuery({
            query: departmentListData,
            variables: {
                page: 0,
                limit: 1000
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.Departments.code === 200) {
                that.listData = res.data.Departments.data.map((el: any) => ({
                    title: el.department_name.zh,
                    key: el.id,
                    info: el
                }));
                if (this.listData.length === 0) {
                    this.NoDataStatus = true;
                }
                this.TreeLoading = false;
            }else{
                this.message.error(res.data.Departments.msg);
                this.TreeLoading = false;
            }
        })
    }
    nzEvent(event: Required<NzFormatEmitEvent>): void {
        let that = this;
        let id: any = event.keys[event.keys.length - 1];
        const node = event.node;
        if (event.eventName === 'expand') {
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(id).then(data => {
                    node.addChildren(data)
                })
            }
        } else if (event.eventName === 'click') {
            that.departmentId = id ? id : false;
            let arr = node.origin.info.duties.map(el => {
                return el.id
            });
            that.reloadDutiesData(arr)
        } else {

        }
    }
    loadNode(value: any): Promise<NzTreeNodeOptions[]> {
        let that = this;
        return new Promise(resolve => {
            that.apollo.watchQuery({
                query: departmentList,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: {
                        parent_id: value
                    }
                }
            }).valueChanges.subscribe((res: any) => {
                if (res.data.Departments.code === 200) {
                    let data = res.data.Departments.data.map((el: any) => ({
                        title: el.department_name.zh,
                        key: el.id,
                        info: el
                    }));
                    resolve(data)
                } else {
                    resolve()
                }
            })
        })
    }
    // 点击树形结构
    TreeClick(val) {
        this.searchShow = false
        this.TreeData = val.node.origin;
        // this.OrganizeList = val.node.origin.info.duties;
        this.getNodeDuity(0, 0, val.node.origin.key,1)
    }
    // 通过组织树获取人员列表
    getNodeDuity(page = 0, limit = 0, departmentsId: number,frequentType=1) {
        this.checkOptionsOne = []
        const variables = {
            page,
            limit,
            condition:{
                departmentsId,
                frequentType
            }
        }
        this.apollo.watchQuery({
            query: documentTeacher,
            variables,
        }).valueChanges.subscribe((res: any) => {
            if (res && res.data.documentTeacher.code === 200) {
                let data = res.data.documentTeacher.data;
                let users = []
                let user = {}
                users = data.map(item => {                   
                    if (this.teacherList) {
                        let index = this.teacherList.findIndex(res=>res.value === item.user.id);     
                        if (index >-1) {                           
                            return {
                                value: item.user.id,
                                label: item.name ? item.name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: true
                            }
                        } else {                            
                            return{
                                value: item.user.id,
                                label: item.name ? item.name.zh : item.user.username,
                                idcard: item.user.idcard,
                                checked: false
                            }                            
                        }                        
                    } else {
                       return {
                            value: item.user.id,
                            label: item.name ? item.name.zh : item.user.username,
                            idcard: item.user.idcard,
                            checked: false
                        }                       
                    }
                })   
                let teacher = []
                users.map(item => {
                    let index = teacher.findIndex(res => res.value === item.value)
                    if (index === -1) {
                        teacher.push(item)
                    } else {
                        return false
                    }
                })
                this.checkOptionsOne = teacher
                
            }else{
                this.message.error(res.data.documentTeacher.msg)
            }
        })
    }


    reloadDutiesData(data: Array<number>) {
        let list = [...this.dutiesListData];
        if (data) {
            list.map((el: any) => {
                let bool = data.find(w => w === el.key)
                if (bool) {
                    el.direction = 'right'
                } else {
                    el.direction = 'left'
                }
            });
            this.dutiesListData = list
        }
    }
    updateAllChecked(): void {
        this.indeterminate = false;
        if (this.allChecked) {
            this.checkOptionsOne = this.checkOptionsOne.map(item => {
                return {
                    ...item,
                    checked: true
                };
            });
            this.teacherList = this.checkOptionsOne;
            
        } else {
            this.checkOptionsOne = this.checkOptionsOne.map(item => {
                return {
                    ...item,
                    checked: false
                };
            });
            this.teacherList = []
        }
        this.titles=`选中(${this.teacherList.length})人`
    }

    updateSingleChecked(): void {
        this.checkOptionsOne.map(item => {
            if (item.checked === true) {
                let index = this.teacherList.findIndex(res=>res.value === item.value)
                if(index === -1){
                    this.teacherList.push(item)
                }else{
                    return false
                }
                
            } 
            if(item.checked === false){
                let index = this.teacherList.findIndex(res=>res.value === item.value)
                if(index > -1){
                    this.teacherList.splice(index)
                }else{
                    return false
                }
            }
        })
        
        this.titles=`选中(${this.teacherList.length})人`
        this.teacherData.emit(this.teacherList)
        if (this.checkOptionsOne.every(item => !item.checked)) {
            this.allChecked = false;
            this.indeterminate = false;
        } else if (this.checkOptionsOne.every(item => item.checked)) {
            this.allChecked = true;
            this.indeterminate = false;
        } else {
            this.indeterminate = true;
        }
    }
    delete(_id: any) {
        this.loading = true
        this.checkOptionsOne.map(item => {
            if (item.value === _id) {
                item.checked = false
            }
        })
        let index = this.teacherList.findIndex(item => item.value === _id)
        this.teacherList.splice(index, 1)
        this.loading = false
        this.teacherData.emit(this.teacherList);
        this.titles=`选中(${this.teacherList.length})人`
    }
}
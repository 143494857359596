import { Injectable } from '@angular/core';

import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { AttendanceSpecialNocardComponent } from './rule/attendance-special-nocard/attendance-special-nocard.component';

import { NzMessageService, TransferItem } from 'ng-zorro-antd';
import { PermissionsService } from 'src/app/service/permissions/permissions.service';
import { attendanceNewGroups, addAttendanceNewGroup, deleteAttendanceTeacher, attendanceTeachers, addAttendanceTeacher, deleteAttendanceTime, attendanceTimes, addAttendanceTime, attendanceLeaves, deleteAttendanceNewGroup } from '../../graphQl/attendance/attendance-rule';
import { dictionaryList, typeList } from '../../graphQl/base/dictionary';
import { newTeachers } from '../../graphQl/systemctl/departments';
import { attendanceGroups } from '../../graphQl/attendance/rule/attendance-rule-group';
import { Subject, Observable } from 'rxjs';
import { formatDate, DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AttendanceServiceService {
    prices = new Subject<any>();
    listTeachers: any[] = [];
    // title: string;
    // direction?: TransferDirection;
    // disabled?: boolean;
    // checked?: boolean;
    // hide?: boolean;
    // [key: string]: any;
    // listTransferAll: TransferItem[] = [];    
    //  添加作弊人员的穿梭集合
    // List<String> preList = getAllDay(c.getTime(), true);


    //   date转string
    //   HH:mm:ss
    userInfo: any;

    constructor(
        private graph: GraphqlServiceService,
        private message: NzMessageService,
        private user_service: PermissionsService,
        private datePipe: DatePipe
    ) {
        this.getTransferListAll((list) => {
        });
        this.user_service.getOtherUserInfo().then((res) => {
            this.userInfo = res
        })
    }
    getFormatDateTime(date: Date): string {
        return formatDate(date, 'HH:mm:ss', 'zh-Hans');
    }

    getFormatDateTimeFull(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'zh-Hans');
    }


    //  yyyy-MM-dd
    getFormatDate(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd', 'zh-Hans')
    }

    //   HH:mm:ss
    getDateFromStringTime(date: string): Date {
        let a = Number.parseInt(date.slice(0, 2));
        let b = Number.parseInt(date.slice(3, 5));
        let c = Number.parseInt(date.slice(6, 8));
        return new Date(0, 0, 0, a, b, c);
    }

    //   yyyy:MM:dd
    getDateFromStringDate(date: string): Date {
        let a = Number.parseInt(date.slice(0, 4));
        let b = Number.parseInt(date.slice(5, 7)) - 1;
        let c = Number.parseInt(date.slice(8, 10));
        return new Date(a, b, c, 0, 0, 0);
    }

    //  yyyy-MM-dd
    getFirstDay(date: Date): string {
        
        return this.getFormatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    }

    // getDate(date){
    //     var now = new Date(),
    //             y = now.getFullYear(),
    //             m = now.getMonth() + 1,
    //             d = now.getDate();
    //         return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
    // }
    //  获取考勤分组信息
    getAttendanceGroups(schoolId, cb) {
        this.graph.GQuery({ query: attendanceNewGroups, variables: { schoolId: schoolId }, name: 'attendance' })
            .subscribe((res: any) => {
                cb(res);
            })
    }
    getCalendarPrice() {
        let priceChanged: Observable<any> = this.prices.asObservable();
        setTimeout(() => { this.generateData(); }, 10);
        return priceChanged;
    }

    

    formatDate(date) {
        date = new Date(date);
        let myyear = date.getFullYear();
        let mymonth = date.getMonth() + 1;
        let myweekday = date.getDate();
        mymonth < 10 ? mymonth = '0' + mymonth : mymonth;
        myweekday < 10 ? myweekday = '0' + myweekday : myweekday;
        return `${myyear}-${mymonth}-${myweekday}`;
    }
    mGetDate() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let d = new Date(year, month, 0);
        return d.getDate();
    }
    //获取当月日期
    generateData() {
        let arrPrice = [];
        let startDay = new Date();
        let now = new Date();
        let current_month_num = this.mGetDate();
        let current_month = [];
        for (let i = 1; i <= current_month_num; i++) {
            let day = now.setDate(i);
            let everyDay = this.formatDate(day);
            let dateDay  = new Date(everyDay)
            current_month.push(dateDay);
        }
        return current_month;
        // this.prices.next({ prices: current_month, monthList: new Date()} );
    }
    //  获取教师列表
    getTeachers(cb) {
        this.graph.GQuery({
            query: newTeachers, variables: {
                page: 0, limit: 0, condition: {
                    work_status: 'workstatus_on-the-job'
                }
            }
        }).subscribe((res: any) => {
            
            cb(res);
        })
    }

    teacherList: any[];

    //    获取穿梭集合
    getTransferListAll(cb) {
        this.getTeachers((data) => {
            this.teacherList = data.data.teachersNew.data;
            let listTransferAll: TransferItem[] = [];
            for (let item of data.data.teachersNew.data) {
                if (item.user === null || item.user.id === null || item.user.username === null) {
                    continue;
                }
                listTransferAll.push({
                    key: item.user.id.toString(),
                    title: item.name ? `${item.name.zh}(${item.telnumber})` : (`${item.user.username}(${item.telnumber})`? `${item.user.username}(${item.telnumber})` : '未知'),
                    direction: 'left'
                });
            }
            cb(listTransferAll)
        });
    }

    // getTransferListTeachers(cb) {
    //   this.getTeachers((data) => {
    //     let listTransferAll: TransferItem[] = [];
    //     data.data.teachers.data.forEach(item => {
    //       listTransferAll.push({key:item.user.id})
    //     })
    //   })
    // }

    showMessage(type: any) {
        if (type === 200) {
            this.message.success('操作成功');
        } else {
            this.message.error('操作失败');
        }
    }

    showMessageByCodeAndMsg(code: any, msg: string) {
        if (code === 200) {
            this.message.success('操作成功');
        } else {
            this.message.error('操作失败: ' + msg);
        }
    }

    showMessageLoading(type: any) {
        if (type === 200) {
            this.message.success('数据获取成功');
        } else {
            this.message.error('数据获取失败,请重试');
        }
    }

    //  获取请假人员列表
    getLeaves(data: number, cb) {
        this.graph.GQuery({ query: attendanceLeaves, variables:data, name: 'attendance' })
            .subscribe((res: any) => {
                cb(res);

            }, (error) => {
                cb(400);
                // this.error = error.constructor.name;
            })
    }

    //    根据数据字典查询请假
    getLeaveType(cb) {

        this.graph.GQuery({ query: typeList, variables: { dictionary_code: 'teacher_leave_kind' } })
            .subscribe((res: any) => {
                cb(res);
            })
    }

    //    获取user基础信息(userId,schoolId等等)
    getUserInfo() {

    }

    deleteOneLeaveInfo() {

    }

    //  添加考勤表(维护)
    addAttendanceTimeTable(SinputData: any, cb) {
        this.graph.GMutation({ mutation: addAttendanceTime, variables: { inputData: SinputData }, name: 'attendance' })
            .subscribe((res: any) => {
                cb(res);
            })
    }

    //  获取考勤表
    getAttendanceTimeTable(SschoolId: number, title: string,page:number,limit:number,cb) {
        this.graph.GQuery({ query: attendanceTimes, variables: { page: page, limit: limit, schoolId: SschoolId, title: title }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }

    //删除考勤表
    deleteAttendanceTimeTable(data, cb) {
        let idArray: string[] = [];
        if (typeof data === 'string') {
            idArray.push(data);
        } else {
            data.forEach(item => {
                idArray.push(item.id);
            })
        }
        this.graph.GMutation({ mutation: deleteAttendanceTime, variables: { id: idArray }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }
    //  添加分组教师(添加和维护)
    addAttendanceTeacher(SinputData: any, cb) {
        this.graph.GMutation({ mutation: addAttendanceTeacher, variables: { inputData: SinputData }, name: 'attendance' })
            .subscribe((res: any) => {
                cb(res);
            })
    }
    //  获取教师分组
    getAttendanceTeachers(SschoolId: number, title: string,page:number,limit:number, cb) {
        this.graph.GQuery({ query: attendanceTeachers, variables: { page: page, limit: limit, schoolId: SschoolId, title: title }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }
    //  删除教师分组
    deleteAttendanceTeacher(data, cb) {
        let idArray: any[] = [];
        if (typeof data === 'string') {
            idArray.push(data);
        } else {
            data.forEach((item: any) => {
                idArray.push(item.id);
            });
            idArray.push()
        }
        this.graph.GMutation({ mutation: deleteAttendanceTeacher, variables: { id: idArray }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }

    addAttendanceNewGroup(Sinputdata, cb) {
        this.graph.GMutation({ mutation: addAttendanceNewGroup, variables: { inputData: Sinputdata }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }

    getAttendanceNewGroup(SschoolId: number, title: string,page:number,limit:number, cb) {
        this.graph.GQuery({ query: attendanceNewGroups, variables: { page: page, limit: limit, schoolId: SschoolId, title: title }, name: 'attendance' })
            .subscribe(res => {
                cb(res);
            })
    }

    deleteAttendanceGroupNew(data, cb) {
        let deleteType;
        let idArray: string[] = [];
        if (typeof data === 'string') {
            idArray.push(data);
            deleteType = 1;
        } else {
            data.forEach(item => {
                idArray.push(item.id);
            })
            deleteType = 2;
        }
        this.graph.GMutation({ mutation: deleteAttendanceNewGroup, variables: { id: idArray }, name: 'attendance' })
            .subscribe(res => {
                cb(res, deleteType);
            })
    }
}


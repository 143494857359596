import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addShowPerformance, findshowPerformance } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';

@Component({
  selector: 'performance-operation',
  templateUrl: './performance-operation.component.html',
  styleUrls: ['./performance-operation.component.less']
})
export class PerformanceOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        time:null,
        entime:null
    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            authornumber : [null, Validators.required],//第几作者
            time: ['', Validators.required],//展演时间
            image : [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//留学学校名称
            fundunit: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//展演名称
            fundcontent: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//举办机构
        })
    }
    dictionaryList: any = {
        afewauthors: [],
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findshowPerformance,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.showPerformance
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd');  
            form.get('time')!.setValue(data.time);   
            form.get('authornumber')!.setValue(data.authornumber?data.authornumber.dictionary_value:'');  
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('name')!.setValue(data.name?data.name:{mn:null,zh:null,en:null});
            form.get('fundunit')!.setValue(data.fundunit?data.fundunit:{mn:null,zh:null,en:null});
            form.get('fundcontent')!.setValue(data.fundcontent?data.fundcontent:{mn:null,zh:null,en:null});
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }
    
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'afewauthors'
        ];
        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addShowPerformance,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addShowPerformance.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addShowPerformance.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);
                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];

    disabledDate = (current: Date): boolean => {
        // Can not select days before today and today
        return current > new Date()
    };
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef} from '@angular/core';

@Component({
    selector: 'orh-disc-card',
    styleUrls: ['./orh-disc-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-disc-card" omc-container>
            <div class="orh-disc-card-item" *ngFor="let item of data; let  i = index;">
                {{item.name}}
            </div>
        </div>
    `
})
export class OrhDiscCardComponent implements OnChanges {

    @Input()
    data: any[];

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cdr.detectChanges();
    }

}

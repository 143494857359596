import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { findtagData } from 'src/app/graphQl/base/tag';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
  selector: 'orh-lesson-files',
  templateUrl: './orh-lesson-files.component.html',
  styleUrls: ['./orh-lesson-files.component.less'],
  providers:[DatePipe]
})
export class OrhLessonFilesComponent implements OnInit {

    @Input() fileData: any;
    backgroundColor: any = {
        // background: 'linear-gradient(-90deg, var(--omc-color-primary), var(--omc-color-primary-contrast-rgb))',
        background: `linear-gradient(-90deg, rgba(68, 163, 255, 1), rgba(11, 135, 255, 1))`,
        boxShadow: '0px 10px 10px 0px rgba(17, 138, 255, 0.38)'
    };
    data: any[] = [];
    activestyle:any={
        width:'150px !important'
    }
    constructor(
        private http: RequestService,
        private date:DatePipe,
        private graph:GraphqlServiceService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fileData.currentValue) {		
            
                
            this.fileData.map((ind: any) => {
                this.http.get('resource/file/items', {
                    identifiers: ind.fileId,
                }).subscribe(res => {
                    if (res.code === 200) {
                        const datas = res.data[0];						
                        let files = {
                            status: true,
                            checked: true,
                            name: datas.properties['sr:fileName'],
                            type: datas.properties['sr:fileType'],
                            mime_type: datas.properties['sr:fileType'] ? datas.properties['sr:fileType'] : '',
                            created: this.date.transform(datas.properties['sr:convertStartAt'], 'yyyy-MM-dd'),
                            size: datas.properties['sr:fileSize'] ? datas.properties['sr:fileSize'] : '',
                            parentRef: datas.properties['sr:identifier'] ? datas.properties['sr:identifier'] : '',
                            osspath: datas.properties['sr:osspath'] ? datas.properties['sr:osspath'] : '',
                            thumbnail: datas.properties['sr:thumbnail'] ? datas.properties['sr:thumbnail'] : null,
                            convertStatus: datas.properties['sr:convertStatus'],
                            properties:datas.properties
                        };						
                        ind.files = files;												
                    }else{
                        this.message.error(res.msg)
                    }
                });
                ind.fileCover = ind.fileCover;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath;
                ind.fileTitle = ind.fileTitle;
                ind.tagID = ind.tagID;
                if(ind.tagID === undefined || ind.tagID === 'file'){
                    ind.type_name = ''
                }else{
                    const params = {
                        query: findtagData,
                        variables: {
                            id: ind.tagID
                        },
                        policy: 'no-cache',
                    };
                    this.graph.GQuery(params).subscribe((respos: any) => {
                        let data = respos.data.tag;
                        ind.type_name= data.name.mn ? data.name.mn : data.name.zh;
                    })
                }
                
                this.data.push(ind)
            })
        }
    }
    //下载
    addDownload(file: any) {
        let objectName = file.osspath
        let api = 'resource/file/download';
        this.http.post(api, {
            objectName: objectName,
            newName: file.name
        }).subscribe((item: any) => {
            window.open(item, '_blank');
            //this.message.success(this.langs.download_success, { duration: 3000 });

        })
    }

}

import gql from 'graphql-tag';

/**
 * 工作经历列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const workExperiences = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		workExperiences (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
           
            data {
                id
                teacher_id 
                startdate
                enddate
                shenhe
                reason
                name {
                    en
                    mn
                    zh
                }
                postname{
                    en
                    mn
                    zh
                }
                created_by
            }
		}
	}
`;

// /**
//  * 工作经历添加数据
//  */
const addWorkExperience = gql`
	mutation addWorkExperience($inputData: WorkExperienceInput){
		addWorkExperience(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`
// /**
//  * 工作经历单条数据
//  */
const findworkExperience = gql`
	query ($id: Int!){
		workExperience (id: $id) {
            id
            teacher_id 
            startdate
            enddate
            shenhe
            name {
                en
                mn
                zh
            }
            postname{
                en
                mn
                zh
            }
            created_by
			
		}
	}
`;
/**
 * 工作经历删除数据
 */


const deleteWorkExperience = gql`
  mutation deleteWorkExperience($id: [Int]!){
    deleteWorkExperience(id:$id){
		code
		msg
    }
  }
`;

/**
 * 职(执)业资格列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const practicingQualifications = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		practicingQualifications (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            
            data {
                id
                teacher_id 
                inputstyle{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                takedate
                certificateno
                image
                shenhe
                reason
                awarddept {
                    en
                    mn
                    zh
                }
                name{
                    en
                    mn
                    zh
                }
                grade {
                    en
                    mn
                    zh
                }
                created_by
            }
		}
	}
`;

// /**
//  * 职(执)业资格添加数据
//  */
const addPracticingQualification = gql`
	mutation addPracticingQualification($inputData: PracticingQualificationInput){
		addPracticingQualification(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`
// /**
//  * 职(执)业资格单条数据
//  */
const findpracticingQualification = gql`
	query ($id: Int!){
		practicingQualification (id: $id) {
            id
                teacher_id 
                inputstyle{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                takedate
                certificateno
                image
                shenhe
                awarddept {
                    en
                    mn
                    zh
                }
                name{
                    en
                    mn
                    zh
                }
                grade {
                    en
                    mn
                    zh
                }
                created_by
			
		}
	}
`;
/**
 * 职(执)业资格删除数据
 */
const deletePracticingQualification = gql`
    mutation deleteWorkExperience($id: [Int]!){
        deletePracticingQualification(id:$id){
            code
            msg
        }
    }
`;

/**
 * 学习经历列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const learningExperiences = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		learningExperiences (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                teacher_id 
                startdate
                enddate
               
                learnlevel {
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                way{
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                degree {
                    id
                    dictionary_value
                    name{
                        zh
                        mn 
                        en
                    }
                }
                image
                shenhe
                reason
                type
                academy{
                    zh
                    mn
                    en
                }
                profession{
                    zh
                    mn
                    en
                }
                workstationname {
                    zh
                    mn
                    en
                }
                researche{
                    zh
                    mn
                    en
                }
                duration 

            }
		}
	}
`;
// /**
//  * 学习经历单条数据
//  */
const findlearningExperience = gql`
	query ($id: Int!){
		learningExperience (id: $id) {
            id
            teacher_id 
            startdate
            enddate
           
            learnlevel {
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            way{
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            degree {
                id
                dictionary_value
                name{
                    zh
                    mn 
                    en
                }
            }
            image
            shenhe
            type
            academy{
                zh
                mn
                en
            }
            profession{
                zh
                mn
                en
            }
            workstationname {
                zh
                mn
                en
            }
            researche{
                zh
                mn
                en
            }
            duration 

			
		}
	}
`;
// /**
//  * 学习经历添加数据
//  */
const addLearningExperience = gql`
	mutation addLearningExperience($inputData: LearningExperienceInput){
		addLearningExperience(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 学习经历删除数据
 */
const deleteLearningExperience = gql`
    mutation deleteLearningExperience($id: [Int]!){
        deleteLearningExperience(id:$id){
            code
            msg
        }
    }
`;

/**
 * 获奖情况列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const awards = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		awards (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            
            data {
                id
                teacher_id 
                awardmoney 
                type
                startdate
                enddate
                image
                shenhe
                name  {
                    zh
                    mn 
                    en
                }
                
                grade {
                    zh
                    mn 
                    en
                }
                ranking{
                    zh
                    mn 
                    en
                }
                institution{
                    zh
                    mn
                    en
                }
               

            }
		}
	}
`;
// /**
//  * 获奖情况单条数据
//  */
const findaward = gql`
	query ($id: Int!){
		award (id: $id) {
            id
            teacher_id 
            awardmoney 
            type
            startdate
            enddate
            image
            shenhe
            name  {
                zh
                mn 
                en
            }
            
            grade {
                zh
                mn 
                en
            }
            ranking{
                zh
                mn 
                en
            }
            institution{
                zh
                mn
                en
            }
			
		}
	}
`;
// /**
//  * 获奖情况添加数据
//  */
const addAward = gql`
	mutation addAward($inputData: AwardInput){
		addAward(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 获奖情况删除数据
 */
const deleteAward = gql`
    mutation deleteAward($id: [Int]!){
        deleteAward(id:$id){
            code
            msg
        }
    }
`;

/**
 *外语列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const foreignLanguagess = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		foreignLanguagess (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                
                teacher_id 
                names  {
                    id
                    dictionary_value 
                    name{
                        zh
                        mn 
                        en
                    }
                }
                
                image
                shenhe
                languelevel {
                    zh
                    mn 
                    en
                }
               

            }
		}
	}
`;

const masterWorks = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int, $tabType: Int){
		masterWorks (page: $page, limit: $limit,teacher_id:$teacher_id, tabType: $tabType){
            code
            msg
            data {
                id
                acceptTime
                filePath
                testTime
                filePath1
                achievement
                shenhe
                reason
                level{
                    id
                    dictionary_value 
                    name{
                        zh
                        mn 
                        en
                    }
                }
            }
		}
	}
`;

const headExtTeachers = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		headExtTeachers (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            data {
                id
                startTime
                endTime
                classNumber
                filePath
                shenhe
                reason
            }
		}
	}
`;

// /**
//  *外语单条数据
//  */
const findforeignLanguages = gql`
	query ($id: Int!){
		foreignLanguages (id: $id) {
            id
            teacher_id 
            names  {
                id
                dictionary_value 
                name{
                    zh
                    mn 
                    en
                }
            }
            image
            shenhe
            languelevel {
                zh
                mn 
                en
            }
			
		}
	}
`;

const masterWork = gql`
	query ($id: Int!){
		masterWork (id: $id) {
            id
            acceptTime 
            filePath
            testTime
            filePath1
            achievement
            level{
                id
                dictionary_value 
                name{
                    zh
                    mn 
                    en
                }
            }
		}
	}
`;

const headExtTeacher = gql`
	query ($id: Int!){
		headExtTeacher (id: $id) {
            startTime
            endTime
            classNumber
            filePath
            id
		}
	}
`;

// /**
//  *外语添加数据
//  */
const addForeignLanguages = gql`
	mutation addForeignLanguages($inputData: ForeignLanguagesInput){
		addForeignLanguages(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

const addMasterWork = gql`
	mutation addMasterWork($inputData: MasterWorkInput){
		addMasterWork(inputData: $inputData){
			code
			msg
		}
	}
`;

const addHeadTeacher = gql`
	mutation addHeadTeacher($inputData: HeadTeacherExtInput){
		addHeadTeacher(inputData: $inputData){
			code
			msg
		}
	}
`;

/**
 *外语删除数据
 */
const deleteForeignLanguages = gql`
    mutation deleteForeignLanguages($id: [Int]!){
        deleteForeignLanguages(id:$id){
            code
            msg
        }
    }
`;

const deleteMasterWork = gql`
mutation deleteMasterWork($id: Int){
    deleteMasterWork(id:$id){
        code
        msg
    }
}
`;
/**
 * 荣誉称号列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const honoraryTitles = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		honoraryTitles (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
          
            data {
                id
                teacher_id 
                leve{
                    id
                    dictionary_value
                    name{
                        mn
                        zh
                        en
                    }
                }
                time
                image
                shenhe
                reason
                name{
                    zh
                    mn 
                    en
                }
                
                grade{
                    id
                    name{
                        zh
                        mn 
                        en
                    }
                }
                remark{
                    zh
                    mn 
                    en
                }
            }
		}
	}
`;
// /**
//  * 荣誉称号单条数据
//  */
const findhonoraryTitle = gql`
	query ($id: Int!){
		honoraryTitle (id: $id) {
            id
                teacher_id 
                leve{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                honorType{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                time
                image
                image1
                shenhe
                name{
                    zh
                    mn 
                    en
                }
                
                grade{
                    id
                    dictionary_value
                    name{
                        zh
                        mn
                        en
                    }
                }
                remark{
                    zh
                    mn 
                    en
                }
		}
	}
`;
// /**
//  * 荣誉称号添加数据
//  */
const addHonoraryTitle = gql`
	mutation addHonoraryTitle($inputData: HonoraryTitleInput){
		addHonoraryTitle(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 荣誉称号删除数据
 */
const deleteHonoraryTitle = gql`
    mutation deleteHonoraryTitle($id: [Int]!){
        deleteHonoraryTitle(id:$id){
            code
            msg
        }
    }
`;

/**
 * 培训情况列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const Trainings = gql`
	query ($page: Int, $limit: Int,$teacher_id: Int){
		trainings (page: $page, limit: $limit,teacher_id:$teacher_id){
            code
            msg
            
            data {
                id
                teacher_id 
                startdate
                enddate
                image
                shenhe
                reason
                name{
                    zh
                    mn 
                    en
                }
                
                organizer {
                    zh
                    mn 
                    en
                }
                duration{
                    zh
                    mn 
                    en
                }
                content{
                    zh
                    mn 
                    en
                }
            }
		}
	}
`;
// /**
//  * 培训情况单条数据
//  */
const findtraining = gql`
	query ($id: Int!){
		training (id: $id) {
            id
            teacher_id 
            startdate
            enddate
            image
            shenhe
            name{
                zh
                mn 
                en
            }
            
            organizer {
                zh
                mn 
                en
            }
            duration{
                zh
                mn 
                en
            }
            content{
                zh
                mn 
                en
            }
		}
	}
`;
// /**
//  * 培训情况添加数据
//  */
const addTrainings = gql`
	mutation addTrainings($inputData: TrainingsInput){
		addTrainings(inputData: $inputData){
			code
			msg
            data{
                id
                teacher_id 
            }
		}
	}
`;

/**
 * 培训情况删除数据
 */
const deleteTrainings = gql`
    mutation deleteTrainings($id: [Int]!){
        deleteTrainings(id:$id){
            code
            msg
        }
    }
`;


export {
    workExperiences,
    addWorkExperience,
    findworkExperience,
    deleteWorkExperience,
    practicingQualifications,
    addPracticingQualification,
    findpracticingQualification,
    deletePracticingQualification,
    learningExperiences,
    findlearningExperience,
    addLearningExperience,
    deleteLearningExperience,
    findaward,
    awards,
    addAward,
    deleteAward,
    foreignLanguagess,
    findforeignLanguages,
    addForeignLanguages,
    deleteForeignLanguages,
    honoraryTitles,
    findhonoraryTitle,
    addHonoraryTitle,
    deleteHonoraryTitle,
    Trainings,
    findtraining,
    addTrainings,
    deleteTrainings,
    addMasterWork,
    masterWorks,
    masterWork,
    deleteMasterWork,
    headExtTeachers,
    addHeadTeacher,
    headExtTeacher
}

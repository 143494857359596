import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'orh-lesson-content',
  templateUrl: './orh-lesson-content.component.html',
  styleUrls: ['./orh-lesson-content.component.less']
})
export class OrhLessonContentComponent implements OnInit {

    @Input() content: any;
    data:any;
    constructor() { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.content.currentValue){
            this.data=this.content;
            
            
        }
        
    }

}

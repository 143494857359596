import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'orh-anchor',
    templateUrl: './orh-anchor.component.html',
    styleUrls: ['./orh-anchor.component.scss']
})
export class OrhAnchorComponent implements OnInit {
    @Input() number:number;
    @ViewChild('anchorContent', { static: true }) anchorContent: ElementRef;
    @Output() retPage:EventEmitter<any> = new EventEmitter()
    subscribeScoll: any;

    timeout: any;
    index = 1;
    limit=1000;
    total=1;
    
    constructor() {
    }

    ngOnInit() {
        this.total = this.anchorContent.nativeElement.children.length
    }

    ngAfterViewInit(): void {
        this.subscribeScoll = fromEvent(this.anchorContent.nativeElement, 'scroll').subscribe((event: any) => {
            this.onWindowScroll(event);
        });
    }

    onWindowScroll(event: any): void {
        
        
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            let scrollTop = event.target.scrollTop;
            this.index = 0;
            for (let i = 0; i < event.target.children.length; i++) {
                scrollTop -= event.target.children[i].height;
                if (scrollTop >= 0) {
                    this.index = i + 2;
                }
            }
            console.log(this.index,'3333');
        }, 50);
        
    }

    omcClick(evt: any) {
        console.log('evt111',evt);
        
        const num = parseInt(evt.target.value)-1;
        this.retPage.emit(num)
        // console.log(this.anchorContent.nativeElement.children[1]);
        // console.log(num);
        // console.log(this.anchorContent.nativeElement.children.length);
        // this.index = num;
        
        // this.anchorContent.nativeElement.children[num-2].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        // this.handleAnchor('next')
        this.anchorContent.nativeElement.children[num].scrollIntoView();
        // this.anchorContent.nativeElement.children[this.index].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
    handleAnchor(type: string): void {
        
        
        let num = 0;
        let nums = 0
        if (type === 'next') {
            if (this.index < this.anchorContent.nativeElement.children.length) {
                num = this.index === 1 ? 1 : this.index;
                nums = num++
            } else {
            }
        } else if(type === 'pre') {
            if (this.index === 1) {
                num = 0;
                
            } else {
                num = this.index - 2;
                nums = num--
            }
        }
        
        console.log(this.index, this.index, '0099');
        this.retPage.emit(nums)
        this.anchorContent.nativeElement.children[num].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });

    }


}

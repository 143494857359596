// 消息graphQl接口
const MESSAGE = 'message';
// 模块列表
const MODELLIST = 'model-list';
// 模块首页
const MODULEHOME = 'module-home';
// 菜单列表
const MENUSLIST = 'userMenusList';
// 存储菜单路径 用户拥有菜单路径
const MENUSPATH = 'menu-path';
// 主题颜色变量值
const COLOR = 'custom-color';
// 通知公告
const NOTICE = 'notice';
//年级
const GRADE = 'grade';
//
const MENUSTYPE = 'menus-type'

//学科
const SUBJECT = 'subject';
//学段
const PERIODS='period';
//排课路由
const URLS = '/api/coursearrangement';
// const URLS = 'http://localhost';
export { MESSAGE, MODELLIST, MENUSLIST,MENUSTYPE, MENUSPATH, MODULEHOME, COLOR, NOTICE, URLS, GRADE, SUBJECT,PERIODS };

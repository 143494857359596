import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-orh-form-lang',
    templateUrl: './orh-form-lang.component.html',
    styleUrls: ['./orh-form-lang.component.less']
})
export class OrhFormLangComponent implements OnInit {

    constructor() { }

    status: number = 1;

    ngOnInit() {
    }

}

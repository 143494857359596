import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[orhMouseWheel]'
})
export class ScrollDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('mousewheel', ['$event'])
    onMouseWheel(event: any) {
       
        
        this.el.nativeElement.scrollTop += event.deltaY;
        
    }

}
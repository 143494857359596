import { Injectable } from '@angular/core';
import { BusinessService } from '../business/business.service';
import { gradesNewList } from '../../graphQl/base/grades';
import { GRADE, SUBJECT, PERIODS } from '../../utils/constant';
import { Apollo } from 'apollo-angular';
import { subjectNewLists } from '../../graphQl/base/subject';
import { periodList, schoolPeriods } from '../../graphQl/teachers/information';
import { formatDate } from '@angular/common';
import { GraphqlServiceService } from '../graphqlService/graphql-service.service';
import { BehaviorSubject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    colorItem$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    constructor(
        private business: BusinessService,
        private apollo: Apollo,
        private graph: GraphqlServiceService,
        private message: NzMessageService
    ) {

    }

    getGradeData() {

        const params = {
            query: gradesNewList,
            variables: {
                page: 1,
                limit: 1000,
                condition: {}
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.gradesNew.code === 200) {

                this.business.setItem(GRADE, res.data.gradesNew.data);

            }
            // else{
            //     this.message.error(res.data.gradesNew.msg)
            // }
        });
    }
    getSubjectData() {
        this.apollo.watchQuery({
            query: subjectNewLists,
            fetchPolicy: 'no-cache',
            variables: {
                page: 1,
                limit: 1000
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.subjectsNew.code === 200) {

                this.business.setItem(SUBJECT, res.data.subjectsNew.data);
            }
            else {
                // this.message.error(res.data.subjectsNew.msg)
            }
        })
    }
    getPeriodData(): void {
        this.apollo.watchQuery({
            query: schoolPeriods,
            variables: {
                page: 1,
                limit: 1000
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.schoolPeriods.code === 200) {

                this.business.setItem(PERIODS, res.data.schoolPeriods.data)
            } else {
                // this.message.error(res.data.periods.msg)
            }
        })
    }
    // 获取日期 yyyy-MM-dd
    getFormatDate(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd', 'zh-Hans');
    }
    // 获取日期 yyyy-MM-dd HH:mm
    getFormatDateTime(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd HH:mm', 'zh-Hans');
    }
    // 获取日期和时间一起 'yyyy-MM-dd HH:mm:ss'
    getFormatDateTimeFull(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'zh-Hans');
    }

    getMouthDate(date: Date): string {
        return formatDate(date, 'yyyy-MM', 'zh-Hans');
    }
    //时间戳转换为 yyyy-MM-dd
    formatDate(time) {
        let date = new Date(time);
        let YY = date.getFullYear() + '-';
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        // var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        // var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        // var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return YY + MM + DD;
    }
}

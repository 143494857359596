import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzFormatEmitEvent, NzMessageService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { knowledgePointsLists, newTextbooks, textbookChapterList } from 'src/app/graphQl/book';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { UtilsService } from 'src/app/service/utils/utils.service';

@Component({
  selector: 'orh-textbook',
  templateUrl: './orh-textbook.component.html',
  styleUrls: ['./orh-textbook.component.less']
})
export class OrhTextbookComponent implements OnInit {

    @Input() subjectId: any[] =[];
    @Input() periodId: any[] =[];
    @Output() textbooks: EventEmitter<any> = new EventEmitter();
    nodes: any[] = [];
    textbookId: any;
    constructor(
        private graph: GraphqlServiceService,
        private utils: UtilsService,
        private message:NzMessageService
    ) { }

    ngOnInit() {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.subjectId && changes.subjectId.currentValue) {
            if(changes.subjectId.currentValue !== []){
               
                
                this.getTextbooks()
            }
            
        }
        if (changes.periodId && changes.periodId.currentValue) {
            if(changes.periodId.currentValue !== []){
                
                this.getTextbooks()
            }
            
        }
    }
    
    // 教材数据
    getTextbooks(): void {
        const hello = {
            periodId: this.periodId?this.periodId:[],  // 学段id
            subjectId: this.subjectId?this.subjectId:[]  // 学科id
        };
        const form = this.utils.handleFilterNull(hello);
        const that = this;
        const params = {
            query: newTextbooks,
            variables: {
                page: 0,
                limit: 0,
                condition: form
            }
        };
        that.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.newTextbooks.code === 200) {
                let nodes = []
                res.data.newTextbooks.data.forEach(element => {
                    let node = {
                        title: element.textbookName.zh ? element.textbookName.zh : element.textbookName.mn,
                        key: element.id,
                        expanded: false,
                        type: 'textbook',
                        children: []
                    }
                    nodes.push(node)
                })
                this.nodes = nodes
            }else {
                this.message.error(res.data.newTextbooks.msg)
            }
        });
    }
    //点击树形
    nzEvent(event: Required<NzFormatEmitEvent>): void {
    
        let id: any = event.keys[event.keys.length - 1];
        const node = event.node;
        if (event.eventName === 'expand') {
            let parmas: any;
            if (node.level === 0) {
                parmas = {
                    textbookId: id,
                }
            } else {
                parmas = {
                    parentId: id,
                }
            }
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(parmas).then(data => {
                    node.addChildren(data)
                })
            }
        }
    }
    loadNode(data: any): Promise<NzTreeNodeOptions[]> {
        let that = this;
        return new Promise(resolve => {

            let params = {
                query: textbookChapterList,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: data
                }
            }
            that.graph.GQuery(params).subscribe((res: any) => {
                if (res.data.textbookChapters.code === 200) {
                    let array = res.data.textbookChapters.data.filter(item => item.parentId === null)
                    let array1 = res.data.textbookChapters.data.filter(item => item.parentId !== null)
                    let datas: any[] = [];
                    let data1: any[] = [];
                    let data2: any[] = [];
                    if (array1.length > 0) {
                        data1 = this.childrenTextbook(array, array1);

                    } else {
                        data2 = array.map(el => {
                            let arr: any;
                            arr = {
                                textbookId: el.textbookId,
                                title: el.chapterName.zh ? el.chapterName.zh : el.chapterName.mn,
                                key: el.id,
                                type: 'chapters',
                                isLeaf: false,
                                parentId: el.parentId,
                                children: [],
                            }
                            let params = {
                                query: knowledgePointsLists,
                                variables: {
                                    page: 0,
                                    limit: 0,
                                    condition: {
                                        textbookChapters: el.id
                                    }
                                }
                            }
                            that.graph.GQuery(params).subscribe((res: any) => {
                                if (res.data.knowledgePoints.code === 200) {
                                    if (res.data.knowledgePoints.data.length > 0) {
                                        arr.children = res.data.knowledgePoints.data.map((item: any) => {
                                            return {
                                                chapterId: el.id,
                                                key: item.id,
                                                title: item.knowledgeName.zh ? item.knowledgeName.zh : item.knowledgeName.mn,
                                                isLeaf: false,
                                                type: 'knowledge',
                                                children: [],
                                            }
                                        });
                                    } else {
                                        arr.isLeaf = true
                                    }

                                }
                            })
                            return arr;

                        });

                    }
                    datas = [...data1, ...data2];
                    
                    resolve(datas)
                } else {
                    resolve()
                }
            })

        })
    }
    childrenTextbook(event: any[], array1: any[]) {
        let datas = event.map(el => {
            const data = array1.filter(item => item.parentId === el.id);
            if (data.length > 0) {
                return {
                    textbookId: el.textbookId,
                    title: el.chapterName.zh ? el.chapterName.zh : el.chapterName.mn,
                    key: el.id,
                    type: 'chapters',
                    isLeaf: false,
                    parentId: el.parentId,
                    children: data.length ? this.childrenTextbook(data, array1) : [],
                }
            } else {
                let arrs = {
                    textbookId: el.textbookId,
                    title: el.chapterName.zh ? el.chapterName.zh : el.chapterName.mn,
                    key: el.id,
                    type: 'chapters',
                    isLeaf: true,
                    parentId: el.parentId,
                    children: [],
                }
                let params = {
                    query: knowledgePointsLists,
                    variables: {
                        page: 0,
                        limit: 0,
                        condition: {
                            textbookChapters: el.id
                        }
                    }
                }
                this.graph.GQuery(params).subscribe((res: any) => {
                    if (res.data.knowledgePoints.code === 200) {
                        if (res.data.knowledgePoints.data.length > 0) {
                            arrs.children = res.data.knowledgePoints.data.map((item: any) => {
                                return {
                                    chapterId: el.id,
                                    key: item.id,
                                    title: item.knowledgeName.zh ? item.knowledgeName.zh : item.knowledgeName.mn,
                                    isLeaf: true,
                                    type: 'knowledge',
                                    children: [],
                                }
                            });
                        } else {
                            arrs.isLeaf = true
                        }

                    }else {
                        this.message.error(res.data.knowledgePoints.msg)
                    }
                })
                return arrs;
            }
        })
        return datas;
    }
    nzEvents(evt: any) {
        this.textbooks.emit(evt.nodes)
    }
    
}


import { BrowserModule, HAMMER_LOADER, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule, Provider } from '@angular/core';
import { MenuService } from './service/menu/menu.service';

import { AppRoutingModule } from './app-routing.module';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, DatePipe } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { GraphQLModule } from './graphql.module';
import { DndModule } from 'ngx-drag-drop';
import { SharedModule } from './shared/shared.module';
import { DelonFormModule } from '@delon/form';
import { ParentsApplyComponent } from './parents-apply/parents-apply.component';
// import { EventService } from './service/event/event.service';

//组件
import { AppComponent } from './app.component';
import { ComponentModule } from './component/component.module';
import { LoginComponent } from './login/login.component';

//验证
import { LoginGuard } from './guards/login/login.guard';

// 服务
import { PermissionsService } from './service/permissions/permissions.service';
import { RequestService } from './service/request/request.service';
import { BaseService } from './service/base/base.service';
/**
 * 工具类
 */
import { UtilsService } from './service/utils/utils.service';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

// 二维码生成
import { QRCodeModule } from 'angular2-qrcode';
import { AttendanceServiceService } from './pages/attendance/attendance-service.service';
import { AttendanceStudentComponent } from './src/app/pages/attendance-student/attendance-student.component';
import { ResourcesService } from './pages/resources/resources.service';
import { QRcodeCallbackComponent } from './login/qrcode-callback/qrcode-callback.component';
import { SafePipe } from './pipe/safa/safa-page.pipe';
import { ZwRouteReuseStrategy } from './service/route-reuse/zw-route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';

// 1. 先导入


// 2.导出 
export const DINER_COMPONENTS: Provider[] = [SafePipe];


registerLocaleData(zh);

// 初始化加载Hammer.js
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        // override hammerjs default configuration
        pan: { threshold: 5 },
        swipe: {
            velocity: 0.4,
            threshold: 20,
            direction: 31,
        },
        press: {},
    };
}

const dbConfig: DBConfig = {
    name: 'OrhonSmartCampus',
    version: 1,
    objectStoresMeta: [
        {
            store: 'regions',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
    ],
};

@NgModule({
    declarations: [AppComponent, LoginComponent, ParentsApplyComponent, AttendanceStudentComponent, QRcodeCallbackComponent,DINER_COMPONENTS],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgZorroAntdModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ComponentModule,
        BrowserAnimationsModule,
        GraphQLModule,
        DndModule,
        SharedModule,
        DelonFormModule.forRoot(),
        NgxIndexedDBModule.forRoot(dbConfig),
        QRCodeModule


    ],
    providers: [
        { provide: NZ_I18N, useValue: zh_CN },
        LoginGuard,
        DatePipe,
        PermissionsService,
        BaseService,
        MenuService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestService,
            multi: true,
        },
        {
            provide:RouteReuseStrategy,useClass:ZwRouteReuseStrategy
        },
        {
            provide: HAMMER_LOADER,
            useClass: MyHammerConfig,
        },
        UtilsService,
        AttendanceServiceService,
        ResourcesService,
        // EventService
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

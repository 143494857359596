import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input
} from '@angular/core';

import {OrhDiscType} from './orh-disc.model';
import { UploadXHRArgs } from 'ng-zorro-antd/upload';
import { HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { PageConfig } from '../../model';
import { HttpService } from '../../service/http/http.service';
import { NzMessageService } from 'ng-zorro-antd';
@Component({
    selector: 'orh-disc',
    styleUrls: ['./orh-disc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-disc" omc-container
             [style.width.%]="100"
             [style.height.%]="100">
            <div class="orh-disc-header">
                <nz-button-group>
                    <button nz-button *ngFor="let item of tabsList">{{item.label}}({{item.number}})</button>
                    <nz-upload [nzAction]="ImageUrl" [nzCustomRequest]="customReq" [nzShowUploadList]="false" (nzChange)="handleUpload($event)">
                        
                        <div >
<!--                            <button nz-button nzType="primary"  ><span>上传</span></button>-->
                            <div *ngIf="upLoadDisabled">
                                <button nz-button nzType="primary"  disabled><span>上传中...</span></button>
                            </div>
                            <div *ngIf="!upLoadDisabled">
                                <button nz-button nzType="primary"  ><span>上传</span></button>
                            </div>
                        </div>

                    </nz-upload>
                </nz-button-group>
                <nz-input-group style="margin-left: 20px;width: 250px" nzSearch [nzAddOnAfter]="suffixIconSearch"
                                [nzSuffix]="suffixTemplate">
                    <input style="width:200px" class="input-search300" nz-input placeholder="请输入文件名称"
                           [(ngModel)]="fileName"/>
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <button
                            style="font-size: 20px;margin-right: -11px;margin-left: -12px;margin-top: -1px;"
                            nz-button
                            nzSearch
                            nzType="primary"
                            (click)="clickSearch()"
                    ><i nz-icon nzType="search" nzTheme="outline"></i>
                    </button>
                </ng-template>
                <ng-template #suffixTemplate
                ><i
                        nz-icon
                        nz-tooltip
                        class="ant-input-clear-icon"
                        nzTheme="fill"
                        nzType="close-circle"
                        *ngIf="fileName"
                        (click)="clear()"
                ></i
                ></ng-template>

            </div>

            <div class="orh-disc-other"></div>
            <div class="orh-disc-content">

                <orh-disc-list [data]="list" (orhChange)="dataChange($event)"></orh-disc-list>
            </div>
            <div class="orh-disc-page" style="margin-top: 20px">
                <ng-template #totalTemplate let-total> 共{{ pageConfig.total }} 条 </ng-template>
                <nz-pagination [(nzTotal)]="pageConfig.total" [(nzPageIndex)]="pageConfig.page"
                               [(nzPageSize)]="pageConfig.limit"  [nzShowTotal]="totalTemplate"
                               (nzPageIndexChange)="pageChange($event)"  nzShowQuickJumper>

                </nz-pagination>
            </div>
        </div>
    `
})
export class OrhDiscComponent implements OnInit {

    @Input()
    type: OrhDiscType;

    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();
    ImageUrl = '/api/resource/file/upload';
    pageConfig: PageConfig = {
        page: 1,
        limit: 10,
        total: 0
    }
    fileName: string | null = '';
    upLoadDisabled = false;
    constructor(
                private http: HttpService,
                private message:NzMessageService,
                private cdr: ChangeDetectorRef) {
    }

    public selected = null;
    public tabsList = [];

    public langsScore: any;
    public list = [];
    public formatOne = (percent: number) => `${percent} / 145`;

    ngOnInit() {
        this.getTabas();
    }


    getTabas(): void {
        const param = {
            url: 'resource/file/count',
            data: {}
        };
        this.http.get(param).subscribe(res => {
            if (res.code === 200) {
                const data = res.data;
                const list = [
                    {
                        label: `文件`,
                        value: null,
                        number: data.countTotal,
                        size: data.sizeTotal,
                        icon: 'iconziyuan_suoyouwenjian'
                    },
                    {
                        label: `图片`,
                        value: 'image',
                        number: data.countImage,
                        size: data.sizeImage,
                        icon: 'iconziyuan_tupian'
                    },
                    {
                        label: `视频`,
                        value: 'video',
                        number: data.countVideo,
                        size: data.sizeVideo,
                        icon: 'iconziyuan_shipin'
                    },
                    {
                        label: `音频`,
                        value: 'audio',
                        number: data.countAudio,
                        size: data.sizeAudio,
                        icon: 'iconziyuan_yinpin'
                    },
                    {
                        label: `文档`,
                        value: 'docs',
                        number: data.countDocs,
                        size: data.sizeDocs,
                        icon: 'iconziyuan_wendang'
                    },
                    {
                        label: `Ppt`,
                        value: 'ppt',
                        number: data.countPPT,
                        size: data.sizePPT,
                        icon: 'iconziyuan_wendang'
                    },
                    {
                        label: `Xls`,
                        value: 'xls',
                        number: data.countXls,
                        size: data.sizePPT,
                        icon: 'iconziyuan_wendang'
                    },
                    {
                        label: `Pdf`,
                        value: 'pdf',
                        number: data.countPDF,
                        size: data.sizePDF,
                        icon: 'iconziyuan_wendang'
                    },
                    {
                        label: `Zip`,
                        value: 'zip',
                        number: data.countZip,
                        size: data.sizeZip,
                        icon: 'iconziyuan_qita'
                    },
                    {
                        label: 'Text',
                        value: 'text',
                        number: data.countText,
                        size: data.sizeText,
                        icon: 'iconziyuan_qita'
                    },
                    {
                        label: `其他`,
                        value: 'other',
                        number: data.countOther,
                        size: data.sizeOther,
                        icon: 'iconziyuan_qita'
                    },
                ];
                this.handleType(list);
                this.getDataList();
            }else{
                this.message.error(res.msg)
            }
        });
    }

    handleType(data: any[]): void {
        this.selected = this.type;
        const list = data.find(el => el.value === this.type);
        switch (this.type) {
            case 'all':
                this.selected = null;
                this.tabsList = data;
                break;
            case 'audio':
                this.tabsList = [list];
                break;
            case 'docs':
                this.tabsList = [list];
                break;
            case 'image':
                this.tabsList = [list];
                break;
            case 'other':
                this.tabsList = [list];
                break;
            case 'pdf':
                this.tabsList = [list];
                break;
            case 'ppt':
                this.tabsList = [list];
                break;
            case 'text':
                this.tabsList = [list];
                break;
            case 'video':
                this.tabsList = [list];
                break;
            case 'xls':
                this.tabsList = [list];
                break;
            case 'zip':
                this.tabsList = [list];
                break;
        }
        console.log('tabsList',this.tabsList)
    }

    getDataList(): void {
        const userId = JSON.parse(localStorage.getItem('userInfo'));
        const param = {
            url: 'resource/file/filter',
            data: {
                path: `/${userId.id}/base`,
                page: this.pageConfig.page,
                limit: this.pageConfig.limit,
                fileType: this.selected
            }
        };
        this.http.post(param).subscribe(res => {
            console.log('data',res)
            if (res.code === 200) {
                this.list = res.data;
                this.pageConfig.total = res.total;
                this.cdr.detectChanges();
            }else{
                this.message.error(res.msg)
            }
        });
    }

    pageChange(event: number): void {
        console.log('event',event)
        this.pageConfig.page = event;
        this.list = [];
        this.getDataList();
    }

    tabsChange(event: any): void {
        this.selected = event;
    }

    btnClick(type: number): void {
        switch (type) {
            case 1:
                console.log('上传');
                break;
            case 2:
                console.log('下载');
                break;
            case 3:
                console.log('删除');
                break;
        }
    }

    dataChange(event: any[]): void {
        this.orhChange.emit(event);
    }

    customReq = (item: UploadXHRArgs) => {

        // const formData = new FormData();
        // console.log(item);
        // formData.append('file', item.file as any);
        // formData.append('parentRef',  this.parentRef );
        // const req = new HttpRequest('POST', item.action!, formData);
        //
        // this.upLoadDisabled = true;
        // return this.http.request(req).subscribe(
        //     (event: HttpEvent<any>) => {
        //         if (event.type === HttpEventType.UploadProgress) {
        //             if (event.total! > 0) {
        //
        //                 (event as any).percent = (event.loaded / event.total!) * 100;
        //             }
        //             item.onProgress!(event, item.file!);
        //         } else if (event instanceof HttpResponse) {
        //             item.onSuccess!(event.body, item.file!, event);
        //         }
        //     },
        //     err => {
        //         item.onError!(err, item.file!);
        //     }
        // )

    };


    handleUpload(event: any) {

        // console.log('上传的event',event)
        // if(event.type === 'success'){
        //     this.message.create('success', '上传成功');
        //
        //     this.upLoadDisabled = false;
        //     if(this.path){
        //         this.getDataList(this.path);
        //         if(this.radioValue !== '1'){
        //             this.getParentId();
        //             this.radioValue = '1';
        //         }
        //     }else{
        //         this.getDataList( this.getUrl);
        //         this.getParentId();
        //         this.radioValue = '1';
        //     }
        //
        // }else if(event.type === 'error'){
        //     this.upLoadDisabled = false;
        //     this.message.create('error', '上传失败');
        // }

    }
    clickSearch() {
        console.log(this.fileName, '5555555555555')
        console.log('click search run');
        this.getDataList();
    }
    clear() {
        this.fileName = '';
        this.getDataList();
    }


}

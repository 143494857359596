export default {
    // iconfont地址 实例注册地址- app/app.component.ts
    iconUrl: '//at.alicdn.com/t/c/font_1783076_r8aefkep8za.js',
    ////at.alicdn.com/t/font_1279512_pt0f0br2an.css
    // 高德地图key值
    key: '03b0f9acdd37a3e4ac6843bf72028406',
    language: 'zh', //zh,en,mn,
    baseUrl: '/api/',
    baseUrlOne: '/beiyong/',
    paperUrl: '/paper/',
    graphQlUrl: '/api/base/graphql', // GraphQl url地址
    ImageUrl: 'https://api.edu.mongol.pub/', // 图片上传地址
    Affix: true, // demo 是否显示
    // 路由白名单
    white_path: ['/pages/notice-content'],
    ResourcesUrl: 'https://www.dev.campus.orhonedu.com/api/resource',//资源接口地址
};

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RequestService } from '../../service/request/request.service';
import { Apollo } from 'apollo-angular';
import { ZorroTableTreeUtil } from 'great-zorroutils';
import { NzMessageService } from 'ng-zorro-antd';
@Component({
  selector: 'orh-mul-tag',
  templateUrl: './orh-mul-tag.component.html',
  styleUrls: ['./orh-mul-tag.component.less']
})
export class OrhMulTagComponent implements OnInit,OnChanges {

    @Input() type: string = null
    @Output() postTag: EventEmitter<any> = new EventEmitter();
    @ViewChild('originSelect', { static: false }) originSelect: any;
    backgroundColor: any = {
        background: 'linear-gradient(-90deg, var(--omc-color-primary), var(--omc-color-primary-contrast-rgb))',
        'box-shadow': 'var(--omc-color-primary-background-rgb) 0px 10px 10px 0px'
    };
    langTags: any;
    langfiles: any;
    langCourse: any;
    delParams: any;
    conditions: any | boolean;
    icons = 'iconxinjian';
    top: number = 0;
    data = [];
    checkArray: any[] = [];
    nodes: any[] = [];
    defaultCheckedKeys: any[] = [];
    
    form: any =
        {
            parentId: null

        }
    opertionShow = false;
    treeUtils: any;
    constructor(
        private apollo: Apollo,
        private message: NzMessageService,
        private req: RequestService
    ) {

    }
    checkboxChange(state: any, node: any) {
        this.checkArray = []
        this.treeUtils.updateCheckState(state, node);
        let datas = this.nodes.filter((item: any) => item.checked === true)
        datas.map((res: any) => {
            this.checkArray.push(res.id);

        })
        this.postTag.emit(this.checkArray)
    }
    ngOnInit() {
        this.getData()
        
    }
    collapse(array: any[], data: any, $event: boolean): void {
        this.treeUtils.collapse(array, data, $event);
        this.loadChildren(data);
    }

    loadChildren(data: any) {
        let newNodes = data.tagEntities;
        if (data && !data.children) {

            this.treeUtils.addNodes(data, newNodes);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            this.checkArray.push(parseInt(this.type));
            this.postTag.emit(this.checkArray)
            this.getData()
        } else {
            this.getData()
        }
    }
    clickTags(event, data: any) {
        if (data.checked === false) {
            data.checked = true;
        } else {
            data.checked = false;
        }
        const index = this.checkArray.indexOf(data.value);
        if (data.checked) {
            if (index === -1) {
                this.checkArray.push(data.value);

            }
        } else {
            if (index >= 0) {
                this.checkArray.splice(index, 1);

            }
        }
        this.postTag.emit(this.checkArray)
    }

    getData() {
        const that = this;

        this.req.get('base/tag/list', {
            page: 1, limit: 100,lavel:'school'
        }).subscribe((res: any) => {
            
            if (res.code === 200) {


                that.nodes = res.data.map((item: any) => {
                    return {
                        key: item.id,
                        id: item.id,
                        title: JSON.parse(item.name).zh?JSON.parse(item.name).zh:JSON.parse(item.name).mn,
                        lavel: item.lavel,
                        expand: false,
                        top: 0,
                        isLeaf: item.tagEntities.length > 0 ? false : true,
                        children: item.tagEntities ? this.getEntitles(0, item.tagEntities) : item.tagEntities
                    }
                })
                if (this.treeUtils) {
                    this.treeUtils.data = this.nodes
                } else {
                    this.treeUtils = new ZorroTableTreeUtil({
                        keys: { idKey: 'key', pIdKey: 'parentKey', pKey: 'parent', childKey: 'children' },
                        data: this.nodes
                    });
                }
                console.log(that.nodes);
                
                
            }else{
                this.message.error(res.msg)
            }
        })
    }
    getEntitles(num: number, data: any[]) {

        if (data.length > 0) {
            let res = data.map((item: any) => {
                return {
                    key: item.id,
                    id: item.id,
                    title:JSON.parse(item.name).zh?JSON.parse(item.name).zh:JSON.parse(item.name).mn,
                    lavel: item.lavel,
                    top: num + 15,
                    isLeaf: item.tagEntities.length > 0 ? false : true,
                    children: item.tagEntities ? this.getEntitles(num + 15, item.tagEntities) : item.tagEntities
                }
            })
            return res;
        }


    }
    

    // 添加标签
    addClick() {
        this.conditions = false;
        this.opertionShow = true;
    }

    

    // 操作
    moveChange(data: any, str: string) {

        if (str === 'parent') {
            this.conditions = null
            this.form.parentId = data.id;
            this.opertionShow = true;
            this.top = data.top
        } else if (str === 'edit') {
            this.conditions = data;
            this.opertionShow = true;
            this.top = data.top
        } else if (str === 'delete') {
            this.conditions = data;
            this.getDelete(data);
        }
    }


    /** 为id为11节点进行更新 **/

    toRemoveNode(item?: any) {
        let newNode = {
            id: item.id,
            key: item.id,
            lavel: item.lavel,
            name: item.name,
            tagEntities: item.tagEntities,
            // top: item.tagEntity ? 15 : 0
        };
        if (item) {
            this.treeUtils.toRemoveNode(newNode);
        }
    }

    // 删除操作
    getDelete(deleteData: any) {
        this.delParams = [deleteData.id];
        const that = this;
        that.apollo.mutate({
            mutation: deleteData,
            variables: {
                id: this.delParams,
            },
            fetchPolicy: 'no-cache',
        })
            .subscribe((res: any) => {
                if (res.data.deleteTag.code === 200) {
                    that.message.success(this.langfiles.delete_success);
                    //this.getData();
                    this.toRemoveNode(deleteData)
                } else {
                    that.message.error(res.data.deleteTag.msg);
                }
            });
    }

    //
    closes(item: any) {
        this.opertionShow = false;
        // this.getData();
        if (this.conditions) {
            this.toUpdateNode(item)
        } else {
            this.toAddNode(item);
        }

    }
    //
    toAddNode(item: any) {
        let newNodes = [{
            id: item.id,
            key: item.id,
            lavel: item.lavel,
            name: item.name,
            // tagEntities: item.tagEntities,
            parentKey: item.tagEntity ? item.tagEntity.id : null,
            top: this.top + 15
        }] as Array<any>;
        this.treeUtils.toAddNode(newNodes);
    }
    toUpdateNode(item: any) {
        let newNode = {
            id: item.id,
            key: item.id,
            lavel: item.lavel,
            name: item.name,
            // tagEntities: item.tagEntities,
            top: this.top
        };
        this.treeUtils.toUpdateNode(newNode);
    }
}
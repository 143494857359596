/*
 * @Author: yuanyuan
 * @Date: 2019-09-02 11:39:50
 * @Last Modified by: 姚双城
 * @Last Modified time: 2019-12-12 20:37:08
 */


import gql from 'graphql-tag';

/**
 * 查询学段列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const periodList = gql`
	query ($page: Int!, $limit: Int!){
		periods (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                number
                slug
                mark
            }
		}
	}
`

/**
 * 查询年级列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const gradesList = gql`
	query ($page: Int!, $limit: Int!){
		grades (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
            }
		}
	}
`

/**
 * 查询学科列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const subjectList = gql`
	query ($page: Int, $limit: Int){
		subjects (page: $page, limit: $limit){
			total
			code
            data {
                id
                subject_name {
                    en
                    mn
                    zh
                }
                mark
            }
		}
	}
`
const schoolPeriods  = gql`
    query ($page: Int!, $limit: Int!){
        schoolPeriods (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                number
                slug
                mark
            }
        }
    }
`

/**
 * 查询教职工列表数据
 * @param page 分页`
 * @param limit 每页数据量
 */

const queryTeacherList = gql`
	query ($page: Int!,$limit: Int!, $condition: TeacherCondition){
		teachers (
        page: $page,
        limit: $limit,
        condition: $condition
			){
	      		code
            msg
            total
            data{ 
                name {
                  en
                  mn
                  zh
                }
                id
                idcard
                imgphotos
                gender{
                  id
                  dictionary_value
                  name{
                    zh
                    en
                    mn
                  }
                }
                user{
                      id
                      school{
                        id
                      }
                  }
                nation{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                nationality{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                political_type{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                work_status{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                job_title{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                education{
                  id
                  dictionary_value
                  name{
                    zh
                    mn
                    en
                  }
                }
                telnumber
                birthday
                introduction{
                  zh
                  mn
                  en
                }
                periods{
                  id
                  mark
                  name{
                    zh
                    mn
                    en
                  }
                }
                grades{
                  id
                  
                  name{
                    zh
                    mn
                    en
                  }
                }
                subjects{
                  id
                  subject_number
                  subject_name{
                    zh
                    mn
                    en
                  }
                }
                native_province{
                    id
                    name {
                        zh
                    }
                }
                native_city{
                    id
                    name {
                        zh
                    }
                }
                native_county{
                    id
                    name {
                        zh
                    }
                }
			}
		}
	}
`
const queryTeacherNewList = gql`
	query ($page: Int!,$limit: Int!, $condition: TeacherCondition){
		teachersNew (
			page: $page,
			limit: $limit
			condition: $condition
			){
			code
            msg
            total
			data{ 
				name {
					en
					mn
					zh
				}
				id
				idcard
				imgphotos
				gender{
					id
					name{
						zh
						en
						mn
					}
				}
				user{
                    id
                    school{
                      id
                    }
                }
				nation{
					id
					name{
						zh
						mn
						en
					}
				}
				nationality{
					id
					name{
						zh
						mn
						en
					}
				}
				political_type{
					id
					name{
						zh
						mn
						en
					}
				}
				work_status{
					id
					name{
						zh
						mn
						en
					}
				}
				job_title{
					id
					name{
						zh
						mn
						en
					}
				}
				education{
					id
					name{
						zh
						mn
						en
					}
				}
				telnumber
				birthday
				introduction{
					zh
					mn
					en
				}
				periods{
					id
					name{
						zh
						mn
						en
					}
				}
				grades{
					id
					name{
						zh
						mn
						en
					}
				}
				subjects{
					id
					subject_name{
						zh
						mn
						en
					}
				}
			}
		}
	}
`
// /**
//  * 教职工添加数据
//  */
const addTeacherData = gql`
	mutation addTeacher($inputData: TeacherInput){
		addTeacher(inputData: $inputData){
			code
			msg
		}
	}
`
// /**
//  * 教职工修改数据
//  */
const findeTeacherData = gql`
	query ($id: Int!){
		teacher (id: $id) {
            id
			name{
				mn
				en
				zh
			}
            idcard
            user{
                id
              }
			gender {
				dictionary_value
				name{
					zh
				}
            }
			imgphotos
			telnumber
			nationality{
				dictionary_value
				name{
					zh
				}
			}
			political_type{
				dictionary_value
				name{
					zh
				}
			}
			work_status{
				dictionary_value
				name{
					zh
					mn
					en
				}
			}
			birthday
			job_title{
				dictionary_value
				name{
					zh
				}
			}
			education{
				dictionary_value
				name{
					zh
				}
			}
			degree{
				dictionary_value
				name{
					zh
				}
			}
			master_supervisor{
				dictionary_value
				name{
					zh
				}
			}
			teach{
				dictionary_value
				name{
					zh
				}
			}
			nation{
				dictionary_value
				name{
					zh
				}
			}
			introduction {
				mn
				zh
				en
			}
			periods{
				id
				name{
					zh
				}
			}
			grades{
				id
				name{
					zh
				}
			}
			subjects{
				id
				subject_name{
					zh
				}
			}
			school{
				name{
					zh
				}
			}
            native_province{
                id
                name {
                    zh
                }
            }
            native_city{
                id
                name {
                    zh
                }
            }
            native_county{
                id
                name {
                    zh
                }
            }
		}
	}
`
/**
 * 教职工删除数据
 */


const delTeacher = gql`
  mutation deleteTeacher($id: [Int]!){
    deleteTeacher(id:$id){
		code
		msg
    }
  }
`

/**
 * 教职工按年级筛选
 */
const gradeTeacherList = gql`
	query ($page: Int, $limit: Int,$id:Int ){
		grades (page: $page, limit: $limit,condition:{id:$id}){
            code
            msg
            total
            data {
				teacher{
					name {
						en
						mn
						zh
					}
					id
					gender{
						id
						name{
							zh
							en
							mn
						}
					}
					nation{
						id
						name{
							zh
							mn
							en
						}
					}
					telnumber
					birthday
				}
            }
		}
	}
`

/**
 * 教职工按年级筛选
 */
const subjectTeacherList = gql`
	query ($id:Int! ){
		subject (id:$id){
			teacher{
				name {
					en
					mn
					zh
				}
				id
				gender{
					id
					name{
						zh
						en
						mn
					}
				}
				nation{
					id
					name{
						zh
						mn
						en
					}
				}
				telnumber
				birthday
			}
		}
	}
`
export { queryTeacherList, delTeacher, addTeacherData, findeTeacherData, gradeTeacherList, subjectTeacherList, subjectList, periodList, gradesList, queryTeacherNewList,schoolPeriods }

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginGuard} from './guards/login/login.guard';
// import { MongolComponent } from './mongol/mongol.component'
// import { LoginMglComponent } from './mongol/login-mgl/login-mgl.component'
import { QRcodeCallbackComponent } from './login/qrcode-callback/qrcode-callback.component';

// 组件
import {LoginComponent} from './login/login.component';
import { ParentsApplyComponent } from './parents-apply/parents-apply.component';


const routes: Routes = [
    // 主入口页面
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [LoginGuard]
    },
    // 业务组件
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [LoginGuard]
    },
    {
        path: 'big-screen',
        loadChildren: () => import('./big-screen/big-screen.module').then(m => m.BigScreenModule),
    },
    {
        path: 'panel',
        loadChildren: () => import('./pages/panel/panel.module').then(m => m.PanelModule),
        canActivate: [LoginGuard]
    },
    // 登陆
    {
        path: 'login',
        component: LoginComponent
    },
    // 二维码回调
    {
        path: 'qr-callback',
        component: QRcodeCallbackComponent
    },


    // demo演示
    // {
    //   path: 'demo',
    //   loadChildren: () => import('./component/orh-demo/component/component.module').then(m => m.ComponentModule),
    //   canActivate: [LoginGuard]
    // },
    // //蒙文版主页
    // {
    //   path: 'mongol',
    //   component: MongolComponent,
    //   loadChildren:()=> import('./mongol/mongol.module').then(m => m.MongolModule)
    // },

    {
        path: '**',
        redirectTo: 'home'
    },
    {
        path:'parentsApply',
        component:ParentsApplyComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
    selector: 'orh-image',
    templateUrl: './orh-image.component.html',
    styleUrls: ['./orh-image.component.scss']
})
export class OrhImageComponent implements OnInit,OnChanges {
    @Input() images:any
    data:any[]=[]
    constructor(
        private http:RequestService
    ) { }

    ngOnInit() {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.images.currentValue){
            this.data = this.images.map((data: any) => {
                this.http.post('resource/file/download', {
                    objectName: data.filePath,
                    newName: data.fileTitle
                }).subscribe((item) => {
                    data.fileUrl = item
                });

                data.fileCover = data.fileCover
                data.fileId = data.fileId;
                data.filePath = data.filePath;
                data.fileTitle = data.fileTitle;
                return data;

            })
        }
        
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollDirective } from './scroll/scroll.directive';
import { AnchorDirective } from './anchor/anchor.directive';



@NgModule({
    declarations: [ScrollDirective,AnchorDirective],
    imports: [
      CommonModule
    ],
    exports:[
      ScrollDirective,AnchorDirective
    ]
})
export class DirectiveMnModule { }

/*
 * @Author: 阿拉斯
 * @Date: 2019-09-02 16:43:03
 * @Last Modified by: 阿拉斯
 * @Last Modified time: 2019-09-21 20:47:09
 */
import gql from 'graphql-tag';

/**
 * 查询数据字典列表数据
 * @param page 分页
 * @param limit 每页数据量
 * @param dictionary_code 查询分类
 */
const dictionaryList = gql`
	query (
        $page: Int!,
        $limit: Int!,
        $dictionary_code: String,
        $value:String
    ){
		Dictionarys (page: $page, limit: $limit, condition: {
            dictionary_code: $dictionary_code
            value:$value
        }){
            code
            msg
            total
            data {
                id
                alias
                status
                meta {
                    en
                    mn
                    zh
                }
                name {
                    en
                    mn
                    zh
                }
                type
                sort
                description {
                    en
                    mn
                    zh
                }
                dictionary_code
                dictionary_value
                value
            }
		}
	}
`
const findDictionaryData = gql`
	query ($id: Int!){
		Dictionary(id: $id){
            id
            alias
            status
            sort
            meta {
                en
                mn
                zh
            }
            name {
                en
                mn
                zh
            }
            type
            description {
                en
                mn
                zh
            }
            dictionary_code
            value
        }
    }
`
/**
 * 查询数据字典类型数据
 */
const allDictionaryData = gql`
		 query($page:Int,$limit:Int,$condition:DictionaryCondition){
      Dictionarys(page:$page,limit:$limit,condition:$condition){
            total
            code
            msg
            data {
                sort
                id
                name {
                    en
                    mn
                    zh
                }
                value
                description{
                  zh
                }
            }
		}
	}
`
/**
 * 查询数据字典类型数据
 */
const typeList = gql`
	query ($dictionary_code: String) {
		Dictionarys (page: 1, limit: 1000, condition: {
            dictionary_code: $dictionary_code
        }) {
            code
            msg
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                dictionary_value
            }
		}
	}
`
/**
 * 查询从业专业
 */
const teacherQualifications = gql`
	query ($pid : Int,$type:Int,$name:String) {
		teacherQualifications (pid: $pid, type:$type, name:$name ) {
            code
            msg
            data {
                id
                name
                pid
                type
            }
		}
	}
`
/**
 * 数据字典信息删除
 * @param id 数据字典id
 */
const delDictionaryData = gql`
	mutation deleteDictionary($id: [Int]!) {
		deleteDictionary(id: $id) {
			code
			msg
		}
	}
`
/**
 * 数据字典添加数据
 */
const addDictionaryData = gql`
	mutation addDictionary(
        $id: Int
        $value: String!
        $dictionary_code: String!
        $name: LangInput!
        $description: LangInput
        $type: String!
        $meta: LangInput
        $parent_id: Int
        $alias: String
        $status: Int
        $sort: Int
	){
		addDictionary(inputData: {
            id: $id
            value: $value
            dictionary_code: $dictionary_code
            name: $name
            description: $description
            type: $type
            meta: $meta
            parent_id: $parent_id
            alias: $alias
            status: $status
            sort: $sort
		}){
			code
			msg
		}
	}
`

export { dictionaryList,teacherQualifications, findDictionaryData, allDictionaryData, delDictionaryData, addDictionaryData, typeList }

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

interface ColorConfig {
    position: string,
    color: string,
    ok?: boolean,
    okText?: string,
    cancel?: boolean,
    cancelText?: string,
}

@Component({
    selector: 'app-orh-color-picker',
    templateUrl: './orh-color-picker.component.html',
    styleUrls: ['./orh-color-picker.component.less']
})

export class OrhColorPickerComponent implements OnInit {

    constructor() { }

    @Input() config: ColorConfig;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onOk: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    /**
	 * 选择颜色时
	 * @param event 更改的颜色
	 */
    colorChange(event: string): void {
        this.onChange.emit(event)
    }

    /**
	 * 取消选择时
	 */
    colorCancel(): void {
        if (this.config.cancel) this.onCancel.emit()
    }

    /**
	 * 确定颜色
	 * @param event 更改的颜色
	 */
    colorSelect(event: string): void {
        if (this.config.ok) this.onOk.emit(event)
    }

    ngOnInit() {
    }

}

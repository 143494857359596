/*
 * @Author: 阿拉斯
 * @Date: 2019-09-23 17:03:26
 * @Last Modified by: 阿拉斯
 * @Last Modified time: 2019-09-24 16:59:33
 */
import gql from 'graphql-tag';

/**
 * 查询部门数据
 * @param page 分页
 * @param limit 每页数据量
 * @param condition 查询条件
 */
const departmentList = gql`
	query ($page: Int!, $limit: Int!, $condition: DepartmentCondition){
		Departments (page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data {
                id
                department_name {
                    en
                    mn
                    zh
                }
                duties {
                    id
                      userList{
                        id
                        username
                        idcard
                      }
                    duties_name{
                        zh
                        en
                        mn
                    }
                    duties_order
                    level
                    users{
                        id
                        user{
                            id
                            email
                            username
                            password
                            status
                        }
                        duty{
                            id
                            duties_name{
                                zh
                                en
                                mn
                            }
                            duties_order
                            level
                            users{
                                id
                                user{
                                    id
                                    email
                                    username
                                    status
                                    idcard
                                }
                                is_leaders
                                leave_at
                                user_name{
                                    zh
                                    en
                                    mn
                                }
                            }
                        }
                    }
                }
                grade {
                    id
                }
                status
                subject {
                    id
                }
                type
                departmentType
                parent {
                    id
                }
                group_type
                department_order
            }
		}
	}
`
const departmentListData = gql`
	query ($page: Int!, $limit: Int!){
		Departments (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                department_name {
                    en
                    mn
                    zh
                }
                duties {
                    id
                      userList{
                        id
                        username
                        idcard
                      }
                    duties_name{
                        zh
                        en
                        mn
                    }
                    duties_order
                    level
                    users{
                        id
                        user{
                            id
                            email
                            username
                            password
                            status
                        }
                        duty{
                            id
                            duties_name{
                                zh
                                en
                                mn
                            }
                            duties_order
                            level
                            users{
                                id
                                user{
                                    id
                                    email
                                    username
                                    status
                                    idcard
                                }
                                is_leaders
                                leave_at
                                user_name{
                                    zh
                                    en
                                    mn
                                }
                            }
                        }
                    }
                }
                grade {
                    id
                }
                status
                subject {
                    id
                }
                type
                parent {
                    id
                }
                parentId
                group_type
                department_order
            }
		}
	}
`


const departmentListDatas = gql`
	query ($page: Int!, $limit: Int!){
		Departments (page: $page, limit: $limit){
        code
        msg
        total
        data {
            id
            parent { id }
            department_name {
                en
                mn
                zh
            }
            group_type
            type
            duties{
              id
              duties_name{zh}
            }
            heads{
              id
              name{
                zh
              }
            }
        }
		}
	}
`

const teachers = gql`
	query($page: Int!, $limit: Int!){
		teachers(page: $page, limit: $limit){
        code
        msg
        total
        data {
          user{
            id
          }
          name{
            zh
          }
        }
		}
	}
`

const newTeachers = gql`
	query($page: Int!, $limit: Int!,$condition:TeacherCondition){
		teachersNew(page: $page, limit: $limit,condition:$condition){
        code
        msg
        total
        data {
            id
            telnumber
          user{
            id
            username
            idcard
          }
          name{
            zh
            mn
          }
        }
		}
	}
`

/**
 * 查询部门数据
 * @param page 分页
 * @param limit 每页数据量
 * @param condition 查询条件
 */
const dutiesList = gql`
	query ($page: Int!, $limit: Int!, $condition: DutyCondition){
		Duties (page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data {
                id
                duties_name {
                    en
                    mn
                    zh
                }
            }
		}
	}
`

/**
 * 学生信息删除
 * @param id 学生id
 */
const delDepartmentData = gql`
	mutation deleteDepartment($id: [Int]!){
		deleteDepartment(id: $id){
			code
			msg
		}
	}
`

/**
 * 学生添加 / 修改 数据
 * @param id id
 * @param number 编号
 */
const addDepartmentData = gql`
	mutation addDepartment (
      $id: Int
      $department_name: LangInput
      $parent_id: Int
      $department_order: Int
      $type: String
      $group_type: String
      $departmentType: Int
      $status: Int
      $grade: [Int]
      $subject: [Int]
      $duties: [Int]
      $heads:[Int]
	){
		addDepartment (inputData: {
        id: $id
        department_name: $department_name
        departmentType: $departmentType
        parent_id: $parent_id
        department_order: $department_order
        type: $type
        group_type: $group_type
        status: $status
        grade: $grade
        subject: $subject
        duties: $duties
        heads:$heads
		}){
			code
			msg
		}
	}
`

/**
 *
 */
const findeDepartmentData = gql`
    query ($page: Int, $limit: Int, $condition: DepartmentCondition){
		Departments (page: $page, limit: $limit, condition: $condition) {
            code
            msg
            total
            data {
                id
                department_name {
                    en
                    mn
                    zh
                }
                duties {
                    id
                    duties_name{
                        zh
                        en
                        mn
                    }
                    duties_order
                    level
                    users{
                        id
                        user{
                            id
                            email
                            username
                            password
                            status
                        }
                        duty{
                            id
                            duties_name{
                                zh
                                en
                                mn
                            }
                            duties_order
                            level
                        }
                    }
                }
                grade {
                    id
                }
                departmentType
                status
                subject {
                    id
                }
                type
                parent {
                    id
                }
                group_type
                department_order
                heads{
                  id
                  name{
                    zh
                  }
                }
                icon
                
            }
		}
	}
	
`



const DepartmentUsers = gql`
	query ($page: Int!, $limit: Int!, $condition: DepartmentUserRecordCondition){
		DepartmentUsers (page: $page, limit: $limit, condition: $condition){
      code
      msg
      total
      data {
        duty{
          duties_name {
            zh
          }
        }
        user_name{
          zh
        }
        user{
          id
          email 
        }
      }
		}
	}
`
// const departmentAllUsers = gql`
// 	query ($page: Int, $limit: Int, $condition: DepartmentUserRecordCondition){
// 		departmentAllUsers (page: $page, limit: $limit, condition: $condition){
//       code
//       msg
//       total
//       data {
//         name
//         duties
//         email
//         department_id
//         user_id
//       }
// 		}
// 	}
// `
const departmentAllUsers = gql`
	query ($page: Int, $limit: Int, $condition: DepartmentUserRecordCondition){
		departmentAllUsers (page: $page, limit: $limit, condition: $condition){
      code
      msg
      total
      data {
        name{zh}
        duties
        email
        idcard 
        department_id
        user_id
      }
		}
	}
`


const Dutiess = gql`
	query ($page: Int!, $limit: Int!, $condition: DutyCondition){
		Duties (page: $page, limit: $limit, condition: $condition){
      code
      msg
      total
      data {
        id
        duties_name{
          zh
        }
        userList{
          id
          name{
            zh
          }
        }
      }
		}
	}
`

const setDepartmentUserRecord = gql`
    mutation setDepartmentUserRecord(
        $duty_id: Int!
        $department_id: Int!
        $userIds: [Int]
      ){
        setDepartmentUserRecord(inputData: {
          duty_id: $duty_id
          department_id: $department_id
          userIds: $userIds
       }){
			code
			msg
		}
	}`

const addDepartmentDutiesRelation = gql`
    mutation addDepartmentDutiesRelation (
        $dutyId: Int
        $departmentId: Int
        $modules: [String]
        $menus: [Object]
        $widgets: [String]
        $userId: Int
      ){
        addDepartmentDutiesRelation(inputData: {
          dutyId: $dutyId
          departmentId: $departmentId
          modules: $modules
          menus: $menus
          widgets: $widgets
          userId: $userId
       }){
			code
			msg
		}
	}`

const deleteDepartmentDutiesRelation = gql`
    mutation deleteDepartmentDutiesRelation (
        $dutyId: Int
        $departmentId: Int
        $modules: [String]
        $menus: [Object]
        $widgets: [String]
        $userId: Int
      ){
        deleteDepartmentDutiesRelation(inputData: {
          dutyId: $dutyId
          departmentId: $departmentId
          modules: $modules
          menus: $menus
          widgets: $widgets
          userId: $userId
       }){
			code
			msg
		}
	}`

const Modules = gql`
	query ($page: Int!, $limit: Int!,$condition:ModulesCondition){
		Modules (page: $page, limit: $limit,condition:$condition){
      code
      msg
      total
      data {
        id
        uniqueIdentifier
        module_name{
          zh
        }
        menus{
          id
          uniqueIdentifier
          title{zh}
          parent{
            uniqueIdentifier
          }
          widgets{
            id
            uniqueIdentifier
            title{zh}
            menu{
              uniqueIdentifier
            }
          }
        }
      }
		}
	}
`


const departmentDutiesRelation = gql`
	query ($condition: DepartmentDutiesRelationCondition){
		departmentDutiesRelation (condition: $condition){
      code
      msg
      data
		}
	}
`
const attendanceLeave = gql`
	query ($id: String){
        attendanceLeave(id:$id){
                idCard
                id
                schoolId
                schoolName
                userId
                userName
                leaveType
                leaveTypeTitle
                leaveReason
                startTime
                endTime
                firstApprove
                firstApproveName
                startTimeStr
                endTimeStr
                leaveCount
                status
                resumptionStatus
                calcLeaveCount
        }
	}
`

// 获取部门
const getDepartments = gql`
	query($page: Int!, $limit: Int!,$condition: DepartmentCondition){
        Departments (page: $page, limit: $limit, condition: $condition){
            code
            msg
            data{
                id
                department_name{
                    zh
                }
            }
            total
        }
    }
`


export {
    deleteDepartmentDutiesRelation, departmentDutiesRelation, addDepartmentDutiesRelation, Modules, departmentAllUsers,
    setDepartmentUserRecord, Dutiess, DepartmentUsers, teachers, departmentListDatas, departmentList, departmentListData,
    delDepartmentData, addDepartmentData, findeDepartmentData, dutiesList, newTeachers, attendanceLeave,
    getDepartments,
}

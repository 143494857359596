import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { regionsLists } from 'src/app/graphQl/base/regions';
import { addCurrentUnitSituation, findcurrentUnitSituation } from 'src/app/graphQl/teachers/teacher-profile/information';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'current-unit-operation',
  templateUrl: './current-unit-operation.component.html',
  styleUrls: ['./current-unit-operation.component.less']
})
export class CurrentUnitOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    regions: any[] = [];
    regionsChildren: any[] = [];
    regionsXian: any[] = [];
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            property: [null, Validators.required],//单位性质
            phone: ['', Validators.required],//单位电话
            sheng: [null, Validators.required],//工作单位驻地-省
            shi: [null, Validators.required],//工作单位驻地-市
            xian: [null, Validators.required],//工作单位驻地-县
            image: [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            dizhi : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//工作单位驻地-地址
            commonproject: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//单位全称
            membername: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//所属部门或机构
            membereducation : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//行政职务
            memberpost : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//工作内容
        })
    }
    dictionaryList: any = {
        unitproperties: []
    };
    ngOnInit() {
        this.dictionaryData();
        this.regionData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findcurrentUnitSituation,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.currentUnitSituation
            let form = this.form;
            form.get('property')!.setValue(data.property?data.property.dictionary_value:null);
            form.get('phone')!.setValue(data.phone);
            form.get('sheng')!.setValue(data.sheng.id);
            form.get('shi')!.setValue(data.shi.id);
            form.get('xian')!.setValue(data.xian.id);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('dizhi')!.setValue(data.dizhi ? data.dizhi : { mn: null, zh: null, en: null });
            form.get('commonproject')!.setValue(data.commonproject ? data.commonproject : { mn: null, zh: null, en: null });
            form.get('membername')!.setValue(data.membername ? data.membername : { mn: null, zh: null, en: null });
            form.get('membereducation')!.setValue(data.membereducation ? data.membereducation : { mn: null, zh: null, en: null });
            form.get('memberpost')!.setValue(data.memberpost ? data.memberpost : { mn: null, zh: null, en: null });
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
                
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    regionData(): void {
        let that = this;
        that.apollo.watchQuery({
            query: regionsLists,
            variables: {
                page: 0,
                limit: 0,
                level: 0,
                condition: {}
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.regions.code === 200) {
                that.regions = res.data.regions.data.map(item => {
                    return {
                        value: item.id,
                        label: item.name.zh ? item.name.zh : item.name.mn,
                        level:item.level
                    }
                })
            }else{
                this.msg.error(res.data.regions.msg)
            }
        })
    }
    omcChange(event:any) {
       console.log(event);
       
        let that = this;
        that.regionsChildren = []
        that.apollo.watchQuery({
            query: regionsLists,
            variables: {
                page: 0,
                limit: 0,
                level:1,
                condition: {
                    parent: event
                }
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.regions.code === 200) {
                that.regionsChildren = res.data.regions.data.map(item => {
                    return {
                        value: item.id,
                        label: item.name.zh ? item.name.zh : item.name.mn,
                        level:item.level
                    }
                })
            }else{
                this.msg.error(res.data.regions.msg)
            }
        })
    }
    cityChange(event:any) {
        
        let that = this;
        that.apollo.watchQuery({
            query: regionsLists,
            variables: {
                page: 0,
                limit: 0,
                level:2,
                condition: {
                    parent: event
                }
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.regions.code === 200) {
                that.regionsXian = res.data.regions.data.map(item => {
                    return {
                        value: item.id,
                        label: item.name.zh ? item.name.zh : item.name.mn,
                        level:item.level
                    }
                })
            }else{
                this.msg.error(res.data.regions.msg)
            }
        })
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'unitproperties',
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        console.log();
        
        params.teacher_id = this.teacherId
        console.log(params);
        const mutationParams = {
            mutation: addCurrentUnitSituation,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addCurrentUnitSituation.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            }else{
                this.msg.error(res.data.addCurrentUnitSituation.msg)
            }
            that.form.reset()
        })
    }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addHonoraryTitle, findhonoraryTitle } from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
    selector: 'teacher-honor-operation',
    templateUrl: './teacher-honor-operation.component.html',
    styleUrls: ['./teacher-honor-operation.component.less']
})
export class TeacherHonorOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    ImageUrl1 = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    fileList1: any[] = [];
    endOpen: boolean = false;
    formBody: any = {
        startdate: null,
        enddate: null

    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            leve: [null, Validators.required],//入选等级
            honorType: [null, Validators.required],//入选等级
            time: ['', Validators.required],//入选时间
            image: [''],//证书扫描件(上传图片)
            image1: [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//奖励项目
            grade: [null, Validators.required],//等级
            remark: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//排名

        })
    }
    dictionaryList: any = {
        selected_level: []
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findhonoraryTitle,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.honoraryTitle
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd')

            form.get('leve')!.setValue(data.leve ? data.leve.dictionary_value : null);
            form.get('honorType')!.setValue(data.honorType ? data.honorType.dictionary_value : null);

            form.get('time')!.setValue(data.time);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('name')!.setValue(data.name ? data.name : { mn: null, zh: null, en: null });
            form.get('grade')!.setValue(data.grade ? data.grade.dictionary_value : null);
            form.get('remark')!.setValue(data.remark ? data.remark : { mn: null, zh: null, en: null })

            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if (datas) {
                    this.updateMethods(datas)
                }
            }

            if (data.image1 !== null || data.image1 !== '') {
                let datas1 = data.image1 ? JSON.parse(`["${data.image1}"]`) : null;
                if (datas1) {
                    this.updateMethods1(datas1)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index = item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    updateMethods1(data: any) {
        this.fileList1 = data.map((item: any, i: any) => {
            let index = item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    onClose() {
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'selected_level',
            'ranking',
            'honor_type'
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                        console.log(that.dictionaryList);

                    } else {
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    handelChange1(event) {
        if (event.fileList1[0].response && event.fileList1[0].response.data) {
            this.form.value.image1 = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }

        if (this.fileList1.length > 0) {
            let arr: any
            this.fileList1.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image1 = arr;
        }
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');

        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addHonoraryTitle,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addHonoraryTitle.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else {
                this.msg.error(res.data.addHonoraryTitle.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    nzRemove1 = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };

    handlePreview1 = async (file: UploadFile) => {

    };

    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];

    filters1: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList1: UploadFile[]) => {

                const filterFiles = fileList1.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList1.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList1;
            }
        },
    ];

    disabledDate = (current: Date): boolean => {
        // Can not select days before today and today
        return current > new Date()
    };
}

import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BusinessService } from '../../service/business/business.service';
import Config from '../../config';
import * as wangEdit from 'wangeditor';

@Component({
    selector: 'app-orh-editor',
    templateUrl: './orh-editor.component.html',
    styleUrls: ['./orh-editor.component.less']
})
export class OrhEditorComponent implements OnInit, OnChanges {
    @Input() content: string=null;
    public editor: any;
    ImageUrl = Config.ImageUrl + 'base/file/ossupload';
    constructor(private el: ElementRef, private business: BusinessService) { }



    ngOnInit() {
    }
    ngAfterViewInit() {
        let editordom = this.el.nativeElement.querySelector('#editorElem');
        this.editor = new wangEdit(editordom);
        this.editor.customConfig.menus = [
            'head', // 标题
            'bold', // 粗体
            'fontSize', // 字号
            'fontName', // 字体
            'italic', // 斜体
            // 'underline', // 下划线
            // 'strikeThrough', // 删除线
            'foreColor', // 文字颜色
            'backColor', // 背景颜色
            // "link", // 插入链接
            'list', // 列表
            'justify', // 对齐方式
            // 'quote', // 引用
            // "emoticon", // 表情
            'image', // 插入图片
            'table', // 表格
            // "video", // 插入视频
            // "code", // 插入代码
            // 'undo', // 撤销
            // 'redo', // 重复
        ];
        // 配置服务器端地址
        this.editor.customConfig.uploadImgServer = this.ImageUrl;// '/api/admin/tmTaskManagement/taskupload';
        this.editor.customConfig.uploadImgParams = {
            // 如果版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
            // 如果版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
            token: this.business.getItem('token'),
        };
        this.editor.customConfig.uploadFileName = 'file';
        this.editor.customConfig.uploadImgHooks = {
            before: function (xhr, editor, files) {
                // 图片上传之前触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
                // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
                // return {
                //     prevent: true,
                //     msg: '放弃上传'
                // }
            },
            success: function (xhr, editor, result) {
                // 图片上传并返回结果，图片插入成功之后触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            fail: function (xhr, editor, result) {
                // 图片上传并返回结果，但图片插入错误时触发
                console.log(1);
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            error: function (xhr, editor) {
                // 图片上传出错时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },
            timeout: function (xhr, editor) {
                // 图片上传超时时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },
            // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
            // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
            customInsert: function (insertImg, result, editor) {
                // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

                // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                let url = result.data[0].newName;
                insertImg(url);
                console.log(url);
                // result 必须是一个 JSON 格式字符串！！！否则报错
            },
        };
        this.editor.customConfig.uploadImgShowBase64 = true; // 使用 base64 保存图片
        this.editor.customConfig.uploadImgTimeout = 5000; //上传图片的超时时间
        this.editor.customConfig.uploadImgMaxSize = 20 * 1024 * 1024; //图片的上传大小
        // 隐藏“网络图片”tab
        this.editor.customConfig.showLinkImg = false;
        this.editor.create();
        this.setEditorValue(this.content);
    }
    ngOnChanges(value: SimpleChanges) {
        // console.log(value, 'value');
        if (value && value.content.currentValue) {
            this.setEditorValue(value.content.currentValue);
        }
    }
    //获取编辑器值
    getValue(): any {
        let data = this.editor.txt.html();
        return data;
    }
    //设置编辑器值
    setEditorValue(val: string) {
        this.editor.txt.html(val || '');
    }


}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService } from 'ng-zorro-antd';
import { AliPlayerConfig } from 'ngx-orhon-aliplayer/lib/player/player.component.modal';
import { BitrateOption, VgMedia } from 'videogular2/compiled/src/core/core';
import { BusinessService } from '../../../service/business/business.service';
import { RequestService } from '../../../service/request/request.service';

@Component({
    selector: 'orh-file-priview',
    templateUrl: './orh-file-priview.component.html',
    styleUrls: ['./orh-file-priview.component.scss']
})
export class OrhFilePriviewComponent implements OnInit {
    @Input() fileInfo: any;
    previewAudioArray: any;
    workFiles: any[] = []
    files: any[] = [];
    iframFile:any=null;    
    previewArray: any;
    langfiles: any;
    workImgNum: number = 0;
    workImg: string = '';
    imgVisible: boolean = false;
    loading: boolean = false;
    userId: any;
    getUrl: string = '';
    @ViewChild('myMedia',{static:true}) myMedia: VgMedia;
    videoPath:string;
    nzBodyStyle = {
        width:'100%',
        height: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
    videoQuality1: BitrateOption = {
        qualityIndex: 720,
        width: 500,
        height: 300,
        bitrate: 1,
        mediaType: 'video/mp4',
        label: '高清',
    };
    videoQuality2: BitrateOption = {
        qualityIndex: 960,
        width: 500,
        height: 300,
        bitrate: 1,
        mediaType: 'video/mp4',
        label: '超清',
    };
    dashBitrates:Array<BitrateOption> = [];

    anchorConfig = {
        page: 1,
        limit: 1,
        total: 0
    }
    config: AliPlayerConfig = {
        autoplay: false
    }
    media: any;
    paramsData = {
        fileName: null,
        fileType: 'image',
        orderBy: 'createAt',
        path: null,
        sortBy: 'desc',
    }
    dataList: any[] = [];
    imgStyle = '';
    constructor(
        private business:BusinessService,
        private http:RequestService,
        private message:NzMessageService,
        private date: DatePipe,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;
        this.paramsData.path = '/' + this.userId + '/base';
        this.getFilter();
        this.videoPath = 'assets/demo.mp4';
        this.dashBitrates.push(this.videoQuality1, this.videoQuality2)
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fileInfo) {

            this.getFile(this.fileInfo)
        }

    }

    // 文件类型筛选
    getFilter() {

        this.dataList = [];
        this.http.post('resource/file/filter/all', this.paramsData).subscribe((res) => {
            if (res.code === 200) {
                const datas = [];
                res.data.map((el) => {
                    if (el.type !== 'sr:smartfolder') {
                        datas.push({
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            osspath: el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null,
                            properties: el.properties,
                            status: true,
                            checked: false,
                        });
                    } else {
                        return false;
                    }

                });
                this.dataList = datas;
            }else{
                this.message.error(res.msg)
            }
        });
    }
    //放大图片的下一个和上一个
    imgAnchor(type: string) {
        this.workImg = ''
        if (type === 'next') {
            if (this.workImgNum < this.workFiles.length) {
                this.workImgNum++;
            } else {
                this.workImgNum = this.workFiles.length;
            }

        } else if (type === 'pre') {
            if (this.workImgNum === 1) {
                this.workImgNum = 1;
            } else {
                this.workImgNum--;
            }

        }
        this.workImg = this.workFiles[this.workImgNum-1] ? this.workFiles[this.workImgNum-1] : ''

    }
    getImage(img: string, num: number) {
        this.workImgNum = num
        this.workImg = img
        this.imgVisible = true;

    }
    // 文件预览
    getFile(data: any) {
        this.loading = false
        this.files = [];
        this.workFiles = [];
        this.previewAudioArray = {};
        this.previewArray = {};
        const objectName = data.osspath;
        if (data.mime_type === 'image') {

            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.files = item;

            });
        } else if (data.mime_type === 'video') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {

                this.previewArray = item
                this.loading = true

            });

        } else if (data.mime_type === 'audio') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.previewAudioArray = item;
                this.loading = true
            });
        } else {
            this.http.get('resource/file/preview', {
                identifier: data.parentRef
            }).subscribe((item) => {
                console.log('文件数据:', item);
                const datas = item.data.properties['sr:preview'];
                if (datas) {
                    let reg = RegExp(/\"/g);
                    if (datas.match(reg)) {
                       
                        const guide = datas.replace(/\"/g, '').split(',');
                        this.workFiles = guide
                        this.anchorConfig.total = this.workFiles.length
                    } else {
                        this.iframFile = this.sanitizer.bypassSecurityTrustResourceUrl(datas);
                    }

                } else {

                    this.message.create('error','正在转码中，暂时预览不了');
                }

            });
        }
    }

    anchorChange(event: number) {
        this.anchorConfig.page = event + 1;

    }

    //关闭图表放大
    discSubmit() {
        this.imgVisible = false
    }

    handleAnchor(type: string): void {
        if (type === 'next') {
            if (this.anchorConfig.page < this.workFiles.length) {
                this.anchorConfig.page++
            } else {
                this.anchorConfig.page = this.workFiles.length - 1
            }
        } else if (type === 'pre') {
            if (this.anchorConfig.page === 1) {
                this.anchorConfig.page = 1

            } else {
                this.anchorConfig.page--
            }
        }
    }
    nzPageIndexChange(evt:number){
        this.anchorConfig.page =evt
    }
}

import gql from 'graphql-tag';

//标签列表
const tagsData = gql`
	query ($page: Int,$limit:Int,$condition:TagCondition) {
		tags (page: $page, limit: $limit, condition:$condition ) {
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                lavel
                tagSlug 
                tagOrder
                tagEntity{
                    id
                    name {
                        en
                        mn
                        zh
                    }
                    lavel
                    tagSlug 
                    tagOrder
                }
                tagEntities{
                    id
                    name {
                        en
                        mn
                        zh
                    }
                    lavel
                    tagSlug 
                    tagOrder
                }
            }
		}
	}
`;
//单条
const findtagData = gql`
	query ($id :Int!) {
		tag (id: $id) {           
            id
            name {
                en
                mn
                zh
            }
            lavel
            tagSlug 
            tagOrder
            tagEntity{
                id
                name {
                    en
                    mn
                    zh
                }
                lavel
                tagSlug 
                tagOrder
            }
            tagEntities{
                id
                name {
                    en
                    mn
                    zh
                }
                lavel
                tagSlug 
                tagOrder
            }           
		}
	}
`;
/**
 * 删除标签
 * @param
 */
const deleteTag = gql`
	mutation ($id: [Int]!) {
		deleteTag(id: $id) {
			code
			msg
		}
	}
`;
//添加标签
const addTag = gql`
    mutation addTag(
    $inputData: TagInput
    ){
        addTag(
        inputData:$inputData
        ){
        code
        msg
        data{
            id
            name {
                en
                mn
                zh
            }
            lavel
            tagSlug 
            tagOrder
            tagEntity{
                id
                name {
                    en
                    mn
                    zh
                }
                lavel
                tagSlug 
                tagOrder
            }
            tagEntities{
                id
                name {
                    en
                    mn
                    zh
                }
                lavel
                tagSlug 
                tagOrder
            }
        }
        }
    }
`;
export {
    tagsData,
    findtagData,
    addTag,
    deleteTag
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NzFormatEmitEvent, NzMessageService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Semesters } from 'src/app/graphQl/moral/education-data';
import { BusinessService } from 'src/app/service/business/business.service';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { RequestService } from 'src/app/service/request/request.service';
import { PERIODS, SUBJECT } from 'src/app/utils/constant';

@Component({
  selector: 'orh-questions-search',
  templateUrl: './orh-questions-search.component.html',
  styleUrls: ['./orh-questions-search.component.less']
})
export class OrhQuestionsSearchComponent implements OnInit {

    @Output() postSearch: EventEmitter<any> = new EventEmitter();
    @Output() searchName: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    typeShow: boolean = true;
    knowShow: boolean = false;
    fileNames: string = '';
    typeSelect: number = 0;
    selected: number = 1;
    semeterSelect: number = 0;
    type: any[] = [
        {
            value: 1,
            label: '试卷知识点'
        },
        {
            value: 2,
            label: '课本知识点'
        }
    ]
    qustypes: any[] = [
        {
            value: '',
            label: '全部'
        },
        {
            value: 'radio',
            label: '单选题'
        },
        {
            value: 'multiple',
            label: '多选题'
        },
        {
            value: 'stuff',
            label: '填空题'
        },
        {
            value: 'short',
            label: '简答题'
        },
        {
            value: 'judgment',
            label: '判断题'
        },
        {
            value: 'combination',
            label: '组合题'
        }
    ];
    subjectData: any[] = [];
    periodData: any[] = [];
    semesterList: any[] = [];
    nodes: any[] = [];
    nodesNext: any[] = [];
    perintID: any;
    knowsName: any[] = [];
    langs: any;
    selectShow: boolean = true;
    subjectSelect: number = 0;
    periodSelect: number = 0;
    constructor(
        private business: BusinessService,
        private graph: GraphqlServiceService,
        private fb: FormBuilder,
        private req: RequestService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
        this.getSemesters();
        
        // 学科数据
        this.subjectData.push({
            value: '',
            label: '全部'
        })
        this.business.getItem(SUBJECT).map((item: any) => {
            let res = {
                value: item.subject_number,
                label: item.subject_name.zh ? item.subject_name.zh : item.subject_name.mn
            };
            this.subjectData.push(res)
        });
        this.periodData.push({
            value: '',
            label: '全部'
        })
        // 学段数据
        this.business.getItem(PERIODS).map((item: any) => {
            let res = {
                value: item.mark,
                label: item.name.zh ? item.name.zh : item.name.mn
            };
            this.periodData.push(res)
        });
        this.form = this.fb.group({
            ques_type: [null],
            acayear: [null],
            knows: [[]],
            stage: [null],
            subject: [null],
        });
    }
    getConcal(event) {
        let div = document.getElementById('knowwrap');
        if (!event.path.includes(div)) {
            this.knowShow = false;
        }

    }
    getSetType() {
        this.typeShow = !this.typeShow;
    }
    //名称搜索
    nameSearch() {
        this.searchName.emit(this.fileNames)
    }
    selectType(type: number) {
        this.selectShow = !this.selectShow;
        this.selected = type;
        console.log(this.selected);

    }
    //学期
    getSemesters(): void {
        this.semesterList = [];
        const params = {
            query: Semesters,
            variables: {
                page: 0,
                limit: 0,
                condition: {}
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.Semesters.code === 200) {
                this.semesterList = res.data.Semesters.data.map((item: any) => {
                    return {
                        value: item.zujuanCode,
                        label: item.name.zh ? item.name.zh : item.name.mn
                    }
                })
            }else{
                
            }
        })
    }
    //试卷知识点
    getKnow(num: number) {
        if (this.form.value.stage && this.form.value.subject) {
            this.req.paperGet('knowledge', {
                type: num,
                stage: this.form.value.stage,
                subject: this.form.value.subject,
            }).subscribe((item: any) => {
                this.nodes = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
        } else {
            // this.message.error(this.planLang.select_grade_on_subject);
        }
    }
    //课本知识点
    getKnowNext(num: number) {
        if (this.form.value.stage && this.form.value.subject) {
            this.req.paperGet('knowledge', {
                type: num,
                stage: this.form.value.stage,
                subject: this.form.value.subject,
            }).subscribe((item: any) => {
                this.nodesNext = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
        } else {
            // this.message.error(this.planLang.select_grade_on_subject);
        }
    }
    //展开
    nzEvents(event: Required<NzFormatEmitEvent>) {
        console.log(event);

        this.perintID = event.node.key;
        if (event.eventName === 'expand') {
            const node = event.node;
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode().then(data => {
                    node.addChildren(data);
                });
            }
        }
    }
    loadNode(): Promise<NzTreeNodeOptions[]> {
        return new Promise(resolve => {
            let datas = []
            this.req.paperGet('knowledge?pid=' + this.perintID).subscribe((item: any) => {
                datas = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
            setTimeout(
                () =>
                    resolve(datas),
                1000
            );
        });
    }
    nzEvent(event: any, num: number) {
        if (event.eventName === 'click') {
            let neowid = this.form.value.knows;
            let index = neowid.indexOf(event.node.key);
            if (index === -1) {
                neowid.push(event.node.key)
                this.knowsName.push({
                    title: event.node._title,
                    value: event.node.key
                })
            } else if (index >= 0) {
                let indexName = this.knowsName.findIndex((item: any) => item.value === event.node.key);
                neowid.splice(index, 1);
                this.knowsName.splice(indexName, 1);
            }
            this.form.get('knows')!.setValue(neowid);
        }
    }
    //取消选中的知识点
    closeKnowsName(data: any) {
        let index = this.knowsName.findIndex(item => item.value === data.value);
        this.knowsName.splice(index, 1);
        this.nodes = this.nodes.map(item => {
            if (item.key === data.value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, data.value) : []
            }
            return item
        })
        this.nodesNext = this.nodesNext.map(item => {
            if (item.key === data.value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, data.value) : []
            }
            return item
        })
        this.getSelected(data.value)
    }
    //循环子集
    getSelected(data) {
        this.nodes.includes(item => {
            this.getSelected(item)
            if (item.key === data.value) {
                item.selected = false;
            }
            return item
        })
    }
    //
    nodeChildren(data: any[], value: string) {
        return data.map((item: any) => {
            if (item.key === value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, value) : []
            }
            return item
        })
    }
    //学科
    getSubject(id: any, num: number) {
        this.subjectSelect = num;
        this.form.get('subject')!.setValue(id);
        if (this.form.value.stage) {
            this.getKnow(1);
            this.getKnowNext(2)
        }
    }
    //题类型
    getType(id: any, num: number) {
        this.typeSelect = num;
        this.form.get('ques_type')!.setValue(id)
    }
    //学期
    getPeriod(id: any, num: number) {
        this.periodSelect = num;
        this.form.get('stage')!.setValue(id);
        if (this.form.value.subject) {
            this.getKnow(1);
            this.getKnowNext(2)
        }
    }
    //学期
    selectSemester(id: any, num: number) {
        this.semeterSelect = num;
        this.form.get('acayear')!.setValue(id)
    }
    //选择知识点
    omcFocus() {
        this.knowShow = true;
        this.getKnow(1)
        this.getKnowNext(2);
    }
    getSearch() {
        this.postSearch.emit(this.form.value);
        this.typeShow = true;
    }
    //关闭筛选
    getRease() {
        // this.form.reset()
        this.typeShow = true
    }
}

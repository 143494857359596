import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
import 'hammerjs';

if (environment.production) {
    enableProdMode();
    // 生产环境去除console
    // window.console.log = function() {};
    // window.console.info = function() {};
    // window.console.warn = function() {};
    // window.console.error = function() {};
}

if (environment.hmr) {
    // tslint:disable-next-line: no-string-literal
    if (!module['hot']) {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    } else {
        hmrBootstrap(module, bootstrap);
    }
} else {
    bootstrap().catch(err => console.log(err));
}

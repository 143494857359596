import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaperServiceService {

  constructor(private http: HttpClient) { }
  getToken() {
    let userId = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : 0;
    this.http.post('/paper/token', { 'user_id': userId, 'lang': 'mn' }).subscribe((res:any) => {
      localStorage.setItem('papertoken', res.token);
    })
  }
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { prepareLesson } from 'src/app/graphQl/preparation';
import { GraphqlQueryParams } from 'src/app/model';
import { BusinessService } from 'src/app/service/business/business.service';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { MenuService } from 'src/app/service/menu/menu.service';
import { MENUSLIST } from 'src/app/utils/constant';

@Component({
  selector: 'orh-lesson-lesson',
  templateUrl: './orh-lesson-lesson.component.html',
  styleUrls: ['./orh-lesson-lesson.component.less']
})
export class OrhLessonLessonComponent implements OnInit {

    @Input() lessonData: any;
    data: any[] = []
    constructor(
        private graph: GraphqlServiceService,
        private business:BusinessService,
        private menu:MenuService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lessonData.currentValue) {
            
            this.data=[]
            this.lessonData.map((ind: any) => {
                const param = {
                    query: prepareLesson,
                    variables: {
                        id: ind.fileId
                    },
                    policy: 'no-cache',
                    name: 'preparation'
                };
                this.graph.GQuery(param).subscribe((res: any) => {
                    if (res.data.prepareLesson.code === 200) {
                        let datas = res.data.prepareLesson.data
                        ind.content = datas.prepareLessonContent ? JSON.parse(datas.prepareLessonContent) : [];
                    }else{
                        this.message.error(res.data.prepareLesson.msg)
                    }
                });
                ind.fileCover = ind.fileCover?ind.fileCover:null;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath?ind.filePath:null;
                ind.fileTitle = ind.fileTitle?ind.fileTitle:null;
                this.data.push(ind)
            })
            // console.log(this.data);

        }

    }
    getGql(param: GraphqlQueryParams, callback: any): void {
        this.graph.GQuery(param).subscribe((res: any) => {
            callback(res);
        });
    }
    getLesson(file: any) {
        if (file) {
            return file
        } else {
            return '/assets/image/img.jpg'
        }
    }
    getLessonData(el) {
        this.getMenu()
        window.open('/pages/prepare-course/prepare-detail?id=' + el.fileId)

    }
    getMenu() {
        let menus = this.business.getItem(MENUSLIST);
        let arr = []
        menus.map((item: any) => {
            if (item.path.search('/pages/prepare-course') !== -1) {
                arr.push(item)
            } else {
                return false
            }
        })
        this.business.setItem('SiderMenu', arr)
        let files = arr.find(item => item.path === '/pages/prepare-course/prepare-square');
        localStorage.setItem('slider-history', JSON.stringify(files));
        this.menu.setBreadCrumbs([files.title.mn])
    }

}

import { Injectable } from '@angular/core';
// import { EventEmitter } from 'eventemitter3';
import {Subject} from 'rxjs';
// tslint:disable-next-line:no-duplicate-imports
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

    private _selectedPoint: Subject<any> = new Subject<any>();
    public eventEmit: any;

    constructor() {
        // this.eventEmit = new EventEmitter();
    }

    public setSelectedPoint(selectedPointsIfo: any): void {
        this._selectedPoint.next(selectedPointsIfo);
    }

    public currentSelectedPoint(): Observable<any> {
        return this._selectedPoint.asObservable();
    }
}

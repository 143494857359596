import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { BusinessService } from '../../service/business/business.service';

@Component({
    selector: 'orh-icon',
    templateUrl: './orh-icon.component.html',
    styleUrls: ['./orh-icon.component.less']
})
export class OrhIconComponent implements OnInit, OnChanges {

    @Input() icon: string;
    @Input() size: Array<number | string> | string;
    @Input() color: string;

    width: string = '1em';
    height: string = '1em';
    iconColor:string = ''
    //#008054
    constructor(
        private business:BusinessService
    ) {
    }

    ngOnInit() {
    //    let color= this.business.getItem('userInfo').school.settings ? this.business.getItem('userInfo').school.settings.themeSettings.zh:'#206F55';
       let colors= this.business.getItem('userInfo').school ? (this.business.getItem('userInfo').school.settings ? this.business.getItem('userInfo').school.settings.themeSettings.zh:'#206F55'):'#206F55';
        this.iconColor = 'drop-shadow('+colors+' 55px 0px 0px)';
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        let that = this;
        if (changes.size) {
            let val = changes.size.currentValue;
            let bool = Array.isArray(changes.size.currentValue);
            if (bool) {
                that.width = `${val[0]}px`;
                that.height = `${val[1]}px`;
            } else {
                that.width = that.height = `${val}px`;
            }
        }
    }
}

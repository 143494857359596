import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { Semesters } from 'src/app/graphQl/moral/education-data';
import { addTeachingDesign, TeachingDesigns } from 'src/app/graphQl/preparation';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { HttpService } from 'src/app/service/http/http.service';

@Component({
    selector: 'orh-desgin',
    templateUrl: './orh-desgin.component.html',
    styleUrls: ['./orh-desgin.component.scss']
})
export class OrhDesginComponent implements OnInit {
    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();
    public page = 1;
    public limit = 10;
    public total = 0;
    public list = [];
    semesterList: any[] = [];
    delParams: any[] = [];
    datas: any[] = [];
    selectTotal: number = 0;
    icons = 'iconxinjian';
    public semesterId = null;
    infoShow: boolean = false;
    backgroundColor: any = {
        background: `linear-gradient(-90deg,rgba(255,192,70,1),rgba(245,144,0,1))`,
        boxShadow: '0px 10px 10px 0px rgba(245,161,0,0.38)'
    };

    constructor(private graph: GraphqlServiceService,
        private router: Router,
        private http: HttpService,
        private message:NzMessageService
    ) {
    }

    ngOnInit() {
        this.getSemesters();
    }

    chancgeSemester(): void {
        this.page = 1;
        this.getList();
    }

    getList(): void {
        const param = {
            query: TeachingDesigns,
            variables: {
                page: this.page,
                limit: this.limit,
                condition: {
                    semesterId: this.semesterId,
                    isMyPrepareLesson: true
                }
            },
            name: 'preparation'
        };
        this.graph.GQuery(param).subscribe((res: any) => {
            if (res.data.teachingDesigns.code === 200) {
                this.total = res.data.teachingDesigns.total;
                this.list = res.data.teachingDesigns.data.map((item:any)=>{
                    return {
                        checked:false,
                        ...item
                    }
                });
            
            }else{
                this.message.error(res.data.teachingDesigns.msg)
            }
        });
    }
    checkChange(): void {
        let datas = this.list.map((item: any) => {
            return {
                ...item,
                image: this.getdeparmentIcon(item.departmentId)
            }
        })
        console.log(datas);

        const data = datas.filter(el => el.checked);
        this.selectTotal = datas.length;
        this.orhChange.emit(data);
    }
    // 学期
    getSemesters(): void {
        this.semesterList = [];
        const params = {
            query: Semesters,
            variables: {
                page: 0,
                limit: 0,
                condition: {}
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.Semesters.code === 200) {
                const data = res.data.Semesters.data.find(el => el.is_current === 0);
                this.semesterList = res.data.Semesters.data.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.name.mn ? item.name.mn : item.name.zh
                    };
                });
                this.semesterId = data.id;
                this.getList();
            }else{
                
            }
        });
    }
    addClick() {
        this.infoShow = true;
        const param = {
            url: 'cms/template/list',
            data: {
                page: 1,
                limit: 1000
            }
        };
        this.http.post(param).subscribe((item: any) => {
            this.datas = item.data.data
        })
    }
    omcOk() {
        this.infoShow = false;
    }
    //新添加
    addData(): void {
        this.infoShow = false;
        const param = {
            mutation: addTeachingDesign,
            variables: {
                inputData: {
                    prepareLessonTitle: ' ',
                    prepareLessonPlanType: 2,
                    publishType: 1,
                    prepareLessonPlanId: null,
                    departmentId: null,
                    prepareLessonContent: '[]',
                    semesterId: this.semesterId
                }
            },
            name: 'preparation'
        };
        this.graph.GMutation(param).subscribe((res: any) => {
            if (res.data.addTeachingDesign.code) {

                this.router.navigate(['/pages/prepare-course/prepare-design-add'], {
                    queryParams: {
                        id: res.data.addTeachingDesign.id,
                        type: 'my'
                    }
                });

            }
        });
    }
    //选择模块
    addTemplate(data: any) {

        // let contents = JSON.parse(data.content)
        this.infoShow = false;

        const param = {
            mutation: addTeachingDesign,
            variables: {
                inputData: {
                    prepareLessonTitle: ' ',
                    prepareLessonPlanType: 2,
                    publishType: 1,
                    prepareLessonPlanId: null,
                    departmentId: null,
                    prepareLessonContent: data.content,
                    semesterId: this.semesterId
                }
            },
            name: 'preparation'
        };
        this.graph.GMutation(param).subscribe((res: any) => {
            if (res.data.addTeachingDesign.code) {

                this.router.navigate(['/pages/prepare-course/prepare-design-add'], {
                    queryParams: {
                        id: res.data.addTeachingDesign.id,
                        type: 'my'
                    }
                });

            }
        });
    }
    //处理图片
    getdeparmentIcon(data: any) {
        if(data){
            if (data.subjectImageZh) {
                return data.subjectImageZh
            }else if(data.subjectImageMn){
                return data.subjectImageMn
            }else {
                return '/assets/image/lesson/other.jpg'
            }
        }else{
            return '/assets/image/lesson/other.jpg'
        }
    }



    pageChange(page: number): void {
        this.page = page;
        this.list = [];
        this.getList();
    }

}


import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-orh-card',
  templateUrl: './orh-card.component.html',
  styleUrls: ['./orh-card.component.less']
})
export class OrhCardComponent implements OnInit {
  // 标题
  @Input() title ?: string = '';
  // 右上角操作区域
  @Input() nzExtra?: boolean = false;
  // 是否有边框
  @Input() nzBordered?: boolean = true;
  // 鼠标移上是否可浮起
  @Input() nzHoverable?: boolean = true;
  constructor() {
  }

  ngOnInit() {
  }

}

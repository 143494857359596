import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter} from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addMasterWork, findforeignLanguages, masterWork , addHeadTeacher, headExtTeacher} from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'head-teacher-operation',
  templateUrl: './head-teacher-operation.component.html',
  styleUrls: ['./head-teacher-operation.component.less']
})
export class HeadTeacherOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Input() tabType: any;

    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    ImageUrl1 = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    fileList1: any[] = [];
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacherId: [''],
            startTime: ['', Validators.required],
            endTime: ['', Validators.required],
            classNumber: ['', Validators.required]
        })
    }
    dictionaryList: any = {
        languagename: []
    };
    ngOnInit() {
        this.dictionaryData();
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: headExtTeacher,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.headExtTeacher;
            let form = this.form;
            form.get('startTime')!.setValue(data.startTime);
            form.get('endTime')!.setValue(data.endTime);
            form.get('classNumber')!.setValue(data.classNumber);
            if (data.filePath !== null || data.filePath !== '') {
                let datas = data.filePath ? JSON.parse(`["${data.filePath}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }

            
        })
    }
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ]; 
    filters1: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList1: UploadFile[]) => {
                console.log('fileList0033', fileList1);

                const filterFiles = fileList1.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList1.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList1;
            }
        },
    ]; 
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    updateMethods1(data: any) {
        this.fileList1 = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }

    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'languagename',
            'mandarin_leave'
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                        console.log(that.dictionaryList);
                        
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.filePath = event.fileList[0].response.data[0].newName;
        }
    }

    handelChange1(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.filePath = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.filePath = arr;
        }

        params.teacherId = this.teacherId
        params.startTime = this.date.transform(params.startTime, 'yyyy-MM-dd');
        params.endTime = this.date.transform(params.endTime, 'yyyy-MM-dd');
        console.log(params);
        const mutationParams = {
            mutation: addHeadTeacher,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addHeadTeacher.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addHeadTeacher.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    nzRemove1 = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };

    handlePreview1 = async (file: UploadFile) => {

    };
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'orh-lesson-editor',
    styleUrls: ['./orh-lesson-editor.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-tinymce">
            <div class="orh-tinymce-tool {{toolbar}}"></div>
            <div class="orh-tinymce-content {{selector}}">
                <ng-container *ngIf="custom">
                    <ng-content></ng-content>
                </ng-container>
                <ng-container *ngIf="!custom">
                    <orh-lesson-editor-container [delay]="delay" [selector]="selector" [toolbar]="toolbar" [(ngModel)]="counts"
                        width="100%"
                        [placeholder]="placeholder"
                        selector="orh-tinymce-container"
                        (orhChange)="editorChange($event)"
                        (orhInput)="editorInput($event)"
                        (orhDelay)="tinymceDelay($event)">
                    </orh-lesson-editor-container>
                </ng-container>
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrhLessonEditorComponent),
            multi: true
        }
    ]
})
export class OrhLessonEditorComponent implements ControlValueAccessor {

    @Input()
    selector!: string;

    @Input()
    toolbar!: string;

    @Input()
    custom = false;

    @Input()
    delay = 2000;

    
    @Input()
    placeholder = '请输入内容';


    @Output()
    orhDelay: EventEmitter<any> = new EventEmitter();

    @Output()
    orhInput: EventEmitter<any> = new EventEmitter();
    public counts = '';

    get count() {
        return this.counts;
    }

    set count(value: string) {
        this.counts = value;
        this.propagateChange(this.counts);
        this.cdr.detectChanges();
    }

    constructor(private cdr: ChangeDetectorRef) {
    }

    propagateChange = (_: any) => {
    };

    writeValue(value: any) {
        if (value !== this.count) {
            this.count = value;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
    }

    editorChange(event: any): void {
        this.count = event;
        
    }

    editorInput(event: any): void {
        if (!this.custom) {
            this.orhInput.emit(event);
        }
    }

    tinymceDelay(event: string): void {
        this.orhDelay.emit(event);
    }

}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd';
import { addWorkExperience, findworkExperience } from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'work-experience-operation',
  templateUrl: './work-experience-operation.component.html',
  styleUrls: ['./work-experience-operation.component.less']
})
export class WorkExperienceOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        startdate:null,
        enddate:null

    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            startdate: ['', Validators.required],//开始时间
            enddate: ['', Validators.required],//结束时间
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//单位名称
            postname : this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//职称
        })
    }
    ngOnInit() {
        if (this.condition) {
            this.findData()
        }
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //单条
    findData() {
        let that = this;
        const params = {
            query: findworkExperience,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.workExperience;
            let form = this.form;
            data.startdate = this.date.transform(data.startdate, 'yyyy-MM-dd')    
            data.enddate = this.date.transform(data.enddate, 'yyyy-MM-dd')    
            
            form.get('startdate')!.setValue(data.startdate);  
            form.get('enddate')!.setValue(data.enddate);  
            form.get('name')!.setValue(data.name?data.name:{mn:null,zh:null,en:null});
            form.get('postname')!.setValue(data.postname?data.postname:{mn:null,zh:null,en:null});
            
        })
    }
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.timeEnd) {
            return false;
        }
        return startValue.getTime() > this.formBody.timeEnd.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.timeStart) {
            return false;
        }
        return endValue.getTime() <= this.formBody.timeStart.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.timeStart = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.timeEnd = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = open;
    }
    

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
       
        params.startdate = this.date.transform(params.startdate, 'yyyy-MM-dd');
        params.enddate = this.date.transform(params.enddate, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addWorkExperience,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addWorkExperience.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else {
                this.msg.error(res.data.addWorkExperience.msg)
            }

            that.form.reset()


        })

    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelonFormModule, WidgetRegistry } from '@delon/form';
import { NgxTinymceModule, TinymceOptions } from 'ngx-tinymce';
//delon
import { DelonACLModule } from '@delon/acl';

// tinymce
import { EditorTinymceComponent } from '../component/orh-customWidgets/editor-tinymce/editor-tinymce.component';
import { FormsModule } from '@angular/forms';

export const SCHEMA_THIRDS_COMPONENTS = [EditorTinymceComponent];

@NgModule({
    declarations: SCHEMA_THIRDS_COMPONENTS,
    entryComponents: SCHEMA_THIRDS_COMPONENTS,
    imports: [
        CommonModule,
        DelonACLModule.forRoot(),
        DelonFormModule,
        NgxTinymceModule.forRoot({
            baseURL: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/5/',
            fileName: 'tinymce.min.js',
        }),
        FormsModule,
    ],
    exports: [DelonACLModule, ...SCHEMA_THIRDS_COMPONENTS],
})
export class SharedModule {
    constructor(widgetRegistry: WidgetRegistry) {
        widgetRegistry.register(EditorTinymceComponent.KEY, EditorTinymceComponent);
    }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { addAcademicOrganization, findacademicOrganization } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'academic-groups-operation',
  templateUrl: './academic-groups-operation.component.html',
  styleUrls: ['./academic-groups-operation.component.less']
})
export class AcademicGroupsOperationComponent implements OnInit {
    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        time:null,
        endtime:null

    }
    constructor(
        private fb: FormBuilder,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
           
            time: ['', Validators.required],//开始时间
            entime: ['', Validators.required],//结束时间
           
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//组织或期刊名称
            position: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//职务
            
        })
    }
    dictionaryList: any = {
        education: [],
        trainingmode:[],
        degree:[]

    };
    ngOnInit() {
        if (this.condition) {
            this.findData()
        }
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //单条
    findData() {
        let that = this;
        const params = {
            query: findacademicOrganization,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.academicOrganization;
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd')    
            data.entime = this.date.transform(data.entime, 'yyyy-MM-dd')              
            form.get('time')!.setValue(data.time);  
            form.get('entime')!.setValue(data.entime);              
            form.get('name')!.setValue(data.name?data.name:{mn:null,zh:null,en:null});
            form.get('position')!.setValue(data.position?data.position:{mn:null,zh:null,en:null});
        })
    }
    
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        console.log('startValue',startValue);
        
        if (!startValue || !this.formBody.endtime) {
            return false;
        }
        return startValue.getTime() > this.formBody.endtime.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        console.log('endValue',endValue);
        
        if (!endValue || !this.formBody.time) {
            return false;
        }
        return endValue.getTime() <= this.formBody.time.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        console.log('onStartChange',date);
        
        this.formBody.time = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        console.log(date);
        this.formBody.endtime = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        // if (!open) {
            this.endOpen = true;
        // }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = true;
    }
    
    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
   
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.entime = this.date.transform(params.entime, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addAcademicOrganization,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addAcademicOrganization.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else {
                that.msg.error('添加失败')
            }

            that.form.reset()


        })

    }
}


import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { addPrepareLesson, prepareLessons } from 'src/app/graphQl/preparation';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';

import { HttpService } from 'src/app/service/http/http.service';
import { myLessonGroup } from '../../../graphQl/preparation';

@Component({
    selector: 'orh-prepare',
    templateUrl: './orh-prepare.component.html',
    styleUrls: ['./orh-prepare.component.scss']
})
export class OrhPrepareComponent implements OnInit {
    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();
    public page = 1;
    public limit = 10;
    public total = 0;
    public list = [];
    semesterList: any[] = [];
    delParams: any[] = [];
    datas: any[] = [];
    icons = 'iconxinjian';
    public semesterId = null;
    infoShow: boolean = false;
    selectTotal:number = 0;
    departmentId:any[] = [];
    backgroundColor: any = {
        background: `linear-gradient(-90deg,rgba(255,192,70,1),rgba(245,144,0,1))`,
        boxShadow: '0px 10px 10px 0px rgba(245,161,0,0.38)'
    };

    constructor(private graph: GraphqlServiceService,
        private router: Router,
        private http: HttpService,
        private message:NzMessageService
    ) {
    }

    ngOnInit() {
        this.myGroup()
    }
    myGroup() {
        let params = {
            query: myLessonGroup
        }
        this.graph.GQuery(params).subscribe((item: any) => {
            let datas = item.data.getLoginUserPreparationSubjectGroupInfo.data;
            if (datas.length > 0) {
                
                datas.filter(item => item.departmentType === 'preparation').map((el: any) => {
                    this.departmentId.push(el.departmentId)
                })
                
                
                this.getList()
                
            }

        })
    }

    getList(): void {
        const param = {
            query: prepareLessons,
            variables: {
                page: this.page,
                limit: this.limit,
                condition: {
                    semesterId: this.semesterId,
                    departmentId: this.departmentId,
                    publishType:[1,2,3],
                    order:'id',
                    sort:'desc'
                }
            },
            name: 'preparation'
        };
        this.graph.GQuery(param).subscribe((res: any) => {
            if (res.data.prepareLessons.code === 200) {
                this.total = res.data.prepareLessons.total;
                this.list = res.data.prepareLessons.data.map((item:any)=>{
                    return {
                        checked:false,
                        ...item
                    }
                });
            }else{
                this.message.error(res.data.prepareLessons.msg)
            }
        });
    }

    checkChange(): void {
        let datas = this.list
        
        const data = datas.filter(el => el.checked);
        this.selectTotal = datas.length;
        this.orhChange.emit(data);
    }
    addClick() {
        this.infoShow = true;
        const param = {
            url: 'cms/template/list',
            data: {
                page: 1,
                limit: 1000
            }
        };
        this.http.post(param).subscribe((item: any) => {
            this.datas = item.data.data
        })
    }
    omcOk() {
        this.infoShow = false;
    }
    //新添加
    addData(): void {
        this.infoShow = false;
        const param = {
            mutation: addPrepareLesson,
            variables: {
                inputData: {
                    prepareLessonTitle: ' ',
                    prepareLessonPlanType: 2,
                    publishType: 1,
                    prepareLessonPlanId: null,
                    departmentId: null,
                    prepareLessonContent: '[]',
                    semesterId: this.semesterId
                }
            },
            name: 'preparation'
        };
        this.graph.GMutation(param).subscribe((res: any) => {
            if (res.data.addPrepareLesson.code) {

                this.router.navigate(['/pages/prepare-course/my-prepare-edit'], {
                    queryParams: {
                        id: res.data.addPrepareLesson.id
                    }
                });

            }
        });
    }
    //选择模块
    addTemplate(data: any) {
        // let contents = JSON.parse(data.content)
        this.infoShow = false;

        const param = {
            mutation: addPrepareLesson,
            variables: {
                inputData: {
                    prepareLessonTitle: ' ',
                    prepareLessonPlanType: 2,
                    publishType: 1,
                    prepareLessonPlanId: null,
                    departmentId: null,
                    prepareLessonContent: data.content,
                    semesterId: this.semesterId
                }
            },
            name: 'preparation'
        };
        this.graph.GMutation(param).subscribe((res: any) => {
            if (res.data.addPrepareLesson.code) {

                this.router.navigate(['/pages/prepare-course/my-prepare-edit'], {
                    queryParams: {
                        id: res.data.addPrepareLesson.id
                    }
                });

            }
        });
    }
    //处理图片
    getdeparmentIcon(data: any) {        
        if(data){
            if (data.subjectImageZh) {
                return data.subjectImageZh
            }else if(data.subjectImageMn){
                return data.subjectImageMn
            }else {
                return '/assets/image/lesson/other.jpg'
            }
        }else{
            return '/assets/image/lesson/other.jpg'
        }
    }

    pageChange(page: number): void {
        this.page = page;
        this.list = [];
        this.getList();
    }
}

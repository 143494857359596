import gql from 'graphql-tag';

/**
 * 前缀 moral
 */
/**
 * 德育数据添加
 */
const newaddMoralData = gql`
    mutation addMoralData($inputData: MoralDataInput) {
        addMoralData(inputData: $inputData) {
            code
            msg
        }
    }
`;

// 根据宿舍获取学生

const SiteQueryStudent = gql`
    query($ids: [Int]) {
        studentByIds(condition: { ids: $ids }) {
            code
            msg
            data {
                id
                name {
                    zh
                }
                idcard
            }
            total
        }
    }
`;

/**
 * 获取楼宇场地
 */
const querySeleSites = gql`
    query {
        SeleSites(page: 0, limit: 0) {
            code
            msg
            data {
                id
                site_name
                gqlBuildings {
                    id
                    buildings_name
                    units {
                        id
                        unit_name
                        floorEntities {
                            id
                            floor_name
                            roomEntities {
                                id
                                room_name
                            }
                        }
                    }
                }
            }
        }
    }
`;
/**
 * 德育数据添加
 */
const addMoralData = gql`
    mutation addMoralData(
        $checkTeacherUserId: String
        $checkTeacherName: String
        $classExaminationPercentage: Float
        $collectiveType: Int
        $gradeId: String
        $describe: String
        $dormitoryId: String
        $dormitoryName: String
        $isClassExamination: Int
        $isStudentExamination: Int
        $isTeacherExamination: Int
        $moralDetailId: String
        $moralDetailName: String
        $moralDetailStatus: Int
        $moralProjectId: String
        $moralProjectName: String
        $moralProjectSort: Int
        $moralProjectType: Int
        $schoolId: Int
        $score: Float
        $classId: String
        $semesterId: String
        $className: String
        $semesterName: String
        $studentExaminationPercentage: Float
        $studentList: [studentListInput]
        $teacherExaminationPercentage: Float
        $time: String
        $gradeName: String
    ) {
        addMoralData(
            inputData: {
                classId: $classId
                gradeName: $gradeName
                gradeId: $gradeId
                className: $className
                checkTeacherUserId: $checkTeacherUserId
                checkTeacherName: $checkTeacherName
                classExaminationPercentage: $classExaminationPercentage
                collectiveType: $collectiveType
                describe: $describe
                dormitoryId: $dormitoryId
                dormitoryName: $dormitoryName
                isClassExamination: $isClassExamination
                isStudentExamination: $isStudentExamination
                isTeacherExamination: $isTeacherExamination
                moralDetailId: $moralDetailId
                moralDetailName: $moralDetailName
                moralDetailStatus: $moralDetailStatus
                moralProjectId: $moralProjectId
                moralProjectName: $moralProjectName
                moralProjectSort: $moralProjectSort
                moralProjectType: $moralProjectType
                schoolId: $schoolId
                score: $score
                semesterId: $semesterId
                semesterName: $semesterName
                studentExaminationPercentage: $studentExaminationPercentage
                studentList: $studentList
                teacherExaminationPercentage: $teacherExaminationPercentage
                time: $time
            }
        ) {
            code
            msg
        }
    }
`;
/**
 * 德育数据添加
 */
 const updateMoralData = gql`
 mutation addMoralData( $inputData: MoralDataInput) {
    addMoralData(
        inputData: $inputData
    ) {
        code
        msg
    }
 }
`;
/**
 * 查询德育数据列表
 */

const moralDataList = gql`
    query($page: Int, $limit: Int, $condition: MoralDataCondition) {
        moralData(page: $page, limit: $limit, condition: $condition) {
            code
            msg
            total
            data {
                id
                schoolId
                semesterId
                semesterName
                moralProjectId
                moralProjectName
                moralProjectType
                collectiveType
                moralProjectSort
                isClassExamination
                classExaminationPercentage
                isStudentExamination
                studentExaminationPercentage
                isTeacherExamination
                teacherExaminationPercentage
                moralDetailId
                moralDetailName
                moralDetailStatus
                score
                studentUserId 
                studentName
                gradeId
                gradeName
                classId
                className
                dormitoryId
                dormitoryName
                time
                image
                describe
                teacherUserId
                teacherName
                teacherScore
                checkTeacherUserId
                checkTeacherName
                dataType
                studentScore
                teacherScore
                classScore

            }
        }
    }
`;

/**
 * 学期
 */
const Semesters = gql`
    query($page: Int, $limit: Int, $condition: SemesterCondition) {
        Semesters(page: $page, limit: $limit, condition: $condition) {
            code
            msg
            total
            data {
                id
                semester_code
                name {
                    zh
                    mn
                    en
                }
                yearly_code
                is_current
                zujuanCode
                teaching_start_date
                teaching_end_date
            }
        }
    }
`;
const grades = gql`
    query($page: Int, $limit: Int, $condition: GradesInput) {
        grades(page: $page, limit: $limit, condition: $condition) {
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
            }
        }
    }
`;

const Eclasses = gql`
    query($condition: EclassCondition) {
        Eclasses(page: 0, limit: 0, condition: $condition) {
            code
            msg
            data {
                id
                grade {
                    id
                    name {
                        zh
                    }
                }
                name {
                    en
                    mn
                    zh
                }
                head_teacher {
                    teacher {
                        user{
                            id
                        }
                        name {
                            zh
                        }
                    }      
                }
                students {
                    id
                    users{
                        id
                    }
                    student {
                        id
                        name {
                            zh
                            mn
                        }
                    }
                }
            }
        }
    }
`;
/**
 * 删除德育基础信息
 */

const deleteMoralData = gql`
    mutation ($id: String!){
        deleteMoralData(
            id: $id
            ){
            code
            msg
        }
    }
`;
export { deleteMoralData, addMoralData,updateMoralData, newaddMoralData, moralDataList, Semesters, grades, Eclasses, querySeleSites, SiteQueryStudent };

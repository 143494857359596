import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService } from 'ng-zorro-antd';
import { AliPlayerConfig } from 'ngx-orhon-aliplayer/lib/player/player.component.modal';
import { BusinessService } from '../../service/business/business.service';
import { RequestService } from '../../service/request/request.service';

@Component({
  selector: 'orh-leaning-periview',
  templateUrl: './orh-leaning-periview.component.html',
  styleUrls: ['./orh-leaning-periview.component.less']
})
export class OrhLeaningPeriviewComponent implements OnInit {

    @Input() itemId: string;
    @Input() fileInfo: any;
    @Output() statusPost: EventEmitter<any> = new EventEmitter();
    @Output() studyCource: EventEmitter<any> = new EventEmitter();
    backgroundColor = {
        background: `linear-gradient(-90deg,rgba(173,216,158,1),rgba(148,197,131,1))`,
        boxShadow: '0px 10px 10px 0px rgba(173,216,158,0.38)'
    };
    previewAudioArray: any;
    config:AliPlayerConfig={
        autoplay: false
    }
    workFiles: any[] = []
    files: any[] = [];
    iframFile:any=null;
    previewArray: any;
    messageShow = false;
    langfiles: any;
    workImgNum = 0;
    workImg = '';
    loading = false;
    form: any = {
        userId: null,
        nodeId: null,
        visit: 0,   // 访问学习次数
        duration: null, // 学习时长
        progress: 20 // 0-100 播放进度进度
    };
    anchorConfig = {
        page: 1,
        limit: 1,
        total: 0
    };
    omcVisible = false;

    constructor(
        private http: RequestService,
        private message: NzMessageService,
        private business: BusinessService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.form.userId = this.business.getItem('userInfo').id;
        
        // if (this.fileInfo) {
        // 	this.getFile(this.fileInfo)
        // }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getFile(this.fileInfo);
    }

    getImage(item: any, ind: any) {

    }

    // 文件预览
    getFile(data: any) {
        this.loading = false;
        this.files = [];
        this.workFiles = [];
        this.previewAudioArray = {};
        this.previewArray = {};
        const objectName = data.osspath;
        if (data.mime_type === 'image') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.files = item;

            });
        } else if (data.mime_type === 'video') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.previewArray = item;
                this.loading = true;
            });

        } else if (data.mime_type === 'audio') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.previewAudioArray = item;
                this.loading = true;
            });
        } else {
            this.http.get('resource/file/preview', {
                identifier: data.parentRef
            }).subscribe((item) => {
                const datas = item.data.properties['sr:preview'];
                if (datas) {
                    let reg = RegExp(/\"/g);
                    if (datas.match(reg)) {
                       
                        const guide = datas.replace(/\"/g, '').split(',');
                        this.workFiles = guide
                        this.anchorConfig.total = this.workFiles.length
                    } else {
                        this.iframFile = this.sanitizer.bypassSecurityTrustResourceUrl(datas);
                    }
                } else {

                    this.message.error('正在转码中，暂时预览不了');
                }

            });
        }
    }

    anchorChange(event: number) {
        this.anchorConfig.page = event + 1;
        const num = (this.anchorConfig.page / this.workFiles.length) * 100;
        const num2 = Math.round(num);
        if (this.workFiles.length === this.anchorConfig.page) {
            this.messageShow = true;
        }
        this.statusPost.emit(num2);
    }

    timeupdate(event: any) {
        console.log(event);

    }

    // 视频截取
    omcPlayer(event: any) {
        const num = event.instance.getCurrentTime();
        const num1 = event.instance.getDuration();
        const num2 = ((num / num1) * 100);
        const evt = Math.round(num2);
        this.statusPost.emit(evt);
    }
    // 下一个/上一个按钮
    handleAnchor(type: string): void {
        if (type === 'next') {
            if (this.anchorConfig.page < this.workFiles.length) {
                this.anchorConfig.page++;
            } else {
                // this.anchorConfig.page = this.workFiles.length - 1;
                this.messageShow = true;
                this.omcVisible = true;
            }
        } else if (type === 'pre') {
            if (this.anchorConfig.page === 1) {
                // this.anchorConfig.page = 1
                this.studyCource.emit('pre');
            } else {
                this.anchorConfig.page--;
            }
        }
    }
    //放大图片的下一个和上一个
    imgAnchor(type: string) {
        this.workImg = ''
        if (type === 'down') {
            if (this.workImgNum < this.workFiles.length) {
                this.workImgNum++;
            } else {
                this.workImgNum = this.workFiles.length;
            }

        } else if (type === 'up') {
            if (this.workImgNum === 1) {
                this.workImgNum = 1;
            } else {
                this.workImgNum--;
            }

        }
        this.workImg = this.workFiles[this.workImgNum-1] ? this.workFiles[this.workImgNum-1] : ''

    }

    // 确定
    onlyOmcOk() {
        this.omcVisible = false;
        this.studyCource.emit('next');
        this.anchorConfig.page = 1;
    }
    nzOnCancel(){
        this.omcVisible = false;
    }
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy(): void {
        this.workFiles = [];
    }
    nzPageIndexChange(evt:number){
        this.anchorConfig.page =evt
    }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
    selector: 'orh-cloud',
    templateUrl: './orh-cloud.component.html',
    styleUrls: ['./orh-cloud.component.scss']
})
export class OrhCloudComponent implements OnInit, OnChanges {
    @Input() classData: any;
    data: any[] = []
    constructor(
        private http: RequestService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.classData.currentValue) {
            this.classData.map((ind: any) => {
                this.http.get('cms/course/one', {
                    id: ind.fileId,
                }).subscribe(res => {
                    if (res.code === 200) {

                        ind.files = res.data.chapters.map((item: any) => {
                            console.log(item);
                            
                            return {
                                fileCover: item.coverFile,
                                fileId: item.id,
                                filePath: item.nodeType,
                                title: item.title,
                                sections: item.sections.map((res: any) => {
                                    return {
                                        fileCover: res.coverFile,
                                        fileId: res.id,
                                        filePath: res.nodeType,
                                        fileTitle: res.title,
                                    }
                                })
                            }
                        })
                    }else{
                        this.message.error(res.msg)
                    }
                });
                ind.fileCover = ind.fileCover;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath;
                ind.fileTitle = ind.fileTitle;
                this.data.push(ind)
            })
            console.log(this.data);
        }
    }
}

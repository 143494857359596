import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { queryList, queryDepartmentUsers } from 'src/app/graphQl/base/dutys';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { NzMessageService } from 'ng-zorro-antd';
@Component({
    selector: 'app-orh-sift',
    templateUrl: './orh-sift.component.html',
    styleUrls: ['./orh-sift.component.less']
})
export class OrhSiftComponent implements OnInit {
    queryList: any[] = [];
    loading: boolean = false;
    visible: boolean = false;
    titles: string = '';
    isDisabled: boolean;
    duties: any;
    condition: number | boolean = false;
    radioValue: any;
    checkOptionsOne: any;
    validateForm: FormGroup
    constructor(
        private apollo: Apollo,
        private fb: FormBuilder,
        private graph: GraphqlServiceService,
        private message:NzMessageService
    ) {

    }

    ngOnInit(): void {
        this.getDataList()
    }

    close() {
        this.visible = false
    }

    log(value: { label: string; value: string; age: number }): void {
        console.log(value);
    }

    submitForm(): void {

    }
    getDataList() {
        let that = this
        let params = {
            query: queryList,
            variables: {
                page: 0,
                limit: 0
            }
        }
        this.graph.GQuery(params).subscribe((res: any) => {
            console.log(res)
            if (res.data.Duties.code === 200) {
                that.queryList = res.data.Duties.data
            } else {
                this.message.error(res.data.Duties.msg)
            }
            that.loading = false
        }
        )
    }

    getPeopleList(duty_id) {
        let that = this
        let params = {
            query: queryDepartmentUsers,
            variables: {
                page: 0,
                limit: 0
            }
        }
        this.graph.GQuery(params).subscribe((res: any) => {
            console.log(res)
            if (res.data.Duties.code === 200) {
                that.queryList = res.data.Duties.data
            }else {
                this.message.error(res.data.Duties.msg)
            }
            that.loading = false
        }
        )
    }

}

import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[orhAnchor]'
})
export class AnchorDirective implements OnChanges {

    @Input()
    anchorIndex: number;

    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();

    public anchor = 0;

    constructor(private el: ElementRef) {
    }

    // @HostListener('mousewheel', ['$event'])
    // onMouseWheel(event: any) {

    //     const left = this.el.nativeElement.scrollTop + event.deltaY;
    //     this.el.nativeElement.scrollTo(left, 0, 200);


    // }

    @HostListener('scroll')
    onAnchorScroll() {
        const len = this.el.nativeElement.children.length;
        //console.log(len);
        for (let i = 0; i < len; i++) {
            const y = this.el.nativeElement.children[i].getBoundingClientRect().y;
            if (y > 0) {
                this.anchor = i;
                this.orhChange.emit(this.anchor);
                return false;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { anchorIndex } = changes;
        if (anchorIndex && anchorIndex.currentValue !== this.anchor) {
            this.el.nativeElement.children[anchorIndex.currentValue].scrollIntoView();
        }
    }
}

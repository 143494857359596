import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzFormatEmitEvent, NzMessageService } from 'ng-zorro-antd';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
  selector: 'orh-tag',
  templateUrl: './orh-tag.component.html',
  styleUrls: ['./orh-tag.component.less']
})
export class OrhTagComponent implements OnInit {
    @Input() findTag:any;
    @Output() postTag:EventEmitter<any> = new EventEmitter();
    nodes:any[]=[]
    constructor(
        private req:RequestService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
        this.getData()
    }
    getData() {
        const that = this;
        
        this.req.get('base/tag/list', {
            page: 1, limit: 100,lavel:'school'
        }).subscribe((res: any) => {
            if (res.code === 200) {
                that.nodes = res.data.map((item: any) => {
                    return {
                        key: item.id,
                        value:item.id,
                        title: JSON.parse(item.name).zh?JSON.parse(item.name).zh:JSON.parse(item.name).mn,
                        lavel: item.lavel,
                        expanded: false,
                        top: 0,
                        isLeaf: item.tagEntities.length > 0 ? false : true,
                        children: item.tagEntities ? this.getEntitles(0, item.tagEntities) : item.tagEntities
                    }
                })               
            }else{
                this.message.error(res.msg)
            }
        })
    }
    getEntitles(num: number, data: any[]) {

        if (data.length > 0) {
            let res = data.map((item: any) => {
                return {
                    key: item.id,
                    value:item.id,
                    title: JSON.parse(item.name).zh?JSON.parse(item.name).zh:JSON.parse(item.name).mn,
                    lavel: item.lavel,
                    top: num + 15,
                    isLeaf: item.tagEntities.length > 0 ? false : true,
                    children: item.tagEntities ? this.getEntitles(num + 15, item.tagEntities) : item.tagEntities
                }
            })
            return res;
        }


    }
    nzEvent(event: Required<NzFormatEmitEvent>): void {
        console.log(event);
    }
    nzEvents(event: Required<NzFormatEmitEvent>): void {
    
        this.postTag.emit(event.node.origin);
    }
}
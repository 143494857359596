import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attendance-student',
  templateUrl: './attendance-student.component.html',
  styleUrls: ['./attendance-student.component.less']
})
export class AttendanceStudentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-parents-apply',
  templateUrl: './parents-apply.component.html',
  styleUrls: ['./parents-apply.component.less']
})
export class ParentsApplyComponent implements OnInit {
  validateForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}

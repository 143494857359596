import { Component, OnInit,EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../service/utils/utils.service';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { NzMessageService, NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Apollo } from 'apollo-angular';
import { teacherData } from '../../graphQl/school-administration/school-notice/announcement';
import { departmentListData, departmentList, newTeachers } from '../../graphQl/systemctl/departments';
import { QueryDuityPeople } from '../../graphQl/base/personnelAlloction';

@Component({
    selector: 'app-orh-one-teacher',
    templateUrl: './orh-one-teacher.component.html',
    styleUrls: ['./orh-one-teacher.component.less']
})
export class OrhOneTeacherComponent implements OnInit ,OnChanges{
    @Input() userData: any;
    @Output() teacherData: EventEmitter<any> = new EventEmitter();
    searchValue: any;
    searchShow: boolean = true
    // 点击获取到的树附属信息
    TreeData: any = [];
    departmentId: number | boolean = false;
    // 树形加载状态
    TreeLoading: boolean = true;
    // 树形没有数据
    NoDataStatus: boolean = false;
    formValidate = {
        duties_id: ''
    }
    listData: any[] = [];
    dutiesListData: any[] = [];
    checkOptionsOne: any[] = [];
    userList:any;
    loading: boolean = false;
    constructor(
        private utils: UtilsService,
        private graph: GraphqlServiceService,
        private message: NzMessageService,
        private apollo: Apollo,
    ) {	}

    ngOnInit() {
        
        this.getDepartmentData();
        this.getTeacher();
        
    }
    ngOnChanges(changes:SimpleChanges){
        this.userList = changes.userData.currentValue;
        
        this.getTeacher();
    }
    getTeacher(): void {
        this.checkOptionsOne = []
        const params = {
            query: newTeachers,
            variables: {
                page: 0,
                limit: 0,
                condition: {
                    work_status:'workstatus_on-the-job'
                }
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.teachersNew.code === 200) {
                let data = res.data.teachersNew.data;
                let user = {}
                this.checkOptionsOne=data.map(item => {
                    return {
                        value: item.user.id,
                        label: item.name ? item.name.zh : item.user.username,
                        idcard: item.user.idcard,
                        checked: false
                    }
                })
            }else{
                // this.message.error(res.data.teachersNew.msg)
            }
        });
    }
    searchClick() {
        this.checkOptionsOne = []
        this.utils.getStorage().then(res => {
            let item = {
                name: this.searchValue
            }
            let datas = this.utils.handelCondition(item)
            const params = {
                query: teacherData,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: datas
                },
                policy: 'no-cache'
            };
            this.graph.GQuery(params).subscribe((res: any) => {
                if (res.data.teachers.code === 200) {
                    let data = res.data.teachers.data;
                    let user = {}
                    data.map(item => {
                        user = {
                            value: item.user.id,
                            label: item.name ? item.name.zh : item.user.username,
                            idcard:item.user.idcard,
                            checked: false
                        }
                        this.checkOptionsOne.push(user)
                    })
                }else{
                   
                }
            });
        });
    }
    // 获取组织树的信息
    getDepartmentData(): void {
        let that = this;
        that.apollo.watchQuery({
            query: departmentListData,
            variables: {
                page: 0,
                limit: 1000
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.Departments.code === 200) {
                that.listData = res.data.Departments.data.map((el: any) => ({
                    title: el.department_name.zh,
                    key: el.id,
                    info: el
                }));
                if (this.listData.length === 0) {
                    this.NoDataStatus = true;
                }
                this.TreeLoading = false;
            }else{
                this.message.error(res.data.Departments.msg)
            }
        })
    }
    nzEvent(event: Required<NzFormatEmitEvent>): void {
        let that = this;
        let id: any = event.keys[event.keys.length - 1];
        const node = event.node;
        if (event.eventName === 'expand') {
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(id).then(data => {
                    node.addChildren(data)
                })
            }
        } else if (event.eventName === 'click') {
            that.departmentId = id ? id : false;
            let arr = node.origin.info.duties.map(el => {
                return el.id
            });
            that.reloadDutiesData(arr)
        }
    }
    loadNode(value: any): Promise<NzTreeNodeOptions[]> {
        let that = this;
        return new Promise(resolve => {
            that.apollo.watchQuery({
                query: departmentList,
                variables: {
                    page: 0,
                    limit: 1000,
                    condition: {
                        parent_id: value
                    }
                }
            }).valueChanges.subscribe((res: any) => {
                if (res.data.Departments.code === 200) {
                    let data = res.data.Departments.data.map((el: any) => ({
                        title: el.department_name.zh,
                        key: el.id,
                        info: el
                    }));
                    resolve(data)
                } else {
                    resolve()
                }
            })
        })
    }
    // 点击树形结构
    TreeClick(val) {
        this.searchShow = false
        this.TreeData = val.node.origin;
        this.getNodeDuity(0, 0, val.node.origin.key)
    }
    // 通过组织树获取人员列表
    getNodeDuity(page = 0, limit = 0, department_id: number) {
        this.checkOptionsOne = []
        const variables = {
            page,
            limit,
            department_id
        }
        this.apollo.watchQuery({
            query: QueryDuityPeople,
            variables,
        }).valueChanges.subscribe((res: any) => {
            if (res && res.data.DepartmentUsers.code === 200) {
                let data = res.data.DepartmentUsers.data;
                let users = []
                let user = {}
                data.map(item => {
                    user = {
                        label: item.user_name ? item.user_name.zh : item.user.username,
                        value: item.user.id,
                        idcard:item.user.idcard,
                    }
                    users.push(user)
                })
                let teacher = []
                users.map(item => {
                    let index = teacher.findIndex(res => res.value === item.value)
                    if (index === -1) {
                        teacher.push(item)
                    } else {
                        return false
                    }
                })
                this.checkOptionsOne = teacher
            }else{
                this.message.error(res.data.DepartmentUsers.msg)
            }
        })
    }


    reloadDutiesData(data: Array<number>) {
        let list = [...this.dutiesListData];
        if (data) {
            list.map((el: any) => {
                let bool = data.find(w => w === el.key)
                if (bool) {
                    el.direction = 'right'
                } else {
                    el.direction = 'left'
                }
            });
            this.dutiesListData = list
        }
    }
    ngModelChange(evt){                
        let name =this.checkOptionsOne.find(item=>item.value === evt);
        this.teacherData.emit(name);
    }
}


import {Component, OnChanges, SimpleChanges, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {initOptsModel} from '../../model/chart';

@Component({
    selector: 'app-orh-chart',
    templateUrl: './orh-chart.component.html',
    styleUrls: ['./orh-chart.component.less']
})
export class OrhChartComponent implements OnChanges, OnInit {
    // @Input() initOpts?: initOptsModel;
    @Input() options: any;
    @Input() height: string;
    @Output() dataInit: EventEmitter<any> = new EventEmitter();
    @Output() chartClicks:EventEmitter<any> = new EventEmitter();
    optionsVal: any = {};
    // initOptsVal: initOptsModel = {
    //   renderer: 'svg',
    //   width: 300,
    //   height: 300
    // };

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue) {
            this.optionsVal = changes.options.currentValue;
        }

    }

    ngOnInit() {
        console.log(this.height)
        // if (this.initOpts) {
        //   this.initOptsVal = this.initOpts;
        // }
    }


    onChartInit(ec: any) {
        this.dataInit.emit(ec);
    }
    chartClick(event){
        this.chartClicks.emit(event)
    }
    chartContextMenu(event){
        console.log(event);
        
    }
}

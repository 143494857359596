import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'orh-sider-icon',
  templateUrl: './orh-sider-icon.component.html',
  styleUrls: ['./orh-sider-icon.component.less']
})
export class OrhSiderIconComponent implements OnInit,OnChanges {

    @Input() icon: string;
    @Input() size: Array<number | string> | string;
    @Input() color: string;
    width: string = '1em';
    height: string = '1em';
    constructor() { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        let that = this;
        if (changes.size) {
            let val = changes.size.currentValue;
            
            
            let bool = Array.isArray(changes.size.currentValue);
            if (bool) {
                that.width = `${val[0]}px`;
                that.height = `${val[1]}px`;
                
                
            } else {
                that.width = that.height = `${val}px`;
               
            }
        }
    }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageConfig } from 'src/app/model';

@Component({
    selector: 'app-orh-table',
    templateUrl: './orh-table.component.html',
    styleUrls: ['./orh-table.component.less']
})
export class OrhTableComponent implements OnInit {

    @Input() pageConfig: PageConfig = {
        total: 0,
        page: 1,
        limit: 10
    };
    @Input() slots: Array<string>;

    @Output() limitChange: EventEmitter<any> = new EventEmitter();
    @Output() pageChange: EventEmitter<any> = new EventEmitter();

    operation: boolean = false;
    searchShow:boolean = true;
    operationShow:boolean = true;
    search: boolean = false;

    constructor() {
    }

    pageTotals () {
        if (this.pageConfig.limit) {
            let val = Math.ceil(this.pageConfig.total / this.pageConfig.limit)
            return val
        } else {
            return 1
        }
    }
    getShow(){
        console.log('0033');
        
        this.searchShow = !this.searchShow
    }
    getOpetion(){
        this.operationShow = !this.operationShow
    }

    pageSizeChange (event: number): void {
        if (this.pageConfig) this.limitChange.emit(event)
    }

    pageIndexChange (event: number): void {
        if (this.pageConfig) this.pageChange.emit(event)
    }

    ngOnInit() {
        if (this.slots) {
            this.operation = this.slots.includes('operation');
            this.search = this.slots.includes('search');
        }
    }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../request/request.service';
import { Router } from '@angular/router';
import { BusinessService } from '../business/business.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo } from 'apollo-angular';
import { GRADE, MENUSLIST, MENUSPATH, MENUSTYPE, MODELLIST } from '../../utils/constant';
import Config from '../../config/index';
import { BaseService } from '../base/base.service';
import { GraphqlServiceService } from '../graphqlService/graphql-service.service';
import { PaperServiceService } from '../paper/paper-service.service';
import { ZwRouteReuseStrategy } from '../route-reuse/zw-route-reuse-strategy'
@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    // 获取用户信息api地址
    public userInfoApi: string = 'base/systemctl/system/get/userinfo';
    // 获取模块列表api地址
    public modulesApi: string = 'base/get/newUser/module/list';
    // 获取菜单列表api地址
    public userMenusApi: string = 'base/get/newUser/menus/list';
    userLoadingId: string;
    ModelListId: string;
    constructor(
        private http: RequestService,
        private router: Router,
        private business: BusinessService,
        private message: NzMessageService,
        private apollo: Apollo,
        private base: BaseService,

        private graph: GraphqlServiceService,
        private paperService: PaperServiceService

    ) {
        this.userLoadingId = '';
        this.ModelListId = '';
    }

    // 获取token
    getToken(username: string, password: string) {
        const data = {
            username,
            password,
        };
        return new Observable(observer => {
            this.http.post('oauth/oauth-login', data).subscribe(
                (res: any) => {
                    if (res) {
                        this.business.setItem('token', res.access_token);
                        this.getUserInfo();

                        // this.menus.getUserMenus();
                        observer.next(res);
                    } else {
                        observer.error(res);
                    }
                },
                error => {
                    observer.error(error);
                },
            );
        });
    }
    // 获取token
    getPhoneToken(username: string, code: string) {
        const data = {
            username,
            code
        };
        return new Observable(observer => {
            this.http.post('base/sms/phone_code', data).subscribe(
                (res: any) => {
                    if (res.code === 200) {
                        this.business.setItem('token', res.token);
                        this.getUserInfo();

                        // this.menus.getUserMenus();
                        observer.next(res);
                    } else {
                        observer.error(res);
                    }
                },
                error => {
                    observer.error(error);
                },
            );
        });
    }
    // 获取用户信息
    getUserInfo() {
        this.userLoadingId = this.message.loading('获取用户信息中...', { nzDuration: 0 }).messageId;
        this.http.get(this.userInfoApi).subscribe(res => {

            if (res && res.code === 200) {
                this.business.setItem('userInfo', res.data);
                this.message.remove(this.userLoadingId);

                this.getModelList();
                this.paperService.getToken();
                this.business.setcolos(res.data.school ? res.data.school.settings.themeSettings.zh : '#108ee9')
            } else {
                this.message.error('获取用户信息失败!');
                this.message.remove(this.userMenusApi);
            }
        });
        // this.message.remove(this.userLoadingId);
    }

    // 其他页面获取用户信息
    getOtherUserInfo() {
        return new Promise((resolve, reject) => {
            this.http.get(this.userInfoApi).subscribe(res => {
                if (res && res.code === 200) {
                    this.business.setItem('userInfo', res.data);
                    resolve(res);
                } else {
                    this.message.error('获取用户信息失败!');
                }
            });
        });
    }

    // 获取模块列表
    getModelList() {

        this.ModelListId = this.message.loading('获取模块列表中...', { nzDuration: 0 }).messageId;
        this.getMenu()
        // this.message.remove(this.ModelListId);
    }
    // getUser(){
    //     this.http.get(this.userInfoApi).subscribe(res => {

    //         if (res && res.code === 200) {
    //             this.business.setItem('userInfo', res.data);
    //             this.message.remove(this.userLoadingId);



    //             this.getMenu();
    //             this.paperService.getToken();
    //             this.business.setcolos(res.data.school ? res.data.school.settings.themeSettings.zh : '#108ee9')
    //         } else {
    //             this.message.error('获取用户信息失败!');
    //             this.message.remove(this.userMenusApi);
    //         }
    //     });
    // }
    getMenu() {
        this.http.get(this.modulesApi).subscribe(res => {

            if (res && res.code === 200) {
                // 处理过滤pc端显示的模块
                let app_model = [];
                res.data.forEach(item => {
                    if (item.clients.length > 0) {
                        const array_res = item.clients[0].split(',');
                        const res: any = array_res.find(value => value === 'cn_pc');
                        if (res) {
                            app_model.push(item);
                        }
                    }
                });
                this.business.setItem(MODELLIST, app_model);
                this.getMenusList();
                this.getModeType()
            } else {
                this.message.error('获取模块列表失败');
                this.message.remove(this.ModelListId);
            }
        });
    }
    getModeType() {
        this.http.get('base/get/newUser/moduleType/list?lang_name=cn_pc').subscribe((res: any) => {

            let munes = res.data.filter((item: any) => JSON.parse(item.name).zh !== '其他');
            console.log('munes', munes);
            this.business.setItem(MENUSTYPE, munes);
        })
    }
    // 获取菜单列表
    getMenusList() {
        this.http.get(this.userMenusApi).subscribe(res => {
            if (res && res.code === 200) {
                this.business.setItem(MENUSLIST, res.data);
                // 存储拥有的菜单
                let menuPath = [];
                res.data.map(item => {
                    menuPath.push(item.path);
                });
                menuPath = menuPath.concat(Config.white_path);
                this.business.setItem(MENUSPATH, menuPath);
                this.loginSuccess();

            } else {
                this.message.error('获取菜单列表失败!');
                this.message.remove(this.ModelListId);
            }
        });
        this.message.remove(this.ModelListId);
    }

    // 登录成功
    loginSuccess() {
        this.message.remove(this.ModelListId);
        this.message.success('登录成功');
        this.apollo.getClient().resetStore();
        this.router.navigate(['home']);
    }

    // 判断是否有登陆
    public canActivate(value: any) {
        const val = localStorage.getItem('token');
        if (val !== null && val) {
            this.business.freshUserInfo()
            return this.canActiveRoute(value);

        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
    // public getTheme() {
    //     // const userinfo =this.business.getItem('userInfo');

    //     let backgroundColors = this.business.getItem('userInfo').school.settings ? this.business.getItem('userInfo').school.settings.themeSettings.zh : '#206F53';

    //     return backgroundColors;

    // }

    // 判断是否有权限访问路由
    public canActiveRoute(val: any) {
        if (val.url.includes('/pages/prepare-course/prepare-detail')) {
            return true
        }
        if (val.url.includes('/pages/single/class-detail')) {
            return true
        }
        if (val.url.includes('/pages/single/cloud-square-detail')) {
            return true
        }
        // TODO 权限

        if (val.url.includes('/pages/resources/resources-detail')) {
            return true;
        }
        if (val.url.includes('/pages/teacher/information-detail')) {
            return true;
        }
        if (val.url.includes('/panel/information-list')) {
            return true;
        }
        if (val.url.includes('/home')) {
            return true;
        }
        else {
            const url_list = localStorage.getItem('menu-path') ? JSON.parse(localStorage.getItem('menu-path')) : null;

            let index = val.url.lastIndexOf('?');


            let str = val.url;
            if (index >= 0) {
                str = val.url.substring(0, index);
            }
            const value = url_list.find(item => item === str);

            if (value) {
                return true;
            } else {
                this.router.navigateByUrl('/home');
                // this.notification.create('info', '提示', '暂无权限访问');
                return true;
            }
        }
    }

    // 业务组件错误处理
    public redirectTo() {
        history.back();
    }

    // 退出
    public logOut() {
        //this.apollo.getClient().clearStore();
        console.log('000333', this.apollo.getClient().clearStore());

        localStorage.clear();
        ZwRouteReuseStrategy.clear();
        // this.business.clearIndexedDb();
        this.router.navigate(['login']);
    }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import Config from '../../../../config';
import { teacherQualifications, typeList } from 'src/app/graphQl/base/dictionary';
import { addProfessionalQualification, findProfessionalQualification } from 'src/app/graphQl/teachers/teacher-profile/information';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { DatePipe } from '@angular/common';
import Observable, { Observer } from 'zen-observable-ts';


@Component({
    selector: 'technical-qualifications-operation',
    templateUrl: './technical-qualifications-operation.component.html',
    styleUrls: ['./technical-qualifications-operation.component.less']
})
export class TechnicalQualificationsOperationComponent implements OnInit {
    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    jopVisible: boolean = false;
    avatarUrl: string;
    nzBodyStyle = {
        width: '100%',
        'max-height': '75vh',
        'height': 'auto',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    isVisible: boolean = false;
    fileList: any[] = [];
    qualificationList: any[] = [];
    qualificationTypeList: any[] = [];
    inputstylename: any;
    childrenList: any[] = [];
    childrenJopList: any[] = [];
    today = new Date();
    previewImage: string | undefined = '';
    previewVisible = false;
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            inputstylename: [''],
            jobtypeename: [''],
            experttype: [null, Validators.required],//专家类别id
            // tradeid: [''],//行业类别id
            // inputstyle: [''],//资格id
            jobtypeid: [null, Validators.required],//现从事专业id
            takedate: ['', Validators.required],//取得时间
            jtlevelid: [null, Validators.required],//等级id
            certificateno: [''],//证书编号
            image: [''],//证书扫描件(上传图片)
            // isemploy: [''],//是否聘任(单选)1、不是2、是
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            awarddept: this.fb.group({
                zh: [''],
                mn: [''],
                en: ['']
            })//授予部门 
        })
    }
    dictionaryList: any = {
        industrycategory: [],
        expertcategory: [],
        professional_technology: [],

    };
   
    filters: UploadFilter[] = [
        {
          name: 'type',
          fn: (fileList: UploadFile[]) => {
              console.log('fileList0033',fileList);
              
            const filterFiles = fileList.filter(w => ~['image/png','image/jpeg','image/jpg','image/bmp','application/pdf'].indexOf(w.type));
            if (filterFiles.length !== fileList.length) {
              this.msg.error(`请选择图片或者pdf`);
              return filterFiles;
            }
            return fileList;
          }
        },
        
      ];
    
    disabledDate = (current: Date): boolean => {
        // Can not select days before today and today
        return current > new Date()
    };
    ngOnInit() {
        this.dictionaryData();
        this.qualifications();
        this.newQualification();
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findProfessionalQualification,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            // console.log(res);

            let data = res.data.professionalQualification
            let form = this.form;
            data.takedate = this.date.transform(data.takedate, 'yyyy-MM-dd');

            form.get('experttype')!.setValue(data.experttype ? data.experttype.dictionary_value : null)
            // form.get('tradeid')!.setValue(data.tradeid?data.tradeid.dictionary_value:null)
            // form.get('inputstyle')!.setValue(data.inputstyle)
            // form.get('jobtypeid')!.setValue(data.jobtypeid)
            form.get('jobtypeid')!.setValue(data.jobtypeid ? data.jobtypeid.dictionary_value : null)
            //  form.get('inputstylename')!.setValue(data.inputStyleQualification.name);
            // form.get('jobtypeename')!.setValue(data.jobTypeIdQualification.name)
            form.get('takedate')!.setValue(data.takedate)
            form.get('jtlevelid')!.setValue(data.jtlevelid ? data.jtlevelid.dictionary_value : null)
            form.get('certificateno')!.setValue(data.certificateno)
            form.get('shenhe')!.setValue(data.shenhe)
            // form.get('isemploy')!.setValue(parseInt(data.isemploy))
            form.get('awarddept')!.setValue(data.awarddept ? data.awarddept : { mn: null, zh: null, en: null })
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if (datas) {
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {

        this.fileList = data.map((item: any, i: any) => {
            let index = item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })

    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'industrycategory',
            'expertcategory',
            'professional_technology',
            'all_subject'
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el,
                        type: 'option'
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    } else {
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }
    onClose() {
        this.dataStatus.emit(false)
    }
    //点击选择资格
    inputstyleradio(event: number) {
        let name = this.childrenList.find(item => item.id === event).name;
        this.form.get('inputstylename')!.setValue(name)
        this.form.get('inputstyle')!.setValue(event)
    }
    //点击选择先从事专业
    jobtyperadio(event: number) {
        let name = this.childrenJopList.find(item => item.id === event).name;
        this.form.get('jobtypeename')!.setValue(name)
        this.form.get('jobtypeid')!.setValue(event)
    }
    handleOk() {
        this.isVisible = false;
        console.log(this.form.value)
    }
    //关闭model框
    handleCancel() {
        this.isVisible = false;
    }
    //点击打开现从事专业
    jobBut() {
        this.jopVisible = true;
        console.log(this.jopVisible);

    }
    //
    jopCancel() {
        this.jopVisible = false;
    }
    handleJop() {
        this.jopVisible = false;
    }
    //资格
    qualifications() {
        this.apollo.watchQuery({
            query: teacherQualifications,
            variables: {
                type: 1,
                pid: 0
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.teacherQualifications.code === 200) {
                this.qualificationList = res.data.teacherQualifications.data.map(item => {
                    return {
                        active: false,
                        ...item
                    }
                });

            } else {
                this.msg.error(res.data.teacherQualifications.msg)
            }
        });
    }
    //选择资格
    selectQualification(pids: any) {
        this.apollo.watchQuery({
            query: teacherQualifications,
            variables: {
                type: 1,
                pid: pids
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.teacherQualifications.code === 200) {
                this.childrenList = res.data.teacherQualifications.data

            } else {
                this.msg.error(res.data.teacherQualifications.msg)
            }
        });

    }
    //选择先从事事业
    selectJopQualification(pids: any) {
        this.apollo.watchQuery({
            query: teacherQualifications,
            variables: {
                type: 2,
                pid: pids
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.teacherQualifications.code === 200) {
                this.childrenJopList = res.data.teacherQualifications.data

            } else {
                this.msg.error(res.data.teacherQualifications.msg)
            }
        });

    }
    //打开资格
    inputBut() {
        this.isVisible = true;
    }
    //从事专业
    newQualification() {
        this.apollo.watchQuery({
            query: teacherQualifications,
            variables: {
                type: 2,
                pid: 0
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.teacherQualifications.code === 200) {
                this.qualificationTypeList = res.data.teacherQualifications.data
            } else {
                this.msg.error(res.data.teacherQualifications.msg)
            }
        });
    }
    handelChange(event: any) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }
    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.takedate = this.date.transform(params.takedate, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId
        delete params.inputstylename
        delete params.jobtypeename
        const mutationParams = {
            mutation: addProfessionalQualification,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addProfessionalQualification.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else {
                this.msg.error(res.data.addProfessionalQualification.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {
        this.previewImage = file.url || file.thumbUrl;
        this.previewVisible = true;
    };
}

import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BaseService } from 'src/app/service/base/base.service';


@Directive({
    selector: '[timeTable]'
})
export class TimeTableDirective implements OnInit{
    
    @Input()
    timeTable!: string | number;

    constructor(private el: ElementRef, private rd2: Renderer2, private base: BaseService) {
    }

    ngOnInit() {
        const obj = this.base.colorItem$.value;
        if (obj[this.timeTable]) {
            this.rd2.setStyle(this.el.nativeElement, 'color', obj[this.timeTable]);
        } else {
            const color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
            this.rd2.setStyle(this.el.nativeElement, 'color', color);
            obj[this.timeTable] = color;
            this.base.colorItem$.next(obj);
        }
    }

}

import gql from 'graphql-tag';

/** 
*查询公文
*/

const documentsData = gql`
	query (
        $page: Int!,
        $limit: Int!,
        $condition:BusinessDocumentCondition
    ){
		businessDocuments (page: $page, limit: $limit,condition: $condition){
            code
            msg
            data {
                id
                documentTitle{
                    zh
                }
                type
                organ
                wordSize
                addresseeTime
                registrationTitanic
                proposeOpinion
                level 
                documentContent 
                documentType{
                    name{
                        zh
                    }
                }
                approves{               
                    id
                    userId
                    approvalOpinion
                    approvalProcess
                    approvalStatus
                    approvalTime
                    userName{
                        zh
                    }
                }
                leaderships{
                    id
                    userId
                    isRead
                    readTime
                    createTime
                    userName{
                        zh
                    }
                }   
                directors{
                    id
                    userId
                    isRead
                    readTime
                    createTime
                    userName{
                        zh
                    }
                } 
                instructions{
                    id
                    createUser 
                    instructions
                    remark
                    createUserName{
                        zh
                    }
                    createdAt 
                }
                status
                createdAt
            }
            total
		}
	}
`;
/** 
*单条公文
*/
const findDocumentData = gql`
    query ($id:String!){
        businessDocument(id:$id){
            id
            documentTitle{
                zh
                mn
                en
            }
            type
            organ
            wordSize
            addresseeTime
            registrationTitanic
            proposeOpinion
            level 
            documentContent 
            postUserName{
                zh
            }
            documentType{
                id
                name{
                    zh
                }
            }
            approves{               
                id
                userId
                approvalOpinion
                approvalProcess
                approvalStatus
                approvalTime
                userName{
                    zh
                }
            }
            peoples{
                id
                userId
                isRead
                readTime
                createTime
                userName{
                    zh
                }
            }
            leaderships{
                id
                userId
                isRead
                readTime
                createTime
                userName{
                    zh
                }
            }   
            directors{
                id
                userId
                isRead
                readTime
                createTime
                userName{
                    zh
                }
            }      
            files{
                id
                fileName
                filePath 
                accessPath
                previewPath
            }
            instructions{
                id
                createUser 
                instructions
                remark
                createUserName{
                    zh
                }
                createdAt 
            }
            status
        }
    }
`
/** 
*单条附件
*/
const findDocumentfileData = gql`
    query ($page: Int!,
        $limit: Int!,
        $condition:DocumentFileCondition){
            documentFiles(page: $page, limit: $limit,condition: $condition){
            data{
                id 
                fileName
                filePath
            }
        }
    }
`
const addDocumentInstructions = gql`
    mutation addDocumentInstructions($inputData: DocumentInstructionsInput ){
        addDocumentInstructions(inputData: $inputData){
            code
            msg
        }
    }
`
/** 
*添加公文
*/
const saveDocument = gql`
    mutation addBusinessDocument(
        $id:String
        $documentTitle:LangInput
        $type :Int
        $organ:String
        $wordSize:String
        $addresseeTime:Date
        $registrationTitanic:String
        $proposeOpinion:String
        $level:String
        $documentContent:String
        $documentType:Int
        $schoolId:Int
        $status:String
    ){
        addBusinessDocument(inputData:{
            id:$id
            documentTitle:$documentTitle
            type:$type
            organ:$organ
            wordSize:$wordSize
            addresseeTime:$addresseeTime
            registrationTitanic:$registrationTitanic
            proposeOpinion:$proposeOpinion
            level:$level
            documentContent:$documentContent
            documentType:$documentType
            schoolId:$schoolId
            status:$status
        }){
            code
            msg
            data{
                id
                documentTitle{
                    zh
                }
                type
                organ
                wordSize
                addresseeTime
                registrationTitanic
                proposeOpinion
                level 
                documentContent 
                documentType{
                    name{
                        zh
                    }
                }
            }
        }
    }
`;
const saveDocumentApprove = gql`
    mutation addDocumentApprove($inputData: DocumentApproveInput ){
        addDocumentApprove(inputData: $inputData){
            code
            msg
        }
    }
`;
// const saveDocumentApprove=gql`    
//     mutation addDocumentApprove(
//         $id:Int
//         $userId:Int
//         $approvalProcess:Int
//         $approvalStatus:String
//         $documentId:String
//     ){
//         addDocumentApprove(inputData:{
//             id:$id
//             userId:$userId,
//             approvalProcess:$approvalProcess,
//             approvalStatus:$approvalStatus,
//             documentId:$documentId}){
//                 msg
//                 code
                
//           }
//     }
// `;

const saveDocumentPeople=gql`    
    mutation addDocumentPeople(
        $inputData:DocumentPeopleInput
    ){
        addDocumentPeople(inputData:$inputData){
                msg
                code
          }
    }
`;
const saveDocumentFile=gql`    
    mutation addDocumentFile(
        $inputData:DocumentFileDate
        
    ){
        addDocumentFile(inputData:$inputData){
                msg
                code
          }
    }
`;
//公文类型
const documentTypeData = gql`
    query (
        $page: Int,
        $limit: Int,
        $condition:DocumentDictCondition
    ){
        documentDicts (page: $page, limit: $limit,condition: $condition){
            code
            msg
            data {
                id
                name{
                    zh
                }
            }
            total
        }
    }
`
//删除公文
const delDocument = gql`
    mutation deleteBusinessDocument($id: [String]!) {
        deleteBusinessDocument(id: $id) {
            code
            msg
        }
    }
    
`;
const deleteDocumentFile = gql`
    mutation deleteDocumentFile($id: [Int]!) {
        deleteDocumentFile(id: $id) {
            code
            msg
        }
    }
`
//常用人列表
const frequentContactss = gql`
    query ($page: Int!,
        $limit: Int!,
        $condition:FrequentContactsCondition
    ) {
        frequentContactss(page: $page, limit: $limit,condition:$condition) {
            code
            msg
            data{
                id
                user {
                    id
                    name{
                        zh
                    }
                    idcard
                }
                teacher{
                    id
                    name{
                        zh
                    }
                }
                isDefault 
            }
        }
    }
`;
const findfrequentContacts = gql`
    query ($id:Int!){
        frequentContacts(id:$id){
            id
            isDefault
            sorting
            teacher{
                id
                name{
                    zh
                }
                user{
                    id
                    name{
                        zh
                    }
                }
            }            
        }
    }
`
//添加常用人员
const addFrequentContacts = gql`
    mutation addFrequentContacts(
        $inputData:FrequentContactsInput
        
    ){
        addFrequentContacts(inputData:$inputData){
            msg
            code
        }
    }
`;
//删除常用人员
const deleteFrequentContacts = gql`
    mutation deleteFrequentContacts($id: [Int]!) {
        deleteFrequentContacts(id: $id) {
            code
            msg
        }
    }
`;

const documentAllTeacher = gql`
    query ($page: Int!,
        $limit: Int!,
        $condition:TeacherCondition
    ) {
        documentAllTeacher(page: $page, limit: $limit,condition:$condition) {
            code
            msg
            data{
                id
                user {
                    id
                    username
                    name{
                        zh
                    }
                }
                name{
                    zh
                }
                isDefault 
            }
        }
    }
`;
//公文除了已添加常用老师的接口
const documentTeacher = gql`
    query ($page: Int!,
        $limit: Int!,
        $condition:TeacherCondition
    ) {
        documentTeacher(page: $page, limit: $limit,condition:$condition) {
            code
            msg
            data{
                id
                name{
                    zh
                }
                user {
                    id
                    username
                    name{
                        zh
                    }
                }
                isDefault 
            }
        }
    }
`;
export {
    documentsData, findDocumentData,delDocument,saveDocument,saveDocumentPeople,saveDocumentApprove,saveDocumentFile,documentTypeData,findDocumentfileData,frequentContactss,addFrequentContacts,deleteFrequentContacts,documentAllTeacher,documentTeacher,addDocumentInstructions,
    findfrequentContacts,deleteDocumentFile
}
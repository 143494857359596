import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, EventEmitter,
    forwardRef, Input, OnDestroy,
    Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {interval, Observable, Subscription} from 'rxjs';

declare  let tinymce: any;

@Component({
    selector: 'orh-new-editor-container',
    styleUrls: ['./orh-new-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-tinymce-container" [ngClass]="selector" [style.minHeight]="height" (blur)="orhBlur()"
            placeholder="请输入内容"  (input)="inputTinymce($event)">
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrhNewEditorContainerComponent),
            multi: true
        }
    ]
})
export class OrhNewEditorContainerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

    @Input()
    selector!: string;

    @Input()
    toolbar!: string;

    @Input()
    placeholder = '请输入内容';

    @Input()
    height = '32px';

    @Input()
    delay = 2000;

    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();

    @Output()
    orhInput: EventEmitter<any> = new EventEmitter();

    @Output()
    orhDelay: EventEmitter<any> = new EventEmitter();

    public interval$: Subscription;

    public apiKey = 't3c6qnygees4ydj2cwy1wdi6k0ft4468dzhcg21zap0stjs5';
    public editorConfig = {
        selector: '',
        fixed_toolbar_container: '',
        menubar: false,
        inline: true,
        disabled: true,
        // language: 'mn_CN',
        // language_url: '/assets/tinymce1/langs/mn_CN.js',
        toolbar: 'undo redo bold italic forecolor alignleft aligncenter alignright alignjustify outdent indent removeformat'
    };

    public counts = '';

    get count() {
        return this.counts;
    }

    set count(value: string) {
        this.counts = value;
        this.propagateChange(this.counts);
    }

    constructor() {
    }

    propagateChange = (_: any) => {};

    writeValue(value: any) {
        if (value !== this.count) {
            this.count = value;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
    }

    ngAfterViewInit(): void {
        this.editorConfig.selector = `.${this.selector ? this.selector : 'orh-tinymce-content'}`;
        this.editorConfig.fixed_toolbar_container = `.${this.toolbar}`;
        setTimeout(() => {
            tinymce.init(this.editorConfig).then(res => {
                res[0].setContent(this.counts);
            });
        }, 1000);
        
    }

    ngOnDestroy(): void {
        tinymce.remove(this.selector);
    }

    inputTinymce(event: any): void {
        this.orhInput.emit(event);
        if (this.interval$) {
            this.interval$.unsubscribe();
        }
        const interval$: Observable<number> = interval(this.delay);
        this.interval$ = interval$.subscribe(res => {
            this.interval$.unsubscribe();
            const data = tinymce.activeEditor.getContent();
            this.orhDelay.emit(data);
        });
    }

    orhBlur(): void {
        const data = tinymce.activeEditor.getContent();
        this.count = data;
        this.orhChange.emit(data);
    }

}

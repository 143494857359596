import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidV4 } from 'uuid';
import { AnnexList, ContentList, ItemList } from './orh-content-template.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RequestService } from 'src/app/service/request/request.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
    selector: 'orh-content-template',
    templateUrl: './orh-content-template.component.html',
    styleUrls: ['./orh-content-template.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrhContentTemplateComponent),
            multi: true
        }
    ]
})
export class OrhContentTemplateComponent implements ControlValueAccessor, OnInit {

    @Input()
    menuList: Array<ItemList> = [];

    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();
    prepareShow: boolean = false;
    cloudShow: boolean = false;
    public visible = false;
    public type: any = 'image';
    public fileList: Array<AnnexList> = [];
    checkArray: any[] = [];
    public dataIndex: number;
    tagid: any;
    public lessonShow = false;
    desginShow: boolean = false;
    questionShow: boolean = false;
    public counts: Array<ContentList> = [];
    nzVisible: boolean = false;
    actives: any = {
        'border-right': '2px solid #999'
    }
    nzBodyStyle = {
        width:'100%',
        'max-height': '75vh',
       'height': 'auto',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
    get count() {
        return this.counts;
    }

    set count(value: Array<ContentList>) {
        this.counts = value;
        this.propagateChange(this.counts);

    }
    imageURL: string;

    constructor(
        private http: RequestService
    ) { }

    ngOnInit() {
        // 获取语言文件
        if (!this.menuList.length) {
            this.menuList = [
                {
                    label: '文本',
                    icon: 'iconwenzi',
                    value: 'text'
                },
                {
                    label: '单课',
                    icon: 'iconneirong',
                    value: 'course'
                },
                {
                    label: '图片',
                    icon: 'icontupian1',
                    value: 'image'
                },
                {
                    label: '视频',
                    icon: 'iconshipin',
                    value: 'video'
                }
            ];
        }
    }

    propagateChange = (_: any) => { };

    writeValue(value: any) {
        if (value !== this.count) {
            this.count = value;
        }
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.counts, event.previousIndex, event.currentIndex);
        let obj = {};
        event.previousContainer.data.forEach((item: any, index) => {
            obj[item.id] = index + 1;
        })
        console.log('counts0033', obj);
        console.log(this.counts);
        // this.orhChange.emit();
    }
    drops(event: CdkDragDrop<string[]>, ind: number) {
        moveItemInArray(this.counts[ind].annexList, event.previousIndex, event.currentIndex);
        let obj = {};
        event.previousContainer.data.forEach((item: any, index) => {

            obj[item.id] = index + 1;
        })

        this.orhChange.emit();
    }
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
    }

    delayContent(): void {
        this.orhChange.emit();
    }

    menuClick(event: string, index: number): void {
        if (!this.count) {
            this.count = []
        }
        this.nzVisibleChange()
        this.dataIndex = index;
        const uuid = uuidV4();
        const data: ContentList = {
            type: 'text',
            uuid,
            annexList: [],
            content: ''
        };
        let that = this;
        switch (event) {
            case 'text':
                data.type = 'text';
                this.count.splice(index, 0, data);
                console.log(this.count);

                break;
            case 'image':
                this.type = 'image';
                this.visible = true;
                break;
            case 'video':
                this.type = 'video';
                this.visible = true;
                break;
            case 'course':
                this.lessonShow = true;
                break;

            case 'cloud_course':
                this.cloudShow = true;
                break;
            case 'lesson':
                this.prepareShow = true;
                break;
            case 'design':
                this.desginShow = true;
                break;
            case 'question':
                this.questionShow = true;
                break;
            default:
                this.type = event;
                this.visible = true;
                console.log(event);

                break;
        }
    }
    nzVisibleChange() {
        this.nzVisible = false;
    }
    menuChildrenClick(event: any, index: number) {
        if (!this.count) {
            this.count = []
        }
        this.dataIndex = index;
        const uuid = uuidV4();
        const data: ContentList = {
            type: event.value,
            uuid,
            annexList: [],
            content: ''
        };
        this.type = event.value;
        this.tagid = event.value
        this.visible = true;
    }
    discChange(event: any[]): void {

        this.fileList = [];

        this.fileList = event.map(el => {
            this.checkArray.push(el.properties['sr:identifier'])
            return {
                fileId: el.properties['sr:identifier'],
                fileTitle: el.name,
                filePath: el.properties['sr:osspath'],
                fileCover: el.properties['sr:thumbnail'],
                fileType: el.properties['sr:fileType'],
                tagID: this.type
            };
        });
    }
    cloudClassChange(event: any) {

        this.fileList = [];
        this.fileList = event.map(el => {
            return {
                fileId: el.id,
                fileTitle: el.title,
                filePath: '',
                fileCover: el.coverImage ? el.coverImage : '/assets/image/course.jpg',
            };
        });
    }
    prepareChange(event: any) {

        this.fileList = [];
        this.fileList = event.map(el => {
            return {
                fileId: el.id,
                fileTitle: el.prepareLessonTitle,
                filePath: '',
                fileCover: el.subjectImageMn ? el.subjectImageMn : (el.subjectImageZh ? el.subjectImageZh : '/assets/image/lesson/other.jpg'),
            };
        });

    }
    desginChange(event: any) {
        this.fileList = [];
        this.fileList = event.map(el => {
            return {
                fileId: el.id,
                fileTitle: el.prepareLessonTitle,
                filePath: '',
                fileCover: el.subjectImageMn ? el.subjectImageMn : (el.subjectImageZh ? el.subjectImageZh : '/assets/image/lesson/other.jpg'),
            };
        });
        console.log(this.fileList);

    }
    //
    // getFile(file:any){

    //     if(file){
    //         return file
    //     }else{
    //         return '/assets/image/img.jpg'
    //     }
    // }
    getVideo(data: any) {
        if (data.fileCover) {
            return data.fileCover.replace(/\"/g, '').split(',')
        } else {
            let tIcon = ''
            switch (data.fileType) {
                case 'image':
                    tIcon = '/assets/image/img.jpg';
                    break;
                case 'video':
                    tIcon = '/assets/image/video.jpg';
                    break;
                case 'audio':
                    tIcon = '/assets/image/audio.jpg';
                    break;
                case 'ppt':
                    tIcon = '/assets/image/ppt.jpg';
                    break;
                case 'xls':
                    tIcon = '/assets/image/XLS.jpg';
                    break;
                case 'pdf':
                    tIcon = '/assets/image/pdf.jpg';
                    break;
                case 'docs':
                    tIcon = '/assets/image/word.jpg';
                    break;
                case 'doc':
                    tIcon = '/assets/image/word.jpg';
                    break;
                case 'text':
                    tIcon = '/assets/image/text.jpg';
                    break;
                case 'zip':
                    tIcon = '/assets/image/zip.jpg';
                    break;
                default:
                    tIcon = '/assets/image/other.jpg';
            }
            return tIcon
        }
    }
    getLesson(file: any) {
        if (file) {
            return file
        } else {
            return '/assets/image/img.jpg'
        }
    }
    prepareSubmit() {
        this.prepareShow = false;
        const uuid = uuidV4();
        const data: ContentList = {
            type: 'lesson',
            uuid,
            annexList: [],
            content: ''
        };
        data.annexList = this.fileList;
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }
    designSubmit() {
        this.desginShow = false;
        const uuid = uuidV4();
        const data: ContentList = {
            type: 'design',
            uuid,
            annexList: [],
            content: ''
        };
        data.annexList = this.fileList;
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }
    //取消教学设计model
    designCancel() {
        this.desginShow = false
    }
    //取消备课model
    prepareCancel() {
        this.prepareShow = false
    }
    //取消云课堂model
    cloudCancel() {
        this.cloudShow = false
    }
    //取消备课model
    lessonCancel() {
        this.lessonShow = false
    }
    //取消文件model
    discCancel() {
        this.visible = false
    }
    //
    questionCancel() {
        this.questionShow = false;
    }
    // 图片框选择确定
    discSubmit(): void {
        this.imageURL = this.fileList[0].fileCover;
        this.visible = false;
        this.getTags();
        const uuid = uuidV4();
        let data: any;
        if (this.tagid) {
            data = {
                type: 'sharedFile',
                uuid,
                annexList: [],
                content: ''
            };
        } else {
            data = {
                type: this.type,
                uuid,
                annexList: [],
                content: ''
            };
        }
        data.annexList = this.fileList;
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }
    getTags() {
        this.http.get('resource/file/tag/save', {
            tagIds: [this.tagid],
            uuids: this.checkArray
        }).subscribe((item) => {
            if (item.code === 200) {

            }

        })
    }
    cloudClassSubmit() {
        this.cloudShow = false;
        const uuid = uuidV4();
        const data: any = {
            type: 'cloud_course',
            uuid,
            annexList: [],
            content: ''
        };
        data.annexList = this.fileList;
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }
    singleLessonChange(event: any[]): void {
        this.fileList = [];
        this.fileList = event.map(el => {
            return {
                fileId: el.info.id,
                fileTitle: el.info.title,
                filePath: '',
                fileCover: el.info.coverFile ? el.info.coverFile : '/assets/image/course.jpg',
            };
        });
    }

    singleLessonSubmit(): void {
        this.lessonShow = false
        const uuid = uuidV4();
        const data: ContentList = {
            type: 'course',
            uuid,
            annexList: [],
            content: ''
        };
        data.annexList = this.fileList;
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }

    deleteItem(data: any, index: number, pIndex: number): void {
        if (data.annexList.length) {
            data.annexList.splice(index, 1);
        }
        if (!data.annexList.length) {
            this.count.splice(pIndex, 1);
        }
        this.orhChange.emit();
    }

    handleCancel() {

    }
    questionSubmit() {
        this.questionShow = false;
        const uuid = uuidV4();
        const data: ContentList = {
            type: 'question',
            uuid,
            annexList: [],
            content: ''
        };
        data.annexList = this.fileList;

        this.fileList.map(item => {
            data.content += item.fileTitle
        })
        this.count.splice(this.dataIndex, 0, data);
        this.orhChange.emit();
    }
    //题库
    questionChange(event: any) {
        console.log(event);
        this.fileList = [];
        this.fileList = event.map(el => {
            return {
                fileId: el._id,
                fileTitle: el.stem,
                filePath: '',
                fileCover: '/assets/image/lesson/other.jpg',
            };
        });
    }

}

import gql from 'graphql-tag';
const attendanceNewGroups = gql`
    query($page: Int, $limit: Int, $schoolId: Int, $title: String){
        attendanceNewGroups(page: $page, limit: $limit, schoolId: $schoolId, title: $title){
            code
            msg
            total
            data{
                id
                schoolId
                schoolName
                type
                monthType
                weekType
                dayType
                attendanceDate{
                    date
                }
                noAttendanceDate{
                    date
                } 
                attendanceGroupName
                attendanceTeacherList{
                    attendanceTeacherId
                    attendanceTeacherName               
                    attendanceTeachersList{
                        teacherName
                    }                
                }
                workDayList{
                    weekId
                    weekName
                }
                attendanceTimeList{   
                    attendanceTimeId            
                    attendanceTimeName
                    attendanceTimeList{
                        type
                        attendanceType
                        timeStart
                        timeEnd
                    }
                }      
            }
        }
    }
`;
const findattendanceNewGroup = gql`
    query($id: String){
        attendanceNewGroup(id: $id){  
            data{
                id
                schoolId
                schoolName
                type
                monthType
                weekType
                dayType
                attendanceDate{
                    date
                }
                noAttendanceDate{
                    date
                } 
                attendanceGroupName
                attendanceTeacherList{
                    attendanceTeacherId
                    attendanceTeacherName               
                    attendanceTeachersList{
                        teacherName
                    }                
                }
                workDayList{
                    weekId
                    weekName
                }
                attendanceTimeList{       
                    attendanceTimeId        
                    attendanceTimeName
                    timeStart
                    timeEnd
                    attendanceTimeList{
                        type
                        attendanceType
                        timeStart
                        timeEnd
                    }
                }     
            }                         
        }
    }
`
const addAttendanceNewGroup = gql`
mutation($inputData:AttendanceNewGroupInput){
    addAttendanceNewGroup(inputData:$inputData){
        code
        msg
    }
}
`;
const deleteAttendanceTeacher = gql`
mutation($id:[String]!){
    deleteAttendanceTeacher(id:$id){
        code
        msg
    } 
}
`;
const attendanceTeachers = gql`
    query($page:Int,$limit:Int,$schoolId:Int,$title:String){
        attendanceTeachers(page:$page,limit:$limit,schoolId:$schoolId,title:$title){
            code
            msg
            total
            data{
                id
                schoolId
                attendanceTeacherName
                attendanceTeachersList{
                userId
                idCard
                teacherName
                }
            }
        }
    }  
`;
const addAttendanceTeacher = gql`
mutation($inputData:AttendanceTeachersInput){
    addAttendanceTeacher(inputData:$inputData){
        code
        msg
    }
}  
`;
const deleteAttendanceTime = gql`
mutation($id:[String]!){
    deleteAttendanceTime(id:$id){
        code
    }
}
`;
const attendanceTimes = gql`
    query($page:Int,$limit:Int,$schoolId:Int,$title:String){
        attendanceTimes(page:$page,limit:$limit,schoolId:$schoolId,title:$title){
            code
            msg
            total
            data{
                id
                schoolId
                attendanceTimeTitle
                timeStart
                timeEnd
                attendanceTimesList{
                attendanceType
                type
                timeStart
                timeEnd
                }
            }
        }
    }
`;
const addAttendanceTime = gql`
    mutation($inputData:AttendanceTimesInput){
        addAttendanceTime(inputData:$inputData){
            code
            msg
        } 
    }
`
const attendanceLeaves = gql`
    query($page:Int,$limit:Int,$schoolId:Int,$firstApprove:Int,$status:Int,$userName:String,$leaveType:Int, $resumptionStatus:Int,$isMyLeave:String,$currentDate: String,$id:String){
        attendanceLeaves(page:$page,limit:$limit,schoolId:$schoolId,firstApprove:$firstApprove,status:$status,userName:$userName,leaveType:$leaveType, resumptionStatus:$resumptionStatus,isMyLeave:$isMyLeave,currentDate:$currentDate,id:$id){
            code
            msg
            total
            data{
                idCard
                id
                schoolId
                schoolName
                userId
                userName
                leaveType
                leaveTypeTitle
                leaveReason
                startTime
                endTime
                firstApprove
                firstApproveName
                startTimeStr
                endTimeStr
                leaveCount
                status
                resumptionStatus
                calcLeaveCount
            }
        }
    }
`;
const deleteAttendanceNewGroup = gql`
    mutation($id:[String]!){
        deleteAttendanceNewGroup(id:$id){
            code
        }
    }
`;
export {
    attendanceNewGroups,
    addAttendanceNewGroup,
    deleteAttendanceTeacher,
    attendanceTeachers,
    addAttendanceTeacher,
    deleteAttendanceTime,
    attendanceTimes,
    addAttendanceTime,
    attendanceLeaves,
    deleteAttendanceNewGroup,
    findattendanceNewGroup
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orh-button',
  templateUrl: './orh-button.component.html',
  styleUrls: ['./orh-button.component.less']
})
export class OrhButtonComponent implements OnInit {

    @Input() backgroundColor: any;
    @Input() filename:any;
    @Input() icon:any;
    @Input() color:string ='#fff'
    background:any;
    constructor(
    ) { }

    ngOnInit() {
        
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollDirective } from './scroll/scroll.directive';
import { AnchorDirective } from './anchor/anchor.directive';
import { TimeTableDirective } from './time-table/time-table.directive';



@NgModule({
    declarations: [ScrollDirective, AnchorDirective,TimeTableDirective],
    imports: [
        CommonModule
    ],
    exports: [
        ScrollDirective, AnchorDirective,TimeTableDirective
    ]
})
export class AnchorModule { }

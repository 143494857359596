import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzFormatEmitEvent, NzMessageService, NzModalService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { BusinessService } from '../../../service/business/business.service';
import { RequestService } from '../../../service/request/request.service';
import { UtilsService } from '../../../service/utils/utils.service';
import { HashService } from '../../../service/hash/hash.service';

@Component({
    selector: 'orh-folder-list',
    templateUrl: './orh-folder-list.component.html',
    styleUrls: ['./orh-folder-list.component.less'],
    providers: [DatePipe],
})
export class OrhFolderListComponent implements OnInit {
    formGroup: FormGroup
    userId: string = null;
    getUrl: string = null;
    iconcolor: string = null;
    blockrename: any;
    backgroundColor: any = {
        background: 'var(--antd-wave-shadow-color)',
        'box-shadow': 'var(--antd-wave-shadow-color) 0px 5px 10px 0px'
    };
    icon: string = 'iconshangchuan';
    backgroundColorDisk: any = {
        background: 'linear-gradient(180deg, #FFC045, #F59101)',
        'box-shadow': '0px 10px 10px 0px rgba(251, 171, 39, 0.38)'
    };
    formatOne = (percent: number) => `${percent}G/1024G`;
    iconDisk: string = 'iconxinjian';
    identifier: any[] = [];
    data: any[] = [];
    dataList: any[] = [];
    tabList: any[] = [];
    type: string = '';
    checkArray: any[] = [];
    checkeType: any[] = [];
    show: boolean = false;
    i: number = 0;
    processTotal: number = 0;
    reductionData: number = 0;
    listShow: number = 1;
    isPreviewVisible: boolean = false;
    totalSize: any;
    filesPath: any;
    tags: any;
    nodes: any;
    nzBodyStyle = {
        width: '100%',
        'height': '85vh',
        overflow: 'hidden',

    }
    file_type: string = '';
    fileName: string = '';
    folderName: string = '';
    listNum: boolean = false;
    paramsData: any = {
        page: 1,
        limit: 10,
        fileName: null,
        fileType: null,
        path: null,
        orderBy: 'CreateAt',
        sortBy: 'desc'
    };
    path: any;
    uploaData: any;
    listTotal: number = 0;
    nzSuccessPercent: number = 1024;
    total: number = 0;
    condition: number = 0;
    isuploadTag: boolean = false;
    isLoadingOne: boolean = false;
    resoucesShow: boolean = true;
    tagids: any;
    editID: any;
    fileInfo: any;
    copyShow: boolean = false;
    statusShow: boolean = false;
    shareShow: boolean = false;
    isVisible: boolean = false;
    isTag: boolean = false;
    renameNewParentRef: any;
    isAllDisplayDataChecked: boolean = false;
    title: string = '';
    uploadList: any[] = [];
    breadCrumb: any[] = [
        {
            path: '/1',
            name: '',
            parentRef: ''
        },
    ];
    searchValue = '';
    loading = false;
    langfiles: any = {};
    iconColor = '';
    ImageUrl = '';
    upLoadDisabled = false;
    cisVisible = false;

    constructor(
        private business: BusinessService,
        private http: RequestService,
        private date: DatePipe,
        private utils: UtilsService,
        private cdr: ChangeDetectorRef,
        private message: NzMessageService,
        private model: NzModalService,
        private fb: FormBuilder,
        private fileHash: HashService
    ) {
        this.formGroup = this.fb.group({
            folderName: [null]
        });
    }

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;
        this.getUrl = '/' + this.userId + '/base';
        this.iconcolor = this.business.getItem('userInfo') ? this.business.getItem('userInfo').school.settings.themeSettings.zh : '#206F53';
        this.paramsData.orderBy = 'createAt';
        this.paramsData.sortBy = 'desc';
        this.paramsData.path = this.getUrl;
        this.getParentId();
        this.getDataList()
    }
    addClick() {
        this.isuploadTag = true;
    }
    //打开文件夹添加框
    openDisk() {
        this.isVisible = true
    }
    shandleCancel() {
        this.shareShow = false;
    }
    // 按照文件大小排序
    sortLarge(sort?: any) {

        this.dataList = [];
        this.paramsData.orderBy = 'fileSize';
        if (sort) {
            this.paramsData.sortBy = sort;
        } else {
            if (this.paramsData.sortBy === 'asc') {
                this.paramsData.sortBy = 'desc';
            } else {
                this.paramsData.sortBy = 'asc';
            }
        }

        this.getDataList();
    }

    // 按照文件创建时间排序
    sortCreated(sort?: string) {

        this.dataList = [];
        this.paramsData.orderBy = 'createAt';
        if (sort) {
            this.paramsData.sortBy = sort;
        } else {
            if (this.paramsData.sortBy === 'asc') {
                this.paramsData.sortBy = 'desc';
            } else {
                this.paramsData.sortBy = 'asc';
            }
        }


        this.getDataList();
    }

    // 按照文件名排序
    sartName() {
        this.dataList = [];
        if (this.paramsData.sortBy === 'asc') {
            this.paramsData.sortBy = 'desc';
        } else if (this.paramsData.sortBy === 'desc') {
            this.paramsData.sortBy = 'asc';
        }
        this.paramsData.orderBy = 'fileName';

        this.getDataList();

    }
    // 获取底层文件
    getParentId() {
        this.breadCrumb = [];
        this.http.post('resource/file/myfiles', {
            path: '/' + this.userId
        }).subscribe((res: any) => {
            if (res.code === 200) {
                this.data = res.data.map((el) => {
                    if (el.name === 'bin') {
                        return {
                            mn_name: '回收箱',
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null
                        };
                    } else if (el.name === 'base') {
                        return {
                            mn_name: '全部文件',
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null
                        };
                    }
                });
                const temp = this.data[0];
                this.data[0] = this.data[1];
                this.data[1] = temp;
                const breadCrumb = {
                    name: this.data[0].mn_name,
                    path: this.data[0].path,
                    parentRef: this.data[0].parentRef
                };
                this.uploaData = {
                    parentRef: breadCrumb.parentRef
                };
                this.breadCrumb.push(breadCrumb);
            }else{
                this.message.error(res.msg)
            }
        });
    }
    //关闭预览窗口

    previewCancel() {
        this.isPreviewVisible = false;
    }
    //关闭分享窗口
    isCancel() {
        this.isuploadTag = false;
    }
    //关闭移动/复制窗口、
    copyCancel() {
        this.copyShow = false;
    }
    //关闭标签窗口
    tagCancel() {
        this.isTag = false;
    }
    //添加标签之后的返回数据
    postuploadTag(tag: any) {
        this.tags = tag
    }
    // 提交新建文件夹
    submitForm() {
        this.isVisible = false;
        this.http.post('resource/folder/insert', {
            folderName: this.formGroup.value.folderName,
            parentRef: this.breadCrumb[this.breadCrumb.length - 1].parentRef,
        }).subscribe((res) => {
            if (res.code === 200) {
                this.formGroup.reset()
                this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                this.paramsData.orderBy = 'createAt';
                this.paramsData.sortBy = 'desc';
                if (this.paramsData.fileType !== null) {
                    this.getFilter();
                } else if (this.paramsData.fileType === null) {
                    this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                    this.getDataList();
                }

            } else if (res.code === 550) {
                this.message.create('error', '有相同的文件名，请从新输入');
            }
        });
    }
    handleCancel() {
        this.isVisible = false
    }
    // tabs 发生变化
    tabsClick(event: any) {

        // this.breadCrumb = [];
        this.dataList = [];
        this.show = false;
        this.isAllDisplayDataChecked = false;
        this.paramsData.fileName = null;
        this.paramsData.page = 1;
        this.checkArray = [];

        if (!event) {
            this.resoucesShow = true;
            this.paramsData.fileType = null;
            const breadCrumb = {
                name: this.data[0].mn_name,
                path: this.data[0].path,
                parentRef: this.data[0].parentRef
            };
            this.breadCrumb.push(breadCrumb);
            this.paramsData.path = '/' + this.userId + '/base';

            this.getDataList();
            this.listNum = false;
            this.listShow = 1;
        } else {
            this.resoucesShow = false;
            if (event === 'image' || event === 'video') {
                this.listNum = true;
                this.listShow = 2;
            } else {
                this.listNum = false;
                this.listShow = 1;
            }
            this.paramsData.path = '/' + this.userId + '/base';
            this.paramsData.fileType = event;
            this.getFilter();
        }
    }
    // 名称搜索
    clickSearch() {
        // this.paramsData.fileName = this.fileNames;
        if (this.paramsData.fileType !== null) {
            this.getFilter();
        } else if (this.paramsData.fileType === null) {
          
            this.getDataList();
        }
       
    }
    getDataList() {
        this.dataList = [];
        this.path = this.paramsData.path;
        const params = this.utils.handelCondition(this.paramsData);
        this.http.post(`resource/file/myfiles`, params).subscribe((res: any) => {
            if (res.code === 200) {
                this.dataList = res.data.map(el => {
                    el.id = `${this.i}`,
                        el.status = true;
                    el.mn_name = '全部';
                    el.name = el.name;
                    el.type = el.type;
                    el.mime_type = el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '';
                    el.created = this.date.transform(el.created, 'yyyy-MM-dd');
                    el.size = el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '';
                    el.parentRef = el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '';
                    el.osspath = el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '';
                    el.thumbnail = el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null;
                    el.properties = el.properties;
                    el.checked = false;
                    this.i++;
                    return el;
                });
                this.listTotal = res.total;
                this.total = res.total;
                this.show = false;
                this.cdr.detectChanges();
                this.getNum();
            }else{
                this.message.error(res.msg)
            }
        });
    }
    getNum() {
        this.http.get('resource/file/count').subscribe((item: any) => {
            const res = item.data;
            this.totalSize = item.data;
            this.tabList = [
                {
                    label: '全部',
                    value: null,
                    number: res.countTotal,
                    size: res.sizeTotal,
                    icon: 'iconwenjianlan'
                },
                {
                    label: '图片',
                    value: 'image',
                    number: res.countImage,
                    size: res.sizeImage,
                    icon: 'icontupian3'
                },
                {
                    label: '视频',
                    value: 'video',
                    number: res.countVideo,
                    size: res.sizeVideo,
                    icon: 'iconshipin3'
                },
                {
                    label: '音频',
                    value: 'audio',
                    number: res.countAudio,
                    size: res.sizeAudio,
                    icon: 'iconyinpin1'
                },
                {
                    label: '文档',
                    value: 'docs',
                    number: res.countDocs,
                    size: res.sizeDocs,
                    icon: 'icondoc'
                },
                {
                    label: `Ppt`,
                    value: 'ppt',
                    number: res.countPPT,
                    size: res.sizePPT,
                    icon: 'iconPPT2'
                },
                {
                    label: `Xls`,
                    value: 'xls',
                    number: res.countXls,
                    size: res.sizePPT,
                    icon: 'iconxlsx'
                },
                {
                    label: `Pdf`,
                    value: 'pdf',
                    number: res.countPDF,
                    size: res.sizePDF,
                    icon: 'iconpdf'
                },
                {
                    label: `Zip`,
                    value: 'zip',
                    number: res.countZip,
                    size: res.sizeZip,
                    icon: 'iconZip1'
                },
                {
                    label: 'Text',
                    value: 'text',
                    number: res.countText,
                    size: res.sizeText,
                    icon: 'iconwenben'
                },
                {
                    label: '其他',
                    value: 'other',
                    number: res.countOther,
                    size: res.sizeOther,
                    icon: 'iconwenjianjia'
                },
            ];
            let num: any;
            let size: any;
            num = res.sizeTotal / (1024 * 1024 * 1024);
            size = num.toFixed(2);
            this.processTotal = Number(size);

        });
        this.http.get('resource/file/count/bin').subscribe((item: any) => {
            this.reductionData = item.data.countTotal;
        });
    }
    // 列表和图表切换
    cilckList(num: number) {
        this.listNum = !this.listNum;
        this.listShow = num
    }
    // 点击面包屑
    breadLink(item: any) {
        while (true) {
            if (item.path === this.breadCrumb[this.breadCrumb.length - 1].path) {
                break;
            } else {
                this.breadCrumb.pop();
            }
        }
        this.uploaData = {
            parentRef: item.parentRef
        };
        this.path = item.path;
        this.paramsData.path = item.path;
        this.paramsData.page = 1;
        this.paramsData.limit = 20;
        this.getDataList();
    }
    //返回上一级
    back(): void {
        let index = this.path.lastIndexOf('/');
        this.paramsData.orderBy = 'createAt';
        this.paramsData.sortBy = 'desc';
        this.paramsData.path = this.path.substring(0, index)
        this.path = this.path.substring(0, index);
        if (this.breadCrumb.length > 1) {
            this.breadCrumb.pop()
            this.getDataList()
        }
    }
    //设置标签
    tagModel() {
        this.isTag = true;
    }
    // 接收标签数据
    postTag(evt: any) {
        this.tags = evt
    }
    pageSizeChange(evt) {
        this.getDataList()
    }
    pageIndexChange(evt) {
        this.getDataList()
    }
    // 获取上传的文件名称
    customReq = (res: any) => {
        console.log('0033312',res.file);
        
        this.listNum = true;
        // this.uploadChange.emit(false);
        const data = {
            name: res.file.name,
            progress: 0,
            type: 'primary',
            status: false
        };
        this.uploadList.push(data);
        this.getFileHash(res.file, data);
    }
    getFileHash(file: any, data: any): void {
        this.isuploadTag = false;
        this.fileHash.getHash(file).then(str => {
            const name = file.name;
            this.http.get('resource/file/upload/url', {
                objectName: str
            }).subscribe((el: any) => {
                const url = el.data;
                const newUrl = url.replace('http', 'https');
                if (el.extra && el.extra.status) {
                    data.progress = 100;
                    this.uploadFileSave(name, str, data);
                } else {
                    this.uploadFile(file, newUrl, name, str, data);
                }
            });
        });
    }
    uploadFileSave(fileName: string, objectName: string, data: any) {
        // this.isuploadTag = false;
        console.log(this.breadCrumb[this.breadCrumb.length - 1].parentRef);
        
        this.http.post('resource/file/upload/save', {
            objectName,
            fileName,
            parentRef: this.breadCrumb[this.breadCrumb.length - 1].parentRef
        }).subscribe((res: any) => {
            if (res.code === 200) {
                this.postTagData(res.data.properties['sr:identifier'])
                this.handleUploadList();
            } else {
                data.type = 'error';
                this.message.error(res.msg);
                this.handleUploadList();
            }
            this.cdr.detectChanges();
        });
    }
    postTagData(uuid: string) {
        this.http.get('resource/file/tag/save', {
            tagIds: this.tags,
            uuids: uuid
        }).subscribe((item) => {
            if (item.code === 200) {
                if (this.paramsData.fileType !== null) {
                    this.getFilter();
                } else if (this.paramsData.fileType === null) {
                    this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                    this.getDataList();
                }
            }else{
                this.message.error(item.message)
            }
        })
    }
    // 获取上传进度条
    uploadFile(event: any, url: string, name: string, hashStr: string, data: any): void {
        const xhrs = new XMLHttpRequest();
        xhrs.open('put', url, true);
        xhrs.setRequestHeader('Content-Type', 'application/octet-stream');
        xhrs.onreadystatechange = (e: any) => {
            if (xhrs.readyState === 4) {
                this.uploadFileSave(name, hashStr, data);
            }
        };
        xhrs.upload.onprogress = (ev: any) => {
            if (ev.lengthComputable) {
                const progress = Math.round(100 * ev.loaded / ev.total);
                data.progress = progress;
            }
        };
        xhrs.send(event);
    }
    handleUploadList(): void {
        const bool = this.uploadList.find(el => el.progress !== 100);
        if (!bool) {
            const data = this.uploadList.filter(el => el.type === 'error');
            this.uploadList = [...data];
            // this.uploadChange.emit(true);
            if (this.paramsData.fileType !== null) {
                this.getFilter();
            } else if (this.paramsData.fileType === null) {
                this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                this.getDataList();
            }
        }
    }
    // 双击文件如果是文件，打开预览窗口预览，文件夹的话打开下一个
    dbclick(data: any) {
        if (data.path) {
            if (data.type === 'sr:smartfolder') {
                this.breadCrumb.push({
                    name: data.name,
                    path: data.path,
                    parentRef: data.parentRef
                });
                this.path = data.path;
                this.paramsData.path = data.path;
                this.paramsData.page = 1;
                this.getDataList();
                this.uploaData = {
                    parentRef: data.parentRef
                };
            } else {
                this.fileInfo = data;
                if (data.mime_type === 'image' || data.mime_type === 'video' || data.mime_type === 'audio') {
                    this.isPreviewVisible = true
                } else {

                    if (data.properties['sr:convertStatus'] === 'complete') {
                        this.isPreviewVisible = true
                    } else {
                        this.message.error('正在转码中，暂时预览不了');
                    }
                }
            }
        }
    }
    //提交设置标签的数据
    omcTag() {
        this.isTag = false

        this.http.get('resource/file/tag/save', {
            tagIds: this.tags,
            uuids: this.checkArray
        }).subscribe((item) => {
            if (item.code === 200) {
                this.message.success('设置标签成功');
                if (this.paramsData.fileType) {
                    this.getFilter();
                } else if (this.paramsData.fileType === null) {
                    this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                    if (this.paramsData.fileType !== null) {
                        this.getFilter();
                    } else if (this.paramsData.fileType === null) {
                        this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                        this.getDataList();
                    }
                }
            }else{
                this.message.error(item.message)
            }

        })
    }
    //全选
    checkAll($event) {
        this.checkArray.length = 0;
        if ($event) {
            this.dataList.forEach(item => {
                item.checked = $event;
                this.checkArray.push(item.properties['sr:identifier']);
            })
            this.show = true;
        } else {
            this.checkArray.length = 0;
            this.dataList.forEach(item => {
                item.checked = $event;
            })
            this.show = false;
        }
    }
    //
    checkedChange(event: any, data: any) {
        data.checked = event;
        const index = this.checkArray.indexOf(data.parentRef);
        if (data.checked) {
            if (index === -1) {
                this.checkArray.push(data.parentRef);
                this.checkeType.push(data.type);
            }
        } else {
            if (index >= 0) {
                this.checkArray.splice(index, 1);
                this.checkeType.splice(index, 1);
            }
        }
        if (this.checkArray.length === this.dataList.length) {
            this.isAllDisplayDataChecked = true;
        } else {
            this.isAllDisplayDataChecked = false;
        }
        if (this.checkArray.length === 0) {
            this.show = false;
        } else {
            this.show = true;
        }
    }
    // 转换文件大小
    formatFileSize(fileSize: number) {
        let number: number;
        let size: string;
        if (fileSize) {
            if (fileSize < 1024) {
                return fileSize + 'B';
            } else if (fileSize < (1024 * 1024)) {
                number = fileSize / 1024;
                size = number.toFixed(2);
                return size + 'KB';
            } else if (fileSize < (1024 * 1024 * 1024)) {
                number = fileSize / (1024 * 1024);
                size = number.toFixed(2);
                return size + 'M';
            } else {
                number = fileSize / (1024 * 1024 * 1024);
                size = number.toFixed(2);
                return size + 'GB';
            }
        } else {
            return '—';
        }
    }
    // 点击文件处理
    handleType2(el: any) {
        if (el.type === 'sr:smartfolder') {
            // 这个绝对是文件夹
            return 'iconFolder';
        } else {
            return this.handelImg(el.mime_type);
        }
    }

    // 处理文件类型缩略图
    handelImg(type) {
        let tIcon = 'iconText_File';
        if (type !== null) {
            switch (true) {
                case /image/i.test(type):
                    tIcon = 'iconJPG';
                    break;
                case /video/i.test(type):
                    tIcon = 'iconVideo_File';
                    break;
                case /audio/i.test(type):
                    tIcon = 'iconMP3';
                    break;
                case /word/i.test(type):
                    tIcon = 'iconDOC';
                    break;
                case /excel/i.test(type):
                    tIcon = 'iconXLS';
                    break;
                case /ppt/i.test(type):
                    tIcon = 'iconPPT';
                    break;
                case /pdf/i.test(type):
                    tIcon = 'iconPDF';
                    break;
                case /zip/i.test(type):
                    tIcon = 'iconZIP';
                    break;
                case /text/i.test(type):
                    tIcon = 'iconTXT';
                    break;
                default:
                    tIcon = 'iconDOC';
            }
        }
        return tIcon;
    }
    // 提交移动复制数据
    handleOk(): void {
        let url = '';
        if (this.renameNewParentRef) {
            if (this.checkArray.length > 0) {

                const arr = this.checkArray.join(',');
                if (this.condition === 1) {// 复制
                    url = 'resource/file/filemanager/copy?newParentRef=' + this.renameNewParentRef + '&identifiers=' + arr;
                } else if (this.condition === 2) {// 移动
                    url = 'resource/file/filemanager/move?newParentRef=' + this.renameNewParentRef + '&identifiers=' + arr;
                }
                this.http.get(url).subscribe((res: any) => {
                    if (res.code === 200) {

                        this.message.success(this.condition === 1 ? '复制成功' : '移动成功');

                        this.checkArray.length = 0;
                        if (this.paramsData.fileType) {
                            this.getFilter();
                        } else if (this.paramsData.fileType === null) {
                            this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                            this.getDataList();
                        }
                        this.isAllDisplayDataChecked = false;
                        this.show = false;
                    } else {
                        this.message.error(res.msg);

                    }
                });
            } else {
                if (this.condition === 1) {// 复制
                    url = 'resource/file/filemanager/copy?newParentRef=' + this.renameNewParentRef + '&identifiers=' + this.filesPath.parentRef;
                } else if (this.condition === 2) {// 移动
                    url = 'resource/file/filemanager/move?newParentRef=' + this.renameNewParentRef + '&identifiers=' + this.filesPath.parentRef;
                }
                this.http.get(url).subscribe((res: any) => {
                    if (res.code === 200) {

                        this.message.success(this.condition === 1 ? '复制成功' : '移动成功');
                        if (this.paramsData.fileType) {
                            this.getFilter();
                        } else if (this.paramsData.fileType === null) {
                            this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                            this.getDataList();
                        }
                        this.show = false;
                    } else {
                        this.message.error(res.msg);

                    }
                });
            }
        }
        this.checkArray.length = 0;
        this.copyShow = false;
        this.dataList.forEach(item => {
            item.checked = false;
        });
    }
    // 多条删除
    allDelete() {
        if (this.checkArray.length > 0) {
            const arr = this.checkArray.join(',');
            this.model.confirm({
                nzTitle: '<i>删除确认</i>',
                nzContent: '<b>确认要把所选文件放入回收站吗？删除的文件可通过回收站还原</b>',
                nzOnOk: () => {
                    this.http.get('resource/file/filemanager/delete', { files: arr }).subscribe((res: any) => {
                        if (res.code === 200) {
                            this.message.create('success', '删除功能');
                            if (this.paramsData.fileType) {
                                this.getFilter();
                            } else if (this.paramsData.fileType === null) {
                                this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                                this.getDataList();
                            }
                            this.show = false;
                        } else if (res.code === 500) {
                            this.message.create('error', '文件已经分享，不能删除');
                        } else {
                            this.message.create('error', '删除失败');
                        }
                    });
                },
            })

        } else {

        }
    }
    // 多条分享
    allShare() {
        const index = this.checkeType.findIndex(item => item === 'sr:smartfolder');
        if (index >= 0) {
            this.message.create('error', '文件夹分享不了');
        } else {

            this.shareShow = true;
            this.identifier = this.checkArray
        }
    }
    submitPost(str: boolean) {
        if (str === true) {
            this.checkArray = [];
            this.checkeType = [];
            this.shareShow = false;
            this.show = false;
            if (this.paramsData.fileType !== null) {
                this.getFilter();
            } else if (this.paramsData.fileType === null) {
                this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                this.getDataList();
            }
        } else {
            this.shareShow = false;
        }


    }
    // 操作
    moveChange(data: any, str: string, ind: number) {
        this.filesPath = data;
        switch (str) {
            case 'move':  // 移动到
                this.condition = 2;
                this.copyShow = true;
                this.title = '移动';
                this.generateOptions().subscribe((res: any[]) => {

                    this.nodes = res;
                    this.show = false;
                });
                break;
            case 'copy':  // 复制到
                this.condition = 1;
                this.title = '复制';
                this.copyShow = true;
                this.generateOptions().subscribe((res: any[]) => {
                    this.nodes = res;
                    this.show = false;
                });
                // this.getCopy(data);
                break;
            case 'rename': // 重命名
                this.getRename(data, ind);
                break;
            case 'delete':   // 删除
                this.getDetele(data);
                break;
            case 'share':   // 分享
                this.getShare(data);
                break;
        }
    }
    // 重命名
    getRename(data: any, ind: number) {
        this.editID = data.parentRef;
        this.file_type = data.type;
        data.status = false;
        // this.statusShow = true;
        if (data.type === 'sr:smartfolder') {

            this.fileName = data.name;
        } else {
            const fileFormat = data.name.substring(0, data.name.indexOf('.'));
            this.fileName = fileFormat;
        }
    }

    // 删除data
    getDetele(data: any) {
        this.model.confirm({
            nzTitle: '<i>删除确认</i>',
            nzContent: '<b>确认要把所选文件放入回收站吗？删除的文件可通过回收站还原</b>',
            nzOnOk: () => {
                this.http.get('resource/file/filemanager/delete', {
                    files: [data.parentRef],
                }).subscribe((res) => {
                    if (res.code === 200) {
                        this.message.create('success', '删除成功');
                        if (this.paramsData.fileType) {
                            this.getFilter();
                        } else if (this.paramsData.fileType === null) {
                            this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                            this.getDataList();
                        }
                        this.show = false;
                    } else {
                        this.message.create('error', '删除失败');
                    }
                });
            },
        })

    }


    // 分享
    getShare(data: any) {
        this.title = '文件分享';
        this.editID = data.parentRef;
        this.tagids = data.properties['sr:tagIds'];
        this.identifier.push(data.parentRef)
        this.shareShow = true;
    }
    // 全部
    generateOptions() {
        let result = [];
        return new Observable(observa => {
            this.http.post(`resource/file/myfiles`, {
                path: '/' + this.userId
            }).subscribe((res: any) => {
                if (res.code === 200) {
                    // const data = res.data.filter(el => !el.resource);
                    const data = res.data.find(el => el.name === 'base');

                    result = [{
                        title: '全部',
                        path: data.path,
                        icon: 'folder',
                        key: data.properties['sr:identifier'],
                        children: [],
                        isLeaf: false
                    }];
                    observa.next(result);
                }else{
                    this.message.error(res.msg)
                }
            });
        });

    }
    //下载
    showDownload(file: any) {
        let objectName = file.osspath
        let api = 'resource/file/download';
        this.http.post(api, {
            objectName: objectName,
            newName: file.name
        }).subscribe((item: any) => {
            window.open(item);
            // this.message.success(this.langs.download_success, { duration: 3000 });

        })
    }

    // 点击树形
    nzEvent(event: NzFormatEmitEvent): void {

        // load child async
        if (event.eventName === 'expand') {
            const node = event.node;
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(event.node.origin.path).then(data => {
                    node.addChildren(data);
                });
            }
        }
        if (event.eventName === 'click') {
            this.renameNewParentRef = null;
            this.renameNewParentRef = event.node.origin.key;
        }
    }

    loadNode(path): Promise<NzTreeNodeOptions[]> {

        return new Promise(resolve => {
            this.http.post(`resource/file/myfiles`, {
                page: 1,
                limit: 10000,
                path
            }).subscribe((res: any) => {

                if (res.code === 200) {
                    const data = res.data.filter(el => !el.resource);
                    const result = data.map(el => {
                        return {
                            title: el.name,
                            path: el.path,
                            icon: 'folder',
                            key: el.properties['sr:identifier'],
                            children: [],
                            isLeaf: false
                        };
                    });
                    resolve(result);
                }else{
                    this.message.error(res.msg)
                }
            });
        });
    }
    // 多条移动/复制
    showModel(str: string) {
        if (str === 'copy') {

            this.condition = 1;
            this.title = '复制';
        } else if (str === 'move') {
            this.condition = 2;
            this.title = '移动';
        }
        this.generateOptions().subscribe((res: any[]) => {
            this.nodes = res;
            this.copyShow = true;
            // this.show = false;
        });
    }
    // 重命名
    postRename() {
        const data = this.dataList.find(item => item.parentRef === this.checkArray[0]);
        const index = this.dataList.findIndex(item => item.parentRef === this.checkArray[0]);
        this.blockrename = { data, index };
        this.getRename(data, index);
    }
    // 图表返回事件
    checkedChangeBlock(data) {
        data.checked = data.checked;
        const index = this.checkArray.indexOf(data.parentRef);

        if (data.checked) {
            if (index === -1) {
                this.checkArray.push(data.parentRef);
            }

        } else {
            if (index >= 0) {
                this.checkArray.splice(index, 1);
            }
        }

        if (this.checkArray.length === this.dataList.length) {
            this.isAllDisplayDataChecked = true;
        } else {
            this.isAllDisplayDataChecked = false;
        }

        if (this.checkArray.length === 0) {
            this.show = false;
        } else {
            this.show = true;
        }
    }
    // 提交重命名数据
    submitShow() {
        if (this.file_type === 'sr:smartfolder') {
            this.http.post('resource/folder/edit', {
                folderName: this.fileName,
                identifier: this.editID,
            }).subscribe((res) => {
                if (res.code === 200) {
                    console.log(this.paramsData.fileType);

                    this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                    if (this.paramsData.fileType !== null) {
                        this.getFilter();
                    } else if (this.paramsData.fileType === null) {
                        this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                        this.getDataList();
                    }
                    this.show = false;
                    this.folderName = '';
                } else if (res.code === 550) {
                    this.message.create('error', '重命名失败');
                }
            });
        } else {
            this.http.post('resource/file/filemanager/rename', {
                fileName: this.fileName,
                identifier: this.editID,
            }).subscribe((res) => {
                if (res.code === 200) {


                    if (this.paramsData.fileType !== null) {
                        this.getFilter();
                    } else if (this.paramsData.fileType === null) {
                        this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                        this.getDataList();
                    }
                    this.fileName = '';
                    this.statusShow = false;
                    this.show = false;
                } else if (res.code === 550) {
                    this.message.create('error', '重命名失败');
                }
            });
        }
    }
    // 重命名返回
    returnRename(str: boolean) {
        if (str === true) {
            this.show = false;
            if (this.paramsData.fileType) {
                this.getFilter();
            } else if (this.paramsData.fileType === null) {
                this.paramsData.path = this.breadCrumb[this.breadCrumb.length - 1].path;
                this.getDataList();
            }
        }
    }
    pageSizeChanges(ent) {

        this.getFilter()
    }
    pageIndexChanges(ent) {
        this.getFilter()
    }
    // 文件类型筛选
    getFilter() {
        console.log(this.paramsData);
        this.dataList = [];
        this.http.post('resource/file/filter', this.paramsData).subscribe((res) => {
            if (res.code === 200) {
                const datas = [];
                res.data.map((el) => {
                    if (el.type !== 'sr:smartfolder') {
                        datas.push({
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            osspath: el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null,
                            properties: el.properties,
                            status: true,
                            checked: false,
                        });
                    } else {
                        return false;
                    }

                });
                this.dataList = datas;
                this.listTotal = res.total;
                this.total = res.total;
            }else{
                this.message.error(res.msg)
            }
        });
    }
    // 取消修改
    submitCloses(item) {
        item.status = true;
        this.statusShow = false;
        this.fileName = '';
    }
}

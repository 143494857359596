import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { tagsData } from '../../graphQl/base/tag';
import { SUBJECT } from '../../utils/constant';
import { BusinessService } from '../../service/business/business.service';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';

import { departmentList } from 'src/app/graphQl/preparation';
import { DepartmentUsers } from 'src/app/graphQl/systemctl/departments';
import { Apollo } from 'apollo-angular';
@Component({
    selector: 'orh-screen',
    templateUrl: './orh-screen.component.html',
    styleUrls: ['./orh-screen.component.less']
})
export class OrhScreenComponent implements OnInit {

    @Input() subjectId: string = null;
    @Input() type: string = null
    @Output() getTextbooks: EventEmitter<any> = new EventEmitter();
    @Output() getPeriod: EventEmitter<any> = new EventEmitter();
    @Output() getSubject: EventEmitter<any> = new EventEmitter();
    @Output() getType: EventEmitter<any> = new EventEmitter();
    @Output() postReset: EventEmitter<any> = new EventEmitter();
    @Output() getPersonal: EventEmitter<any> = new EventEmitter();
   
    subjectList: any[] = [];
    collapseList: any[] = [];
    subjects: any[] = [];
    typeList: any[] = [];
    groupList: any[] = [];
    personalList:any[] = [];
    dataSet:any[] = [];
    subject_num: number = 0;
    tag_num: number = 0;
    type_num: number = 0;
    group_num: number = 0;
    personal_num: number = 0;
  
    CollapseDetailValue = 1;
    selected: string = null;
    isCollapseDetail: boolean = false;
    visible:boolean = false;
    visibleRes:boolean = false;
    visibleTab:boolean = false;
    visibleGroup:boolean  = false;
    style: any = {
        color: 'rgb(111, 177, 173) !important',
        'background-color': 'rgba(111, 177, 173, 0.15) !important'
    }
    constructor(
        private business: BusinessService,
        private graph: GraphqlServiceService,
        private apollo:Apollo
    ) { }

    ngOnInit() {
        this.getDepartmentList()
        this.typeList = [
            {
                label: '全部',
                value: null,
                type: 'type',

            },
            {
                label: '图片',
                value: 'image',
                type: 'type'
            },
            {
                label: '视频',
                value: 'video',
                type: 'type'
            },
            {
                label: '音频',
                value: 'audio',
                type: 'type'
            },
            {
                label: '文档',
                value: 'docs',
                type: 'type'
            },
            {
                label: `Ppt`,
                value: 'ppt',
                icon: 'iconPPT2'
            },
            {
                label: `Xls`,
                value: 'xls',
                icon: 'iconxlsx'
            },
            {
                label: `Pdf`,
                value: 'pdf',
                icon: 'iconpdf'
            },
            {
                label: `Zip`,
                value: 'zip',
                icon: 'iconZip1'
            },
            {
                label: 'Text',
                value: 'text',
                icon: 'iconwenben'
            },
            {
                label: '其他',
                value: 6,
                type: 'type'
            },
        ]
        let subjects = this.business.getItem(SUBJECT);
        this.subjectList = []
        this.subjectList.push({
            value: null,
            label: '全部',
            mark: 'iconquanbu',
            type: 'subjects'
        })
        subjects.map(item => {
            this.subjectList.push({
                value: item.id,
                label: item.subject_name.zh ? item.subject_name.zh : item.subject_name.mn,
                mark: item.mark,
                type: 'subjects'
            })
        })
        this.getSearch();

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.subjectId && changes.subjectId.currentValue) {
            let index = this.subjectList.findIndex(item => item.value === parseInt(changes.subjectId.currentValue));
            this.subject_num = index;
        }
    }
   
    //筛选条件
    getSearch() {
        this.collapseList = [
            {
                label: '类型',
                value: 1
            },
            {
                label: '学科',
                value: 2
            },
            {
                label: '教材',
                value: 3
            },
            {
                label: '标签',
                value: 4
            },
            {
                label: '备课组',
                value: 5
            },
            {
                label: '人员',
                value: 6
            },
        ]
    }
    showHint(val) {
        this.isCollapseDetail = true;
        this.CollapseDetailValue = val;
    }
    //移动鼠标
    hidenHint() {
        this.isCollapseDetail = false;
    }
    //课本
    textbooks(books: any) {
        this.isCollapseDetail = false;
        this.collapseList[2].label = books.title;
        this.getTextbooks.emit(books);
    }
    //标签返回数据
    postTag(data: any) {
        this.collapseList[3].label = data.title;
        this.getPeriod.emit(data)

    }
    clickPersonal(data:any,num:number){
        this.personal_num = num;
        this.collapseList[5].label = data.label;
        this.getPersonal.emit(data)
    }

    //点击学科
    clickSubject(data: any, num: number) {
       
       this.subject_num = num;
        //if (data.value) {
            this.subjects = []
            this.subjects.push(data.value)
           
            this.getSubject.emit(data)
        //}

    }
    //点击类型
    clickType(data: any, num: number) {
        this.collapseList[0].label = data.label
        this.type_num = num;
        this.getType.emit(data)
    }
    // 获取部门
    getDepartmentList() {
        const that = this;
        that.apollo.watchQuery({
            query: departmentList,
            variables: {
                page: 0,
                limit: 0,
                condition: {
                    group_type: 'preparation'
                }
            },
            fetchPolicy: 'no-cache',
        }).valueChanges.subscribe((res: any) => {
            if (res.data.Departments.data.length > 0) {
                

                this.groupList = res.data.Departments.data.map(el => {
                    
                    return {
                        label: el.department_name.zh ? el.department_name.zh : el.department_name.mn,
                        value: el.id
                    };
                });
            }
        });
    }
    //获取备课组下的人员
    DepartmentUsers(departmentId: number) {
        
        this.dataSet = []
        let params = {
            query: DepartmentUsers,
            variables: {
                page: 0,
                limit: 0,
                condition: { department_id: departmentId }
            }
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            this.dataSet = res.data.DepartmentUsers.data.map(el => {
                return {
                    label: el.user_name ? (el.user_name.zh ? el.user_name.zh : el.user_name.mn) : '',
                    value: el.user.id,
                }
            })

        });
    }
    clickGroup(data:any,num:number){
        this.group_num =num 
        this.collapseList[4].label = data.label;
        this.DepartmentUsers(data.value)
        
    }
    //重置
    getReset() {
        this.subject_num = -1;
        this.getSearch()
        this.postReset.emit(true)
    }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { findtagData } from '../../../graphQl/base/tag';
import { GraphqlServiceService } from '../../../service/graphqlService/graphql-service.service';
import { RequestService } from '../../../service/request/request.service';


@Component({
    selector: 'orh-files',
    templateUrl: './orh-files.component.html',
    styleUrls: ['./orh-files.component.scss'],
    providers:[DatePipe]
})
export class OrhFilesComponent implements OnInit, OnChanges {
    @Input() fileData: any;
    data: any[] = [];
    backgroundColor: any = {
        background: `linear-gradient(-90deg, rgba(68, 163, 255, 1), rgba(11, 135, 255, 1))`,
        boxShadow: '0px 10px 10px 0px rgba(17, 138, 255, 0.38)',
        width:'150px'
    };
    icons = 'iconxiazai3';
    activestyle:any={
        width:'150px !important'
    }
    constructor(
        private http: RequestService,
        private date:DatePipe,
        private graph:GraphqlServiceService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fileData.currentValue) {		
            this.fileData.map((ind: any) => {
                this.http.get('resource/file/items', {
                    identifiers: ind.fileId,
                }).subscribe(res => {
                    if (res.code === 200) {
                        const datas = res.data[0];	
                       
                        
                        if(datas){
                            let files = {
                                status: true,
                                checked: true,
                                name: datas.properties['sr:fileName'],
                                type: datas.properties['sr:fileType'],
                                mime_type:datas.properties['sr:fileType'],
                                created: datas.properties['sr:fileType'],
                                size: datas.properties['sr:fileSize'] ? datas.properties['sr:fileSize'] : '',
                                parentRef: datas.properties['sr:identifier'] ? datas.properties['sr:identifier'] : '',
                                osspath: datas.properties['sr:osspath'] ? datas.properties['sr:osspath'] : '',
                                thumbnail: datas.properties['sr:thumbnail'] ? datas.properties['sr:thumbnail'] : null,
                                convertStatus: datas.properties['sr:convertStatus'],
                                properties:datas.properties
                            };					
                            ind.files = files;
                        }				
                                                                        
                    }else{
                        this.message.error(res.msg)
                    }
                });
                ind.fileCover = ind.fileCover;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath;
                ind.fileTitle = ind.fileTitle;
                ind.tagID = ind.tagID;
                
                
                if(ind.tagID === undefined ||ind.tagID === 'file'){
                    ind.type_name= null
                }else{
                    const params = {
                        query: findtagData,
                        variables: {
                            id: ind.tagID
                        },
                        policy: 'no-cache',
                    };
                    this.graph.GQuery(params).subscribe((respos: any) => {
                        let data = respos.data.tag;
                        ind.type_name= data.name.zh ? data.name.zh : data.name.mn;
                    })
                }
                
                this.data.push(ind);
               
               
                
            })
            console.log(this.data);
        }
    }
    findData(type:string) {
        if(type === undefined ||type === 'file'){
            return false;
        }else{
            const params = {
                query: findtagData,
                variables: {
                    id: type
                },
                policy: 'no-cache',
            };
            this.graph.GQuery(params).subscribe((res: any) => {
                let data = res.data.tag;
                return data.name.zh ? data.name.zh : data.name.mn;
            })
        }	
    }
    //下载
    addDownload(file: any) {
        let objectName = file.osspath
        let api = 'resource/file/download';
        this.http.post(api, {
            objectName: objectName,
            newName: file.name
        }).subscribe((item: any) => {
            window.open(item, '_blank');
            //this.message.success(this.langs.download_success, { duration: 3000 });

        })
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService } from 'ng-zorro-antd';
import { AliPlayerConfig } from 'ngx-orhon-aliplayer/lib/player/player.component.modal';
import { BusinessService } from '../../service/business/business.service';
import { RequestService } from '../../service/request/request.service';

@Component({
    selector: 'orh-simple-perview',
    templateUrl: './orh-simple-perview.component.html',
    styleUrls: ['./orh-simple-perview.component.less']
})
export class OrhSimplePerviewComponent implements OnInit {

    @Input() itemId: string;
    @Input() fileInfo: any;
    @Output() statusPost: EventEmitter<any> = new EventEmitter()
    backgroundColor = {
        background: `linear-gradient(-90deg,rgba(173,216,158,1),rgba(148,197,131,1))`,
        boxShadow: '0px 10px 10px 0px rgba(173,216,158,0.38)'
    }
    previewAudioArray: any;
    workFiles: any[] = []
    files: any[] = [];
    previewArray: any;
    messageShow: boolean = false;
    imgVisible: boolean = false;
    langfiles: any;
    workImgNum: number = 0;
    workImg: string = '';
    iframFile:any =null;
    loading: boolean = false;
    config: AliPlayerConfig = {
        autoplay: false
    }
    form: any = {
        userId: null,
        nodeId: null,
        visit: 0,   //访问学习次数
        duration: null, //学习时长
        progress: 20 //0-100 播放进度进度
    };
    anchorConfig = {
        page: 1,
        limit: 1,
        total: 0
    }

    constructor(
        private http: RequestService,
        private message: NzMessageService,
        private business: BusinessService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.form.userId = this.business.getItem('userInfo').id;

        if (this.fileInfo) {
            this.getFile(this.fileInfo)
        }
    }


    // 文件预览
    getFile(data: any) {

        this.loading = false
        this.files = [];
        this.workFiles = [];
        this.previewAudioArray = {};
        this.previewArray = {};
        const objectName = data.osspath;
        if (data.mime_type === 'image') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.files = item;

            });
        } else if (data.mime_type === 'video') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.previewArray = item
                this.loading = true
            });

        } else if (data.mime_type === 'audio') {
            this.http.post('resource/file/download', {
                objectName,
                newName: data.name
            }).subscribe((item) => {
                this.previewAudioArray = item;
                this.loading = true
            });
        } else {
            this.http.get('resource/file/preview', {
                identifier: data.parentRef
            }).subscribe((item) => {
                const datas = item.data.properties['sr:preview'];
                if (datas) {

                    let reg = RegExp(/\"/g);
                    if (datas.match(reg)) {

                        const guide = datas.replace(/\"/g, '').split(','); // res.data.guide为上述字符串
                        this.form.duration = guide.length;
                        this.anchorConfig.total = (guide.length * 0.2)
                        let num = Math.ceil(guide.length * 0.2);
                        this.workFiles = guide.slice(0, num);
                    } else {
                        this.iframFile = this.sanitizer.bypassSecurityTrustResourceUrl(datas);
                    }
                } else {

                    this.message.error('正在转码中，暂时预览不了');
                }

            });
        }
    }
    getImage(img: string, num: number) {
        this.workImgNum = num
        this.workImg = img
        this.imgVisible = true;

    }
    //放大图片的下一个和上一个
    imgAnchor(type: string) {
        this.workImg = ''
        if (type === 'down') {
            if (this.workImgNum < this.workFiles.length) {
                this.workImgNum++;
            } else {
                this.workImgNum = this.workFiles.length;
            }

        } else if (type === 'up') {
            if (this.workImgNum === 1) {
                this.workImgNum = 1;
            } else {
                this.workImgNum--;
            }

        }
        this.workImg = this.workFiles[this.workImgNum - 1] ? this.workFiles[this.workImgNum - 1] : ''

    }
    discSubmit() {
        this.imgVisible = false
    }
    anchorChange(event: number) {
        this.anchorConfig.page = event + 1;
        if (this.workFiles.length === this.anchorConfig.page) {
            this.messageShow = true
        }
    }
    //学习
    clickLearn() {
        this.form.nodeId = this.itemId;
        this.http.post('cms/node/learninfo/save', this.form).subscribe((item: any) => {
            if (item.code === 200) {
                this.message.success(this.langfiles.leaning_success);
                this.messageShow = false;
                this.statusPost.emit('my')
            }else{
                this.message.error(item.message)
            }
        })
    }
    //视频截取
    omcPlayer(event: any) {
        // console.log(event);
        const num = event.instance.getDuration();
        if (event.state === 'ready') {

            event.instance.setPreviewTime(num * 0.2);
        }
        if (num !== event.instance.getCurrentTime() && event.state === 'ended') {
            this.messageShow = true
        }
    }
    //下一个/上一个按钮
    handleAnchor(type: string): void {
        if (type === 'next') {
            if (this.anchorConfig.page < this.workFiles.length) {
                this.anchorConfig.page++
            } else {
                this.anchorConfig.page = this.workFiles.length - 1;
                this.messageShow = true
            }
        } else if (type === 'pre') {
            if (this.anchorConfig.page === 1) {
                this.anchorConfig.page = 1

            } else {
                this.anchorConfig.page--
            }
        }
    }
    nzPageIndexChange(evt: number) {
        this.anchorConfig.page = evt
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EssenceNg2PrintComponent } from 'essence-ng2-print';
import { findlearningExperience } from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';

@Component({
  selector: 'learning-experience-postdoctoral-detail',
  templateUrl: './learning-experience-postdoctoral-detail.component.html',
  styleUrls: ['./learning-experience-postdoctoral-detail.component.less']
})
export class LearningExperiencePostdoctoralDetailComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    detail:any={};
    printCSS: string[];
    printStyle: string;
    page:number = 0;
    canvasShow:boolean = false;
    printBtnBoolean: boolean = true;
    @ViewChild('printBtn', { static: true }) printBtn: EssenceNg2PrintComponent;
    fileList:any[]=[]
    constructor(
        private graph:GraphqlServiceService
    ) { 
        
        this.printCSS = ['http://cdn.bootcss.com/bootstrap/3.3.7/css/bootstrap.min.css']
        this.printStyle = `
        table{
            border-top: 1px solid #999;
            border-left: 1px solid #999;
            width:100%;
            border-radius : 5px
        }
        table tbody tr{
            padding:0;
            margin:0;
            width:100%
        }
       
        table tbody tr td {
            margin:0;
            text-align: center;
            border-bottom: 1px solid #999;
            border-right: 1px solid #999;
            padding: 8px 5px;
        }
        table tbody tr td.td_approves{
            text-align:left;
        }
        table tbody tr td div{
            margin-top:0
            display:block;
            padding-left:7px;
            width:100%;
            min-height:30px;
        }
        
        table tbody tr td div span{
            padding-top:0;
            float:left;
            font-style:normal
        }
        table tbody tr td p span p{
            
        }
        table tbody tr td.peoples{
            height:38px;
            padding:0;
        }
        table tbody tr td div.champion {
            border-top: 1px solid #ddd;
        }
    
        table tbody tr td div.champion-td {
            padding: 10px 0;
        }
        .examine {
            font-size: 14px;
            font-weight: 400;
            border: 1px solid #ddd;
            padding: 7px 11px;
            margin-top: 12px;
            background: #f5f5f5;
            border-radius: 5px;
            margin:0 auto;
        }
        .examine-content{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        pdf-viewer{
            width: 100%;
            height: 100%;
        }
        img{
            max-width:80vw;
            max-height:65vh;
        }
        `;
    }

    ngOnInit() {
        this.findData()
    }
    //单条
    findData() {
        let that = this;
        const params = {
            query: findlearningExperience,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            this.detail = res.data.learningExperience;
            
            
            if (this.detail.image !== null || this.detail.image !== '') {
                let datas = this.detail.image ? JSON.parse(`["${this.detail.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('.');
        
            let text = item.substring(index + 1, item.length);
            let arrary = ['jpg','png','jpeg','svg','gif','bmp']
             let ind= arrary.findIndex(item=>item===text);
            if(ind>-1){
                text = 'image';
            }
            return { url: `${item}`, type: text, name: '附件' }
        })
        
    }
    printComplete() {
        
        this.printBtnBoolean = true;
    }
    
    browserPrint() {
        let datas:any = document.querySelectorAll('.canvasWrapper');
        if(!this.canvasShow){
            for (let index = 0; index < datas.length; index++) {
                let image = new Image();
                
                let canvas= datas[index].childNodes[0];
                image.src=canvas.toDataURL('image/png');
                datas[index].removeChild(canvas)
                datas[index].appendChild(image);
               
            }
            this.canvasShow = true;
        }
        
        this.printBtnBoolean = false;
    }
    fileClick(url) {
        window.open(url)
    }
}
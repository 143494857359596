import gql from 'graphql-tag';
const textbooksLists = gql`
    query($page: Int, $limit: Int, $condition: TextbookCondition){
        textbooks(page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data{
                 id
                 textbookVersionId
                  periodId
                  subjectId
                  textbookNumber
                   userId
                   schoolId
                   belong
                   reportNum
                   courseId
                   shareStatus
                   mark
                     textbookName{
                       
                            mn
                            zh
                            en
                      
                    }
               
            }
        }
    }
`;
const knowledgePointsLists = gql`
    query($page: Int, $limit: Int, $condition: KnowledgePointCondition){
        knowledgePoints(page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data{
                 id
                 knowledgeOrder
                 belong
                knowledgeName{
                       
                    mn
                    zh
                    en
                      
                    }
               
            }
        }
    }
`;
const newTextbooks = gql`
query($page: Int, $limit: Int, $condition: NewTextbookCondition){
    newTextbooks(page: $page, limit: $limit, condition: $condition){
        code
        msg
        total
        data{
             id
             textbookVersionId
              periodId
              subjectId
              textbookNumber
               userId
               schoolId
               belong
               reportNum
               courseId
               shareStatus
               mark
                 textbookName{
                   
                        mn
                        zh
                        en
                  
                }
           
        }
    }
}
`;
const textbookChapterList = gql`
    query($page: Int, $limit: Int, $condition: TextbookChapterCondition){
        textbookChapters(page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data{
                id
                textbookId
                chapterOrder 
                chapterType
                parentId 
                chapterName{                      
                    mn
                    zh
                    en               
                }
            }
        }
    }
`;
const tagsList = gql`
    query($page: Int, $limit: Int, $condition: TagCondition){
        tags(page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data{
                id
                tagSlug
                tagOrder
                lavel
                type
                name{                      
                    mn
                    zh
                    en
                }
               
            }
        }
    }
`;


export {
    textbooksLists,
    knowledgePointsLists,
    textbookChapterList,
    newTextbooks,
    tagsList
}
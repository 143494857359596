import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import Icon from 'src/app/config/icon-lsit'
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-orh-icon-list',
    templateUrl: './orh-icon-list.component.html',
    styleUrls: ['./orh-icon-list.component.less']
})
export class OrhIconListComponent implements OnInit {

    @Output() iconValue: EventEmitter<any> = new EventEmitter();
    @Input() isShow: boolean;

    gridStyle = {
        width: '10%',
        padding: '10px',
        cursor: 'pointer'
    }
    nzBodyStyle = {
        height: '500px',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
    iconList: any[] = Icon;
    validateForm: FormGroup

    constructor(
        private fb: FormBuilder,
    ) { }



    ngOnInit() {
        this.validateForm = this.fb.group({
            label: [''],
        });
    }
    searchSubmit(): void {
        let label = this.validateForm.value.label;

        this.searchStr(label,this.iconList);
    }

    resetSubmit(): void {
        this.validateForm.reset();
        this.iconList = Icon;
    }


    searchStr(str:string, arr:any) {
        let newList = []
        // 要匹配字符串的首个字符
        let startChar = str.charAt(0)
        // 要匹配字符串的字符长度
        let strLength = str.length
        for (let i = 0; i < arr.length; i++) {
            // 默认数组arr中对象arr[i]不存在str
            let isExist = false
            let obj = arr[i]
            for (let key in obj) {
                if (typeof (obj[key]) === 'function') {
                    obj[key]()
                } else {
                    let keyValue = ''
                    // 获取arr[i][key]的值
                    if (obj[key] !== null && typeof (obj[key]) === 'string') {
                        keyValue = obj[key]
                    } else if (obj[key] !== null && typeof (obj[key]) !== 'string') {
                        keyValue = JSON.stringify(obj[key])
                    }
                    // arr[i][key]中的各个位置的字符与str的0位置字符startChar对比如果相等，
                    // 在arr[i][key]中从j位置截取与str长度相同的字符，对比是否相等
                    for (let j = 0; j < keyValue.length; j++) {
                        // 把原有数据转化为小写，输入数据也转化为纯小写，实现模糊匹配,如区分大小写，可删除toLowerCase()
                        if (keyValue.charAt(j).toLowerCase() === startChar.toLowerCase()) {
                            if (keyValue.substring(j).substring(0, strLength).toLowerCase() === str.toLowerCase()) {
                                // 模糊匹配到的字符存在表示arr[i]中存在str
                                isExist = true
                                break
                            }
                        }
                    }
                }
            }
            // 当arr[i]中存在str时，把arr[i]放入一个新数组
            if (isExist === true) {
                newList.push(obj)
            }
        }
        // 最后返回这个新数组
       this.iconList = newList;

    }

    iconClick(str: string): void {
        this.iconValue.emit(str);
        this.iconList = Icon;
        this.validateForm.reset();
    }
    handleCancel(): void {
        this.iconValue.emit('');
        this.iconList = Icon;
        this.validateForm.reset();
    }
}

import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';

@Component({
    selector: 'app-orh-demo',
    templateUrl: './orh-demo.component.html',
    styleUrls: ['./orh-demo.component.less']
})
export class OrhDemoComponent implements OnInit {
    @Output() clear = new EventEmitter<any>()
    visible: boolean;
    placement = 'right';

    constructor(private message: NzMessageService, private router: Router) {
    }

    ngOnInit() {
        this.visible = true;
    }

    open(): void {
        this.visible = true;
    }

    SelectColor(val: string) {
        const id = this.message.loading('主题初始化中...', {nzDuration: 0}).messageId;
        // @ts-ignore
        window.less.modifyVars({
            '@primary-color': val,
            '@orh-home-menu-list': val
        }).then(() => {
            this.message.remove(id);
            this.message.success('主题切换成功');
        })
    }

    Drag() {
        this.router.navigateByUrl('/demo');
    }

    close(): void {
        this.visible = false;
        this.clear.emit(false);
    }
}

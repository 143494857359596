import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { RequestService } from 'src/app/service/request/request.service';

@Component({
  selector: 'orh-lesson-class',
  templateUrl: './orh-lesson-class.component.html',
  styleUrls: ['./orh-lesson-class.component.less'],
  providers:[DatePipe]
})
export class OrhLessonClassComponent implements OnInit {

    @Input() classData: any;
    data: any[] = []
    constructor(
        private http: RequestService,
        private date: DatePipe,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.classData.currentValue) {
            console.log(this.classData);
            
            this.classData.map((ind: any) => {
                this.http.get('cms/node/info', {
                    id: ind.fileId,
                }).subscribe(res => {
                    if (res.code === 200) {
                        // if (res.data.type === 'file') {
                            let file = res.data.nodeFileEntities.length ? (res.data.nodeFileEntities[0]) : null;
                            if(file){
                                const fileinfo = JSON.parse(file.fileinfo);
                                let files = {
                                    status: true,
                                    checked: true,
                                    name: fileinfo['sr:fileName'],
                                    type: fileinfo['sr:fileType'],
                                    mime_type: fileinfo['sr:fileType'] ? fileinfo['sr:fileType'] : '',
                                    created: this.date.transform(fileinfo['sr:convertStartAt'], 'yyyy-MM-dd'),
                                    size: fileinfo['sr:fileSize'] ? fileinfo['sr:fileSize'] : '',
                                    parentRef: fileinfo['sr:identifier'] ? fileinfo['sr:identifier'] : '',
                                    osspath: fileinfo['sr:osspath'] ? fileinfo['sr:osspath'] : '',
                                    thumbnail: fileinfo['sr:thumbnail'] ? fileinfo['sr:thumbnail'] : null,
                                    convertStatus: fileinfo['sr:convertStatus']
                                };
    
                                ind.files = files
                            }
                            
                        // } else {
                            ind.contents = res.data.nodeContentEntities.length ? JSON.parse(res.data.nodeContentEntities[0].content) : [];

                        // }
                    }else{
                        this.message.error(res.msg)
                    }
                });
                ind.fileCover = ind.fileCover;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath;
                ind.fileTitle = ind.fileTitle;
                this.data.push(ind)
            })
        }

    }

}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    Output, EventEmitter
} from '@angular/core';

@Component({
    selector: 'orh-disc-list',
    styleUrls: ['./orh-disc-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <div class="orh-disc-list" omc-container>
               
                <nz-table #lists nzBordered [nzData]="data" [nzFrontPagination]="false" >
                    <thead>
                    <tr >
                        <th style="width: 1%"></th>
                        <th style="width: 60%" nzWidth="100px">名称</th>
                        <th style="width: 39%" nzWidth="50px">时间</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of list; let  i = index;" style="text-align: left">
                   
                        <td nzShowCheckbox [(nzChecked)]="item.checked" (nzCheckedChange)="checkChange()"></td>
                        <td>{{ item.info.name }}</td>
                        <td>{{ item.info.created | date: 'yyyy-MM-dd hh:mm' }}</td>
                    </tr>
                    </tbody>
                </nz-table>
        </div>
    `
})
export class OrhDiscListComponent implements OnChanges {

    @Input()
    data: any[];

    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();

    public list = [];

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {data} = changes;
        this.list = data.currentValue.map(el => {
            return {
                checked: false,
                info: el
            };
        });
        this.cdr.detectChanges();
    }

    checkChange(): void {
        const data = this.list.filter(el => el.checked);
        console.log(data)
        this.orhChange.emit(data);
    }

}

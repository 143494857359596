import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaperServiceService } from '../../../service/paper/paper-service.service';
import { RequestService } from '../../../service/request/request.service';
import { UtilsService } from '../../../service/utils/utils.service';

@Component({
  selector: 'orh-questions',
  templateUrl: './orh-questions.component.html',
  styleUrls: ['./orh-questions.component.less']
})
export class OrhQuestionsComponent implements OnInit {

    @Output() orhChange: EventEmitter<any> = new EventEmitter();
    questionList: any[] = [];
    pageConfig: any = {
        page: 1,
        limit: 10,
        total: 0
    }
    stem: string = ''
    condition: any = {
        ques_type: null,
        acayear: null,
        knows: [],
        stage: null,
        subject: null,
    };
    selectTotal: number = 0;
    icons = 'iconxinjian';
    detailShow: boolean = false;
    backgroundColor: any = {
        background: `linear-gradient(-90deg,rgba(255,192,70,1),rgba(245,144,0,1))`,
        boxShadow: '0px 10px 10px 0px rgba(245,161,0,0.38)'
    };
    constructor(
        private req: RequestService,
        private paper: PaperServiceService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        
        this.getData();
    }
    //获取题库
    getData() {
        let parmas :any;
        if (this.condition.subject && this.condition.subject === 'chinese' || this.condition.subject && this.condition.subject === 'english') {
            parmas = {
                lang: 'cn',
                page: this.pageConfig.page,
                status: 3,
                ques_type: this.condition.ques_type,
                acayear: this.condition.acayear,
                knows: this.condition.knows.length > 0 ? this.condition.knows : null,
                stage: this.condition.stage,
                subject: this.condition.subject,
                stem: this.stem
            }
        } else {
            parmas = {
                lang: 'mn',
                page: this.pageConfig.page,
                status: 3,
                ques_type: this.condition.ques_type,
                acayear: this.condition.acayear,
                knows: this.condition.knows.length > 0 ? this.condition.knows : null,
                stage: this.condition.stage,
                subject: this.condition.subject,
                stem: this.stem
            }
        }
        
        let form = this.utils.handelCondition(parmas)
        this.req.paperGet('quesbank', form).subscribe((item: any) => {
            this.questionList = item.data.map((item: any) => {
                return {
                    checked: false,
                    ...item
                }
            });;
            this.pageConfig.total = item.page.total
        })
    }
    checkChange(): void {
        let datas = this.questionList

        const data = datas.filter(el => el.checked);
        this.selectTotal = datas.length;
        this.orhChange.emit(data);
    }
    pageChange(event) {
        // console.log(event);
        this.pageConfig.page =event
        this.getData()
    }
    limitChange(event) {
        // console.log(event);
        this.pageConfig.limit =event
        this.getData()
    }
    //添加题
    addClick() {
        this.detailShow = true;
    }
    //关闭添加
    close() {
        this.detailShow = false
    }
    //添加返回
    getColse(str: boolean) {
        this.detailShow = false;
        if (str) {
            this.getData()
        }
    }
    postSearch(event) {
        this.condition = event;
        this.getData()
    }
    searchName(name: string) {
        this.stem = name;
        this.getData();
    }

}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addStudyAbroad, findstudyAbroad } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';

@Component({
    selector: 'study-abroad-operation',
    templateUrl: './study-abroad-operation.component.html',
    styleUrls: ['./study-abroad-operation.component.less']
})
export class StudyAbroadOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen: boolean = false;
    formBody: any = {
        time: null,
        entime: null

    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            country: [null, Validators.required],//留学国家
            pub: ['', Validators.required],//是否公派【1、是2、否】
            time: ['', Validators.required],//开始时间
            entime: ['', Validators.required],//结束时间
            back: ['', Validators.required],//回国时间
            projectdate: ['', Validators.required],//资助时间
            projectmoney: ['', Validators.required],//资助金额
            image: [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//留学学校名称
            educational: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//取得学历学位
            project: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//资助项目
        })
    }
    dictionaryList: any = {
        nationality: [],
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findstudyAbroad,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.studyAbroad
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd');
            data.entime = this.date.transform(data.entime, 'yyyy-MM-dd');
            data.back = this.date.transform(data.back, 'yyyy-MM-dd');
            data.projectdate = this.date.transform(data.projectdate, 'yyyy-MM-dd');

            form.get('time')!.setValue(data.time);
            form.get('entime')!.setValue(data.entime);
            form.get('back')!.setValue(data.back);
            form.get('pub')!.setValue(data.pub);
            form.get('projectmoney')!.setValue(data.projectmoney);
            form.get('projectdate')!.setValue(data.projectdate);
            form.get('country')!.setValue(data.country ? data.country.dictionary_value : null);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('name')!.setValue(data.name ? data.name : { mn: null, zh: null, en: null });
            form.get('educational')!.setValue(data.educational ? data.educational : { mn: null, zh: null, en: null });
            form.get('project')!.setValue(data.project ? data.project : { mn: null, zh: null, en: null });
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if (datas) {
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index = item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.entime) {
            return false;
        }
        return startValue.getTime() > this.formBody.entime.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.time) {
            return false;
        }
        return endValue.getTime() <= this.formBody.time.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.time = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.entime = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {
        this.endOpen = open;
    }
    onClose() {
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'nationality'
        ];
        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    } else {
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {
        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.entime = this.date.transform(params.entime, 'yyyy-MM-dd');
        params.back = this.date.transform(params.back, 'yyyy-MM-dd');
        params.projectdate = this.date.transform(params.projectdate, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addStudyAbroad,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addStudyAbroad.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else {
                this.msg.error(res.data.addStudyAbroad.msg)
            }
            that.form.reset()
        })
    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },
    ];
}

import { Injectable, Output } from '@angular/core';
// import {Headers} from 'angular2/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PermissionsService } from '../../service/permissions/permissions.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { error } from 'util';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { MESSAGE } from '../../utils/constant';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResourcesService {
    // bodyFolder: BodyFolder;
    // bodyFile: BodyFile;
    // bodyFilter: BodyFilter;
    // bodyConfig: BodyConfig;
    //
    beanUser: BeanUser;

    userInformation: any;
    fileType: string;
    messageService: NzMessageService;
    token: string = null;
    previewArray: any;
    constructor(
        private message: NzMessageService,
        private http: HttpClient,
        private userService: PermissionsService,
        private graph: GraphqlServiceService,
        private modalService: NzModalService
    ) {
        this.token = JSON.parse(localStorage.getItem('token'));
        // console.log('token',this.token);
        // console.log('resource service constructor run ');
        userService.getOtherUserInfo().then(
            (success: any) => {
                //  console.log(success);
                if (success.code === 200) {
                    this.userInformation = success;

                    // console.log(success.data);
                    this.beanUser = new BeanUser();
                    this.beanUser.schoolName = this.userInformation.data.school.name.zh;
                    this.beanUser.schoolId = this.userInformation.data.school.id;
                    this.beanUser.id = this.userInformation.data.id;
                    this.beanUser.idCard = this.userInformation.data.idcard;
                    this.beanUser.username = this.userInformation.data.username;     //  用户登陆的账号


                    this.createSchoolFolderAndUserFolder(this.beanUser, ((res: any) => {
                        // console.log('run createSchoolFolderAndUserFolder success');
                        // console.log(res);
                    }));
                }else{
                    this.message.error(success.msg)
                }
            },
            error => {
                console.log('获取用户信息失败');
            });
        this.messageService = message;
    }

    showMessageSuccess(msg: string) {
        this.messageService.success(msg);
    }

    showMessageError(msg: string) {
        this.messageService.error(msg);
    }

    urlBase = 'http://localhost:8848';
    urlUpload = 'http://localhost:8848/file/ossupload';


    getUserInfo(cb) {
        this.userService.getOtherUserInfo().then(
            (success: any) => {
                //  console.log(success);
                if (success.code === 200) {
                    this.userInformation = success;

                    console.log(success.data);
                    this.beanUser = new BeanUser();
                    this.beanUser.schoolName = this.userInformation.data.school.name.zh;
                    this.beanUser.schoolId = this.userInformation.data.school.id;
                    this.beanUser.id = this.userInformation.data.id;
                    this.beanUser.idCard = this.userInformation.data.idcard;
                    this.beanUser.username = this.userInformation.data.username;     //  用户登陆的账号

                    cb(this.beanUser);

                }else{
                    this.message.error(success.msg)
                }
            },
            error => {
                console.log('获取用户信息失败');
            });
    }

    /**
     * check & create school folder and user folder based on beanUser
     * @param beanUser
     * @param cb
     */
    createSchoolFolderAndUserFolder(beanUser: BeanUser, cb) {
        let header = new HttpHeaders();

        header.append('Content-Type', 'application/x-www-form-urlencoded');
        header.append('token', this.token);
        this.http.post('api/resource/user/insert', {
            headers: header
        }).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * insert new folder under a folder exist
     * @param beanFolder
     * @param cb
     */
    folderInsert(beanFolder: BeanFolder, cb) {
        this.http.post(this.urlBase + '/folders/insert', beanFolder).subscribe(
            success => {
                console.log(success);
                cb(success);
            },
            error => {
                console.log(error);
                cb(error);
            },
        );
    }

    /**
     *
     * @param beanFolder
     * @param cb
     */
    folderEdit(beanFolder: BeanFolder, cb) {
        this.http.post(this.urlBase + '/folders/edit', beanFolder).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                //  console.log(error);
                cb(error);
            },
        );
    }

    /**
     * delete folder by identifier ,ps : this operate will delete all children belongs to this folder
     * @param identifier
     * @param cb
     */
    folderDelete(identifier: string, cb) {
        this.http.post(this.urlBase + 'folders/delete', null, { params: { identifier: identifier } }).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * get children by absolute path
     * @param path
     * @param cb
     */
    getListByPath(path: string, cb) {
        this.http.post(this.urlBase + '/folders/getListByPath', null, { params: { path: path } }).subscribe(
            success => {
                //  console.log(success);
                cb(success);
            },
            error => {
                //  console.log(error);
                cb(error);
            },
        );
    }

    /**
     * get children by folder identifier
     * @param path
     * @param cb
     */
    getListById(identifier: string, cb) {
        this.http.post(this.urlBase + '/folders/getListById', null, { params: { identifier: identifier } }).subscribe(
            success => {
                //  console.log(success);
                cb(success);
            },
            error => {
                //  console.log(error);
                cb(error);
            },
        );
    }


    /**
     * insert a new file
     * @param beanFile
     * @param cb
     */
    fileInsert(beanFile: BeanFile, cb) {
        this.http.post(this.urlBase + '/files/insert', beanFile).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }


    /**
     * file edit
     * @param beanFile
     * @param cb
     */
    fileEdit(beanFile: BeanFile, cb) {
        this.http.post(this.urlBase + '/files/edit', beanFile).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * file delete by identifier
     * @param identifier
     * @param cb
     */
    fileDelete(identifier: string, cb) {
        this.http.post(this.urlBase + '/files/delete', null, { params: { identifier: identifier } }).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }


    /**
     * get file node by path , there are many properties in file node
     * @param path
     * @param cb
     */
    getFileByPath(path: string, cb) {
        this.http.post(this.urlBase + '/files/getFileByPath', path).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * get file node by identifier , there are many properties in file node
     * @param identifier
     * @param cb
     */
    getFileById(identifier: string, cb) {
        this.http.post(this.urlBase + '/files/getFileById', identifier).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * get file property of binary ,ps : file here also a node has another node which's primary type is nt:resource
     * @param path
     * @param cb
     */
    getBinaryByPath(path: string, cb) {
        this.http.post(this.urlBase + '/files/getBinaryByPath', path).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    /**
     * be similar to 'getBinaryByPath' , but here we use identifier instead of path
     * @param identifier
     * @param cb
     */
    getBinaryById(identifier: string, cb) {
        this.http.post(this.urlBase + '/files/getBinaryByPath', identifier).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    fileMove(newParentRef: string, identifier: string, cb) {

        this.http.post(this.urlBase + '/files/move', null, {
            params: {
                'newParentRef': newParentRef,
                'identifier': identifier,
            },
        }).subscribe(
            success => {
                cb(success);
                //  console.log(success);
            },
            error => {
                cb(error);
                //  console.log(error);
            },
        );
    }

    fileUpload(file: Blob, parentRef: string, fileName: string, cb) {

        let reader = new FileReader();
        let result = reader.readAsBinaryString(file);
        console.log(result);
        console.log(file);
        let body = { file: file, parentRef: parentRef };

        let formData = new FormData();
        formData.append('file', file);
        formData.append('parentRef', parentRef);
        formData.append('fileName', fileName);


        this.http.post(this.urlBase + '/files/upload', formData).subscribe(
            success => {
                cb(success);
            },
            error => {
                cb(error);
            },
        );
    }

    getGrades(cb) {
        let body = gql`
        query{
            gradesNew{
                data{
                    id
                    slug
                    name{
                        en
                        mn
                        zh
                    }
                    number
                    order
                    mark
                    periods{
                        name{
                            mn
                            zh
                        }
                    }

                }
            }
        }
    `;
        this.graph.GQuery({ query: body, name: MESSAGE }).subscribe(
            success => {
                console.log(success);
                cb(success);
            },
            error => {
                console.log(error);
                cb(error);
            },
        );
    }


    //  ------------------------------------------------------------------------------------------------------------------------------

    /**
     * ---------------------------------------------------------------------------------------------------
     * old codes (neo4j) below  ,new codes (jcr) above
     * @param userInfoParam
     * @param cb
     */

    //
    // initSchool(userInfoParam: UserInfoParam, cb) {
    //   this.http.post(this.urlBase + 'store/initSchool', userInfoParam).subscribe(
    //     (success: any) => {
    //       //  console.log(success);
    //       cb(success);
    //     },
    //     error => {
    //       //  console.log(error);
    //       cb(error);
    //     },
    //   );
    // }
    //
    // /**
    //  * 文件夹
    //  * @param bodyFolderInsert
    //  * @param cb
    //  */
    // //  新建文件夹
    // folderCreate(bodyFolderInsert: BodyFolder, cb) {
    //   this.http.post(this.urlBase + 'folder/insert', bodyFolderInsert).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  修改文件夹
    // folderEdit(bodyFolderInsert, cb) {
    //   this.http.post(this.urlBase + 'folder/edit', bodyFolderInsert).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    //
    // folderList(uuid: string, cb) {
    //   this.http.post(this.urlBase + 'user/getChild', uuid).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // /**
    //  * 文件
    //  */
    //
    // //   文件新增
    // fileCreate(bodyFile: BodyFile, cb) {
    //   this.http.post(this.urlBase + 'file/insert', bodyFile).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  文件修改
    // fileEdit(bodyFile: BodyFile, cb) {
    //   this.http.post(this.urlBase + 'file/edit', bodyFile).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  文件删除
    // fileDelete(id: string[], cb) {
    //   this.http.get(this.urlBase + 'file/delete').subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  喜欢
    // fileLike(targetUUID: string, cb) {
    //   this.http.get(this.urlBase + 'user/like/' + targetUUID).subscribe(res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  取消喜欢
    // fileDislike(targetUUID: string, cb) {
    //   this.http.get(this.urlBase + 'user/disLike' + targetUUID).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  收藏
    // fileFavourite(targetUUID: string, cb) {
    //   this.http.get(this.urlBase + 'user/favourite/' + targetUUID).subscribe(res => {
    //     cb(res);
    //   }, error => {
    //     cb(error);
    //   });
    // }
    //
    // //  取消收藏
    // fileDisFavourite(targetUUID: string, cb) {
    //   this.http.get(this.urlBase + 'user/disFavourite/' + targetUUID).subscribe(res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     });
    // }
    //
    // //  获取用户收藏列表
    // fileGetFavourite(cb) {
    //   this.http.get(this.urlBase + 'user/getFavourite').subscribe(res => {
    //     cb(res);
    //   }, error => {
    //     cb(error);
    //   });
    // }
    //
    // /**
    //  * 分值配置
    //  */
    //
    // //  新建配置
    // configCreate(bodyConfig: BodyConfig, cb) {
    //   this.http.post(this.urlBase + 'scoreConfig/create', bodyConfig).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  修改配置
    // configEdit(bodyConfig: BodyConfig, cb) {
    //   this.http.post(this.urlBase + 'scoreConfig/edit', bodyConfig).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  删除配置
    // configDelete(targetUUID: string[], cb) {
    //   this.http.post(this.urlBase + 'scoreConfig/delete', targetUUID).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // /**
    //  * 文件筛选
    //  */
    //
    // //  搜索文件
    // filterSearch(content: string, cb) {
    //   this.http.post(this.urlBase + 'filter/search', content).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  筛选文件
    // filterOperator(bodyFilter, cb) {
    //   this.http.post(this.urlBase + 'filter/operator', bodyFilter).subscribe(
    //     res => {
    //       cb(res);
    //     },
    //     error => {
    //       cb(error);
    //     },
    //   );
    // }
    //
    // //  用户
    //
    // /**
    //  * 创建用户节点（顺带创建了学校节点）
    //  */
    // createUserNode(userInfo: UserInfoParam, cb) {
    //   this.http.post(this.urlBase + 'user/insert', userInfo).subscribe(res => {
    //     cb(res);
    //   }, error => {
    //     cb(error);
    //   });
    // }
    //
    // /**
    //  * 删除用户节点
    //  */
    // deleteUserNode(uuids: string[], cb) {
    //   this.http.post(this.urlBase + 'user/delete', uuids).subscribe(res => {
    //     cb(res);
    //   }, error => {
    //     cb(error);
    //   });
    // }
    //
    // //  获取用户子级文件夹
    // /**
    //  *  获取第一级文件夹时传userId,获取文件夹子文件夹时传此文件夹的uuid
    //  * @param cb
    //  */
    // getChild(uuid: string, cb) {
    //   this.http.post(this.urlBase + 'user/getChild', uuid).subscribe(res => {
    //     cb(res);
    //   }, error => {
    //     cb(error);
    //   });
    // }
    //

    getType(type: string) {
        switch (type) {
            case 'txt':
                return '/assets/image/Text.png';
            case 'mp4':
                return '/assets/image/Video.png';
            case 'flv':
                return '/assets/image/Video.png';
            case 'rmvb':
                return '/assets/image/Video.png';
            case 'mkv':
                return '/assets/image/Video.png';
            case 'pdf':
                return '/assets/image/Pdf.png';
            case 'xls':
                return '/assets/image/Excel.png';
            case 'xlsx':
                return '/assets/image/Excel.png';
            case 'doc':
                return '/assets/image/Word.png';
            case 'docx':
                return '/assets/image/Word.png';
            case 'mp3':
                return '/assets/image/Music.png';
            case 'exe':
                return '/assets/image/EXE.png';
            case 'zip':
                return '/assets/image/Zip.png';
            case 'rar':
                return '/assets/image/Zip.png';
            case 'png':
                return '/assets/image/Picture.png';
            case 'jpeg':
                return '/assets/image/Picture.png';
            case 'jpg':
                return '/assets/image/Picture.png';
            case 'ppt':
                return '/assets/image/Ppt.png';
            default:
                return '/assets/image/Code.png';
        }
    }

    getBigType(type: string) {
        switch (type) {
            case 'txt':
                return '/assets/image/text.jpg';
            case 'mp4':
                return '/assets/image/video.jpg';
            case 'flv':
                return '/assets/image/video.jpg';
            case 'rmvb':
                return '/assets/image/video.jpg';
            case 'mkv':
                return '/assets/image/video.jpg';
            case 'pdf':
                return '/assets/image/pdf.jpg';
            case 'xls':
                return '/assets/image/xls.jpg';
            case 'xlsx':
                return '/assets/image/xls.jpg';
            case 'doc':
                return '/assets/image/word.jpg';
            case 'docx':
                return '/assets/image/word.jpg';
            case 'mp3':
                return '/assets/image/audio.jpg';
            // case 'exe':
            //   return '/assets/image/EXE.png';
            case 'zip':
                return '/assets/image/zip.jpg';
            case 'rar':
                return '/assets/image/zip.jpg';
            case 'png':
                return '/assets/image/img.jpg';
            case 'jpeg':
                return '/assets/image/img.jpg';
            case 'jpg':
                return '/assets/image/img.jpg';
            case 'ppt':
                return '/assets/image/ppt.jpg';
            default:
                return '/assets/image/other.jpg';
        }
    }

    getTitle(value: string) {

        switch (value) {
            case 'image':
                return '图片';
            case 'text':
                return '文档';
            case 'audio':
                return '音频';
            case 'video':
                return '视频';
            case 'pdf':
                return 'PDF';
            case 'other':
                return '其他';
        }
    }
    formatFileSize(fileSize: number) {

        let number: number;
        let size: string;
        if (fileSize < 1024) {
            return fileSize + 'B';
        } else if (fileSize < (1024 * 1024)) {
            number = fileSize / 1024;
            size = number.toFixed(2);
            return size + 'KB';
        } else if (fileSize < (1024 * 1024 * 1024)) {
            number = fileSize / (1024 * 1024);
            size = number.toFixed(2);
            return size + 'M';
        } else {
            number = fileSize / (1024 * 1024 * 1024);
            size = number.toFixed(2);
            return size + 'GB';
        }
    }




}

//  用户
export class BeanUser {
    username: string;
    id: string;
    idCard: string;
    schoolId: string;
    schoolName: string;
}

//  学校
export class BeanSchool {
    schoolId: string;
    schoolName: string;
}

//  文件夹
export class BeanFolder {
    folderName: string;
    parentRef: string;
    identifier: string;
}

//  文件
export class BeanFile {
    identifier: string;
    parentRef: string;
    shared: boolean;
    idCard: string;
    username: string;
    fileName: string;
    memory: string;
    prefix: string;
    suffix: string;
    grades: number[];
    subjects: number[];
    type: string;
    path: string;
    date: Date;
}


/**
 * old classes below
 */
export class BodyFolder {
    uuid: string;
    folderName: string = '';
    parentRef: string;  //    父级文件夹uuid
}

export class BodyFile {

    uuid: string;
    parentRef: string;
    fileName: string;
    createAt: Date;
    updateAt: Date;

    grades: number[];
    subjects: number[];
    shared: boolean;
    worth: string;
    prefix: string;
    suffix: string;
    fileType: string;
    fileOldName: string;
    fileNewName: string;
}

export class BodyConfig {
    uuid: string;
    configType: string;
    score: string;
    percent: string;
    schoolId: string;
}

export class BodyFilter {
    fileName: string;
    grade: string;
    subject: string;
    fileType: string;
}

export class UserInfoParam {
    id: string;
    idCard: string;
    userName: string;
    schoolId: string;
    schoolName: string;
}

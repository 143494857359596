import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageConfig } from '../../model';

@Component({
  selector: 'app-orh-table-new',
  templateUrl: './orh-table-new.component.html',
  styleUrls: ['./orh-table-new.component.less']
})
export class OrhTableNewComponent implements OnInit {

  @Input() pageConfig: PageConfig = {
    total: 1,
    page: 1,
    limit: 12
  };
  @Input() slots: Array<string>;

  @Output() limitChange: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<any> = new EventEmitter();

  operation: boolean = false;
  search: boolean = false;
  Options:any = [12,24,36,48];
  constructor() {
  }

  pageTotals () {
    if (this.pageConfig) {
      let val = Math.ceil(this.pageConfig.total / this.pageConfig.limit)
      return val
    } else {
      return 1
    }
  }

  pageSizeChange (event: number): void {
    if (this.pageConfig) this.limitChange.emit(event)
  }

  pageIndexChange (event: number): void {
    if (this.pageConfig) this.pageChange.emit(event)
  }

  ngOnInit() {
    if (this.slots) {
      this.operation = this.slots.includes('operation');
      this.search = this.slots.includes('search');
    }
  }

}

/*
 * @Descripttion: 
 * @version: 
 * @Author: c-zhiqiang
 * @Date: 2020-11-02 14:37:12
 * @LastEditors: c-zhiqiang
 * @LastEditTime: 2020-11-27 12:04:16
 */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissionsService } from '../service/permissions/permissions.service';
import { NzMessageService } from 'ng-zorro-antd';
import { UtilsService } from '../service/utils/utils.service';
import { RequestService } from '../service/request/request.service'
import { EventService } from '../service/event/event.service';
declare var WxLogin: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    validateForm: FormGroup;
    PhoneForm: FormGroup;
    loading = false;
    isClock = true;
    isPhone: boolean = false;
    phoneShow: boolean = false;
    countdownTime: number = 60;
    isUser: boolean = false;
    showtimes: boolean = false;
    showphone: boolean = false;
    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private permiss: PermissionsService,
        private message: NzMessageService,
        private http: RequestService,
        private event: EventService,
        private cdr: ChangeDetectorRef
    ) { }

    submitForm(): void {
        const form: any = this.validateForm.controls;
        this.loading = true;

        if (form.username.value && form.password.value) {
            this.permiss.getToken(form.username.value, form.password.value).subscribe(
                (res: any) => {
                    console.log('res:', res);
                    const loadingId = this.message.loading('登录中...', { nzDuration: 0 }).messageId;
                    this.loading = false;
                    this.message.remove(loadingId);
                    // this.utils.getRegions();
                    if (res.needChangePassFlag === 1) {
                        localStorage.setItem('needChangePassFlag', String(1));
                    }
                },
                error => {
                    this.loading = false;
                    // this.message.remove(loadingId);
                    this.message.error('账户或者密码错误');
                    // this.validateForm.reset();
                },
            );
        } else {
            this.loading = false;
        }
    }
    QClick() {
        this.isPhone = !this.isPhone
    }

    phoneSumbit() {
        const form: any = this.PhoneForm.controls;
        this.loading = true;

        if (form.username.value && form.code.value) {
            this.permiss.getPhoneToken(form.username.value, form.code.value).subscribe(
                (res: any) => {

                    const loadingId = this.message.loading('登录中...', { nzDuration: 0 }).messageId;
                    this.loading = false;
                    this.message.remove(loadingId);
                    if (res.needChangePassFlag === 1) {
                        localStorage.setItem('needChangePassFlag', String(1));
                    }
                },
                error => {
                    this.loading = false;
                    // this.message.remove(loadingId);
                    this.message.error('账户或者密码错误');
                    // this.validateForm.reset();
                },
            );
        } else {
            this.loading = false;
        }
    }
    ngOnInit(): void {
        this.validateForm = this.fb.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true],
        });
        this.PhoneForm = this.fb.group({
            username: [null, [Validators.required]],
            code: [null, [Validators.required]],
        });
        this.QRrefresh();
    }

    sendCode() {

        let that = this


        if (this.PhoneForm.value.username) {
            this.http.get('base/sms/send_code?mobile=' + this.PhoneForm.value.username).subscribe(item => {
                if (item.code === 200) {
                    this.message.success('验证码已经发送到您的手机,请在3分钟内登录');

                    setTimeout(() => {
                        this.deleteCode();
                    }, 180000);

                    this.phoneShow = true;
                    let timeour = setInterval(() => {
                        that.countdownTime--;
                        if (this.countdownTime === 0) {
                            clearInterval(timeour);
                            this.phoneShow = false;
                        }

                        that.cdr.detectChanges()

                    }, 1000);
                }
            })
        }
    }
    switchClick() {
        this.isClock = !this.isClock;
        console.log('this.isClock', this.isClock);
        if (this.isClock) {
            this.isUser = true
        } else {
            this.isUser = false
        }

    }
    deleteCode() {
        this.http.get('base/sms/delete_code?mobile=' + this.PhoneForm.value.username).subscribe(item => {
            if (item.code === 200) {

            }
        })
    }
    // 刷新
    QRrefresh() {
        const url = `https://www.edu.mongol.pub/api/base/wechat/redirect/${window.location.host}/qr-callback`;
        const state = new Date().getTime() + Math.round(Math.random() * 10000000); //随机生成6位数字，后面备用
        // const url = window.location.protocol + '//' + window.location.host;
        console.log('刷新时域名:', url);
        const redirectUrl = encodeURI(url);  // 正式的
        setTimeout(() => {
            let obj = new WxLogin({
                id: 'login_container',
                appid: 'wx46cf3f8e19162a1d',
                scope: 'snsapi_login',
                redirect_uri: redirectUrl,
                response_type: 'code',
                state: state,
            });
            console.log('刷新二维码');
        }, 200);
    }
    sartName() {
        window.open('https://mgl.campus.orhonedu.com/login');
    }

}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { teachingDesign } from '../../../graphQl/preparation';
import { GraphqlQueryParams } from '../../../model';
import { GraphqlServiceService } from '../../../service/graphqlService/graphql-service.service';


@Component({
    selector: 'orh-teaching-desgin',
    templateUrl: './orh-teaching-desgin.component.html',
    styleUrls: ['./orh-teaching-desgin.component.scss']
})
export class OrhTeachingDesginComponent implements OnInit {

    @Input() desginData: any;
    data: any[] = []
    constructor(
        private graph: GraphqlServiceService,
        private message:NzMessageService
    ) { }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.desginData.currentValue) {
            this.desginData.map((ind: any) => {
                const param = {
                    query: teachingDesign,
                    variables: {
                        id: ind.fileId
                    },
                    policy: 'no-cache',
                    name: 'preparation'
                };

                this.getGql(param, (res:any) => {
                    if (res.data.teachingDesign.code === 200) {
                        let datas = res.data.teachingDesign.data
                        ind.content = datas.prepareLessonContent ? JSON.parse(datas.prepareLessonContent) : [];
                    }else{
                        this.message.error(res.data.teachingDesign.msg)
                    }
                });
                ind.fileCover = ind.fileCover;
                ind.fileId = ind.fileId;
                ind.filePath = ind.filePath;
                ind.fileTitle = ind.fileTitle;
                this.data.push(ind)
            })
        }

    }
    getGql(param: GraphqlQueryParams, callback: any): void {
        this.graph.GQuery(param).subscribe((res: any) => {
            callback(res);
        });
    }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestService } from '../../service/request/request.service'
import { NzMessageService } from 'ng-zorro-antd/message';
import { BusinessService } from '../../service/business/business.service';
import { PermissionsService } from '../../service/permissions/permissions.service';
import { BaseService } from '../../service/base/base.service';
import { UtilsService } from '../../service/utils/utils.service';

@Component({
    selector: 'app-qrcode-callback',
    templateUrl: './qrcode-callback.component.html',
    styleUrls: ['./qrcode-callback.component.less']
})
export class QRcodeCallbackComponent implements OnInit {

    onOff: boolean = false;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: RequestService,
        private message: NzMessageService,
        private business: BusinessService,
        private permiss: PermissionsService,
        private base: BaseService,
        private utils: UtilsService,
    ) { }

    ngOnInit() {
        // console.log('路由:', this.router);
        const code = this.router.url.split('?');
        // console.log('code:', code);
        this.callback(code[1]);
    }

    callback(data: any) {
        const url = `base/wechat/authorization?${data}`;
        this.http.get(url, data).subscribe(res => {
            console.log('扫码数据:', res);
            if (res.token) {
                // this.message.info(res.msg, {nzDuration: 5000});
                this.business.setItem('token', res.token);
                this.permiss.getUserInfo();
                // this.base.getGradeData();
                // this.base.getSubjectData();
                // this.base.getPeriodData();
               // this.utils.getRegions();
            } else {
                switch (Number(res.msg)) {
                    case 400001:
                        this.message.info('code已过期，请刷新二维码重试！', { nzDuration: 8000 });
                        this.goBack();
                        break;
                    case 400002:
                        this.message.info('微信端处理错误，请重新登陆！', { nzDuration: 8000 });
                        this.goBack();
                        break;
                    case 400003:
                        this.message.info('微信端处理错误，请重新登陆！', { nzDuration: 8000 });
                        this.goBack();
                        break;
                    case 400004:
                        this.onOff = true;
                        this.message.info('您的微信还未绑定，请绑定微信账号!', { nzDuration: 8000 });
                        break;
                    case 500001:
                        this.message.info('服务器未响应，请稍候再试！', { nzDuration: 8000 });
                        this.goBack();
                        break;
                }
            }
        });
    }

    // 返回
    goBack() {
        history.go(-1);
    }

}

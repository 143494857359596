import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-orh-affix',
    templateUrl: './orh-affix.component.html',
    styleUrls: ['./orh-affix.component.less']
})
export class OrhAffixComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    } Ï

    onChange(status: boolean) {
        console.log(status);
    }

}

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd';
import { gradesNewList } from 'src/app/graphQl/base/grades';
import { tagsList } from 'src/app/graphQl/book';
import { queryTeacherList } from 'src/app/graphQl/teachers/information';
import { BusinessService } from 'src/app/service/business/business.service';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { GRADE, PERIODS, SUBJECT } from 'src/app/utils/constant';

@Component({
    selector: 'orh-folder-share',
    templateUrl: './orh-folder-share.component.html',
    styleUrls: ['./orh-folder-share.component.less']
})
export class OrhFolderShareComponent implements OnInit {
    @Input() identifier:string ='';
    @Input() tagids:any[]=[]
    @Output() submitPost:EventEmitter<any> = new EventEmitter()
    sformGroup:FormGroup
    gradeList:any[] = [];
    subjectList:any[] = [];
    periodsList:any[] = [];
    tagsList:any[] = [];
    userId:any;
    isLoadingOne:boolean = false;
    constructor(
        private fb:FormBuilder,
        private business:BusinessService,
        private graph:GraphqlServiceService,
        private message:NzMessageService,
        private http:HttpClient
    ) { }

    ngOnInit() {
        this.getSubjectData();
        this.getTagList();
        this.sformGroup = this.fb.group({
            grade_id: [[]],
            period_id: [[]],
            subject_id: [[]],
            knowledge_id: [[]],
            textbook_id: [[]],
            chapter_id: [[]],
            tag_id: [[]]
        });
    }
    getSubjectData() {
        this.userId = this.business.getItem('userInfo').id;
        // 年级数据
        this.gradeList = this.business.getItem(GRADE)
        // 学科数据
        this.subjectList = this.business.getItem(SUBJECT)
        // 学段数据
        this.periodsList = this.business.getItem(PERIODS)
        this.getUser() 
    }
    getUser() {
        const queryParams = {
            query: queryTeacherList,
            variables: {
                page: 1,
                limit: 10,
                condition: {
                    user_id: this.userId
                }
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(queryParams).subscribe((res: any) => {
            const data = res.data.teachers.data[0];
            const _periods = [];
            const _grades = [];
            const _subjects = [];
            if (data.periods && data.periods.length > 0) {
                data.periods.forEach(v => {
                    _periods.push(v.id);
                });
                this.sformGroup.get('period_id')!.setValue(data.periods ? _periods : null);
                this.periodChange(_periods);
            }
            if (data.grades && data.grades.length > 0) {
                data.grades.forEach(v => {
                    _grades.push(v.id);
                });
                this.sformGroup.get('grade_id')!.setValue(data.grades ? _grades : null);
            }
            if (data.subjects && data.subjects.length > 0) {
                data.subjects.forEach(v => {
                    _subjects.push(v.id);
                });
                this.sformGroup.get('subject_id')!.setValue(
                    data.subjects ? _subjects : null
                );
            }
        });
    }
    // 学段改变事件
    periodChange(evt: any) {
        if(evt.length > 0){
            this.sformGroup.value.periodId = evt;
            const params = {
                query: gradesNewList,
                variables: {
                    page: 0,
                    limit: 0,
                    condition: {
                        period: evt
                    }
                }
            };
            this.graph.GQuery(params).subscribe((res: any) => {
                if (res.data.gradesNew.code === 200) {
                    this.gradeList = res.data.gradesNew.data
                }else{
                    this.message.error(res.data.gradesNew.msg)
                }
            });
        }
    }

    //课本
    textbooks(books: any) {
        let textbooks = [];
        let chapters = []
        books.map(item => {
            if (item.origin.type === 'textbook') {
                let index = textbooks.findIndex(res => res === item.origin.textbookId);
                if (index === -1) {
                    textbooks.push(item.origin.key)
                }

            }
            if (item.origin.type === 'chapters') {
                let texts = textbooks.findIndex(res => res === item.origin.textbookId);


                if (texts === -1) {
                    textbooks.push(item.origin.textbookId)
                }
                let index = chapters.findIndex(res => res === item.origin.key);
                if (index === -1) {
                    chapters.push(item.origin.key)
                }
            }
        })
        this.sformGroup.get('textbooks')!.setValue(textbooks)
        this.sformGroup.get('chapters')!.setValue(chapters)
    }

    // 标签数据
    getTagList(): void {
        const that = this;
        const params = {
            query: tagsList,
            variables: {
                page: 0,
                limit: 0,
                condition: {}
            }
        };
        that.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.tags.code === 200) {
                that.tagsList = res.data.tags.data
            }else{
                this.message.error(res.data.tags.msg)
            }
        });
    }
    shareSubmitForm() {
        this.isLoadingOne = true;
        let form = this.sformGroup.value;
        this.http.post(`api/resource/sharedFile/share`, {
            fuuid: this.identifier,
            gradeIds: form.grade_id,
            periodIds: form.period_id,
            knowledgeIds: form.knowledge_id,
            subjectIds: form.subject_id,
            textbookIds: form.textbook_id,
            chapterIds: form.chapter_id,
            tagIds: form.tag_id,
        }).subscribe((res: any) => {  
            if (res.code === 200 && res.msg === 'success') {
                this.message.create('success', '文件分享成功');
                this.submitPost.emit(true)
                this.isLoadingOne = false;
            } else {
                this.message.create('error', res.msg);
                this.submitPost.emit(false)
                this.isLoadingOne = false;
            }
            this.sformGroup.reset();  
        })
    }
    postuploadTag(tag: any) {

       this.sformGroup.get('tag_id')!.setValue(tag)
    }
}
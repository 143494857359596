import { Component, OnInit } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd';
import Setting from './config';
import { BusinessService } from './service/business/business.service';
import { UtilsService } from './service/utils/utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from './service/permissions/permissions.service';

declare var window;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    title = 'NgSmartCampusPC';
    private config: { version: string }
    constructor(
        private iconservice: NzIconService,
        private business: BusinessService,
        private util: UtilsService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private permissions: PermissionsService
    ) {
        this.route.queryParams.subscribe(res => {
            if (res.token) {
                let token = String(res.token);
                this.business.setItem('token', token);
                //this.permissions.getUser()
            }

        });
        // 自定义图标实现
        this.iconservice.fetchFromIconfont({
            scriptUrl: Setting.iconUrl,
        });
    }

    ngOnInit() {
        this.config = require('./../assets/config.json');
        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache');

        this.httpClient
            .get<{ version: string }>('/assets/config.json', { headers })
            .subscribe(config => {
                if (config.version !== this.config.version) {
                    this.httpClient
                        .get('', { headers, responseType: 'text' })
                        .subscribe(() => location.reload());
                }
            });

        // const token = localStorage.getItem('token');
        // let id = this.message.loading('主题加载中...').messageId;

        // 初始化保存的主题
        // let backgroundColors = this.business.getItem('userInfo').school.settings ? this.business.getItem('userInfo').school.settings.themeSettings.zh : '#206F53';
        this.business.getcolos().subscribe(item => {
            const backgroundColors = item.school ? (item.school.settings ? item.school.settings.themeSettings.zh : '#206F53') : '';
            const color1 = this.util.color(backgroundColors, 0.05, true);
            const color2 = this.util.color(backgroundColors, 0.8, true);
            const color3 = this.util.color(backgroundColors, 0.38, true);
            window.less.modifyVars({
                '@primary-color': item,
                '@orh-home-menu-list': item,
                '--omc-color-primary': backgroundColors,
                '--omc-color-primary-rgb': color1,
                '--omc-color-primary-contrast': '#ffffff',
                '--omc-color-primary-contrast-rgb': color2,
                '--omc-color-primary-background-rgb': color3
            })
                .then(() => {
                    // this.message.remove(id);
                });

        })

    }
}

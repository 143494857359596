import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as echarts from 'echarts';
import { Observable } from 'apollo-link';

@Component({
    selector: 'orh-echart',
    templateUrl: './orh-echart.component.html',
    styleUrls: ['./orh-echart.component.less']
})
export class OrhEchartComponent implements OnInit {

    @Input() options: any;
    @Input() height: string;
    @Output() dataInit: EventEmitter<any> = new EventEmitter();
    optionsVal: any = {};
    // initOptsVal: initOptsModel = {
    //   renderer: 'svg',
    //   width: 300,
    //   height: 300
    // };
    // window.onresize = myChart.resize;
    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue) {
            this.optionsVal = changes.options.currentValue;
        }
    }

    ngOnInit() {
        // if (this.initOpts) {
        //   this.initOptsVal = this.initOpts;
        // }
    }
    getStyle(el, name) {
        if (window.getComputedStyle) {
            return window.getComputedStyle(el, null);
        } else {
            return el.currentStyle;
        }
    }
    chartssize(container, charts) {
        function getStyle(el, name) {
            if (window.getComputedStyle) {
                return window.getComputedStyle(el, null);
            } else {
                return el.currentStyle;
            }
        }
        let wi = getStyle(container, 'width').width;
        let hi = getStyle(container, 'height').height;
        charts.style.width = wi
        charts.style.height = hi
    }

    onChartInit(ec: any) {
        this.dataInit.emit(ec);
    }
}


import gql from 'graphql-tag';

/**
 * 查询学科列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const subjectList = gql`
	query ($page: Int, $limit: Int){
		subjects (page: $page, limit: $limit){
			code
            msg
            total
            data {
                id
                subject_name {
                    en
                    mn
                    zh
                }
                subject_slug
                subject_order
                subject_number
                type{
                    id
                    name{
                        zh
                    }
                    dictionary_value
                }
                period {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                grade {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                coverImageZh
                coverImageMn
            }
		}
	}
`
const subjectLists = gql`
	query ($page: Int, $limit: Int){
		subjects (page: $page, limit: $limit){
			code
            msg
            total
            data {
                id
                mark
                subject_name {
                    en
                    mn
                    zh
                }
                type{
                    name{
                      zh
                    }
                }
                subject_slug
                subject_number
                period {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                grade {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                
                coverImageZh
                coverImageMn
            }
		}
	}
`

const subjectNewLists = gql`
	query ($page: Int, $limit: Int){
		subjectsNew (page: $page, limit: $limit){
			code
            msg
            total
            data {
                id
                type{
                    name{
                        zh
                    }
                }
                subject_name {
                    en
                    mn
                    zh
                }
                subject_slug
                subject_number
                period {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                grade {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                
            }
		}
	}
`

const findSubjectData = gql`
    query($id: Int!){
        subject (id: $id) {
            id
            subject_name {
                en
                mn
                zh
            }
            subject_slug
            subject_order
            subject_number
            mark
            type{
                id
                name{
                    zh
                }
                dictionary_value
            }
            period {
                id
            }
            grade {
                id
            }
            coverImageZh
            coverImageMn
        }
    }
`

/**
 * 学科添加数据
 */
const addSubjectData = gql`
    mutation addSubject(
        $inputData: SubjectInput){
    addSubject(inputData: $inputData){
        code
        msg
    }
  }
`

/**
 * 学科信息删除
 * @param id 学科id
 */
const delSubjectData = gql`
	mutation deleteSubject($id: [Int]!){
		deleteSubject(id: $id){
			code
			msg
		}
	}
`
export {subjectLists, subjectList, addSubjectData, findSubjectData, delSubjectData, subjectNewLists}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzFormatEmitEvent, NzMessageService, NzModalService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { Semesters } from 'src/app/graphQl/moral/education-data';
import { queryTeacherList } from 'src/app/graphQl/teachers/information';

import { BusinessService } from 'src/app/service/business/business.service';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import { RequestService } from 'src/app/service/request/request.service';
import { PERIODS, SUBJECT } from 'src/app/utils/constant';

@Component({
  selector: 'orh-questions-add',
  templateUrl: './orh-questions-add.component.html',
  styleUrls: ['./orh-questions-add.component.less']
})
export class OrhQuestionsAddComponent implements OnInit {

    form: FormGroup;
    formChildren: FormGroup;
    @Output() getColse: EventEmitter<any> = new EventEmitter();
    detailShow: boolean = true;
    queType: string = '';
    queName: string = '';
    nodes: any[] = [];

    shortValue: string ='';
    type: any[] = [
        {
            value: 1,
            label: '试卷知识点'
        },
        {
            value: 2,
            label: '课本知识点'
        }
    ]
    constructor(
        private fb: FormBuilder,
        private graph: GraphqlServiceService,
        private business: BusinessService,
        private message: NzMessageService,
        private req: RequestService,
        private modalService:NzModalService
    ) {
        this.form = this.fb.group({
            explanation: [null],
            lang: ['mn'],
            optnum: [4],
            ques_type: [null],
            score: [1],
            status: [1],
            acayear: [null, Validators.required],
            class: [null],
            grade: [null],
            knows: [[]],
            stage: [null, Validators.required],
            stem: [null, Validators.required],
            subject: [null, Validators.required],
            type: [1],
            children: [[]],
            value: [null]
        });
        this.formChildren = this.fb.group({
            ques_type: ['radio'],
            optnum: [4],
        });

    }

    qustypes: any[] = [
        {
            value: 'radio',
            label: '单选题'
        },
        {
            value: 'multiple',
            label: '多选题'
        },
        {
            value: 'stuff',
            label: '填空题'
        },
        {
            value: 'short',
            label: '简答题'
        },
        {
            value: 'judgment',
            label: '判断题'
        },
        {
            value: 'combination',
            label: '组合题'
        }
    ];
    qustype: any[] = [
        {
            value: 'radio',
            label: '单选题'
        },
        {
            value: 'multiple',
            label: '多选题'
        },
        {
            value: 'stuff',
            label: '填空题'
        },
        {
            value: 'short',
            label: '简答题'
        },
        {
            value: 'judgment',
            label: '判断题'
        }
    ];
    subjectData: any[] = []
    semesterList: any[] = [];
    periodData: any[] = [];
    radioAnswer: any[] = [];
    stuffList: any[] = [];
    childrenList: any[] = [];
    knowsName: any[] = [];
    combinationIndex: number = -1;
    getSetData: any;
    planLang: any;
    showType: boolean = false;
    knowShow: boolean = false;
    selected: number = 1;
    contents: null;
    judgmentList: any[] = [
        {
            value: 0,
            label: '错'
        },
        {
            value: 1,
            label: '对'
        },
    ];
    perintID: string = '';
    userId: string = '';
    langs: any;
    

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;

        this.getSemesters();
        // 学科数据
        this.subjectData = this.business.getItem(SUBJECT).map((item: any) => {
            return {
                value: item.subject_number,
                label: item.subject_name.zh ? item.subject_name.zh : item.subject_name.mn
            };
        });
       
        
        // 学段数据
        this.periodData = this.business.getItem(PERIODS).map((item: any) => {
            return {
                value: item.mark,
                label: item.name.zh ? item.name.zh : item.name.mn
            };
        });
        console.log(this.periodData);
        this.getUser()
    };
    
    //获取当前老师的年级和学段
    getUser() {
        const queryParams = {
            query: queryTeacherList,
            variables: {
                page: 1,
                limit: 10,
                condition: {
                    user_id: this.userId
                }
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(queryParams).subscribe((res: any) => {
            const data = res.data.teachers.data[0];
            if (data.periods && data.periods.length > 0) {
                this.form.get('stage')!.setValue(data.periods ? data.periods[0].mark : null);
            }

            if (data.subjects && data.subjects.length > 0) {
                this.form.get('subject')!.setValue(
                    data.subjects ? data.subjects[0].subject_number : null
                );
            }
            if (data.periods && data.subjects) {
                // this.getKnow(1)
            }
        });

    }
    getKnow(num: number) {
        if (this.form.value.stage && this.form.value.subject) {
            this.req.paperGet('knowledge', {
                type: num,
                stage: this.form.value.stage,
                subject: this.form.value.subject,
                // textbook: 'new'
            }).subscribe((item: any) => {

                this.nodes = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
        } else {
            // this.message.error(this.planLang.select_grade_on_subject);
        }
    }
    getKnowNext(num: number) {
        if (this.form.value.stage && this.form.value.subject) {
            this.req.paperGet('knowledge', {
                type: num,
                stage: this.form.value.stage,
                subject: this.form.value.subject,
                // textbook: 'new'
            }).subscribe((item: any) => {

                this.nodesNext = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
        } else {
            // this.message.error(this.planLang.select_grade_on_subject);
        }
    }
    
    nodesNext: any[] = [];
    selectShow: boolean = true;
    selectType(type: number) {
        this.selectShow = !this.selectShow;
        this.selected = type;

    }
    //展开
    nzEvents(event: Required<NzFormatEmitEvent>) {
        this.perintID = event.node.key;
        if (event.eventName === 'expand') {
            const node = event.node;
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode().then(data => {
                    node.addChildren(data);
                });
            }
        }
    }
    loadNode(): Promise<NzTreeNodeOptions[]> {
        return new Promise(resolve => {
            let datas = []
            this.req.paperGet('knowledge?pid=' + this.perintID).subscribe((item: any) => {
                datas = item.map((res: any) => {
                    return {
                        key: res._id,
                        title: res.know,
                        isLeaf: !res.hasChild,
                        isExpanded: false,
                        children: []
                    }
                })
            })
            setTimeout(
                () =>

                    resolve(datas),
                1000
            );
        });
    }
    nzEvent(event: any, num: number) {
        if (event.eventName === 'click') {

            let neowid = this.form.value.knows;
            let index = neowid.indexOf(event.node.key);
            if (index === -1) {
                neowid.push(event.node.key)
                this.knowsName.push({
                    title: event.node._title,
                    value: event.node.key
                })

            } else if (index >= 0) {
                let indexName = this.knowsName.findIndex((item: any) => item.value === event.node.key);
                neowid.splice(index, 1);
                this.knowsName.splice(indexName, 1);

            }

            this.form.get('knows')!.setValue(neowid);
        }
    }
    //取消选中的知识点
    closeKnowsName(data: any) {

        let index = this.knowsName.findIndex(item => item.value === data.value);

        this.knowsName.splice(index, 1);


        this.nodes = this.nodes.map(item => {
            if (item.key === data.value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, data.value) : []
            }
            return item
        });
        this.nodesNext = this.nodesNext.map(item => {
            if (item.key === data.value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, data.value) : []
            }
            return item
        })
        this.getSelected(data.value)
    }
    nodeChildren(data: any[], value: string) {
        return data.map((item: any) => {
            if (item.key === value) {
                item.selected = false;
            } else {
                item.children = item.children.length > 0 ? this.nodeChildren(item.children, value) : []
            }
            return item
        })
    }
    //循环子集
    getSelected(data) {
        this.nodes.includes(item => {
            this.getSelected(item)
            if (item.key === data.value) {
                item.selected = false;
            }
            return item
        })
    }

    //学期
    getSemesters(): void {
        this.semesterList = [];
        const params = {
            query: Semesters,
            variables: {
                page: 0,
                limit: 0,
                condition: {}
            },
            policy: 'no-cache'
        };
        this.graph.GQuery(params).subscribe((res: any) => {
            if (res.data.Semesters.code === 200) {
                const data = res.data.Semesters.data.find(el => el.is_current === 0);
                console.log(data);

                this.semesterList = res.data.Semesters.data.map((item: any) => {
                    return {
                        value: item.zujuanCode,
                        label: item.name.mn ? item.name.mn : item.name.zh
                    }
                })
                this.form.get('acayear')!.setValue(data.zujuanCode);
            }else{
                
            }
        })
    }

    //设置答案
    getAnswer() {
        this.detailShow = !this.detailShow;
        this.radioAnswer = [];
        this.childrenList = []
        if (this.queType === 'radio' || this.queType === 'multiple') {
            for (let index = 0; index < this.form.value.optnum; index++) {
                const element = String.fromCharCode(index + 65)
                this.radioAnswer.push({
                    value: element,
                    label: element
                })
            }


        } else if (this.queType === 'stuff') {
            for (let index = 0; index < this.form.value.optnum; index++) {
                this.stuffList.push({
                    v: ''
                })
            }

        } else if (this.queType === 'judgment') {
            this.judgmentList = [
                {
                    value: 0,
                    label: '错'
                },
                {
                    value: 1,
                    label: '对'
                },
            ]
        } else if (this.queType === 'combination') {
            for (let index = 1; index < this.form.value.optnum + 1; index++) {
                this.childrenList.push({
                    label: '答案' + index,
                    ques_type: 'radio',
                    optnum: 4,
                    value: '',
                    score: 1,
                    radios: [
                        {
                            value: 'A', label: 'A'
                        },
                        {
                            value: 'B', label: 'B'
                        },
                        {
                            value: 'C', label: 'C'
                        },
                        {
                            value: 'D', label: 'D'
                        }
                    ]
                })
            }
        }

    }
    //更换组合题的小题题型
    getSetType(item: any, index: number) {
        console.log('index', this.childrenList);

        this.combinationIndex = index;
        this.getSetData = item;
        this.showType = true;

        this.formChildren.get('ques_type')!.setValue(item.ques_type);
        this.formChildren.get('optnum')!.setValue(item.optnum);

    }
    //多选题赋值
    updateSingleChecked(event){
        this.shortValue = event
    }
    //
    updateAllChecked(ind:number,event:any){
        this.childrenList[ind].value = event
        
    }
    //确定选择好的题型
    omcOk() {
        this.queType = 'combination';
        let form = this.formChildren.value;
        this.radioAnswer = []
        this.childrenAnswer = [];
        if (form.ques_type === 'radio') {
            for (let index = 0; index < form.optnum; index++) {
                const element = String.fromCharCode(index + 65)
                this.radioAnswer.push({
                    value: element,
                    label: element
                })
            }

        } else if (form.ques_type === 'multiple') {
            for (let index = 0; index < form.optnum; index++) {
                const element = String.fromCharCode(index + 65)
                this.childrenAnswer.push({
                    value: element,
                    label: element
                })
            }

        } else if (form.ques_type === 'stuff') {
            for (let index = 0; index < form.optnum; index++) {
                let stuff = []
                stuff.push({
                    v: ''
                })
                this.childrenList[this.combinationIndex].value = stuff;

            }

        }
        this.childrenList[this.combinationIndex].ques_type = form.ques_type;
        this.childrenList[this.combinationIndex].optnum = form.optnum;

        this.getChildren(this.childrenList)

    }
    childrenAnswer: any[] = [];

    //重新组合组合体
    getChildren(data) {
        this.childrenList = [];

        data.map((item: any) => {
            let radioData = []
            if (item.ques_type === 'radio') {
                for (let index = 0; index < item.optnum; index++) {
                    const element = String.fromCharCode(index + 65)
                    radioData.push({
                        value: element,
                        label: element
                    })
                }
                item.radios = radioData;
            } else if (item.ques_type === 'multiple') {
                for (let index = 0; index < item.optnum; index++) {
                    const element = String.fromCharCode(index + 65)
                    radioData.push({
                        value: element,
                        label: element
                    })
                }
                item.checkbox = radioData
            }
            else if (item.ques_type === 'stuff') {
                let stuff = [];
                for (let index = 1; index < item.optnum + 1; index++) {

                    stuff.push({
                        v: ''
                    })
                    item.value = stuff;
                }
            }
            this.childrenList.push(item)

        })
        this.showType = false;
    }
    getConcal(event) {
        let div = document.getElementById('knowwrap');

        if (!event.path.includes(div)) {
            this.knowShow = false;
        }

    }
    //选择知识点
    omcFocus() {
        this.knowShow = true;
        this.getKnow(1)
        this.getKnowNext(2);
    }
    //选择题型
    changeType(type: string) {
        this.queType = type;
       
        this.queName = this.qustypes.find(item => item.value === type).label;
    }

    //提交数据
    submit(type: number) {
        const { value, valid } = this.form;
        if (valid) {
            if(type === 1){
                this.modalService.confirm({
                    nzTitle: '确定只保存吗？',
                    nzContent: '只保存题的话在题库里不展示，只有发布的才在题库里展示',
                    nzOkText: '确定',
                    nzOkType: 'danger',
                    nzOnOk: () => this.getSubmit(type),
                    nzCancelText: '取消',
                    nzOnCancel: () => console.log('Cancel')
                  });
            }
            // this.getSubmit(type)
        } else {
            for (const i in this.form.controls) {
                this.form.controls[i].markAsDirty();
                this.form.controls[i].updateValueAndValidity();
            }
        }


    }
    getSubmit(type: number) {
        let form = this.form.value;


        if (form.ques_type === 'stuff') {
            form.value = this.stuffList
        }
        if (form.ques_type === 'short' || form.ques_type === 'radio' || form.ques_type === 'multiple' || form.ques_type === 'judgment') {
            form.value = this.shortValue
        }
        let forms: any;
        for (let index = 0; index < this.childrenList.length; index++) {
            if (this.childrenList[index].checkbox) {
                delete this.childrenList[index].checkbox
            };
            if (this.childrenList[index].radios) {
                delete this.childrenList[index].radios
            };

        }
        if (form.ques_type === 'combination') {
            forms = {
                children: this.childrenList,
                explanation: form.explanation,
                lang: 'cn',
                optnum: form.optnum,
                ques_type: form.ques_type,
                score: form.score,
                status: type,
                stem: form.stem,
                subject: form.subject,
                type: form.type,
                value: form.value,
                others: {
                    acayear: form.acayear,
                    grade: form.stage,
                    knows: form.knows,
                    stage: form.stage,
                    class: []
                }
            }
        } else {
            forms = {
                explanation: form.explanation,
                lang: 'cn',
                optnum: form.optnum,
                ques_type: form.ques_type,
                score: form.score,
                status: type,
                stem: form.stem,
                subject: form.subject,
                type: form.type,
                value: form.value,
                others: {
                    acayear: form.acayear,
                    grade: form.stage,
                    knows: form.knows,
                    stage: form.stage,
                    class: []
                }
            }
        }
        this.req.paperPost('quesbank', forms).subscribe((item: any) => {
            if (item) {
                this.message.success('添加成功');
                this.getColse.emit(true)
            }

        })
    }
    changeCourse(grade: string) {
        if (this.form.value.subject) {
            this.getKnow(1);
            this.getKnowNext(2)
        }
    }
    changeSubject(subject: string) {
        if (this.form.value.stage) {
            this.getKnow(1);
            this.getKnowNext(2)
        }
    }

    closeSubmit() {
        this.detailShow = !this.detailShow;
    }
    omcOnCancel() {

    }
}

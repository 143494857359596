import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { tagsData } from '../../graphQl/base/tag';
import { BusinessService } from '../../service/business/business.service';
import { GraphqlServiceService } from '../../service/graphqlService/graphql-service.service';
import { HashService } from '../../service/hash/hash.service';
import { RequestService } from '../../service/request/request.service';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'orh-image-folder',
  templateUrl: './orh-image-folder.component.html',
  styleUrls: ['./orh-image-folder.component.less']
})
export class OrhImageFolderComponent implements OnInit {
    @Input() fileType: string;
    @Output() dbclick: EventEmitter<any> = new EventEmitter();
    dataList: any[] = [];
    checkNum: number = -1;
    userId: string = '';
    selectNum:number=-1;
    fileInfo:any;
    previewDrawerShow:boolean = false;
    imageURL: string;
    cropperImg = false;
    transform: ImageTransform = {};
    isuploadTag:boolean = false;
    progress:number = 0;
    uploadList:any[] = [];
    tags:any[]=[];
    paramsData: any = {
        page: 1,
        limit: 10000,
        orderBy: 'CreateAt',
        sortBy: 'desc',
        path: null,
        fileType: null
    }
    parentRef:string =''
    constructor(
        private date: DatePipe,
        private http: RequestService,
        private business: BusinessService,
        private message:NzMessageService,
        private fileHash: HashService,
        private graph:GraphqlServiceService
    ) { }

    ngOnInit() {
        // this.userId = this.business.getItem('userInfo').id;
        // this.paramsData.path = '/' + this.userId + '/base';
        // this.getFilter();
        this.getParentId()
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.paramsData.fileType = this.fileType;
        this.userId = this.business.getItem('userInfo').id;
        this.paramsData.path = '/' + this.userId + '/base';
        this.getFilter()
    }
    // 获取底层文件
    getParentId() {
        this.http.post('resource/file/myfiles', {
            path: '/' + this.userId
        }).subscribe((res: any) => {
            if (res.code === 200) {
                this.parentRef = res.data.filter((item:any)=>item.name === 'base')[0].properties['sr:identifier']
            }else{
                this.message.error(res.msg)
            }
        });
    }
    getTagList(): void {
        let that = this;
        const params = {
            query: tagsData,
            variables: {
                page: 1,
                limit: 1000,
                condition: {
                    lavel:'system'
                }
            },
        };
        that.graph.GQuery(params).subscribe((res: any) => {
            let tag:any
            if(this.fileType ==='image'){
                tag = res.data.tags.data.filter((item:any)=>item.name.mn === 'ᠵᠢᠷᠤᠭ')[0].id;
            }else if(this.fileType ==='video'){
                tag = res.data.tags.data.filter((item:any)=>item.name.mn === 'ᠳᠦᠷᠰᠦ ᠪᠢᠴᠢᠯᠭᠡ')[0].id
            }else if(this.fileType ==='audio'){
                tag = res.data.tags.data.filter((item:any)=>item.name.mn === 'ᠳᠠᠭᠤ ᠪᠢᠴᠢᠯᠭᠡ')[0].id
            }
            that.tags.push(tag)
        });
    }
    // 文件类型筛选
    getFilter() {
        this.dataList = [];
        this.http.post('resource/file/filter', this.paramsData).subscribe((res) => {
            if (res.code === 200) {
                const datas = [];
                res.data.map((el) => {
                    if (el.type !== 'sr:smartfolder') {
                        datas.push({
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            osspath: el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null,
                            properties: el.properties,
                            status: true,
                            checked: false,
                        });
                    } else {
                        return false;
                    }

                });
                this.dataList = datas;
            }else{
                this.message.error(res.msg)
            }
        });
    }
    
    //数据列表
    handleType2(el: any) {
        if (el.type === 'sr:smartfolder') {
            // 这个绝对是文件夹
            return 'iconFolder';
        } else {
            return this.handelImg(el);
        }
    }

    // 处理文件类型缩略图
    handelImg(data:any) {
        let type = data.mn_type

        let tIcon = 'iconText_File';
        if (type !== null) {
            switch (true) {
                case /image/i.test(type):
                    tIcon = 'iconJPG';
                    break;
                case /video/i.test(type):
                    tIcon = 'iconVideo_File';
                    break;
                case /audio/i.test(type):
                    tIcon = 'iconMP3';
                    break;
                case /word/i.test(type):
                    tIcon = 'iconDOC';
                    break;
                case /excel/i.test(type):
                    tIcon = 'iconXLS';
                    break;
                case /ppt/i.test(type):
                    tIcon = 'iconPPT';
                    break;
                case /pdf/i.test(type):
                    tIcon = 'iconPDF';
                    break;
                case /zip/i.test(type):
                    tIcon = 'iconZIP';
                    break;
                case /text/i.test(type):
                    tIcon = 'iconTXT';
                    break;
                default:
                    tIcon = 'iconText_File';
            }
        }
        return tIcon;
    }
    clickCheck(data: boolean, items: any,ind:number) {
        this.selectNum = ind
        this.dataList.map((item: any) => {
            if (item.parentRef === items.parentRef) {
                return item.checked = true;
            } else {
                return item.checked = false;
            }
        })
        this.dbclick.emit(items)
    }
    getImage(data:any){
        const objectName = data.osspath;
        this.http.post('resource/file/download', {
            objectName,
            newName: data.name
        }).subscribe((item) => {
            this.imageURL = item;
            this.cropperImg = true;
        });
    }
    // 获取上传的文件名称
    custom = (res: any[]) => {
        this.isuploadTag = false;
        this.getTagList();
        for (let i = 0; i < res.length; i++) {
            const data = {
                name: res[i].name,
                progress: 0,
                type: 'primary',
                status: false
            };
            this.uploadList.push(data);
            this.getFileHash(res[i], data);
        }
    }

    getFileHash(file: any, data: any): void {
        this.fileHash.getHash(file).then(str => {
            const name = file.name;
            this.http.get('resource/file/upload/url', {
                objectName: str
            }).subscribe((el: any) => {
                const url = el.data;
                const newUrl = url.replace('http', 'https');
                if (el.extra && el.extra.status) {
                    data.progress = 100;
                    this.uploadFileSave(name, str, data);
                } else {
                    this.uploadFile(file, newUrl, name, str, data);
                }
            });
        });
    }
    // 获取上传进度条
    uploadFile(event: any, url: string, name: string, hashStr: string, data: any): void {
        const xhrs = new XMLHttpRequest();
        xhrs.open('put', url, true);
        xhrs.setRequestHeader('Content-Type', 'application/octet-stream');
        xhrs.onreadystatechange = (e: any) => {
            if (xhrs.readyState === 4) {
                this.uploadFileSave(name, hashStr, data);
            }
        };
        xhrs.upload.onprogress = (ev: any) => {
            if (ev.lengthComputable) {
                const progress = Math.round(100 * ev.loaded / ev.total);
                data.progress = progress;
            }
        };
        xhrs.send(event);
    }

    uploadFileSave(fileName: string, objectName: string, data: any) {
        this.http.post('resource/file/upload/save', {
            objectName,
            fileName,
            parentRef: this.parentRef
        }).subscribe((res: any) => {
            if (res.code === 200) {
                this.handleUploadList();
                this.postTagData(res.data.properties['sr:identifier'])
                let params = {
                    status: true,
                    name: res.data.name,
                    type: res.data.type,
                    mime_type: res.data.properties['sr:fileType'] ? res.data.properties['sr:fileType'] : '',
                    created: this.date.transform(res.data.created, 'yyyy-MM-dd'),
                    size: res.data.properties['sr:fileSize'] ? res.data.properties['sr:fileSize'] : '',
                    parentRef: res.data.properties['sr:identifier'] ? res.data.properties['sr:identifier'] : '',
                    osspath: res.data.properties['sr:osspath'] ? res.data.properties['sr:osspath'] : '',
                    thumbnail: res.data.properties['sr:thumbnail'] ? res.data.properties['sr:thumbnail'] : null,
                    convertStatus: res.data.properties['sr:convertStatus']
                }
               
                this.getFilter()
                // this.checkedChange(true, params)
            } else {
                data.type = 'error';
                this.message.error(res.msg);
                this.handleUploadList();
            }
            // this.cdr.detectChanges();
        });
    }

    handleUploadList(): void {
        const bool = this.uploadList.find(el => el.progress !== 100);
        if (!bool) {
            const data = this.uploadList.filter(el => el.type === 'error');
            this.uploadList = [...data];
            //this.uploadChange.emit(true);
            // this.getDataList();
        }
    }
 
    postTagData(uuid: string) {
        this.http.get('resource/file/tag/save', {
            tagIds: this.tags,
            uuids: uuid
        }).subscribe((item) => {
            console.log(item);
            if (item.code === 200) {

            }
        })
    }
    
    handleclick(data: any) {
        this.fileInfo= data
        this.previewDrawerShow = true

    }
    //鼠标移上事件
    showHint(num: number) {
        this.checkNum = num
    }

    //鼠标移出事件
    hidenHint() {
        this.checkNum = -1
    }
    close(){
        this.previewDrawerShow = false
    }
}

import {Component, OnInit, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { BusinessService } from 'src/app/service/business/business.service';
import { HttpService } from '../../service/http/http.service';

@Component({
    selector: 'orh-single-lesson',
    styleUrls: ['./single-lesson.component.scss'],
    template: `
        <div class="my-prepare-lesson" ng-container>
            <div class="lesson-header">
               
                
                    
               
                <div class="lesson-selected">
                    共 {{selectTotal}} 条
                </div>
            </div>
            <div class="lesson-content">
                <div class="lesson-content-body">
                    <div class="lesson-content-item" *ngFor="let item of list;">
                        <div class="item-content">
                            <img [src]="item.info.coverFile ? item.info.coverFile : imgUrl" style="object-fit: cover;" [style.width.%]="100"
                                 [style.height.%]="100">
                            <div class="item-content-title">{{ item.info.title }}</div>
                            <div class="item-content-check">
                                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="checkChange()">
                </label>
                            </div>
                            <div class="item-content-detail" (click)="lessonDetail(item)">
                                <orh-sider-icon icon="iconshoucang3" size="16" color="#000"></orh-sider-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lesson-pages">
               
                <nz-pagination [(nzTotal)]="total" [(nzPageIndex)]="page" [(nzPageSize)]="limit"
            (nzPageIndexChange)="pageChange($event)">
        </nz-pagination>
            </div>
        </div>
    `
})

export class SingleLessonComponent implements OnInit {
    // <orh-button [backgroundColor]="backgroundColor" [icon]="icons" [filename]="'创建'" (click)="addLesson()">
    // </orh-button>
    @Output()
    orhChange: EventEmitter<any> = new EventEmitter();

    public page = 1;
    public limit = 12;
    public total = 0;
    public list = [];
    public imgUrl = '/assets/image/course.jpg';
    public selectTotal = 0;
    icons = 'iconxinjian';
    userId:any;
    backgroundColor: any = {
        background: `linear-gradient(-90deg,rgba(255,192,70,1),rgba(245,144,0,1))`,
        boxShadow: '0px 10px 10px 0px rgba(245,161,0,0.38)'
    };
    constructor(private http: HttpService,
        private business:BusinessService,
        private message:NzMessageService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;
       
        this.getList();
    }

    getList(): void {
        const param = {
            url: 'cms/node/list',
            data: {
                page: this.page,
                limit: this.limit,
                sort: 'createdAt.desc',
                nodeType: 'lesson',
                createdBy:this.userId,
                token:''
            }
        };
        this.http.get(param).subscribe(res => {
            if (res.code === 200) {
                this.list = res.data.content.map(el => {
                    return {
                        checked: false,
                        info: el
                    };
                });
                console.log(this.list);
                
                this.total = res.data.totalElements;
                this.cdr.detectChanges();
            }else{
                this.message.error(res.msg)
            }
        });
    }

    refreshData(): void {
        this.list = [];
        this.getList();
    }

    pageChange(event: number): void {
        this.list = [];
        this.page = event;
        this.getList();
    }

    lessonDetail(event: any): void {
        console.log(event, '8877');
    }

    addLesson(): void {
        window.open('/pages/single/my-single');
    }

    checkChange(): void {
        const data = this.list.filter(el => el.checked);
        this.selectTotal = data.length;
        this.orhChange.emit(data);
    }

}

/*
 * @Author: 李修闻
 * @Date: 2019-09-12 11:02:26
 * @Last Modified by: 李修闻
 * @Last Modified time: 2019-09-12 15:37:31
 */
import gql from 'graphql-tag';

/**
 * 查询职务列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const queryList = gql`
	query ($page: Int!, $limit: Int! ,$id:Int,$parent_id:Int,$duties_name:String,$system_default:Int),{
		Duties (page: $page, limit: $limit,condition:{
			id:$id
			parent_id:$parent_id
			duties_name:$duties_name
			system_default:$system_default
		}){
            code
            msg
            total
            data {
				id
				duties_name {
					en
					mn
					zh
				}
				duties_order
				parent{
					id
					duties_name {
						en
						mn
						zh
					}
				}
				level
            }
		}
	}
`
/**
 * 职务删除
 * @param id 学段id
 */
const delData = gql`
	mutation deleteDuty($id: [Int]!){
		deleteDuty(id: $id){
			code
			msg
		}
	}
`
/**
 * 职务添加数据
 */
const saveData = gql`
	mutation addDuty(
        $duties_name: LangInput
		$duties_order:Int
		$parent_id:Int
		$level:Int
		$system_default:Int
	){
		addDuty(inputData: {
			duties_name:$duties_name
			duties_order:$duties_order
			parent_id:$parent_id
			level:$level
			system_default:$system_default
		}){
			code
			msg
		}
	}
`
const lookData = gql`
query ($id:Int){
	Duties (condition:{
		id:$id
	}){
		code
		msg
		total
		data {
			id
			duties_name {
				en
				mn
				zh
			}
			duties_order
			parent{
				id
				duties_name {
					en
					mn
					zh
				}
			}
			level
		}
	}
}
`
/**
 * 权限修改数据
 */
const editData = gql`
mutation addDuty(
	$id: Int
	$duties_name: LangInput
	$duties_order:Int
	$parent_id:Int
	$level:Int
){
	addDuty(inputData: {
		id:$id
		duties_name:$duties_name
		duties_order:$duties_order
		parent_id:$parent_id
		level:$level
	}){
		code
		msg
	}
}
`
/**
 * 根据职务筛选人员
 */
const queryDepartmentUsers = gql`
	query ($page: Int!, $limit: Int! ,$record_id:Int,$user_id:Int,$duty_id:Int,$department_id:Int,$is_leaders:Int,$leave_status:boolean),{
		Duties (page: $page, limit: $limit,condition:{
			duty_id:$duty_id
		}){
            code
            msg
            total
			data{
				id
				user_name{
				  zh
				}
			  }
		}
	}
`
export { queryList, delData, saveData, editData,lookData,queryDepartmentUsers}
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService , UploadFile, UploadFilter} from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addLearningExperience, findlearningExperience } from 'src/app/graphQl/teachers/teacher-profile/jop';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'learning-experience-operation',
  templateUrl: './learning-experience-operation.component.html',
  styleUrls: ['./learning-experience-operation.component.less']
})
export class LearningExperienceOperationComponent implements OnInit {
    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        startdate:null,
        enddate:null

    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            learnlevel : [null, Validators.required],//学历
            startdate: ['', Validators.required],//开始时间、进站时间
            enddate: ['', Validators.required],//结束时间，出站时间
            image : [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            degree :[''],//学位
            way:[''],//培训方式
            type :[1],//学习经历类型 1、学习经历2、博士后
            duration: [''],//年限
            academy: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//毕业院校
            profession: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//所学专业
            workstationname: this.fb.group({
                zh: [''],
                mn: [''],
                en: ['']
            }),//工作站名称
            researche: this.fb.group({
                zh: [''],
                mn: [''],
                en: ['']
            })//研究方向
        })
    }
    dictionaryList: any = {
        education: [],
        trainingmode:[],
        degree:[]

    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findlearningExperience,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.learningExperience
            let form = this.form;
            data.startdate = this.date.transform(data.startdate, 'yyyy-MM-dd')    
            data.enddate = this.date.transform(data.enddate, 'yyyy-MM-dd')    
            form.get('learnlevel')!.setValue(data.learnlevel?data.learnlevel.dictionary_value:null);
            form.get('startdate')!.setValue(data.startdate);  
            form.get('enddate')!.setValue(data.enddate);  
            form.get('way')!.setValue(data.way?data.way.dictionary_value:null);
            form.get('degree')!.setValue(data.degree?data.degree.dictionary_value:null);
            form.get('type')!.setValue(data.type);
            form.get('duration')!.setValue(data.duration);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('academy')!.setValue(data.academy?data.academy:{mn:null,zh:null,en:null});
            form.get('profession')!.setValue(data.profession?data.profession:{mn:null,zh:null,en:null});
            form.get('workstationname')!.setValue(data.workstationname?data.workstationname:{mn:null,zh:null,en:null})
            form.get('researche')!.setValue(data.researche?data.researche:{mn:null,zh:null,en:null})
            if (data.image !== null || data.image !== '') {
                let datas = data.image ? JSON.parse(`["${data.image}"]`) : null;
                if(datas){
                    this.updateMethods(datas)
                }
            }
        })
    }
    //文件
    updateMethods(data: any) {
        this.fileList = data.map((item: any, i: any) => {
            let index =item.lastIndexOf('\/');
            let text = item.substring(index + 1, item.length)
            return { url: `${item}`, uid: i, name: text }
        })
    }
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.timeEnd) {
            return false;
        }
        return startValue.getTime() > this.formBody.timeEnd.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.timeStart) {
            return false;
        }
        return endValue.getTime() <= this.formBody.timeStart.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.timeStart = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.timeEnd = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = open;
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'education',
            'trainingmode',
            'degree'
        ];

        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    handelChange(event) {

        if (event.fileList[0].response && event.fileList[0].response.data) {
            this.form.value.image = event.fileList[0].response.data[0].newName;
        }


    }
    filters: UploadFilter[] = [
        {
            name: 'type',
            fn: (fileList: UploadFile[]) => {
                console.log('fileList0033', fileList);

                const filterFiles = fileList.filter(w => ~['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'application/pdf'].indexOf(w.type));
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`请选择图片或者pdf`);
                    return filterFiles;
                }
                return fileList;
            }
        },

    ];
    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.startdate = this.date.transform(params.startdate, 'yyyy-MM-dd');
        params.enddate = this.date.transform(params.enddate, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addLearningExperience,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addLearningExperience.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')

                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addLearningExperience.msg)
            }

            that.form.reset()


        })

    }

    nzRemove = (file): boolean => {
        return true;
    }

    handlePreview = async (file: UploadFile) => {

    };
}

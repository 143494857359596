import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-orh-lottie',
  templateUrl: './orh-lottie.component.html',
  styleUrls: ['./orh-lottie.component.less']
})
export class OrhLottieComponent implements OnInit {
  @Input() width?: number = 500;
  @Input() height?: number = 500;
  @Input() path: string;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;

  constructor() {
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  ngOnInit(): void {
    this.lottieConfig = {
      path: this.path,
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

}

import gql from 'graphql-tag';

/** 
*查询公告
*/

const announcementData = gql`
	query (
        $page: Int!,
        $limit: Int!,
        $condition:SchoolCircularCondition
    ){
		SchoolCirculars (page: $page, limit: $limit,condition: $condition){
            code
            msg
            data {
                id
                title
                type{
                    name{
                        zh
                    }
                }
                TInformtions {
                   
                    name{
                        zh
                    }
                    
                }
                publisher{
                    name{
                        zh
                    }
                    
                  }
                created_at
            }
            total
		}
	}
`;

const findAnnouncementData = gql`
query ($id:Int!){
    SchoolCircular(id:$id){
        id
        title
        content
        type{
            id
            name{
                zh
            }
        }
        
        TInformtions {
            id
            name{
                zh
            }
            
        }
        url    
        publisher{
            name{
                zh
            }
            username
        }
        created_at    
    }
}
`
/** 
*添加公告
*/

const saveAnnouncement = gql`
    mutation addSchoolCircular(
        $inputData:schoolCircularInput
    ){
        addSchoolCircular(inputData:$inputData){
            code
            msg
        }
    }
`;

const teacherData = gql`
    query ($page: Int!,$limit: Int!, $condition: TeacherCondition
    ) {
        teachers(page: $page, limit: $limit,condition:$condition) {
            code
            msg
            data{
                id
                name {
                    zh
                    mn
                    en
                }
                user {
                    id
                    username
                    name {
                        zh
                        mn
                        en
                    }
                    idcard
                }
            }
        }
    }
`;

/** 
*删除公告
*/

const delAnnouncement = gql`
    mutation deleteSchoolCircular($id: [Int]!) {
        deleteSchoolCircular(id: $id) {
            code
            msg
        }
    }
    
`;
export {
    announcementData, findAnnouncementData,teacherData, saveAnnouncement, delAnnouncement
}
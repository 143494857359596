import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'orh-teacher-profile',
    templateUrl: './orh-teacher-profile.component.html',
    styleUrls: ['./orh-teacher-profile.component.less']
})
export class OrhTeacherProfileComponent implements OnInit {
    @Input() Condition: any;
    indexshow: boolean = true;
    nzShowPagination:boolean = true;
    constructor() { }

    ngOnInit() {
    }

}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BusinessService } from '../../service/business/business.service';
import { RequestService } from '../../service/request/request.service';
import { UtilsService } from '../../service/utils/utils.service';
import { HashService } from '../../service/hash/hash.service';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd';


@Component({
    selector: 'orh-folder',
    templateUrl: './orh-folder.component.html',
    styleUrls: ['./orh-folder.component.less']
})
export class OrhFolderComponent implements OnInit {
    @Input() fileType: string = null
    @Input() filefind: object | boolean;
    @Output() findData: EventEmitter<any> = new EventEmitter();
    @Output() uploadChange: EventEmitter<any> = new EventEmitter();
    userId: any;
    getUrl: any;
    show:boolean =false;
    public uploadList = [];
    nzBodyStyle = {
        width:'100%',
       'max-height': '75vh',
       'height': 'auto',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
    upLoadDisabled:boolean = false;
    isAllDisplayDataChecked:boolean = false;
    tabAllList: any[] = [];
    breadCrumb: any[] = [
        {
            path: '/1',
            name: '',
            parentRef: ''
        },
    ];
    icon: string = 'iconshangchuan';
    backgroundColor: any = {
        background: 'var(--antd-wave-shadow-color)',
        'box-shadow': 'var(--antd-wave-shadow-color) 0px 5px 10px 0px'
    };
    ImageUrl:any;
    paramsData: any = {
        page: 1,
        limit: 20,
        fileName: null,
        fileType: null,
        path: null,
        orderBy: 'CreateAt',
        sortBy: 'desc'
    };
    langfiles: any;
   
    
    omcheader = {
        token: ''
    };
    progressShow: boolean = false;
    isPreviewVisible: boolean = false;
    hashNum = '';
    listNum: boolean = false;
    previewDrawerShow: boolean = false;
    isuploadTag: boolean = false;
    loading: boolean = false;
    listShow: number = 1;
    totalSize: any;
    tabList: any[] = [];
    dataList: any[] = [];
    checkArray: any[] = [];
    listTotal: number = 0;
    progress: number = 0;
    data: any[] = [];
    path: string = '';
    fileNames: string = '';
    i: 0;
    uploaData: any;
    fileInfo: any;
    checkfile: any;
    newFileName = '';
    iconcolor:any;
    type:string =''
    tags: any[] = []
    constructor(
        private business: BusinessService,
        private http: RequestService,
        private utils: UtilsService,
        private date: DatePipe,
        private message: NzMessageService,
        private fileHash: HashService,
        private httpC: HttpClient,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.userId = this.business.getItem('userInfo').id;
        this.iconcolor = this.business.getItem('userInfo') ? this.business.getItem('userInfo').school.settings.themeSettings.zh : '#206F53';
        this.getUrl = '/' + this.userId + '/base';
        this.paramsData.path = this.getUrl;
        this.getParentId();
       // this.getDataList();
        this.getNum();
        this.omcheader.token = localStorage.getItem('token');
    }
    ngOnChanges(changes: SimpleChanges): void {
        // this.userId = this.business.getItem('userInfo').id;
        // this.getUrl = '/' + this.userId + '/base';
        // this.paramsData.path = this.getUrl;
        if (changes.filefind) {
            if (changes.filefind.currentValue !== false) {
                this.checkfile = changes.filefind.currentValue;
                console.log(this.checkfile);
                
                this.getDataList()
            }
        }
        this.userId = this.business.getItem('userInfo').id;
        this.getUrl = '/' + this.userId + '/base';
        this.paramsData.path = this.getUrl;
        if (changes.fileType) {
            if (this.fileType === 'file') {
                this.getDataList()
            } else if (this.fileType === 'image') {
                this.tabsClick(this.fileType)
            } else if (this.fileType === 'video') {
                this.tabsClick(this.fileType)
            } else {
                this.tags = []
                this.tags.push(this.fileType);
                // console.log('fileType',this.fileType);
                
                this.tagsClick(this.fileType)
            }

        } else {
            this.getDataList()
        }

    }
    //全选
    checkAll($event) {
        this.checkArray.length = 0;
        if ($event) {
            this.dataList.forEach(item => {
                item.checked = $event;
                this.checkArray.push(item.properties['sr:identifier']);
            })
            this.show = true;
        } else {
            this.checkArray.length = 0;
            this.dataList.forEach(item => {
                item.checked = $event;
            })
            this.show = false;
        }
    }
    // 名称搜索
    clickSearch() {
        // this.paramsData.fileName = this.fileNames;
        this.getDataList();
        this.getFilter();
    }
    handleCancel(){
        this.isuploadTag = false;
    }
    //返回上一级
    back(): void {
        let index = this.path.lastIndexOf('/');
        this.paramsData.orderBy = 'createAt';
        this.paramsData.sortBy = 'desc';
        this.paramsData.path = this.path.substring(0, index)
        this.path = this.path.substring(0, index);
        if (this.breadCrumb.length > 1) {
            this.breadCrumb.pop()
            this.getDataList()
        }
    }
    returnRename(evt){

    }
    //tag筛选
    tagsClick(tag: any) {
        
        
        this.http.get('resource/file/tag/filter', {
            tagIds: [tag]
        }).subscribe((res: any) => {
            this.dataList = res.data.map(el => {
                if (this.checkfile) {
                    if (this.checkfile.parentRef === el.properties['sr:identifier']) {
                        el.checked = true
                    } else {
                        el.checked = false
                    }
                } else {
                    el.checked = false
                }
                el.id = `${this.i}`,
                    el.status = true;

                el.name = el.name;
                el.type = el.type;
                el.mime_type = el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '';
                el.created = this.date.transform(el.created, 'yyyy-MM-dd');
                el.size = el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '';
                el.parentRef = el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '';
                el.osspath = el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '';
                el.thumbnail = el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null;
                el.convertStatus = el.properties['sr:convertStatus'];
                this.i++;
                return el;
            });
        })
    }
    //添加标签之后的返回数据
    postuploadTag(tag: any) {

        this.tags = tag
    }
    // 点击文件处理
    handleType2(el: any) {
        if (el.type === 'sr:smartfolder') {
            // 这个绝对是文件夹
            return 'iconFolder';
        } else {
            return this.handelImg(el.mime_type);
        }
    }
    // 按照文件大小排序
    sortLarge(sort?: any) {

        this.dataList = [];
        this.paramsData.orderBy = 'fileSize';
        if (sort) {
            this.paramsData.sortBy = sort;
        } else {
            if (this.paramsData.sortBy === 'asc') {
                this.paramsData.sortBy = 'desc';
            } else {
                this.paramsData.sortBy = 'asc';
            }
        }

        this.getDataList();
    }
    //上传文件之前先选择tag
    addUpload() {
        this.isuploadTag = true;
    }
    // 按照文件创建时间排序
    sortCreated(sort?: string) {

        this.dataList = [];
        this.paramsData.orderBy = 'createAt';
        if (sort) {
            this.paramsData.sortBy = sort;
        } else {
            if (this.paramsData.sortBy === 'asc') {
                this.paramsData.sortBy = 'desc';
            } else {
                this.paramsData.sortBy = 'asc';
            }
        }
        this.getDataList();
    }

    // 按照文件名排序
    sartName() {
        this.dataList = [];
        if (this.paramsData.sortBy === 'asc') {
            this.paramsData.sortBy = 'desc';
        } else if (this.paramsData.sortBy === 'desc') {
            this.paramsData.sortBy = 'asc';
        }
        this.paramsData.orderBy = 'fileName';

        this.getDataList();

    }
    // 获取上传的文件名称
    customReq = (res: any) => {
        
        
        this.listNum = true;
        // this.uploadChange.emit(false);
        const data = {
            name: res.file.name,
            progress: 0,
            type: 'primary',
            status: false
        };
        this.uploadList.push(data);
        this.getFileHash(res.file, data);
    }
    getFileHash(file: any, data: any): void {
        this.isuploadTag= false;
        this.fileHash.getHash(file).then(str => {
            const name = file.name;
            this.http.get('resource/file/upload/url', {
                objectName: str
            }).subscribe((el: any) => {
                const url = el.data;
                const newUrl = url.replace('http', 'https');
                if (el.extra && el.extra.status) {
                    data.progress = 100;
                    this.uploadFileSave(name, str, data);
                } else {
                    this.uploadFile(file, newUrl, name, str, data);
                }
            });
        });
    }
    uploadFileSave(fileName: string, objectName: string, data: any) {
        // this.isuploadTag = false;
        this.http.post('resource/file/upload/save', {
            objectName,
            fileName,
            parentRef: this.breadCrumb[this.breadCrumb.length - 1].parentRef
        }).subscribe((res: any) => {
            if (res.code === 200) {

                this.postTagData(res.data.properties['sr:identifier'])
                this.handleUploadList();
            } else {
                data.type = 'error';
                this.message.error(res.msg);
                this.handleUploadList();
            }
            this.cdr.detectChanges();
        });
    }
    postTagData(uuid: string) {
        this.http.get('resource/file/tag/save', {
            tagIds: this.tags,
            uuids: uuid
        }).subscribe((item) => {

            if (item.code === 200) {
                if (this.fileType !== null && this.fileType !== 'file') {
                    this.tagsClick(this.tags)
                } else {
                    this.getDataList();
                }
            }else{
                this.message.error(item.message)
            }

        })
    }
    // 获取上传进度条
    uploadFile(event: any, url: string, name: string, hashStr: string, data: any): void {
        const xhrs = new XMLHttpRequest();
        xhrs.open('put', url, true);
        xhrs.setRequestHeader('Content-Type', 'application/octet-stream');
        xhrs.onreadystatechange = (e: any) => {
            if (xhrs.readyState === 4) {
                this.uploadFileSave(name, hashStr, data);
            }
        };
        xhrs.upload.onprogress = (ev: any) => {
            if (ev.lengthComputable) {
                const progress = Math.round(100 * ev.loaded / ev.total);
                data.progress = progress;
            }
        };
        xhrs.send(event);
    }
    handleUploadList(): void {
        const bool = this.uploadList.find(el => el.progress !== 100);
        if (!bool) {
            const data = this.uploadList.filter(el => el.type === 'error');
            this.uploadList = [...data];
            // this.uploadChange.emit(true);
            // this.getDataList();
        }
    }
    // 接收标签数据
    postTag(evt: any) {

        this.tags = evt
    }
    // postTagData(uuid: string) {
    //     this.http.get('resource/file/tag/save', {
    //         tagIds: this.tags,
    //         uuids: uuid
    //     }).subscribe((item) => {
    //         if (item.code === 200) {
    //             if (this.fileType !== null && this.fileType !== 'file') {
    //                 console.log('file',this.fileType);
                    
    //                 this.tagsClick(this.tags)
    //             } else {
    //                 this.getDataList();
    //             }

    //         }

    //     })
    // }
    // 点击面包屑
    breadLink(item) {
        while (true) {
            if (item.path === this.breadCrumb[this.breadCrumb.length - 1].path) {
                break;
            } else {
                this.breadCrumb.pop();
            }
        }
        this.uploaData = {
            parentRef: item.parentRef
        };
        this.path = item.path;
        this.paramsData.path = item.path;
        this.paramsData.page = 1;
        this.paramsData.limit = 20;
        this.getDataList();
    }
    // 转换文件大小
    formatFileSize(fileSize: number) {
        let number: number;
        let size: string;
        if (fileSize) {
            if (fileSize < 1024) {
                return fileSize + 'B';
            } else if (fileSize < (1024 * 1024)) {
                number = fileSize / 1024;
                size = number.toFixed(2);
                return size + 'KB';
            } else if (fileSize < (1024 * 1024 * 1024)) {
                number = fileSize / (1024 * 1024);
                size = number.toFixed(2);
                return size + 'M';
            } else {
                number = fileSize / (1024 * 1024 * 1024);
                size = number.toFixed(2);
                return size + 'GB';
            }
        } else {
            return '—';
        }
    }
    // 名称搜索
    nameSearch() {
        this.paramsData.fileName = this.fileNames;
        this.getDataList();
        this.getFilter();
    }
    // 处理文件类型缩略图
    handelImg(type) {
        let tIcon = 'iconText_File';
        if (type !== null) {
            switch (true) {
                case /image/i.test(type):
                    tIcon = 'iconJPG';
                    break;
                case /video/i.test(type):
                    tIcon = 'iconVideo_File';
                    break;
                case /audio/i.test(type):
                    tIcon = 'iconMP3';
                    break;
                case /word/i.test(type):
                    tIcon = 'iconDOC';
                    break;
                case /excel/i.test(type):
                    tIcon = 'iconXLS';
                    break;
                case /ppt/i.test(type):
                    tIcon = 'iconPPT';
                    break;
                case /pdf/i.test(type):
                    tIcon = 'iconPDF';
                    break;
                case /zip/i.test(type):
                    tIcon = 'iconZIP';
                    break;
                case /text/i.test(type):
                    tIcon = 'iconTXT';
                    break;
                default:
                    tIcon = 'iconDOC';
            }
        }
        return tIcon;
    }
    dbclick(data: any) {
        if (data.path) {
            if (data.type === 'sr:smartfolder') {
                this.breadCrumb.push({
                    name: data.name,
                    path: data.path,
                    parentRef: data.parentRef
                });
                this.path = data.path;
                this.paramsData.path = data.path;
                this.getDataList();
                this.uploaData = {
                    parentRef: data.parentRef
                };
            } else {
                this.fileInfo = data;
                if (data.mime_type === 'image' || data.mime_type === 'video' || data.mime_type === 'audio') {
                    this.openFile();
                } else {

                    if (data.properties['sr:convertStatus'] === 'complete') {
                        this.openFile();
                    } else {
                        this.message.error(this.langfiles.file_not_preview);
                    }
                }
            }
        }
    }
    // 打开文件预览
    openFile() {

        this.isPreviewVisible = true;
        this.loading = true;
    }
    //取消文件预览
    previewCancel(){
        this.isPreviewVisible = false;
    }
    // 文件类型的文件数量统计
    getNum() {
        this.http.get('resource/file/count').subscribe((item: any) => {
            const res = item.data;
            this.totalSize = item.data;
            this.tabList = [
                {
                    label: '全部',
                    value: null,
                    number: res.countTotal,
                    size: res.sizeTotal,
                    icon: 'iconwenjianlan'
                },
                {
                    label: '图片',
                    value: 'image',
                    number: res.countImage,
                    size: res.sizeImage,
                    icon: 'icontupian3'
                },
                {
                    label: '视频',
                    value: 'video',
                    number: res.countVideo,
                    size: res.sizeVideo,
                    icon: 'iconshipin3'
                },
                {
                    label: '音频',
                    value: 'audio',
                    number: res.countAudio,
                    size: res.sizeAudio,
                    icon: 'iconyinpin1'
                },
                {
                    label: '文档',
                    value: 'docs',
                    number: res.countDocs,
                    size: res.sizeDocs,
                    icon: 'icondoc'
                },
                {
                    label: `Ppt`,
                    value: 'ppt',
                    number: res.countPPT,
                    size: res.sizePPT,
                    icon: 'iconPPT2'
                },
                {
                    label: `Xls`,
                    value: 'xls',
                    number: res.countXls,
                    size: res.sizePPT,
                    icon: 'iconxlsx'
                },
                {
                    label: `Pdf`,
                    value: 'pdf',
                    number: res.countPDF,
                    size: res.sizePDF,
                    icon: 'iconpdf'
                },
                {
                    label: `Zip`,
                    value: 'zip',
                    number: res.countZip,
                    size: res.sizeZip,
                    icon: 'iconZip1'
                },
                {
                    label: 'Text',
                    value: 'text',
                    number: res.countText,
                    size: res.sizeText,
                    icon: 'iconwenben'
                },
                {
                    label: '其他',
                    value: 'other',
                    number: res.countOther,
                    size: res.sizeOther,
                    icon: 'iconwenjianjia'
                },
            ];
            this.tabAllList = [
                {
                    label: `全部`,
                    value: null,
                    number: res.countTotal,
                    size: res.sizeTotal,
                    icon: 'iconziyuan_suoyouwenjian'
                }];

        });
    }
    // 列表和图表切换
    cilckList(num: number) {
        this.listNum = !this.listNum;

        this.listShow = num;
        this.listTotal = this.dataList.length;
    }
    getDataList() {
        this.dataList = [];
        this.path = this.paramsData.path;
        const params = this.utils.handelCondition(this.paramsData);
        this.http.post(`resource/file/myfiles`, params).subscribe((res: any) => {
            if (res.code === 200) {
                let datas = []
                res.data.map(el => {

                    if (this.checkfile) {
                        if (this.checkfile.parentRef === el.properties['sr:identifier']) {
                            el.checked = true
                        } else {
                            el.checked = false
                        }
                    } else {
                        el.checked = false
                    }
                    el.id = `${this.i}`,
                        el.status = true;

                    el.name = el.name;
                    el.type = el.type;
                    el.mime_type = el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '';
                    el.created = this.date.transform(el.created, 'yyyy-MM-dd');
                    el.size = el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '';
                    el.parentRef = el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '';
                    el.osspath = el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '';
                    el.thumbnail = el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null;
                    el.convertStatus = el.properties['sr:convertStatus'];
                    this.i++;
                    // if (el.properties['sr:fileType'] === 'image' || el.properties['sr:fileType'] === 'video' || el.properties['sr:fileType'] === 'audio' || el.type === 'sr:smartfolder') {
                    //     datas.push(el)
                    // } else {
                    //     if (el.properties['sr:convertStatus'] === 'complete') {
                    //         datas.push(el)
                    //     } else {
                    //         return false;
                    //     }  
                    // }
                    datas.push(el)
                });
                this.dataList = datas;
               
                this.listTotal = this.dataList.length;
                this.getNum();
            }else{
                this.message.error(res.msg)
            }
        });
    }

    // tabs 发生变化
    tabsClick(event: any) {
        // this.breadCrumb = [];
        this.dataList = [];
        if(event){
            if (event === 'image' || event === 'video') {
                this.listNum = true;
                this.listShow = 2;
    
            } else {
                this.listShow = 1;
                this.listNum = false;
            }
            this.paramsData.path = '/' + this.userId + '/base';
            this.paramsData.fileType = event;
            this.getFilter();
        }else{
            this.paramsData.path = '/' + this.userId + '/base';
            //this.paramsData.fileType = event;
            this.getDataList();
        }
        

       

    }

    // 文件类型筛选
    getFilter() {
      
        this.dataList = [];
        this.http.post('resource/file/filter', this.paramsData).subscribe((res) => {
            if (res.code === 200) {
                const datas = [];
                res.data.map((el) => {
                    if (this.checkfile) {
                        if (this.checkfile.parentRef === el.properties['sr:identifier']) {
                            el.checked = true
                        } else {
                            el.checked = false
                        }
                    } else {
                        el.checked = false
                    }
                    el.status = true;

                    el.name = el.name;
                    el.type = el.type;
                    el.mime_type = el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '';
                    el.created = this.date.transform(el.created, 'yyyy-MM-dd');
                    el.size = el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '';
                    el.parentRef = el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '';
                    el.osspath = el.properties['sr:osspath'] ? el.properties['sr:osspath'] : '';
                    el.thumbnail = el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null;
                    el.convertStatus = el.properties['sr:convertStatus'];
                    this.i++;
                    // if (el.properties['sr:fileType'] === 'image' || el.properties['sr:fileType'] === 'video' || el.properties['sr:fileType'] === 'audio') {
                    //     datas.push(el)
                    // } else {
                    //     if (el.properties['sr:convertStatus'] === 'complete') {
                    //         datas.push(el)
                    //     } else {
                    //         return false;
                    //     }
                        
                    // }
                    datas.push(el)
                });
                this.dataList = datas;
                this.listTotal = res.total;
            }else{
                this.message.error(res.msg)
            }
        });
    }
    // 获取底层文件
    getParentId() {
        this.breadCrumb = [];
        this.http.post('resource/file/myfiles', {
            path: '/' + this.userId
        }).subscribe((res: any) => {
            if (res.code === 200) {
                this.data = res.data.map((el) => {
                    if (el.name === 'bin') {
                        return {
                            mn_name: '回收箱',
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null
                        };
                    } else if (el.name === 'base') {
                        return {
                            mn_name: '全部',
                            name: el.name,
                            type: el.type,
                            mime_type: el.properties['sr:fileType'] ? el.properties['sr:fileType'] : '',
                            path: el.path,
                            created: this.date.transform(el.created, 'yyyy-MM-dd'),
                            size: el.properties['sr:fileSize'] ? el.properties['sr:fileSize'] : '',
                            parentRef: el.properties['sr:identifier'] ? el.properties['sr:identifier'] : '',
                            thumbnail: el.properties['sr:thumbnail'] ? el.properties['sr:thumbnail'] : null
                        };
                    }
                });
                const temp = this.data[0];
                this.data[0] = this.data[1];
                this.data[1] = temp;
                // this.fileClick(this.data[0].path, 0)
                const breadCrumb = {
                    name: this.data[0].mn_name,
                    path: this.data[0].path,
                    parentRef: this.data[0].parentRef
                };
                this.uploaData = {
                    parentRef: breadCrumb.parentRef
                };
                this.breadCrumb.push(breadCrumb);
            }else{
                this.message.error(res.msg)
            }
        });
    }
    checkedChange(data: boolean, items: any) {
        items.checked = data;
        const index = this.checkArray.indexOf(items.parentRef);

        if (items.checked) {
            if (index === -1) {
                this.checkArray.push(items);
            }

        } else {
            if (index >= 0) {
                this.checkArray.splice(index, 1);
            }
        }
        console.log(this.checkArray);
        
        this.findData.emit(this.checkArray)
    }
    checkedChangeBlock(data) {
        data.checked = data.checked;
        const index = this.checkArray.indexOf(data.parentRef);

        if (data.checked) {
            if (index === -1) {
                this.checkArray.push(data);
            }

        } else {
            if (index >= 0) {
                this.checkArray.splice(index, 1);
            }
        }
        this.findData.emit(this.checkArray)
    }
    close() {
        this.previewDrawerShow = false
    }
    // 改变分页
    limitChange(event: any): void {
        this.paramsData.limit = event;
        this.getDataList();
    }

    pageChange(event: any): void {
        this.paramsData.page = event;
        this.getDataList();
    }

    // 改变分页
    limitChanges(event: any): void {
        this.paramsData.limit = event;
        this.getFilter();
    }

    pageChanges(event: any): void {
        this.paramsData.page = event;
        this.getFilter();
    }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd';
import { typeList } from 'src/app/graphQl/base/dictionary';
import { addGetFunding, findgetFunding } from 'src/app/graphQl/teachers/teacher-profile/work';
import { GraphqlServiceService } from 'src/app/service/graphqlService/graphql-service.service';
import Config from '../../../../config';
@Component({
  selector: 'funding-operation',
  templateUrl: './funding-operation.component.html',
  styleUrls: ['./funding-operation.component.less']
})
export class FundingOperationComponent implements OnInit {

    @Input() teacherId: any;
    @Input() condition: any;
    @Output() dataStatus: EventEmitter<any> = new EventEmitter();
    form: FormGroup;
    loading = false;
    status: number = 1;
    avatarUrl: string;
    ImageUrl = Config.baseUrl + 'base/file/ossupload';
    fileList: any[] = [];
    endOpen:boolean = false;
    formBody:any={
        time:null,
        entime:null
    }
    constructor(
        private fb: FormBuilder,
        private apollo: Apollo,
        private msg: NzMessageService,
        private graph: GraphqlServiceService,
        private date: DatePipe,
    ) {
        this.form = this.fb.group({
            teacher_id: [''],
            fundyear : [null, Validators.required],//资助年限
            time: ['', Validators.required],//开始时间
            projectmoney: ['', Validators.required],//资助金额
            image : [''],//证书扫描件(上传图片)
            shenhe: [1],//审核(单选1、未审核2、审核通过)
            name: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//留学学校名称
            fundunit: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            }),//资助单位
            fundcontent: this.fb.group({
                zh: ['', Validators.required],
                mn: [''],
                en: ['']
            })//资助内容
        })
    }
    dictionaryList: any = {
        financedlife: [],
    };
    ngOnInit() {
        this.dictionaryData()
        if (this.condition) {
            this.findData()
        }
    }

    //单条
    findData() {
        let that = this;
        const params = {
            query: findgetFunding,
            variables: { id: that.condition },
        }
        that.graph.GQuery(params).subscribe((res: any) => {
            let data = res.data.getFunding
            let form = this.form;
            data.time = this.date.transform(data.time, 'yyyy-MM-dd');  
            form.get('time')!.setValue(data.time);   
            form.get('projectmoney')!.setValue(data.projectmoney);  
            form.get('fundyear')!.setValue(data.fundyear);
            form.get('shenhe')!.setValue(data.shenhe);
            form.get('name')!.setValue(data.name?data.name:{mn:null,zh:null,en:null});
            form.get('fundunit')!.setValue(data.fundunit?data.fundunit:{mn:null,zh:null,en:null});
            form.get('fundcontent')!.setValue(data.fundcontent?data.fundcontent:{mn:null,zh:null,en:null});
           
        })
    }
    
    //设定开始时间
    disabledStartDate = (startValue: Date): boolean => {
        if (!startValue || !this.formBody.entime) {
            return false;
        }
        return startValue.getTime() > this.formBody.entime.getTime();
    };
    //禁用开始时间之前的时间
    disabledEndDate = (endValue: Date): boolean => {
        if (!endValue || !this.formBody.time) {
            return false;
        }
        return endValue.getTime() <= this.formBody.time.getTime();
    };
    //改变开始时间
    onStartChange(date: Date): void {
        this.formBody.time = date;
    }
    //改变结束时间
    onEndChange(date: Date): void {
        this.formBody.entime = date;
    }
    //开始时间的打开事件
    handleStartOpenChange(open: boolean): void {
        if (!open) {
            this.endOpen = true;
        }
    }
    //结束时间的打开事件
    handleEndOpenChange(open: boolean): void {        
        this.endOpen = open;
    }
    onClose(){
        this.dataStatus.emit(false)
    }
    //数据字典
    dictionaryData(): void {
        let that = this;
        let arr: Array<string> = [
            'financedlife'
        ];
        arr.forEach((el: string) => {
            that.apollo
                .watchQuery({
                    query: typeList,
                    variables: {
                        dictionary_code: el
                    }
                })
                .valueChanges.subscribe((res: any) => {
                    if (res.data.Dictionarys.code === 200) {
                        that.dictionaryList[el] = res.data.Dictionarys.data;
                    }else{
                        this.msg.error(res.data.Dictionarys.msg)
                    }
                });
        });
    }

    submit(): void {
        let { valid } = this.form;
        if (valid) {
            this.postData(this.form.value);
        }
    }
    //提交数据
    postData(params: any) {
        let that = this;
        if (this.fileList.length > 0) {
            let arr: any
            this.fileList.map(item => {
                if (item.response) {
                    arr = item.response.data[0].newName
                }
                if (!item.response) {
                    arr = item.url
                }
            })
            params.image = arr;
        }
        params.time = this.date.transform(params.time, 'yyyy-MM-dd');
        params.teacher_id = this.teacherId

        const mutationParams = {
            mutation: addGetFunding,
            variables: {
                inputData: Object.assign(
                    {
                        id: that.condition ? that.condition : null
                    },
                    params
                )
            },
            policy: 'no-cache'
        };
        this.graph.GMutation(mutationParams).subscribe((res: any) => {
            if (res.data.addGetFunding.code === 200) {
                if (that.condition) {
                    that.msg.success('修改成功')
                } else {
                    that.msg.success('添加成功')
                }
                that.dataStatus.emit(true)

            } else{
                this.msg.error(res.data.addGetFunding.msg)
            }
            that.form.reset()
        })
    }
}
